import { action, observable, runInAction } from "mobx";
import Perm from "../../../app/Perm";
import ClientFactory from "../../../app/WebAPIClientFactory";
import {
    PagedResultWithAreaOfListOfStockInAndOutRecordDTO,
    StockInAndOutRecordDTO,
    StockInAndOutRecordType,
    StoneArtifactType
} from "../../../app/WebAPIClients";
import PermCtl from "../../../components/PermCtrl";
import Util from "../../../components/Util";
import Base from "../../../stores/base";

export interface IFilterData {
    bundleSelectBlockNumber?: string;
    bundleSelectBlockNumberFromQuarry?: string;
    bundleStockingAreaIds?: number[];
    bundleWarehouse?: string[];
    bundleStartDate?: Date;
    bundleEndDate?: Date;
    bundleNo?: number;
    bundleRecordType?: StockInAndOutRecordType;
    slabSelectBlockNumber?: string;
    slabSelectBlockNumberFromQuarry?: string;
    sequenceNumber?: number;
    slabStockingAreaIds?: number[];
    slabWarehouse?: string[];
    slabStartDate?: Date;
    slabEndDate?: Date;
    slabRecordType?: StockInAndOutRecordType;
    tpcCaseNumber?: string;
    tpcProjectNumber?: string;
    tpcStockingAreaIds?: number[];
    tpcWarehouse?: string[];
    tpcStartDate?: Date;
    tpcEndDate?: Date;
    tpcRecordType?: StockInAndOutRecordType;
}
class Store extends Base {
    @observable
    public filterData: IFilterData = {};
    @observable
    public recordList: StockInAndOutRecordDTO[] = [];
    @observable
    public totalArea: number = 0;
    @observable
    public stoneArtifactType: StoneArtifactType = PermCtl.isAuthorized(Perm.S_R)
        ? StoneArtifactType.StoneBundle
        : PermCtl.isAuthorized(Perm.T_R)
        ? StoneArtifactType.Tile
        : StoneArtifactType.StoneBundle;

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }

    @action
    public async reloadData() {
        const filterData = this.filterData;
        const client = ClientFactory.getStockInAndOutRecordClient();
        const callAPI = async () => {
            let content: PagedResultWithAreaOfListOfStockInAndOutRecordDTO = null;
            switch (this.stoneArtifactType) {
                case StoneArtifactType.StoneBundle:
                    const bundleWarehouseId = Util.parseToInt(
                        Util.getCascaderValue(filterData.bundleWarehouse, 1)
                    );
                    content = await client.getAllForBundle(
                        filterData.bundleSelectBlockNumber,
                        filterData.bundleSelectBlockNumberFromQuarry,
                        bundleWarehouseId ? [bundleWarehouseId] : undefined,
                        filterData.bundleStockingAreaIds,
                        filterData.bundleNo,
                        filterData.bundleRecordType,
                        Util.getValidDate(filterData.bundleStartDate),
                        Util.getValidDate(filterData.bundleEndDate),
                        this.pageSize,
                        this.pageNo
                    );
                    break;
                case StoneArtifactType.Slab:
                    const slabWarehouseId = Util.parseToInt(
                        Util.getCascaderValue(filterData.slabWarehouse, 1)
                    );
                    content = await client.getAllForSlab(
                        filterData.slabSelectBlockNumber,
                        filterData.slabSelectBlockNumberFromQuarry,
                        slabWarehouseId ? [slabWarehouseId] : undefined,
                        filterData.slabStockingAreaIds,
                        filterData.sequenceNumber,
                        filterData.slabRecordType,
                        Util.getValidDate(filterData.slabStartDate),
                        Util.getValidDate(filterData.slabEndDate),
                        this.pageSize,
                        this.pageNo
                    );
                    break;
                case StoneArtifactType.Tile:
                    const tpcWarehouseId = Util.parseToInt(
                        Util.getCascaderValue(filterData.tpcWarehouse, 1)
                    );
                    content = await client.getAllForTilePackingCase(
                        filterData.tpcCaseNumber,
                        filterData.tpcProjectNumber,
                        tpcWarehouseId ? [tpcWarehouseId] : undefined,
                        filterData.tpcStockingAreaIds,
                        filterData.tpcRecordType,
                        Util.getValidDate(filterData.tpcStartDate),
                        Util.getValidDate(filterData.tpcEndDate),
                        this.pageSize,
                        this.pageNo
                    );
                    break;
            }
            runInAction(() => {
                this.recordList = content?.result;
                this.totalArea = content?.totalArea;
                this.setPagingInfo(content?.pagingInfo);
            });
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public setStoneArtifactType(stoneArtifactType: StoneArtifactType) {
        this.stoneArtifactType = stoneArtifactType;
    }
}

export default new Store();
