import {
    Checkbox,
    Col,
    Collapse,
    Form,
    FormInstance,
    Input,
    message,
    Pagination,
    Row,
    Select,
    Table
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
    CustomerForListDTO,
    IShippingOrderForListDTO,
    SalesOrderDetailDTO
} from "../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCheckboxGroup,
    formItemLayoutForCol,
    formItemLayoutForSearch,
    SlabExportOptionName
} from "../../components/Consts";
import ErrorHandler from "../../components/ErrorHandler";
import ExportSelector from "../../components/ExportSelector";
import ExportUtil from "../../components/ExportUtil";
import PermCtl from "../../components/PermCtrl";
import "./PageSPOList.styl";
import Store from "./store";

// tslint:disable-next-line:no-submodule-imports
import { ColumnProps } from "antd/lib/table";
import Perm from "../../app/Perm";
import SearchButtonGroup from "../../components/SearchButtonGroup";

const CheckboxGroup = Checkbox.Group;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const { Customers, ShippingOrderStatus, SlabExportOption } = Consts;

declare interface IPageSOListProps extends RouteComponentProps<{}> {}
declare interface IPageSOListState extends IActionErrorState {
    customers: CustomerForListDTO[];
}

@observer
class PageSOList extends React.Component<IPageSOListProps, IPageSOListState> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            customers: []
        };
    }

    public async componentDidMount() {
        const t = this;
        const customers = await Customers.getCustomers();
        t.setState({ customers });
        this.loadData();
    }

    public render() {
        const t = this;
        const s = t.state;
        const sl = Store.shippingOrderList;
        const filterData = Store.filterData;
        const pagingInfo = Store.pagingInfo;

        const checkBoxOptions = Consts.ShippingOrderStatus.data.map(status => {
            return { label: status.text, value: status.value.toString() };
        });

        const customerOptions = s.customers.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        const columns: Array<ColumnProps<IShippingOrderForListDTO>> = [
            {
                title: "装车单号",
                dataIndex: "orderNumber",
                key: "orderNumber",
                align: "center"
            },
            {
                title: "车牌号",
                dataIndex: "licensePlateNumber",
                key: "licensePlateNumber",
                align: "center"
            },
            {
                title: "客户名称",
                dataIndex: "customerName",
                key: "customerName",
                align: "center"
            },
            {
                title: "订单编号",
                dataIndex: "salesOrderNumber",
                key: "salesOrderNumber",
                align: "center"
            },
            {
                title: "工程名称",
                dataIndex: "projectName",
                key: "projectName",
                align: "center"
            },
            {
                title: "装车类型",
                dataIndex: "itemType",
                key: "itemType",
                align: "center"
            },
            {
                title: "货物数量",
                dataIndex: "itemsCount",
                key: "itemsCount",
                align: "center"
            },
            {
                title: "总金额",
                dataIndex: "totalAmount",
                key: "totalAmount",
                render: amount => {
                    return amount.toFixed(2);
                }
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                render: status => {
                    return ShippingOrderStatus.getStatus(status).text;
                }
            }
        ];

        if (
            PermCtl.isAnyAuthorized([
                Perm.SPO_S_E,
                Perm.SPO_B_E,
                Perm.SPO_T_E,
                Perm.S_CS_E
            ])
        ) {
            const actionColumns = [
                {
                    title: "操作",
                    key: "action",
                    render: (text, record: IShippingOrderForListDTO) => (
                        <div>
                            {PermCtl.isAnyAuthorized([
                                Perm.SPO_S_E,
                                Perm.SPO_B_E,
                                Perm.SPO_T_E
                            ]) ? (
                                <span>
                                    <a
                                        href="#"
                                        onClick={t.handlePrint.bind(
                                            t,
                                            record.id
                                        )}
                                    >
                                        导出装车单
                                    </a>
                                </span>
                            ) : null}
                            {PermCtl.isAuthorized(Perm.S_CS_E) &&
                            record.itemType.includes("大板") ? (
                                <span>
                                    <a
                                        href="#"
                                        type="primary"
                                        style={{
                                            marginLeft: "15px",
                                            marginTop: "6px",
                                            color: "rad"
                                        }}
                                        onClick={t.handlePrintBundles.bind(
                                            t,
                                            record
                                        )}
                                    >
                                        导出码单
                                    </a>
                                </span>
                            ) : null}
                        </div>
                    )
                }
            ];
            columns.push(...actionColumns);
        }

        return (
            <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            onFinish={t.handleSearch}
                            ref={this.formRef}
                            name={"spoAdvancedSearchForm"}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                <Col span={19}>
                                    <FormItem
                                        {...formItemLayoutForCheckboxGroup}
                                        label="状态"
                                    >
                                        <CheckboxGroup
                                            options={checkBoxOptions}
                                            value={
                                                filterData.selectedStatusCodes
                                            }
                                            onChange={
                                                t.handleSPOStatusCheckBoxChanged
                                            }
                                        />
                                    </FormItem>
                                </Col>
                                {PermCtl.isAuthorized(Perm.S_CS_E) ? (
                                    <Col
                                        span={5}
                                        style={{
                                            textAlign: "right",
                                            marginTop: "10px"
                                        }}
                                    >
                                        <ExportSelector />
                                    </Col>
                                ) : null}
                            </Row>
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="客户名称">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择客户"
                                            value={filterData.customerIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "customerIds"
                                            )}
                                        >
                                            {customerOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="车牌号">
                                        <Input
                                            placeholder="请输入车牌号"
                                            value={filterData.plateNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "plateNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="装车单号">
                                        <Input
                                            placeholder="请输入装车单号"
                                            value={filterData.orderNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "orderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="订单编号">
                                        <Input
                                            placeholder="请输入订单编号"
                                            value={filterData.salesOrderNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "salesOrderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div>
                    <Table
                        columns={columns}
                        dataSource={sl}
                        pagination={false}
                        rowKey={"id"}
                    />
                </div>
                {pagingInfo ? (
                    <div style={{ marginTop: "10px", textAlign: "right" }}>
                        <Pagination
                            total={pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString}
                            pageSize={pagingInfo.pageSize}
                            current={pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private getTotalRecordString = (): string => {
        return `共 ${Store.pagingInfo.totalRecordCount} 个装车单`;
    };

    private async loadData() {
        await Store.loadData();
    }

    private handlePrint(id: number) {
        const printAPI = "ShippingOrder/Print?id=" + id;
        message.info("正在导出装车单以供下载并打印...");
        ExportUtil.setExportUrl(printAPI);
    }

    private handleSPOStatusCheckBoxChanged = (
        selectedStatusCodes: number[]
    ) => {
        Store.updataFilterData({ ...Store.filterData, selectedStatusCodes });
    };

    private handleChange(label: string, value) {
        Store.updataFilterData({ ...Store.filterData, [label]: value });
    }

    private handleInputChange(label: string, event) {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    }

    private handleSearch = async () => {
        Store.setPageNo(1);
        await this.loadData();
    };

    private handlePaginationChange = async (currentPageNo: number) => {
        Store.setPageNo(currentPageNo);
        await this.loadData();
    };

    private handleClearSearchCriteria = async () => {
        Store.updataFilterData({});
        await this.loadData();
    };

    private getExportOptionValue(optionName: SlabExportOptionName) {
        return SlabExportOption.getSavedExportOptionValue(optionName);
    }

    private async handlePrintBundles(shippingOrder: IShippingOrderForListDTO) {
        const t = this;
        if (!shippingOrder) {
            message.info("请选择需要导出的内容！");
            return false;
        }

        if (shippingOrder.itemsCount === 0) {
            ErrorHandler.handleErr(
                "此装车单没有装车明细，不能打印码单！",
                null
            );
            return false;
        }

        const slabSheetType = t.getExportOptionValue(
            SlabExportOptionName.SlabSheetType
        );
        const exportGrade = t.getExportOptionValue(
            SlabExportOptionName.ExportGrade
        );
        const exportNotes = t.getExportOptionValue(
            SlabExportOptionName.ExportNotes
        );
        if (!slabSheetType || !exportGrade || !exportNotes) {
            message.error("请指定码单导出选项!");
            return;
        }

        await Store.getShippingItems(shippingOrder.id);
        if (!Store.hasError) {
            const items: SalesOrderDetailDTO[] = Store.shippingOrderItems;
            const bundleIds = [];
            if (items) {
                items.forEach(it => {
                    if (
                        typeof it.bundleId !== "undefined" &&
                        it.bundleId !== null
                    ) {
                        bundleIds.push(it.bundleId);
                    }
                });
            }

            if (bundleIds.length === 0) {
                ErrorHandler.handleErr("装车单中没有扎数据，请确认", null);
                return;
            }

            let bundleIdList = "";
            bundleIds.forEach(b => {
                bundleIdList += "bundleIds=" + b + "&";
            });
            const bdIds = bundleIdList.substring(0, bundleIdList.length - 1);
            const printAPI =
                "StoneBundle/Print?" +
                bdIds +
                "&type=" +
                slabSheetType +
                "&exportGrade=" +
                exportGrade +
                "&exportNotes=" +
                exportNotes;
            message.info(
                "正在导出销售订单：" + shippingOrder.orderNumber + "的码单..."
            );
            ExportUtil.setExportUrl(printAPI);
        }
    }
}

export default PageSOList;
