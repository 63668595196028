import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import {
    SalesOrderDetailDTO,
    ShippingOrderForListDTO
} from "../../app/WebAPIClients";
import Base from "../../stores/base";

export interface IFilterData {
    selectedStatusCodes?: number[];
    salesOrderNumber?: string;
    orderNumber?: string;
    plateNumber?: string;
    customerIds?: number[];
}

class Store extends Base {
    @observable
    public shippingOrderList: ShippingOrderForListDTO[] = [];
    @observable
    public shippingOrderItems: SalesOrderDetailDTO[] = [];
    @observable
    public filterData: IFilterData = {};

    @action
    public async loadData() {
        const t = this;
        const filterData = t.filterData;
        const client = ClientFactory.getShippingOrderClient();
        const callAPI = async () => {
            const content = await client.getAll(
                filterData.orderNumber,
                filterData.salesOrderNumber,
                filterData.customerIds,
                filterData.selectedStatusCodes,
                filterData.plateNumber,
                t.pageSize,
                t.pageNo
            );
            runInAction(() => {
                this.shippingOrderList = content.result;
            });
            this.setPagingInfo(content.pagingInfo);
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async getShippingItems(shippingOrderId: number) {
        const client = ClientFactory.getShippingOrderClient();
        const callAPI = async () => {
            const result = await client.getShippingItems(shippingOrderId);
            runInAction(() => {
                this.shippingOrderItems = result;
            });
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }
}

export default new Store();
