import {
    Cascader,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Form,
    FormInstance,
    Input,
    message,
    Pagination,
    Row,
    Table
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import Perm from "../../../app/Perm";
import { StoneArtifactType, WarehouseType } from "../../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCheckboxGroup,
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../components/Consts";
import ExportUtil from "../../../components/ExportUtil";
import PermCtl from "../../../components/PermCtrl";
import SearchButtonGroup from "../../../components/SearchButtonGroup";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import StockingAreaUtil from "../../../components/StockingAreaUtil";
import TransferOrderUtil from "../../../components/TransferOrderUtil";
import { ITransferOrderListWithDetails } from "../../../components/type";
import Util from "../../../components/Util";
import Store from "./store";

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const CheckboxGroup = Checkbox.Group;
const {
    StoneCategories,
    StoneGrades,
    Factories,
    Warehouses,
    ApprovalStatus
} = Consts;

declare interface IBlockTOListProps extends React.Props<PageBlockTOList> {}

declare interface IBlockTOListState {
    factories: any; // 工厂
    warehouseList: any; // 仓库
    warehouseOptions: any;
    warehouseId?: number;
    enableBlockNumberFromQuarry: boolean;
    checkBoxOptions: any; // 状态
    toExpandedArr: number[];
    categoryList: IIdNameItem[];
    gradeList: IIdNameItem[];
}

@observer
class PageBlockTOList extends React.Component<
    IBlockTOListProps,
    IBlockTOListState
> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            factories: [],
            warehouseOptions: [],
            warehouseList: [],
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            ),
            checkBoxOptions: [], // 状态
            toExpandedArr: [],
            categoryList: [],
            gradeList: []
        };
    }

    public async componentDidMount() {
        const t = this;
        const categoryList = await StoneCategories.getCategories();
        const gradeList = await StoneGrades.getGrades();
        const factories = await Factories.getFactories(); // 工厂列表
        let warehouseList = await Warehouses.getWarehouses(); // 仓库列表
        warehouseList = warehouseList.filter(
            w => w.type === WarehouseType.Block
        );
        const checkBoxOptions = ApprovalStatus.data.map(status => {
            return { label: status.text, value: status.value.toString() };
        });

        const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
            warehouseList,
            factories
        );

        t.setState({
            categoryList,
            gradeList,
            warehouseOptions,
            checkBoxOptions
        });

        await t.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const transferOrderList = Store.transferOrderList;
        const checkBoxOptions = s.checkBoxOptions;

        const columns: any = TransferOrderUtil.getTabColumns(
            s.warehouseOptions
        );

        if (PermCtl.isAuthorized(Perm.TO_B_E)) {
            const actionColumns = [
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    render: (text, record) => {
                        return (
                            <span>
                                <a onClick={t.handlePrintTO.bind(t, record)}>
                                    导出调拨单
                                </a>
                            </span>
                        );
                    }
                }
            ];
            columns.push(...actionColumns);
        }

        const {
            orderNumber,
            statusCodes,
            selectFromWarehouse,
            selectToWarehouse,
            startDate,
            endDate
        } = Store.filterData;

        return (
            <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            onFinish={t.handleSearch}
                            ref={this.formRef}
                            name={"blockTOAdvancedSearchForm"}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        {...formItemLayoutForCheckboxGroup}
                                        label="状态"
                                    >
                                        <CheckboxGroup
                                            options={checkBoxOptions}
                                            value={statusCodes}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "statusCodes"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="调拨单号">
                                        <Input
                                            placeholder="请输入调拨单号"
                                            value={orderNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "orderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="调出仓库">
                                        <Cascader
                                            value={selectFromWarehouse}
                                            options={s.warehouseOptions}
                                            placeholder="请选择调出仓库"
                                            onChange={
                                                t.handleFromWarehouseChange
                                            }
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="调入仓库">
                                        <Cascader
                                            value={selectToWarehouse}
                                            options={s.warehouseOptions}
                                            placeholder="请选择调入仓库"
                                            onChange={t.handleToWarehouseChange}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="调拨日期">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            format={dateFormat}
                                            onChange={t.handleDateChange}
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                            value={Util.getMomentArray(
                                                startDate,
                                                endDate
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div>
                    <Table
                        columns={columns}
                        dataSource={transferOrderList}
                        rowKey={"id"}
                        pagination={false}
                        expandedRowRender={t.showTOItems}
                        expandedRowKeys={s.toExpandedArr}
                        onExpand={t.handleRowExpanded}
                    />
                </div>
                {Store.pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={Store.pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString.bind(
                                t,
                                Store.pagingInfo.totalRecordCount
                            )}
                            pageSize={Store.pageSize}
                            current={Store.pageNo}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private showTOItems = (to: ITransferOrderListWithDetails): JSX.Element => {
        const t = this;
        const s = t.state;
        const toItems = to.transferDetails;
        let blockItems = [];
        if (toItems) {
            blockItems = toItems.filter(
                it => it.type === StoneArtifactType.Block
            );
        }

        const showBlockItems =
            blockItems && blockItems.length && blockItems.length > 0;
        const blockColumns: any = TransferOrderUtil.getBlockColumns(
            s.categoryList,
            s.gradeList
        );
        return (
            <div>
                {!showBlockItems ? (
                    <p className="notice">没有荒料信息</p>
                ) : (
                    <Collapse
                        defaultActiveKey={["0"]}
                        style={{ marginLeft: "60px" }}
                    >
                        {showBlockItems ? (
                            <Panel header="荒料列表" key="0">
                                <Table
                                    style={{ background: "#f0fdff" }}
                                    rowKey="id"
                                    size="middle"
                                    columns={blockColumns}
                                    dataSource={blockItems}
                                    pagination={false}
                                />
                            </Panel>
                        ) : null}
                    </Collapse>
                )}
            </div>
        );
    };

    private handleRowExpanded = async (
        expanded: boolean,
        to
    ): Promise<void> => {
        const t = this;
        const s = t.state;
        const id = to.id;

        const expandedArr = Util.updateExpandedArray(
            s.toExpandedArr,
            to.id,
            expanded
        );

        t.setState({
            toExpandedArr: expandedArr
        });
        if (expanded && to && !to.transferDetails) {
            await Store.getTransferOrder(id);
        }
    };

    private async loadData() {
        await Store.reloadData();
    }

    // 跳到第page页
    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);
        await this.loadData();
    };

    private handleClearSearchCriteria = async () => {
        const t = this;

        Store.updataFilterData({});
        Store.setPageNo(1);
        Store.setPageSize(10);

        t.setState({
            toExpandedArr: []
        });

        await this.loadData();
    };

    private handleSearch = async () => {
        Store.setPageNo(1);
        this.setState({
            toExpandedArr: []
        });
        await this.loadData();
    };

    private handleInputChange(label: string, event) {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    }

    private handleChange = (label: string, value) => {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: value
        });
    };

    // 选择调出仓库
    private handleFromWarehouseChange = (value: string[]) => {
        Store.updataFilterData({
            ...Store.filterData,
            selectFromWarehouse: value
        });
    };

    // 选择调入仓库
    private handleToWarehouseChange = (value: string[]) => {
        Store.updataFilterData({
            ...Store.filterData,
            selectToWarehouse: value
        });
    };

    private getTotalRecordString(totalCount: number): string {
        return `共 ${totalCount} 个调拨单`;
    }

    private handleDateChange(_, dateStrings) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        Store.updataFilterData({
            ...Store.filterData,
            startDate,
            endDate
        });
    }

    private handlePrintTO(to: ITransferOrderListWithDetails) {
        if (!to) {
            message.info("请选择需要导出的内容！");
            return false;
        }

        let printAPI = "TransferOrder/Print?";
        printAPI += "id=" + to.id;

        message.info("正在导出调拨单以供下载并打印...");
        ExportUtil.setExportUrl(printAPI);
    }
}

export default PageBlockTOList;
