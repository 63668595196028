import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
  Tabs,
} from "antd";
import { observer } from "mobx-react";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../app/Perm";
import {
  CustomerForListDTO,
  FactoryDTO,
  IMachineryDTO,
  IWorkOrderFullInfoDTO,
  StoneCategoryDTO,
  StoneGradeDTO,
  WorkOrderFullInfoDTO,
  WorkSegments,
} from "../../../app/WebAPIClients";
import AppliedProcessUtil from "../../../components/AppliedProcessUtil";
import ColumnSettings from "../../../components/ColumnSettings";
import Consts, {
  formItemLayoutForCheckboxGroup,
  formItemLayoutForCol,
  formItemLayoutForSearch,
} from "../../../components/Consts";
import ExportUtil from "../../../components/ExportUtil";
import PermCtl from "../../../components/PermCtrl";
import SearchButtonGroup from "../../../components/SearchButtonGroup";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import Util from "../../../components/Util";
import BasicInfo from "./BasicInfo";
import FillingInfo from "./FillingInfo";
import MaterialRequisitionInfo from "./MaterialRequisitionInfo";
import PolishingInfo from "./PolishingInfo";
import SawingInfo from "./SawingInfo";
import Store, { IFilterData } from "./store";
import TrimmingInfo from "./TrimmingInfo";
moment.locale("zh-cn");

const { TabPane } = Tabs;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const {
  Customers,
  Factories,
  StoneCategories,
  StoneGrades,
  Priority,
  Machineries,
  WorkOrderType,
  ManufacturingState: ManufacturingStateOptions,
  ManufacturingMethod: ManufacturingMethodOptions,
} = Consts;

export enum WoInfoLayOut {
  ShowCollapse = 10,
  ShowTab = 20,
}

declare interface IPageWorkOrderListProps extends RouteComponentProps<{}> {}

declare interface IPageWorkOrderListState {
  factories: FactoryDTO[]; // 工厂
  machineries: IMachineryDTO[];
  factoryOptions: JSX.Element[];
  factoryId?: number;
  gradeIds?: number[]; // 石材等级
  categoryList: StoneCategoryDTO[]; // 石材种类
  categoryOptions: any; // 选择石材种类
  gradeList: StoneGradeDTO[]; // 石材等级
  gradeOptions: JSX.Element[]; // 选择等级
  checkBoxOptions: any; // 状态
  visible: boolean; // 控制点击显示关闭下拉菜单
  columnsInfo: ITableColumnInfo[];
  customerList: CustomerForListDTO[];
  customerOptions: JSX.Element[];
  enableBlockNumberFromQuarry: boolean;
  manufacturingMethodOptions?: JSX.Element[];
  priorityOptions?: JSX.Element[];
  buttonDisabled: boolean;
  skipFillingOptions?: JSX.Element[];
  skipFillingList: IOption[];

  woExpandedArr: number[];
  woInfoLayOut: number;
  loading: boolean;
}
@observer
class PageWorkOrderList extends React.Component<
  IPageWorkOrderListProps,
  IPageWorkOrderListState
> {
  private basicInfoTabKey: string = "basicInfo";
  private materialRequisitionInfoTabKey: string = "materialRequisitionInfo";
  private trimmingInfoTabKey: string = "trimmingInfo";
  private sawingInfoTabKey: string = "sawingInfo";
  private fillingInfoTabKey: string = "fillingInfo";
  private polishingInfoTabKey: string = "polishingInfo";
  private attachmentInfoTabKey: string = "attachmentInfo";
  private componentName: string = "WorkOrderList";
  private formRef: React.RefObject<FormInstance>;
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      factories: [],
      machineries: [],
      factoryOptions: [],
      categoryList: [], // 石材种类
      categoryOptions: [], // 选择石材种类
      gradeList: [], // 石材等级
      gradeOptions: [], // 选择等级
      checkBoxOptions: [], // 状态
      customerList: [],
      customerOptions: [], // 所有者选择列表
      visible: false, // 控制点击显示关闭下拉菜单,
      enableBlockNumberFromQuarry: Settings.getBoolValue(
        SettingsDefinition.EnableBlockNumberFromQuarryKey,
      ),
      columnsInfo: this.getWorkOrderColumns(),
      buttonDisabled: false,
      skipFillingList: [
        {
          value: "10",
          text: "是",
        },
        {
          value: "20",
          text: "否",
        },
      ],
      woExpandedArr: [],
      woInfoLayOut: WoInfoLayOut.ShowCollapse,
      loading: false,
    };
  }

  public async componentDidMount() {
    const t = this;
    const categoryList = await StoneCategories.getCategories();
    const gradeList = await StoneGrades.getGrades();
    const factories = await Factories.getFactories(); // 工厂列表
    const machineries = await Machineries.getMachineries();
    const factoryOptions = factories.map((c) => {
      return (
        <Option key={c.id} value={c.id}>
          {c.name}
        </Option>
      );
    });
    const categoryOptions = categoryList.map((c) => {
      return (
        <Option key={c.id} value={c.id}>
          {c.name}
        </Option>
      );
    });
    const gradeOptions = gradeList.map((g) => {
      return (
        <Option key={g.id} value={g.id}>
          {g.name}
        </Option>
      );
    });

    const checkBoxOptions = ManufacturingStateOptions.data.map((status) => {
      return { label: status.text, value: status.value.toString() };
    });

    const manufacturingMethodOptions =
      ManufacturingMethodOptions.processingData.map((m) => {
        return (
          <Option key={m.value as number} value={m.value as number}>
            {m.text}
          </Option>
        );
      });

    const priorityOptions = Priority.data.map((m) => {
      return (
        <Option key={m.value as number} value={m.value as number}>
          {m.text}
        </Option>
      );
    });

    const skipFillingOptions = t.state.skipFillingList.map((m) => {
      return (
        <Option key={m.value as string} value={m.value as string}>
          {m.text}
        </Option>
      );
    });

    const customerList = await Customers.getCustomers();
    const customerOptions = customerList.map((item) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      );
    });

    // 在所有者列表中增加内部选项，用于筛选自有荒料
    customerOptions.unshift(
      <Option key={0} value={0}>
        {"内部"}
      </Option>,
    );

    t.setState({
      customerList,
      customerOptions,
      categoryList,
      gradeList,
      categoryOptions,
      gradeOptions,
      checkBoxOptions,
      factoryOptions,
      factories,
      manufacturingMethodOptions,
      priorityOptions,
      skipFillingOptions,
      machineries,
    });

    t.loadData();
  }

  public render() {
    const t = this;
    const s = t.state;
    const { filterData, workOrderList, pagingInfo } = Store;
    const {
      selectBlockNumber,
      selectOrderNumber,
      selectSalesOrderNumber,
      categoryIds,
      manufacturingStates,
      thickness,
      customerIds,
      factoryIds,
      manufacturingMethods,
      creationEndDate,
      creationStartDate,
      sawingStartDate,
      sawingEndDate,
      trimmingStartDate,
      trimmingEndDate,
      fillingStartDate,
      fillingEndDate,
      polishingStartDate,
      polishingEndDate,
      priorities,
      skipFilling,
    } = filterData;
    const categoryOptions = s.categoryOptions;
    const checkBoxOptions = s.checkBoxOptions;
    return (
      <div>
        {s.buttonDisabled ? (
          <div
            className="importingSpinArea"
            ref={() => {
              "spinArea";
            }}
          >
            <p>{`正在导出文件，当前导出生产工单数据共计：${pagingInfo.totalRecordCount}个，请等待...`}</p>
            <Spin />
          </div>
        ) : null}
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="高级搜索" key="1">
            <Form
              onFinish={t.handleSearch}
              ref={this.formRef}
              name={"woAdvancedSearchForm"}
              {...formItemLayoutForSearch}
            >
              <Row>
                <Col span={24}>
                  <FormItem {...formItemLayoutForCheckboxGroup} label="状态">
                    <CheckboxGroup
                      options={checkBoxOptions}
                      value={manufacturingStates}
                      onChange={t.handleChange.bind(t, "manufacturingStates")}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="荒料编号">
                    <Input
                      placeholder="请输入荒料编号"
                      value={selectBlockNumber}
                      onChange={t.handleInputChange.bind(
                        t,
                        "selectBlockNumber",
                      )}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="订单编号">
                    <Input
                      placeholder="请输入订单编号"
                      value={selectSalesOrderNumber}
                      onChange={t.handleInputChange.bind(
                        t,
                        "selectSalesOrderNumber",
                      )}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="创建日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        creationStartDate,
                        creationEndDate,
                      )}
                      onChange={t.handleCreationDateChange}
                      placeholder={["开始日期", "结束日期"]}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        上个月: [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="工单编号">
                    <Input
                      placeholder="请输入荒料编号"
                      value={selectOrderNumber}
                      onChange={t.handleInputChange.bind(
                        t,
                        "selectOrderNumber",
                      )}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="加工方式">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择加工方式"
                      value={manufacturingMethods}
                      onChange={t.handleChange.bind(t, "manufacturingMethods")}
                    >
                      {s.manufacturingMethodOptions}
                    </Select>
                  </FormItem>
                </Col>

                <Col {...formItemLayoutForCol}>
                  <FormItem label="修边完成日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        trimmingStartDate,
                        trimmingEndDate,
                      )}
                      onChange={t.handleTrimmingDateChange}
                      placeholder={["开始日期", "结束日期"]}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        上个月: [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="石材种类">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择石材种类"
                      value={categoryIds}
                      onChange={t.handleChange.bind(t, "categoryIds")}
                    >
                      {categoryOptions}
                    </Select>
                  </FormItem>
                </Col>

                <Col {...formItemLayoutForCol}>
                  <FormItem label="是否直磨">
                    <Select
                      allowClear={true}
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择是否直磨"
                      value={skipFilling}
                      onChange={t.handleChange.bind(t, "skipFilling")}
                    >
                      {s.skipFillingOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="大切完成日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        sawingStartDate,
                        sawingEndDate,
                      )}
                      onChange={t.handleSawingDateChange}
                      placeholder={["开始日期", "结束日期"]}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        上个月: [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="生产厚度">
                    <Input
                      placeholder="请输入生产厚度"
                      value={thickness}
                      onChange={t.handleInputChange.bind(t, "thickness")}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="优先级">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择优先级"
                      value={priorities}
                      onChange={t.handleChange.bind(t, "priorities")}
                    >
                      {s.priorityOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="补胶完成日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        fillingStartDate,
                        fillingEndDate,
                      )}
                      onChange={t.handleFillingDateChange}
                      placeholder={["开始日期", "结束日期"]}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        上个月: [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="客户">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择客户"
                      value={customerIds}
                      onChange={t.handleChange.bind(t, "customerIds")}
                    >
                      {s.customerOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="工厂">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择工厂"
                      value={factoryIds}
                      onChange={t.handleChange.bind(t, "factoryIds")}
                    >
                      {s.factoryOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="磨抛完成日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        polishingStartDate,
                        polishingEndDate,
                      )}
                      onChange={t.handlePolishingDateChange}
                      placeholder={["开始日期", "结束日期"]}
                      ranges={{
                        本月: [
                          moment().startOf("month"),
                          moment().endOf("month"),
                        ],
                        上个月: [
                          moment().subtract(1, "month").startOf("month"),
                          moment().subtract(1, "month").endOf("month"),
                        ],
                      }}
                    />
                  </FormItem>
                </Col>
              </Row>
              <SearchButtonGroup
                buttonDisabled={s.buttonDisabled}
                onClearClick={t.handleClearSearchCriteria}
              />
            </Form>
          </Panel>
        </Collapse>
        <div style={{ margin: "1%", textAlign: "right" }}>
          <Select
            defaultValue={s.woInfoLayOut}
            style={{ float: "left" }}
            onChange={t.handleSelectClick}
          >
            <Option value={WoInfoLayOut.ShowCollapse}>纵向显示工单详情</Option>
            <Option value={WoInfoLayOut.ShowTab}>横向显示工单详情</Option>
          </Select>
          {PermCtl.isAuthorized(Perm.WO_E) ? (
            <Button disabled={s.buttonDisabled} onClick={t.handleExportClick}>
              导出
            </Button>
          ) : null}
          <Dropdown
            overlay={t.getColumnSettings()}
            trigger={["click"]}
            onVisibleChange={t.handleVisibleChange}
            visible={s.visible}
          >
            <Button>
              更多 <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div>
          <Table
            columns={Util.getTableColumns(s.columnsInfo, t.componentName)}
            dataSource={workOrderList}
            onChange={t.handleSorterChange}
            rowKey={"id"}
            pagination={false}
            expandable={{
              expandedRowRender: t.showWoFullInfoTable,
              onExpand: t.handleRowExpanded,
              expandedRowKeys: s.woExpandedArr,
            }}
            loading={s.loading}
          />
        </div>
        {Store.pagingInfo ? (
          <div className="paginationArea">
            <Pagination
              total={pagingInfo.totalRecordCount}
              showTotal={t.getTotalRecordString}
              pageSize={pagingInfo.pageSize}
              current={pagingInfo.currentPage}
              defaultCurrent={1}
              showQuickJumper={true}
              onChange={t.handlePaginationChange}
              showSizeChanger={false}
            />
          </div>
        ) : null}
      </div>
    );
  }

  // 显示工单详细信息
  private showWoFullInfoTable = (wo) => {
    const t = this;
    const s = t.state;
    const { workOrderInfoList } = Store;
    const woInfo = Util.isNotNullAndNotEmptyArray(workOrderInfoList)
      ? workOrderInfoList.find((w) => w.id === wo.id)
      : null;

    if (!woInfo) {
      return (
        <div className="importingSpinArea">
          <p>正在加载数据，请等待...</p>
          <Spin />
        </div>
      );
    } else {
      const blockAppliedProcesses = t.getBlockAppliedProcessesForWo(woInfo);
      const trimmingAppliedProcesses =
        AppliedProcessUtil.getAppliedProcessesBySegments(
          blockAppliedProcesses,
          WorkSegments.Trimming,
        );

      const sawingAppliedProcesses =
        AppliedProcessUtil.getAppliedProcessesBySegments(
          blockAppliedProcesses,
          WorkSegments.Sawing,
        );

      return s.woInfoLayOut === WoInfoLayOut.ShowTab ? (
        <Tabs
          style={{ marginLeft: "60px" }}
          key={wo.id}
          defaultActiveKey={this.basicInfoTabKey}
          onChange={t.handleTabChanged.bind(t, woInfo)}
        >
          <TabPane tab="基本信息" key={this.basicInfoTabKey}>
            <BasicInfo
              wo={woInfo}
              factoryList={s.factories}
              categoryList={s.categoryList}
              enableBlockNumberFromQuarry={s.enableBlockNumberFromQuarry}
            />
          </TabPane>
          <TabPane tab="领料单" key={this.materialRequisitionInfoTabKey}>
            <MaterialRequisitionInfo
              wo={woInfo}
              gradeList={s.gradeList}
              categoryList={s.categoryList}
              enableBlockNumberFromQuarry={s.enableBlockNumberFromQuarry}
            />
          </TabPane>
          <TabPane tab="修边工序" key={this.trimmingInfoTabKey}>
            <TrimmingInfo
              wo={woInfo}
              factoryList={s.factories}
              trimmingProcesses={trimmingAppliedProcesses}
              machineries={s.machineries}
            />
          </TabPane>
          <TabPane tab="大切工序" key={this.sawingInfoTabKey}>
            <SawingInfo
              wo={woInfo}
              factoryList={s.factories}
              sawingAppliedProcesses={sawingAppliedProcesses}
              machineries={s.machineries}
            />
          </TabPane>
          <TabPane tab="补胶工序" key={this.fillingInfoTabKey}>
            <FillingInfo wo={woInfo} />
          </TabPane>
          <TabPane tab="磨抛工序" key={this.polishingInfoTabKey}>
            <PolishingInfo
              wo={woInfo}
              factoryList={s.factories}
              machineries={s.machineries}
            />
          </TabPane>
        </Tabs>
      ) : (
        <Collapse
          style={{ marginLeft: "60px" }}
          defaultActiveKey={[
            this.basicInfoTabKey,
            this.materialRequisitionInfoTabKey,
            this.trimmingInfoTabKey,
            this.sawingInfoTabKey,
            this.fillingInfoTabKey,
            this.polishingInfoTabKey,
          ]}
        >
          <Panel header="基本信息" key={this.basicInfoTabKey}>
            <BasicInfo
              wo={woInfo}
              factoryList={s.factories}
              categoryList={s.categoryList}
              enableBlockNumberFromQuarry={s.enableBlockNumberFromQuarry}
            />
          </Panel>
          <Panel header="领料单" key={this.materialRequisitionInfoTabKey}>
            <MaterialRequisitionInfo
              wo={woInfo}
              gradeList={s.gradeList}
              categoryList={s.categoryList}
              enableBlockNumberFromQuarry={s.enableBlockNumberFromQuarry}
            />
          </Panel>
          <Panel header="修边工序" key={this.trimmingInfoTabKey}>
            <TrimmingInfo
              wo={woInfo}
              factoryList={s.factories}
              trimmingProcesses={trimmingAppliedProcesses}
              machineries={s.machineries}
            />
          </Panel>
          <Panel header="大切工序" key={this.sawingInfoTabKey}>
            <SawingInfo
              wo={woInfo}
              factoryList={s.factories}
              sawingAppliedProcesses={sawingAppliedProcesses}
              machineries={s.machineries}
            />
          </Panel>
          <Panel header="补胶工序" key={this.fillingInfoTabKey}>
            <FillingInfo wo={woInfo} />
          </Panel>
          <Panel header="磨抛工序" key={this.polishingInfoTabKey}>
            <PolishingInfo
              wo={woInfo}
              factoryList={s.factories}
              machineries={s.machineries}
            />
          </Panel>
        </Collapse>
      );
    }
  };

  private handleTabChanged = (
    woInfo: WorkOrderFullInfoDTO,
    activeKey: string,
  ) => {
    const t = this;
    const workOrderInfo = Store.workOrderInfoList.find(
      (w) => w.id === woInfo.id,
    );
    if (
      (activeKey === this.trimmingInfoTabKey ||
        activeKey === this.sawingInfoTabKey ||
        activeKey === this.polishingInfoTabKey) &&
      !Util.isNotNullAndNotEmptyArray(
        t.getBlockAppliedProcessesForWo(workOrderInfo),
      )
    ) {
      t.loadBlockAppliedProcesses(workOrderInfo);
    }
  };

  private handleRowExpanded = async (expanded: boolean, wo) => {
    const t = this;
    const s = t.state;
    const { workOrderInfoList } = Store;
    const woInfo = Util.isNotNullAndNotEmptyArray(workOrderInfoList)
      ? workOrderInfoList.find((w) => w.id === wo.id)
      : null;

    const expandedArr = Util.updateExpandedArray(
      s.woExpandedArr,
      wo.id,
      expanded,
    );
    if (expanded && !woInfo) {
      await t.loadWOInfo(wo.id);
    }
    t.setState({
      woExpandedArr: expandedArr,
    });
  };

  private loadWOInfo = async (woId: number) => {
    const t = this;
    const s = t.state;
    await Store.loadWO(woId);

    if (!Store.hasError) {
      // 当选择的是纵向显示工单详情，且当该工单对应的blockAppliedProcesses为空时，同步加载数据
      const workOrderInfo = Store.workOrderInfoList.find((w) => w.id === woId);
      if (
        s.woInfoLayOut === WoInfoLayOut.ShowCollapse &&
        !Util.isNotNullAndNotEmptyArray(
          t.getBlockAppliedProcessesForWo(workOrderInfo),
        )
      ) {
        await t.loadBlockAppliedProcesses(workOrderInfo);
      }
    }
  };

  private async loadData() {
    await Store.reloadData();
  }

  // 跳到第page页
  private handlePaginationChange = async (page: number) => {
    Store.setPageNo(page);
    await this.loadData();
  };

  private handleClearSearchCriteria = async () => {
    const t = this;
    const p: IFilterData = {
      selectBlockNumber: undefined,
      selectOrderNumber: undefined,
      selectSalesOrderNumber: undefined,
      categoryIds: [],
      manufacturingStates: [],
      thickness: undefined,
      customerIds: [],
      factoryIds: [],
      manufacturingMethods: [],
      creationEndDate: undefined,
      creationStartDate: undefined,
      sawingStartDate: undefined,
      sawingEndDate: undefined,
      trimmingStartDate: undefined,
      trimmingEndDate: undefined,
      fillingStartDate: undefined,
      fillingEndDate: undefined,
      polishingStartDate: undefined,
      polishingEndDate: undefined,
      priorities: [],
      skipFilling: undefined,
    };

    ColumnSettings.setSortSettings(this.componentName, null);
    Store.setPageNo(1);
    Store.setPageSize(10);
    Store.updataFilterData({ ...p });
    await this.loadData();

    t.setState({
      woExpandedArr: [],
    });
  };

  private handleSearch = async () => {
    Store.setPageNo(1);
    await this.loadData();
    this.setState({
      woExpandedArr: [],
    });
  };

  private handleInputChange(label: string, event) {
    Store.updataFilterData({
      ...Store.filterData,
      [label]: event.target.value,
    });
  }

  private handleChange = (label: string, value) => {
    Store.updataFilterData({
      ...Store.filterData,
      [label]: value,
    });
  };

  private handleVisibleChange = () => {
    this.setState({
      visible: !this.state.visible,
    });
  };

  private handSelectColumnsChange = (label: string, value: boolean) => {
    const t = this;
    const s = t.state;
    const visibleColumns: string[] = Util.getVisibleColumns(
      s.columnsInfo,
      t.componentName,
    );

    if (value) {
      if (!visibleColumns.includes(label)) {
        visibleColumns.push(label);
      }
    } else if (visibleColumns.includes(label)) {
      const index = visibleColumns.indexOf(label);
      visibleColumns.splice(index, 1);
    }

    ColumnSettings.setVisibleColumns(t.componentName, visibleColumns);
    t.setState(s);
  };

  private handleCheckAllChange = (checkAll: boolean) => {
    const t = this;
    const s = t.state;
    const columnsInfo = s.columnsInfo;

    Util.checkAllChange(columnsInfo, t.componentName, checkAll);
    t.setState(s);
  };

  // 选择要显示的列
  private getColumnSettings(): JSX.Element {
    const t = this;
    const s = t.state;
    const visibleColumns = Util.getVisibleColumns(
      s.columnsInfo,
      t.componentName,
    );

    const checkAll: boolean = Util.judgeCheckAll(s.columnsInfo, visibleColumns);

    return (
      <div style={{ width: "100%", background: "#FFFFFF" }}>
        <Checkbox
          onChange={t.handleCheckAllChange.bind(t, checkAll)}
          checked={checkAll}
        >
          全选
        </Checkbox>
        <br />
        {s.columnsInfo && s.columnsInfo.length > 0 && visibleColumns
          ? s.columnsInfo.map((ci, index) => {
              let canView = true; // 当前用户的角色是否有权限查看该字段
              const visible = visibleColumns.some((c) => c === ci.title);
              if (ci.viewingPermissions && ci.viewingPermissions.length > 0) {
                canView = PermCtl.isAnyAuthorized(ci.viewingPermissions);
              }

              if (
                canView &&
                (typeof ci.disabled === "undefined" || !ci.disabled)
              ) {
                return (
                  <div key={index}>
                    <Checkbox
                      checked={visible}
                      disabled={ci.alwaysShow}
                      onChange={t.handSelectColumnsChange.bind(
                        t,
                        ci.title,
                        !visible,
                      )}
                    >
                      {ci.title}
                    </Checkbox>
                  </div>
                );
              }
            })
          : null}
      </div>
    );
  }

  private handleSorterChange = async (_1, _2, sorter) => {
    const t = this;
    Util.updateSortSettings(sorter, t.componentName);
    Store.setPageNo(1);
    Store.setPageSize(10);
    await t.loadData();
  };

  private getItemName = (itemId: number, listName: string): string => {
    const t = this;
    const s = t.state;
    const list = s[listName];
    return Util.getItemName(itemId, list);
  };

  private getTotalRecordString = (): string => {
    return `共 ${Store.pagingInfo.totalRecordCount} 个工单`;
  };

  private getWorkOrderColumns(): ITableColumnInfo[] {
    const columnsFirstBatch: ITableColumnInfo[] = [
      {
        title: "工单编号",
        dataIndex: "orderNumber",
        key: "OrderNumber",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: true,
      },
      {
        title: "荒料编号",
        dataIndex: "blockNumber",
        key: "BlockNumber",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
      },
      {
        title: "品名",
        dataIndex: "categoryId",
        key: "CategoryId",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (categoryId) => this.getItemName(categoryId, "categoryList"),
      },
      {
        title: "生产厚度",
        dataIndex: "thickness",
        key: "Thickness",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
      },
      {
        title: "毛板面积",
        dataIndex: "areaAfterSawing",
        key: "AreaAfterSawing",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
      },
      {
        title: "光板面积",
        dataIndex: "areaAfterPolishing",
        key: "AreaAfterPolishing",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
      },
      {
        title: "生产状态",
        dataIndex: "manufacturingState",
        key: "ManufacturingState",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (ms) => {
          return ManufacturingStateOptions.getState(ms).text;
        },
      },
      {
        title: "加工方式",
        dataIndex: "manufacturingMethod",
        key: "ManufacturingMethod",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (mm) => {
          return ManufacturingMethodOptions.getManufacturingMethod(mm).text;
        },
      },
      {
        title: "生产类型",
        dataIndex: "orderType",
        key: "OrderType",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (ot) => {
          return WorkOrderType.getOrderType(ot).text;
        },
      },
      {
        title: "是否对剖",
        dataIndex: "splittedBlock",
        key: "SplittedBlock",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (splittedBlock) => {
          return splittedBlock === true ? "是" : "否";
        },
      },
      {
        title: "优先级",
        dataIndex: "priority",
        key: "Priority",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (priority) => {
          return Priority.getPriority(priority).text;
        },
      },
      {
        title: "工厂",
        dataIndex: "factoryId",
        key: "FactoryId",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (factoryId) => this.getItemName(factoryId, "factories"),
      },
      {
        title: "报废荒料",
        dataIndex: "blockDiscarded",
        key: "BlockDiscarded",
        align: "center",
        sorter: true,
        showByDefault: false,
        alwaysShow: false,
        render: (blockDiscarded) => {
          return blockDiscarded === true ? "是" : "否";
        },
      },
    ];

    return columnsFirstBatch;
  }

  private setButtonEnable(time) {
    if (!Util.isDefinedAndNotNull(time)) {
      return;
    }
    setTimeout(
      // 设置定时器，由于导出数据量可能较大，设置指定时间后再恢复点亮Button
      () => {
        this.setState({ buttonDisabled: false });
      },
      time * 1000,
    );
  }

  private handleSelectClick = (value: number) => {
    this.setState({ woInfoLayOut: value });
  };

  private handleExportClick = async () => {
    const t = this;
    const { pagingInfo, workOrderList } = Store;
    Store.setPageNo(1);
    await Store.reloadData();
    if (!Store.hasError) {
      if (pagingInfo.totalRecordCount > 3500) {
        Modal.info({
          title: "导出数据量超出限制",
          content: (
            <span className="blockDiscardedStyle">
              当前导出数据超出限制条数：3500条，请调整导出条件，分批导出！
            </span>
          ),
          okText: "知道了",
        });
        return;
      }
      if (workOrderList && workOrderList.length && workOrderList.length > 0) {
        t.exportWorkOrders();
      } else {
        message.error("指定的导出条件没有数据！");
      }
    }
  };

  private exportWorkOrders = () => {
    const t = this;
    const s = t.state;
    const { filterData, pagingInfo } = Store;
    const { totalRecordCount } = pagingInfo;
    const {
      selectBlockNumber,
      selectOrderNumber,
      selectSalesOrderNumber,
      categoryIds,
      manufacturingStates,
      thickness,
      customerIds,
      factoryIds,
      manufacturingMethods,
      creationEndDate,
      creationStartDate,
      sawingStartDate,
      sawingEndDate,
      trimmingStartDate,
      trimmingEndDate,
      fillingStartDate,
      fillingEndDate,
      polishingStartDate,
      polishingEndDate,
      priorities,
      skipFilling,
    } = filterData;
    let exportAPI: string = "WorkOrder/Export?";

    if (
      manufacturingStates &&
      manufacturingStates.length &&
      manufacturingStates.length > 0
    ) {
      manufacturingStates.forEach((ms) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "manufacturingStates",
          ms,
        );
      });
    }

    if (categoryIds && categoryIds.length && categoryIds.length > 0) {
      categoryIds.forEach((categoryId) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "categoryIds",
          categoryId,
        );
      });
    }

    if (factoryIds && factoryIds.length && factoryIds.length > 0) {
      factoryIds.forEach((factoryId) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "factoryIds",
          factoryId,
        );
      });
    }

    if (customerIds && customerIds.length && customerIds.length > 0) {
      customerIds.forEach((customerId) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "customerIds",
          customerId,
        );
      });
    }

    if (Util.isNotNullAndNotEmptyArray(manufacturingMethods)) {
      manufacturingMethods.forEach((ms) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "manufacturingMethods",
          ms,
        );
      });
    }

    if (Util.isNotNullAndNotEmptyArray(priorities)) {
      priorities.forEach((priority) => {
        exportAPI += ExportUtil.addParameter(exportAPI, "priorities", priority);
      });
    }

    if (Util.isNotNullAndNotEmpty(thickness)) {
      exportAPI += ExportUtil.addParameter(exportAPI, "thickness", thickness);
    }

    if (Util.isNotNullAndNotEmpty(selectBlockNumber)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "blockNumber",
        selectBlockNumber,
      );
    }
    if (Util.isNotNullAndNotEmpty(selectOrderNumber)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "orderNumber",
        selectOrderNumber,
      );
    }

    if (Util.isNotNullAndNotEmpty(selectSalesOrderNumber)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "salesOrderNumber",
        selectSalesOrderNumber,
      );
    }

    if (Util.isNotNullAndNotEmpty(creationStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "creationStartDate",
        creationStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(creationEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "creationEndDate",
        creationEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(trimmingStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "trimmingStartDate",
        trimmingStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(trimmingEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "trimmingEndDate",
        trimmingEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(sawingStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "sawingStartDate",
        sawingStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(sawingEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "sawingEndDate",
        sawingEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(fillingStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "fillingStartDate",
        fillingStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(fillingEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "fillingEndDate",
        fillingEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(polishingStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "polishingStartDate",
        polishingStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(polishingEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "polishingEndDate",
        polishingEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(skipFilling)) {
      const selectedSkipFilling = Util.isDefinedAndNotNull(skipFilling)
        ? skipFilling === "10"
          ? true
          : false
        : null;
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "skipFilling",
        selectedSkipFilling,
      );
    }

    message.info("正在导出指定的工单列表数据...");
    ExportUtil.setExportUrl(exportAPI);

    // 为防止导出数据量较大和多用户同时访问操作时，导致服务器性能降低的现象，每次点击导出数据后，将会按不同时长disabled Button

    t.setState({ buttonDisabled: true });

    // 导出工单根据实际测试，按数据量大小设置不同时间
    // 小于100，设置5s;
    // 大于100，小于1000，设置10s;
    // 大于1000，小于3000，设置20s;
    // 大于3000，小于6000，设置60s;
    // 大于6000，设置120s;

    const time =
      totalRecordCount < 100
        ? 5
        : totalRecordCount < 1000
        ? 10
        : totalRecordCount < 3000
        ? 20
        : totalRecordCount < 3500
        ? 60
        : 120;

    t.setButtonEnable(time);
  };

  private handleCreationDateChange = (dates, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    Store.updataFilterData({
      ...Store.filterData,
      creationStartDate: startDate,
      creationEndDate: endDate,
    });
  };

  private handleTrimmingDateChange = (dates, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    Store.updataFilterData({
      ...Store.filterData,
      trimmingStartDate: startDate,
      trimmingEndDate: endDate,
    });
  };

  private handleSawingDateChange = (dates, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    Store.updataFilterData({
      ...Store.filterData,
      sawingStartDate: startDate,
      sawingEndDate: endDate,
    });
  };

  private handleFillingDateChange = (dates, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    Store.updataFilterData({
      ...Store.filterData,
      fillingStartDate: startDate,
      fillingEndDate: endDate,
    });
  };

  private handlePolishingDateChange = (dates, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];

    Store.updataFilterData({
      ...Store.filterData,
      polishingStartDate: startDate,
      polishingEndDate: endDate,
    });
  };

  private loadBlockAppliedProcesses = async (
    workOrder: IWorkOrderFullInfoDTO,
  ) => {
    if (
      !workOrder ||
      !workOrder.materialRequisition ||
      !workOrder.materialRequisition.block
    ) {
      return;
    }

    await Store.getProcessesBySegments(
      workOrder.materialRequisition.blockId,
      workOrder.id,
    );
  };

  private getBlockAppliedProcessesForWo = (workOrder: WorkOrderFullInfoDTO) => {
    if (
      !workOrder ||
      !workOrder.materialRequisition ||
      !workOrder.materialRequisition.block
    ) {
      return [];
    }

    const t = this;
    const s = t.state;
    const blockAppliedProcesses = Store.blockAppliedProcesses;
    if (!Util.isNotNullAndNotEmptyArray(blockAppliedProcesses)) {
      return [];
    }

    return blockAppliedProcesses.filter(
      (bap) =>
        bap.blockId === workOrder.materialRequisition.blockId &&
        bap.workOrderId === workOrder.id,
    );
  };
}

export default PageWorkOrderList;
