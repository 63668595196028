import {
    Col,
    Collapse,
    Form,
    FormInstance,
    Input,
    message,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Table
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { TilePackingCaseStatus } from "../../../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../../components/Consts";
import ExportUtil from "../../../../components/ExportUtil";
import SearchButtonGroup from "../../../../components/SearchButtonGroup";
import "./PageExportTiles.styl";
import Store, { IFilterData } from "./store";

const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;
const { Customers, SalesOrderStatus, TilePackingCaseStatusOptions } = Consts;

declare interface IExportTilesProps extends React.Props<PageExportTiles> {}

declare interface IExportTilesState {
    salesOrderId: number;
    visible: boolean; // 控制显示选择工程板功能
    areaNames: string[];
    caseNumbers: string[];
    positions: string[];
    statusCodes: TilePackingCaseStatus[];
    allExport: boolean;
    customers: any;
    buttonDisabled: boolean;
}

@observer
class PageExportTiles extends React.Component<
    IExportTilesProps,
    IExportTilesState
> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            visible: false,
            salesOrderId: null,
            areaNames: [],
            caseNumbers: [],
            positions: [],
            statusCodes: [],
            allExport: true,
            customers: [],
            buttonDisabled: false
        };
    }

    public async componentDidMount() {
        const t = this;
        const customers = await Customers.getCustomers();
        t.setState({ customers });
        this.loadData();
    }

    public render() {
        const t = this;
        const s = t.state;
        const filterData = Store.filterData;
        const pagingInfo = Store.pagingInfo;
        const customerOptions = s.customers.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        const columns = [
            {
                title: "销售订单号",
                dataIndex: "orderNumber",
                key: "orderNumber"
            },
            {
                title: "客户名称",
                dataIndex: "customerName",
                key: "customerName"
            },
            { title: "工程名称", dataIndex: "projectName", key: "projectName" },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                render: status => {
                    return SalesOrderStatus.getStatus(status).text;
                }
            },
            {
                title: "操作",
                key: "action",
                render: (text, record) => {
                    return (
                        <span>
                            <a
                                onClick={t.getSOTileInfoForFiltering.bind(
                                    t,
                                    record.id
                                )}
                            >
                                导出工程板
                            </a>
                        </span>
                    );
                }
            }
        ];

        return (
            <div>
                <div style={{ marginBottom: "10px" }}>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header="高级搜索" key="1">
                            <Form
                                ref={this.formRef}
                                name={"TileAdvancedSearchForm"}
                                onFinish={t.handleSearch}
                                {...formItemLayoutForSearch}
                            >
                                <Row>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="客户名称"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Select
                                                allowClear={true}
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                optionFilterProp="children"
                                                placeholder="请选择客户"
                                                value={filterData.customerIds}
                                                onChange={
                                                    t.handleSelectedCustomerChange
                                                }
                                            >
                                                {customerOptions}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="工程名称"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Input
                                                placeholder="请输入工程名称"
                                                value={filterData.projectName}
                                                onChange={
                                                    t.handleProjectNameChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="销售订单号"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Input
                                                placeholder="请输入销售订单号"
                                                value={filterData.orderNumber}
                                                onChange={
                                                    t.handleOrderNumberChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <SearchButtonGroup
                                    buttonDisabled={s.buttonDisabled}
                                    onClearClick={t.handleClearSearchCriteria}
                                />
                            </Form>
                        </Panel>
                    </Collapse>
                </div>
                {Store.salesOrderTileInfo ? t.getExportModePage() : null}
                <div>
                    <Table
                        columns={columns}
                        dataSource={Store.salesOrderList}
                        rowKey={"id"}
                        pagination={false}
                    />
                </div>
                {pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString}
                            pageSize={pagingInfo.pageSize}
                            current={pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private getTotalRecordString = (total: number): string => {
        return `共 ${total} 个销售订单`;
    };

    private async loadData() {
        await Store.loadData();
    }

    private handleSearch = async () => {
        Store.setPageNo(1);
        await this.loadData();
    };

    private handleClearSearchCriteria = async () => {
        const filterData: IFilterData = {
            orderType: [60],
            statusCode: [10, 20, 30, 40]
        };
        Store.setPageNo(1);
        Store.setPageSize(10);
        Store.updataFilterData(filterData);
        await this.loadData();
    };

    private handleOrderNumberChange(event) {
        Store.updataFilterData({
            ...Store.filterData,
            orderNumber: event.target.value
        });
    }

    private handleProjectNameChange(event) {
        Store.updataFilterData({
            ...Store.filterData,
            projectName: event.target.value
        });
    }

    private handleSelectedCustomerChange = (value: number[]) => {
        Store.updataFilterData({
            ...Store.filterData,
            customerIds: value
        });
    };

    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);
        await this.loadData();
    };

    private async getSOTileInfoForFiltering(salesOrderId: number) {
        const t = this;
        await Store.getSOTileInfoForFiltering(salesOrderId);
        t.setState({ visible: true, salesOrderId });
    }

    private handleAllExportChange = event => {
        this.setState({ allExport: event.target.value });
    };

    private handleStatusCodeChange = value => {
        this.setState({ statusCodes: value });
    };

    private handleCaseNumberChange = value => {
        this.setState({ caseNumbers: value });
    };

    private handleAreaNameChange = value => {
        this.setState({ areaNames: value });
    };

    private handlePositionChange = value => {
        this.setState({ positions: value });
    };

    private exportTiles = () => {
        const t = this;
        const s = t.state;
        let printAPI: string =
            "Tile/ExportTileSheet?salesOrderId=" + s.salesOrderId;

        if (!s.allExport) {
            if (s.areaNames && s.areaNames.length && s.areaNames.length > 0) {
                s.areaNames.forEach(areaName => {
                    printAPI += ExportUtil.addParameter(
                        printAPI,
                        "areaNames",
                        encodeURIComponent(areaName)
                    );
                });
            }
            if (s.positions && s.positions.length && s.positions.length > 0) {
                s.positions.forEach(position => {
                    printAPI += ExportUtil.addParameter(
                        printAPI,
                        "positions",
                        encodeURIComponent(position)
                    );
                });
            }
            if (
                s.caseNumbers &&
                s.caseNumbers.length &&
                s.caseNumbers.length > 0
            ) {
                s.caseNumbers.forEach(caseNumber => {
                    printAPI += ExportUtil.addParameter(
                        printAPI,
                        "caseNumbers",
                        encodeURIComponent(caseNumber)
                    );
                });
            }
            if (
                s.statusCodes &&
                s.statusCodes.length &&
                s.statusCodes.length > 0
            ) {
                s.statusCodes.forEach(statusCode => {
                    printAPI += ExportUtil.addParameter(
                        printAPI,
                        "statusCodes",
                        statusCode
                    );
                });
            }
        }
        message.info("正在导出指定销售订单中的工程板数据...");

        t.handleCancel();
        ExportUtil.setExportUrl(printAPI);
    };

    private handleCancel = () => {
        const newState = {
            visible: false,
            allExport: true,
            areaNames: [],
            caseNumbers: [],
            positions: [],
            statusCodes: []
        };
        this.setState(newState);
    };

    private getExportModePage() {
        const t = this;
        const s = t.state;
        const soTileinfo = Store.salesOrderTileInfo;
        const areaNameList = soTileinfo.areaNameList;
        const caseNumberList = soTileinfo.caseNumberList;
        const positionList = soTileinfo.positionList;
        const statusList = soTileinfo.statusList;

        const areaNameOption = areaNameList.map(areaName => {
            return (
                <Option key={areaName} value={areaName}>
                    {areaName}
                </Option>
            );
        });
        const positionOption = positionList.map(position => {
            return (
                <Option key={position} value={position}>
                    {position}
                </Option>
            );
        });
        const caseNumberOption = caseNumberList.map(caseNumber => {
            return (
                <Option key={caseNumber} value={caseNumber}>
                    {caseNumber}
                </Option>
            );
        });
        const statusOption = statusList.map(status => {
            const tpcStatu = TilePackingCaseStatusOptions.getStatus(status);
            return (
                <Option
                    key={tpcStatu.value.toString()}
                    value={tpcStatu.value.toString()}
                >
                    {tpcStatu.text}
                </Option>
            );
        });

        // 当状态选择列表不为空，则存在工程板，在箱号选择中添加未装箱选项
        if (statusList && statusList.length && statusList.length > 0) {
            caseNumberOption.push(
                <Option key="未装箱" value="未装箱">
                    未装箱
                </Option>
            );
        }

        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 16 }
        };

        return (
            <Modal
                title="选择导出方式"
                visible={s.visible}
                onOk={t.exportTiles}
                onCancel={t.handleCancel}
                okText="确定"
                cancelText="取消"
            >
                <FormItem
                    {...formItemLayout}
                    label="导出方式"
                    style={{ marginBottom: "10px" }}
                >
                    <Radio.Group
                        value={s.allExport}
                        buttonStyle="solid"
                        onChange={t.handleAllExportChange}
                    >
                        <Radio.Button value={true}>全部导出</Radio.Button>
                        <Radio.Button value={false}>条件导出</Radio.Button>
                    </Radio.Group>
                </FormItem>
                {!s.allExport ? (
                    <div>
                        <FormItem
                            {...formItemLayout}
                            label="状态"
                            style={{ marginBottom: "10px" }}
                        >
                            <Select
                                allowClear={true}
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="请选择状态"
                                value={s.statusCodes}
                                notFoundContent="无"
                                onChange={t.handleStatusCodeChange}
                            >
                                {statusOption}
                            </Select>
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="箱号"
                            style={{ marginBottom: "10px" }}
                        >
                            <Select
                                allowClear={true}
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="请选择箱号"
                                value={s.caseNumbers}
                                notFoundContent="无"
                                onChange={t.handleCaseNumberChange}
                            >
                                {caseNumberOption}
                            </Select>
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="图号"
                            style={{ marginBottom: "10px" }}
                        >
                            <Select
                                allowClear={true}
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="请选择图号"
                                value={s.areaNames}
                                notFoundContent="无"
                                onChange={t.handleAreaNameChange}
                            >
                                {areaNameOption}
                            </Select>
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label="区域位置"
                            style={{ marginBottom: "10px" }}
                        >
                            <Select
                                allowClear={true}
                                mode="multiple"
                                style={{ width: "100%" }}
                                placeholder="请选择区域位置"
                                value={s.positions}
                                notFoundContent="无"
                                onChange={t.handlePositionChange}
                            >
                                {positionOption}
                            </Select>
                        </FormItem>
                    </div>
                ) : null}
            </Modal>
        );
    }
}

export default PageExportTiles;
