import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import { ManufacturingReportDTO } from "../../../../app/WebAPIClients";
import Base from "../../../../stores/base";

class Store extends Base {
    @observable
    public reportData: ManufacturingReportDTO = null;

    @action
    public async loadData() {
        const t = this;

        const client = ClientFactory.getReportClient();
        const callAPI = async () => {
            const reportData = await client.getManufacturingReport();
            runInAction(() => {
                this.reportData = reportData;
            });
        };
        await t.callAPIFunForGet(callAPI);
    }
}

export default new Store();
