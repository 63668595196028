import { Alert, Button, Col, Collapse, Input, Row, Spin } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
    FileInfoViewModel,
    ImportingTemplateClient
} from "../../../../app/WebAPIClients";
import {
    colLayoutForButton,
    colLayoutForInput
} from "../../../../components/Consts";
import ErrorHandler from "../../../../components/ErrorHandler";
import Util from "../../../../components/Util";
import "./PageImportPolishingData.styl";
import Store from "./store";
import { TemplateFileDownloadButton } from "../../../../components/TemplateFileDownloadButton";
import SoushiSlabDataFileDownloadTable from "./SoushiSlabDataFileDownloadTable";
import Settings from "../../../../components/SettingInfo";
import SettingsDefinition from "../../../../components/SettingsDefinition";

const Panel = Collapse.Panel;

declare interface IPageImportPolishingDataProps
    extends RouteComponentProps<{}> {}

declare interface IPageImportPolishingDataState extends IActionErrorState {
    fileName: string;
    uploading?: boolean;
    enableSoushiIntegration: boolean;
}

@observer
class PageImportPolishingData extends React.Component<
    IPageImportPolishingDataProps,
    IPageImportPolishingDataState
> {
    private file: React.RefObject<HTMLInputElement>;
    constructor(props) {
        super(props);
        this.state = {
            fileName: null,
            enableSoushiIntegration: Settings.getBoolValue(
                SettingsDefinition.EnableSoushiIntegrationKey
            )
        };
        this.file = React.createRef();
    }

    public render() {
        const t = this;
        const s = t.state;
        const { result } = Store;

        const slabSeqNumUpdatingSucceeded = [];
        const errorMsgs = []; // 错误消息列表
        const warningMsgs = []; // 警告消息列表
        const successMsgs = []; // 成功消息列表
        if (
            result &&
            Util.isNotNullAndNotEmptyArray(result.dataUpdatingMessages)
        ) {
            result.dataUpdatingMessages.forEach(msg => {
                if (msg.includes("错误：")) {
                    errorMsgs.push(msg);
                    return;
                } else if (msg.includes("警告：")) {
                    warningMsgs.push(msg);
                    return;
                } else if (msg.includes("成功更新大板数据，扎号：")) {
                    const re = /^成功更新大板数据，扎号：[\S\s]*，大板序号：(\d*)$/g;
                    const reResult = re.exec(msg);
                    if (reResult) {
                        slabSeqNumUpdatingSucceeded.push(reResult[1]);
                        return;
                    }
                } else {
                    successMsgs.push(msg);
                }
            });
        }

        var updatingMsgPanelActiveKeys: string[] = [];
        if (successMsgs.length > 0 || slabSeqNumUpdatingSucceeded.length > 0) {
            updatingMsgPanelActiveKeys.push("11");
        }
        if (warningMsgs.length > 0) {
            updatingMsgPanelActiveKeys.push("12");
        }
        if (errorMsgs.length > 0) {
            updatingMsgPanelActiveKeys.push("13");
        }

        return (
            <div>
                <p>导入光板磨抛数据</p>
                <Row style={{ marginTop: "20px" }}>
                    <Col {...colLayoutForInput}>
                        <Input disabled={true} value={s.fileName} />
                        <input
                            type="file"
                            ref={this.file}
                            accept=".xlsx"
                            style={{ display: "none" }}
                            onChange={t.fileChanged}
                        />
                    </Col>
                    <Col {...colLayoutForButton}>
                        <Button
                            type="primary"
                            onClick={t.selectFile}
                            disabled={s.uploading}
                        >
                            选择文件
                        </Button>
                        <Button
                            onClick={t.importFile}
                            type="primary"
                            disabled={s.uploading}
                        >
                            上传并导入
                        </Button>
                        <Button
                            onClick={t.clearResult}
                            danger={true}
                            disabled={s.uploading}
                        >
                            清空导入结果
                        </Button>
                        <TemplateFileDownloadButton
                            onDownload={t.onTemplateDownload}
                        />
                    </Col>
                </Row>

                {s.uploading ? (
                    <div className="importingSpinArea">
                        <p>正在上传文件并导入数据，请等待...</p>
                        <Spin />
                    </div>
                ) : null}

                {Store.result ? (
                    <div style={{ marginTop: "20px" }}>
                        <Collapse defaultActiveKey={["1", "2"]}>
                            {result &&
                            result.dataParsingMessages &&
                            result.dataParsingMessages.length > 0 ? (
                                <Panel header={"导入文件解析结果"} key="1">
                                    {result.dataParsingMessages.map(msg => {
                                        return (
                                            <Alert
                                                key={msg}
                                                message={msg}
                                                type="error"
                                            />
                                        );
                                    })}
                                </Panel>
                            ) : null}
                            {result &&
                            result.dataUpdatingMessages &&
                            result.dataUpdatingMessages.length > 0 ? (
                                <Panel header={"磨抛数据导入结果"} key="2">
                                    <Collapse
                                        defaultActiveKey={
                                            updatingMsgPanelActiveKeys
                                        }
                                    >
                                        {slabSeqNumUpdatingSucceeded.length >
                                            0 || successMsgs.length > 0 ? (
                                            <Panel header={"成功消息"} key="11">
                                                {slabSeqNumUpdatingSucceeded.length >
                                                0 ? (
                                                    <Alert
                                                        message={
                                                            "成功更新大板，序号：" +
                                                            slabSeqNumUpdatingSucceeded.join(
                                                                ", "
                                                            )
                                                        }
                                                        type="success"
                                                    />
                                                ) : null}
                                                {Util.isNotNullAndNotEmptyArray(
                                                    successMsgs
                                                )
                                                    ? successMsgs.map(
                                                          (msg, index) => {
                                                              return (
                                                                  <Alert
                                                                      key={msg}
                                                                      message={
                                                                          msg
                                                                      }
                                                                      type="success"
                                                                      showIcon={
                                                                          true
                                                                      }
                                                                  />
                                                              );
                                                          }
                                                      )
                                                    : null}
                                            </Panel>
                                        ) : null}
                                        {warningMsgs.length > 0 ? (
                                            <Panel header={"警告消息"} key="12">
                                                {warningMsgs.map(msg => {
                                                    return (
                                                        <Alert
                                                            key={msg}
                                                            message={msg}
                                                            type="warning"
                                                            showIcon={true}
                                                        />
                                                    );
                                                })}
                                            </Panel>
                                        ) : null}
                                        {errorMsgs.length > 0 ? (
                                            <Panel header={"出错消息"} key="13">
                                                {errorMsgs.map(msg => {
                                                    return (
                                                        <Alert
                                                            key={msg}
                                                            message={msg}
                                                            type="error"
                                                            showIcon={true}
                                                        />
                                                    );
                                                })}
                                            </Panel>
                                        ) : null}
                                    </Collapse>
                                </Panel>
                            ) : null}
                        </Collapse>
                        {s.enableSoushiIntegration &&
                        Util.isNotNullAndNotEmptyArray(result.bundleData) ? (
                            <div style={{ marginTop: "10px" }}>
                                <SoushiSlabDataFileDownloadTable
                                    bundleData={result.bundleData}
                                />
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        );
    }

    private importFile = () => {
        const t = this;

        const fileDOM = this.file.current;

        if (fileDOM.files.length === 0) {
            ErrorHandler.handleErr("请选择一个.xlsx文件进行导入");
            return;
        }

        t.stageUploading();

        const file = fileDOM.files[0];

        const reader = new FileReader();

        reader.onload = async (evt: ProgressEvent<FileReader>) => {
            let fileContent = evt.target.result as string;
            const fileName = file.name;
            if (fileContent.length > 0 && fileContent.includes("base64,")) {
                fileContent = fileContent.split("base64,")[1];
            }
            if (fileContent.length > 0) {
                try {
                    const params = {
                        fileName,
                        fileContent
                    };
                    await Store.importPolishingData(
                        FileInfoViewModel.fromJS(params)
                    );
                    t.finishUploading();
                } catch (err) {
                    t.finishUploading();
                    ErrorHandler.handleErr(
                        "遇到不可恢复错误，请关闭工作台重新进入再试一次。详细信息：",
                        err
                    );
                }
            }
        };
        reader.readAsDataURL(file);
    };

    private selectFile = () => {
        this.file.current.click();
    };

    private fileChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fileName: evt.target.files[0].name });
    };

    private clearResult = () => {
        Store.setResult();
    };

    private stageUploading() {
        this.setState({ uploading: true });
    }

    private finishUploading() {
        this.setState({ uploading: false });
    }

    private onTemplateDownload = (client: ImportingTemplateClient) => {
        return client.getPolishedSlabImportingTemplate();
    };
}

export default PageImportPolishingData;
