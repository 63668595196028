import settings from "../components/Settings";
import userInfo from "../components/UserInfo";
import JWTWebAPIConfig from "./JWTWebAPIConfig";
import {
    AccountClient,
    AppliedPriceTypeClient,
    BankAccountClient,
    BlockClient,
    CustomerClient,
    CustomerGradeClient,
    CustomerTypeClient,
    DepositIncurredClient,
    DingtalkClient,
    FactoryClient,
    FollowUpRecordClient,
    GuidClient,
    ImagingDeviceClient,
    ImportingTemplateClient,
    MachineryClient,
    MachineryTypeClient,
    PackagingMethodClient,
    PaymentOrderClient,
    ProcessesClient,
    PublicClient,
    QuarryClient,
    ReportClient,
    SalesOrderClient,
    SawingBladeManufacturerClient,
    SecurityClient,
    ShippingAddressClient,
    ShippingOrderClient,
    SlabCheckOutRequestClient,
    SlabClient,
    SlabReturnRequestClient,
    SoushiClient,
    StockInAndOutRecordClient,
    StockingAreaClient,
    StoneBundleClient,
    StoneCategoryClient,
    StoneGradeClient,
    SystemClient,
    TileClient,
    TransferOrderClient,
    UserClient,
    WarehouseClient,
    WebAPIConfig,
    WorkOrderClient
} from "./WebAPIClients";

class WebAPIClientFactory {
    public static getAccountClient(): AccountClient {
        return new AccountClient(this.getJWTWebAPIConfig());
    }

    public static getAppliedPriceTypeClient(): AppliedPriceTypeClient {
        return new AppliedPriceTypeClient(this.getJWTWebAPIConfig());
    }

    public static getBankAccountClient(): BankAccountClient {
        return new BankAccountClient(this.getJWTWebAPIConfig());
    }

    public static getBlockClient(): BlockClient {
        return new BlockClient(this.getJWTWebAPIConfig());
    }

    public static getCustomerClient(): CustomerClient {
        return new CustomerClient(this.getJWTWebAPIConfig());
    }

    public static getCustomerGradeClient(): CustomerGradeClient {
        return new CustomerGradeClient(this.getJWTWebAPIConfig());
    }

    public static getCustomerTypeClient(): CustomerTypeClient {
        return new CustomerTypeClient(this.getJWTWebAPIConfig());
    }

    public static getDepositIncurredClient(): DepositIncurredClient {
        return new DepositIncurredClient(this.getJWTWebAPIConfig());
    }

    public static getDingtalkClient(): DingtalkClient {
        return new DingtalkClient(this.getJWTWebAPIConfig());
    }

    public static getFactoryClient(): FactoryClient {
        return new FactoryClient(this.getJWTWebAPIConfig());
    }

    public static getFollowUpRecordClient(): FollowUpRecordClient {
        return new FollowUpRecordClient(this.getJWTWebAPIConfig());
    }

    public static getGuidClient(): GuidClient {
        return new GuidClient(this.getJWTWebAPIConfig());
    }

    public static getImagingDeviceClient(): ImagingDeviceClient {
        return new ImagingDeviceClient(this.getJWTWebAPIConfig());
    }

    public static getMachineryClient(): MachineryClient {
        return new MachineryClient(this.getJWTWebAPIConfig());
    }

    public static getMachineryTypeClient(): MachineryTypeClient {
        return new MachineryTypeClient(this.getJWTWebAPIConfig());
    }

    public static getPackagingMethodClient(): PackagingMethodClient {
        return new PackagingMethodClient(this.getJWTWebAPIConfig());
    }

    public static getPaymentOrderClient(): PaymentOrderClient {
        return new PaymentOrderClient(this.getJWTWebAPIConfig());
    }

    public static getProcessesClient(): ProcessesClient {
        return new ProcessesClient(this.getJWTWebAPIConfig());
    }

    public static getPublicClient(): PublicClient {
        return new PublicClient(this.getJWTWebAPIConfig());
    }

    public static getQuarryClient(): QuarryClient {
        return new QuarryClient(this.getJWTWebAPIConfig());
    }

    public static getReportClient(): ReportClient {
        return new ReportClient(this.getJWTWebAPIConfig());
    }

    public static getSalesOrderClient(): SalesOrderClient {
        return new SalesOrderClient(this.getJWTWebAPIConfig());
    }

    public static getSawingBladeManufacturerClient(): SawingBladeManufacturerClient {
        return new SawingBladeManufacturerClient(this.getJWTWebAPIConfig());
    }

    public static getSecurityClient(): SecurityClient {
        return new SecurityClient(this.getJWTWebAPIConfig());
    }

    public static getShippingAddressClient(): ShippingAddressClient {
        return new ShippingAddressClient(this.getJWTWebAPIConfig());
    }

    public static getShippingOrderClient(): ShippingOrderClient {
        return new ShippingOrderClient(this.getJWTWebAPIConfig());
    }

    public static getSlabCheckOutRequestClient(): SlabCheckOutRequestClient {
        return new SlabCheckOutRequestClient(this.getJWTWebAPIConfig());
    }

    public static getSlabClient(): SlabClient {
        return new SlabClient(this.getJWTWebAPIConfig());
    }

    public static getSlabReturnRequestClient(): SlabReturnRequestClient {
        return new SlabReturnRequestClient(this.getJWTWebAPIConfig());
    }

    public static getStockInAndOutRecordClient(): StockInAndOutRecordClient {
        return new StockInAndOutRecordClient(this.getJWTWebAPIConfig());
    }

    public static getStockingAreaClient(): StockingAreaClient {
        return new StockingAreaClient(this.getJWTWebAPIConfig());
    }

    public static getStoneBundleClient(): StoneBundleClient {
        return new StoneBundleClient(this.getJWTWebAPIConfig());
    }

    public static getStoneCategoryClient(): StoneCategoryClient {
        return new StoneCategoryClient(this.getJWTWebAPIConfig());
    }

    public static getStoneGradeClient(): StoneGradeClient {
        return new StoneGradeClient(this.getJWTWebAPIConfig());
    }

    public static getTileClient(): TileClient {
        return new TileClient(this.getJWTWebAPIConfig());
    }

    public static getTransferOrderClient(): TransferOrderClient {
        return new TransferOrderClient(this.getJWTWebAPIConfig());
    }

    public static getUserClient(): UserClient {
        return new UserClient(this.getJWTWebAPIConfig());
    }

    public static getWarehouseClient(): WarehouseClient {
        return new WarehouseClient(this.getJWTWebAPIConfig());
    }

    public static getWorkOrderClient(): WorkOrderClient {
        return new WorkOrderClient(this.getJWTWebAPIConfig());
    }

    public static getSystemClient(): SystemClient {
        return new SystemClient(this.getJWTWebAPIConfig());
    }

    public static getImportingTemplateClient(): ImportingTemplateClient {
        return new ImportingTemplateClient(this.getJWTWebAPIConfig());
    }

    public static getSoushiClient(): SoushiClient {
        return new SoushiClient(this.getJWTWebAPIConfig());
    }

    private static getAccessToken(): string {
        return userInfo.getAccessToken();
    }

    private static getWebAPIUrl(): string {
        return settings.backendHost;
    }

    private static getJWTWebAPIConfig(): WebAPIConfig {
        const url = this.getWebAPIUrl();
        const token = this.getAccessToken();
        const config = new JWTWebAPIConfig(url, token);
        return config;
    }
}

export default WebAPIClientFactory;
