import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import {
    BlockAppliedProcessDTO,
    WorkOrderForListDTO,
    WorkOrderFullInfoDTO
} from "../../../app/WebAPIClients";
import ColumnSettings from "../../../components/ColumnSettings";
import Util from "../../../components/Util";
import Base from "../../../stores/base";
export interface IFilterData {
    selectBlockNumber?: string;
    categoryIds?: number[]; // 石材种类
    thickness?: number; // 厚度
    customerIds?: number[];
    manufacturingStates?: number[]; // 状态
    factoryIds?: number[];
    creationEndDate?: Date;
    creationStartDate?: Date;
    sawingStartDate?: Date;
    sawingEndDate?: Date;
    trimmingStartDate?: Date;
    trimmingEndDate?: Date;
    fillingStartDate?: Date;
    fillingEndDate?: Date;
    polishingStartDate?: Date;
    polishingEndDate?: Date;
    selectOrderNumber?: string;
    selectSalesOrderNumber?: string;
    manufacturingMethods?: [];
    priorities?: [];
    skipFilling?: string;
}
class Store extends Base {
    @observable
    public filterData: IFilterData = {
        selectBlockNumber: undefined,
        selectOrderNumber: undefined,
        selectSalesOrderNumber: undefined,
        categoryIds: [],
        manufacturingStates: [],
        thickness: undefined,
        customerIds: [],
        factoryIds: [],
        manufacturingMethods: [],
        creationEndDate: undefined,
        creationStartDate: undefined,
        sawingStartDate: undefined,
        sawingEndDate: undefined,
        trimmingStartDate: undefined,
        trimmingEndDate: undefined,
        fillingStartDate: undefined,
        fillingEndDate: undefined,
        polishingStartDate: undefined,
        polishingEndDate: undefined,
        priorities: [],
        skipFilling: undefined
    };

    @observable
    public workOrderList: WorkOrderForListDTO[] = [];
    @observable
    public workOrderInfoList: WorkOrderFullInfoDTO[] = [];
    @observable
    public blockAppliedProcesses: BlockAppliedProcessDTO[] = [];

    private componentName: string = "WorkOrderList";

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }

    @action
    public async reloadData() {
        const t = this;
        const filterData = t.filterData;
        const {
            selectBlockNumber,
            selectOrderNumber,
            selectSalesOrderNumber,
            categoryIds,
            manufacturingStates,
            thickness,
            customerIds,
            factoryIds,
            manufacturingMethods,
            creationEndDate,
            creationStartDate,
            sawingStartDate,
            sawingEndDate,
            trimmingStartDate,
            trimmingEndDate,
            fillingStartDate,
            fillingEndDate,
            polishingStartDate,
            polishingEndDate,
            priorities,
            skipFilling
        } = filterData;

        const client = ClientFactory.getWorkOrderClient();
        const ss = ColumnSettings.getSortSettingsForBackend(this.componentName);
        const selecteSkipFilling = Util.isDefinedAndNotNull(skipFilling)
            ? skipFilling === "10"
                ? true
                : false
            : null;
        const callAPI = async () => {
            const content = await client.getAll(
                manufacturingStates,
                categoryIds,
                factoryIds,
                customerIds,
                manufacturingMethods,
                priorities,
                thickness,
                selectBlockNumber,
                selectOrderNumber,
                selectSalesOrderNumber,
                Util.getValidDate(creationStartDate),
                Util.getValidDate(creationEndDate),
                Util.getValidDate(trimmingStartDate),
                Util.getValidDate(trimmingEndDate),
                Util.getValidDate(sawingStartDate),
                Util.getValidDate(sawingEndDate),
                Util.getValidDate(fillingStartDate),
                Util.getValidDate(fillingEndDate),
                Util.getValidDate(polishingStartDate),
                Util.getValidDate(polishingEndDate),
                ss,
                selecteSkipFilling,
                t.pageSize,
                t.pageNo
            );

            runInAction(() => {
                t.workOrderList = content.result;
            });
            t.setPagingInfo(content.pagingInfo);
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async loadWO(woId: number) {
        const t = this;
        const client = ClientFactory.getWorkOrderClient();

        const callAPI = async () => {
            const result = await client.get(woId);
            let workOrderInfoList = [...t.workOrderInfoList];
            const woInfo = workOrderInfoList.find(w => {
                return w.id === result.id;
            });

            if (Util.isDefinedAndNotNull(woInfo)) {
                workOrderInfoList = workOrderInfoList.filter(
                    w => w.id === result.id
                );
                workOrderInfoList.push(result);
            } else {
                workOrderInfoList.push(result);
            }

            runInAction(() => {
                this.workOrderInfoList = workOrderInfoList;
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async getProcessesBySegments(blockId: number, workOrderId: number) {
        const t = this;

        const callAPI = async () => {
            const blockClient = ClientFactory.getBlockClient();
            const r = await blockClient.getProcessesBySegments(
                [blockId],
                [],
                workOrderId
            );
            let blockAppliedProcesses = [...t.blockAppliedProcesses];
            if (Util.isNotNullAndNotEmptyArray(r)) {
                blockAppliedProcesses = [...blockAppliedProcesses, ...r];
            }

            runInAction(() => {
                this.blockAppliedProcesses = blockAppliedProcesses;
            });
        };

        await t.callAPIFunForGet(callAPI);
    }
}

export default new Store();
