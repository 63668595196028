import { Button, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import React from "react";
import { IBundlePolishedDataInfoDTO } from "../../../../app/WebAPIClients";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import { FileDownloadButton } from "../../../../components/FileDownloadButton";
import { FileOutlined } from "@ant-design/icons";

interface ISoushiSlabDataFileDownloadTableProps {
    bundleData: IBundlePolishedDataInfoDTO[];
}

const SoushiSlabDataFileDownloadTable = (
    props: ISoushiSlabDataFileDownloadTableProps
) => {
    const { bundleData } = props;

    if (!(bundleData && bundleData.length)) {
        return null;
    }

    const columns: ColumnsType<IBundlePolishedDataInfoDTO> = [
        {
            title: "荒料编号",
            dataIndex: "prefix",
            key: "prefix",
            align: "center"
        },
        {
            title: "扎号",
            dataIndex: "bundleNo",
            key: "bundleNo",
            align: "center"
        },
        {
            title: "操作",
            key: "actions",
            align: "center",
            render: (_, record) => {
                const onDownload = () => {
                    const client = ClientFactory.getSoushiClient();
                    return client.exportSlabData([record.bundleId]);
                };
                return (
                    <FileDownloadButton
                        text="下载本扎导入文件"
                        onDownload={onDownload}
                    />
                );
            }
        }
    ];

    const onAllBundleFilesDownload = () => {
        const client = ClientFactory.getSoushiClient();
        return client.exportSlabData(bundleData.map(d => d.bundleId));
    };

    const footerRender =
        bundleData.length <= 1
            ? null
            : () => {
                  return (
                      <FileDownloadButton
                          text="下载所有扎导入文件"
                          onDownload={onAllBundleFilesDownload}
                      />
                  );
              };

    return (
        <Table
            bordered
            columns={columns}
            dataSource={bundleData}
            pagination={false}
            size="middle"
            title={() => "下载搜石导入文件"}
            footer={footerRender}
        />
    );
};

export default SoushiSlabDataFileDownloadTable;
