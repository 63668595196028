import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import {
    FileInfoViewModel,
    IDataImportResultDTO
} from "../../../../app/WebAPIClients";
import Base from "../../../../stores/base";

class Store extends Base {
    @observable
    public result: IDataImportResultDTO = null;

    @action
    public async importReorganizedBundleData(params: FileInfoViewModel) {
        const t = this;

        const client = ClientFactory.getStoneBundleClient();
        const callAPI = async () => {
            const result = await client.importReorganizedBundleData(params);
            runInAction(() => {
                t.result = result;
            });
        };

        await this.callAPIFun(
            callAPI,
            "数据导入中...",
            "数据导入出错，请重试。详细信息："
        );
    }

    @action
    public setResult(result?: IDataImportResultDTO) {
        this.result = result;
    }
}

export default new Store();
