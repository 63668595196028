import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import {
  BundleWithSlabsForListDTO,
  PagedResultWithAreaOfListOfBundleForListDTO,
  PagedResultWithAreaOfListOfSlabForListDTO,
  SlabBundleStatus,
  SlabForListDTO,
  StoneArtifactType,
} from "../../../../app/WebAPIClients";
import ColumnSettings from "../../../../components/ColumnSettings";
import Util from "../../../../components/Util";
import Base from "../../../../stores/base";

export interface IFilterData {
  selectPrefix?: string;
  categoryIds?: number[];
  gradeIds?: number[];
  stockingAreaIds?: number[];
  statusCodes?: number[];
  maxLength?: number;
  minLength?: number;
  maxWidth?: number;
  minWidth?: number;
  maxUnitPrice?: number;
  minUnitPrice?: number;
  thickness?: number;
  reorganized?: string;
  manufacturingMethods?: number[];
  manufacturingStates?: number[];
  slabTypes?: number[];
  selectBlockNumberFromQuarry?: string;
  stockInTimeStartDate?: Date;
  stockInTimeEndDate?: Date;
  stockOutTimeStartDate?: Date;
  stockOutTimeEndDate?: Date;
  warehouseId?: number;

  /*大板筛选字段定义 */
  slabCategoryIds?: number[];
  slabGradeIds?: number[];
  slabStatusCodes?: number[];
  slabThickness?: number;
  slabBundlePrefix?: string;
  slabBlockNumberFromQuarry?: string;
  slabBlockNumber?: string;
  slabMaxLength?: number;
  slabMinLength?: number;
  slabMaxWidth?: number;
  slabMinWidth?: number;
  slabMaxUnitPrice?: number;
  slabMinUnitPrice?: number;
  slabSequenceNumber?: number;
  slabOriginalSequenceNumber?: number;
  slabShowScatteredSlab?: string;
  slabStockingAreaIds?: number[];
  slabStockInTimeStartDate?: Date;
  slabStockInTimeEndDate?: Date;
  slabStockOutTimeStartDate?: Date;
  slabStockOutTimeEndDate?: Date;
  slabReorganized?: string;
  slabSLTypes?: number[];
  slabManufacturingMethods?: number[];
  slabManufacturingStates?: number[];
  slabWarehouseId?: number;
}

class Store extends Base {
  @observable
  public filterData: IFilterData = {
    reorganized: undefined,
    slabReorganized: undefined,
    slabShowScatteredSlab: undefined,
  };

  @observable
  public stoneBundleList: BundleWithSlabsForListDTO[] = [];
  @observable
  public totalArea: number = 0;

  @observable
  public slabList: SlabForListDTO[] = [];

  @observable
  public stoneArtifactType: StoneArtifactType = StoneArtifactType.StoneBundle;

  private componentName: string = "StoneBundleList";
  private slabComponentName: string = "SlabList";
  @action
  public updataFilterData(filterData: IFilterData) {
    this.filterData = filterData;
  }
  @action
  public setStoneArtifactType(type: StoneArtifactType) {
    this.stoneArtifactType = type;
  }

  @action
  public async reloadData() {
    const t = this;
    const filterData = t.filterData;

    const bundleClient = ClientFactory.getStoneBundleClient();
    const slabClient = ClientFactory.getSlabClient();
    let client = null;
    let lable = null;
    let statusCodes = null;
    switch (t.stoneArtifactType) {
      case StoneArtifactType.StoneBundle:
        lable = "stoneBundleList";
        statusCodes = this.getStatusCodes(
          filterData.statusCodes,
          filterData.stockOutTimeStartDate,
          filterData.stockOutTimeEndDate,
        );
        client = bundleClient.getAll(
          filterData.maxLength,
          filterData.maxWidth,
          filterData.maxUnitPrice,
          filterData.thickness,
          Util.isDefinedAndNotNull(filterData.reorganized)
            ? filterData.reorganized === "10"
              ? true
              : false
            : null, // 因为Select控件的value的数据类型是string | number，并不支持boolean，所以调用后台接口时需转换reorganized的值类型
          Util.getValidDate(filterData.stockInTimeStartDate),
          Util.getValidDate(filterData.stockInTimeEndDate),
          Util.getValidDate(filterData.stockOutTimeStartDate),
          Util.getValidDate(filterData.stockOutTimeEndDate),
          filterData.selectPrefix,
          filterData.selectBlockNumberFromQuarry,
          ColumnSettings.getSortSettingsForBackend(this.componentName),
          filterData.categoryIds,
          filterData.gradeIds,
          Util.isDefinedAndNotNull(filterData.warehouseId)
            ? [filterData.warehouseId]
            : undefined,
          filterData.stockingAreaIds,
          filterData.slabTypes,
          statusCodes,
          filterData.manufacturingStates,
          filterData.manufacturingMethods,
          filterData.minLength,
          filterData.minWidth,
          filterData.minUnitPrice,
          t.pageSize,
          t.pageNo,
        );
        break;
      case StoneArtifactType.Slab:
        lable = "slabList";
        statusCodes = this.getStatusCodes(
          filterData.slabStatusCodes,
          filterData.slabStockOutTimeStartDate,
          filterData.slabStockOutTimeEndDate,
        );
        client = slabClient.getAll(
          filterData.slabMaxLength,
          filterData.slabMaxWidth,
          filterData.slabMaxUnitPrice,
          filterData.slabThickness,
          Util.isDefinedAndNotNull(filterData.slabReorganized)
            ? filterData.slabReorganized === "10"
              ? true
              : false
            : null,
          filterData.slabBundlePrefix,
          filterData.slabBlockNumber,
          filterData.slabBlockNumberFromQuarry,
          filterData.slabCategoryIds,
          filterData.slabGradeIds,
          Util.isDefinedAndNotNull(filterData.slabWarehouseId)
            ? [filterData.slabWarehouseId]
            : undefined,
          filterData.slabStockingAreaIds,
          filterData.slabSequenceNumber,
          filterData.slabOriginalSequenceNumber,
          undefined,
          filterData.slabSLTypes,
          statusCodes,
          filterData.slabManufacturingStates,
          filterData.slabManufacturingMethods,
          ColumnSettings.getSortSettingsForBackend(this.slabComponentName),
          Util.getValidDate(filterData.slabStockInTimeStartDate),
          Util.getValidDate(filterData.slabStockInTimeEndDate),
          Util.getValidDate(filterData.slabStockOutTimeStartDate),
          Util.getValidDate(filterData.slabStockOutTimeEndDate),
          filterData.slabMinLength,
          filterData.slabMinWidth,
          filterData.slabMinUnitPrice,
          t.pageSize,
          t.pageNo,
          Util.isDefinedAndNotNull(filterData.slabShowScatteredSlab)
            ? filterData.slabShowScatteredSlab === "10"
              ? true
              : false
            : null,
          undefined,
          undefined,
        );
        break;
    }
    if (client != null) {
      const callAPI = async () => {
        const content:
          | PagedResultWithAreaOfListOfBundleForListDTO
          | PagedResultWithAreaOfListOfSlabForListDTO =
          await Promise.resolve(client);
        runInAction(() => {
          t[lable] = content.result;
          t.totalArea = content.totalArea;
        });
        t.setPagingInfo(content.pagingInfo);
      };
      await t.callAPIFunForGet(callAPI);
    }
  }

  @action
  public async loadSlabs(stoneBundleId: number) {
    const t = this;
    const client = ClientFactory.getStoneBundleClient();
    const callAPI = async () => {
      const result = await client.getFullInfo(stoneBundleId);
      const stoneBundleList = [...t.stoneBundleList];
      const bundle = stoneBundleList.find((b) => {
        return b.id === stoneBundleId;
      });
      if (bundle) {
        bundle.slabs = result.slabs;
      }
      runInAction(() => {
        this.stoneBundleList = stoneBundleList;
      });
    };

    await t.callAPIFunForGet(callAPI);
  }

  private getStatusCodes(
    selectStatusCodes: number[] | undefined | null,
    stockOutStartDate: string | Date | undefined | null,
    stockOutEndDate: string | Date | undefined | null,
  ) {
    // 当用户选择stockOutStartDate和stockOutEndDate，并且未指定状态的情况下，筛选状态默认为排除在库存之外的其它状态，
    // 做此变更是因为在实际应用场景中，如果选择出库时间，通常是需要搜索实际出库的物品，
    // 而通过调拨单产生的在库存的物品会存在出库时间，需要把这部分排除

    const statusCodes =
      Util.isNotNullAndNotEmpty(stockOutStartDate) &&
      Util.isNotNullAndNotEmpty(stockOutEndDate) &&
      !Util.isNotNullAndNotEmptyArray(selectStatusCodes)
        ? [
            SlabBundleStatus.CheckedOut,
            SlabBundleStatus.Manufacturing,
            SlabBundleStatus.ReservedBySalesOrder,
            SlabBundleStatus.ReservedBySlabCheckOutRequest,
            SlabBundleStatus.ReservedBySlabReturnRequest,
            SlabBundleStatus.Sold,
            SlabBundleStatus.Discarded,
          ]
        : selectStatusCodes;
    return statusCodes;
  }
}

export default new Store();
