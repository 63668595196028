import { Button, Col, Input, Row } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
    colLayoutForButton,
    colLayoutForInput
} from "../../../../components/Consts";
import ErrorHandler from "../../../../components/ErrorHandler";
import settings from "../../../../components/Settings";
import userInfo from "../../../../components/UserInfo";
import "./PageCompareInventoryExcelFile.styl";

declare interface IPageCompareInventoryExcelFileProps
    extends RouteComponentProps<{}> {}

declare interface IPageCompareInventoryExcelFileState {
    fileName: string;
}

class PageCompareInventoryExcelFile extends React.Component<
    IPageCompareInventoryExcelFileProps,
    IPageCompareInventoryExcelFileState
> {
    private file: React.RefObject<HTMLInputElement>;
    private customForm: React.RefObject<HTMLFormElement>;

    constructor(props) {
        super(props);
        this.state = {
            fileName: ""
        };
        this.file = React.createRef();
        this.customForm = React.createRef();
    }

    public render() {
        const t = this;
        const s = t.state;

        return (
            <div>
                <p>生成库存盘点Excel文件对比结果</p>
                <Row style={{ marginTop: "20px" }}>
                    <Col {...colLayoutForInput}>
                        <Input
                            id="fileName"
                            name="fileName"
                            readOnly={true}
                            value={s.fileName}
                        />
                        <form
                            encType="multipart/form-data"
                            ref={t.customForm}
                            method="post"
                            action={t.getFormAction()}
                        >
                            <input
                                type="file"
                                ref={t.file}
                                id="excelFile"
                                name="excelFile"
                                accept=".xlsx"
                                style={{ display: "none" }}
                                onChange={t.fileChanged}
                            />
                        </form>
                    </Col>
                    <Col {...colLayoutForButton}>
                        <Button type="primary" onClick={t.selectFile}>
                            选择文件
                        </Button>
                        <Button onClick={t.uploadFile} type="primary">
                            上传文件并对比
                        </Button>
                    </Col>
                </Row>
            </div>
        );
    }

    private uploadFile = () => {
        const fileDOM = this.file.current;

        if (fileDOM.files.length === 0) {
            ErrorHandler.handleErr("请选择一个.xlsx文件进行上传");
            return;
        }

        const form = this.customForm.current;

        form.submit();
    };

    private selectFile = () => {
        this.file.current.click();
    };

    private fileChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fileName: evt.target.files[0].name });
    };

    private getFormAction() {
        const webApiUrl = settings.webApiUrl;
        return (
            webApiUrl +
            "StoneBundle/CompareInventoryExcelFile?access_token=" +
            userInfo.getAccessToken()
        );
    }
}

export default PageCompareInventoryExcelFile;
