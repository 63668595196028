import {
    Button,
    Checkbox,
    Col,
    Collapse,
    Form,
    FormInstance,
    Input,
    message,
    Pagination,
    Row,
    Select,
    Spin,
    Table
} from "antd";
// tslint:disable-next-line: no-submodule-imports
import { CheckboxChangeEvent } from "antd/lib/checkbox";
// tslint:disable-next-line: no-submodule-imports
import { ColumnProps } from "antd/lib/table";
import { observer } from "mobx-react";
import React, { ChangeEvent } from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../../app/Perm";
import { CustomerForListDTO } from "../../../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../../components/Consts";
import ExportUtil from "../../../../components/ExportUtil";
import PermCtl from "../../../../components/PermCtrl";
import SearchButtonGroup from "../../../../components/SearchButtonGroup";
import { ISalesOrderForListForUI } from "../../../../components/type";
import Util from "../../../../components/Util";
import "./PageExportTilePackingSheet.styl";
import Store, { IFilterData } from "./store";

const FormItem = Form.Item;
const Option = Select.Option;
const Panel = Collapse.Panel;

const { Customers, TilePackingCaseStatusOptions, SalesOrderStatus } = Consts;

declare interface IPageExportTilePackingSheetProps
    extends RouteComponentProps<{}> {}

declare interface IPageExportTilePackingSheetState extends IActionErrorState {
    caseIdsForExporting: Array<{ salesOrderId: number; caseIds: number[] }>;
    tpcExpandedArr: number[];
    customers: CustomerForListDTO[];
}

@observer
class PageExportTilePackingSheet extends React.Component<
    IPageExportTilePackingSheetProps,
    IPageExportTilePackingSheetState
> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            caseIdsForExporting: [], // 存放已选择导出的工程板包装箱的Id，结构为：[{ salesOrderId: 1, caseIds: [ 1, 2, 3 ]}, { salesOrderId: 2, caseIds: [ 5, 8 ]}]
            tpcExpandedArr: [],
            customers: []
        };
    }

    public async componentDidMount() {
        const t = this;
        const customers = await Customers.getCustomers();
        t.setState({ customers });
        this.loadData();
    }

    public render() {
        const t = this;
        const s = t.state;
        const soList = Store.salesOrderList;
        const filterData = Store.filterData;
        const pagingInfo = Store.pagingInfo;

        const customerOptions = s.customers.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        const columns: Array<ColumnProps<ISalesOrderForListForUI>> = [
            {
                title: "销售订单号",
                dataIndex: "orderNumber",
                key: "orderNumber"
            },
            {
                title: "客户名称",
                dataIndex: "customerName",
                key: "customerName"
            },
            { title: "工程名称", dataIndex: "projectName", key: "projectName" },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                render: status => {
                    return SalesOrderStatus.getStatus(status).text;
                }
            }
        ];

        if (PermCtl.isAuthorized(Perm.TPC_E)) {
            const actionColumns = [
                {
                    title: "操作",
                    key: "action",
                    render: (text, salesOrder) => {
                        return (
                            <Button
                                type="primary"
                                onClick={t.printTilePackingSheet.bind(
                                    t,
                                    salesOrder.id
                                )}
                                disabled={
                                    t.getSelectedCaseIdsForExporting(
                                        salesOrder.id
                                    ) === null
                                }
                            >
                                导出装箱单
                            </Button>
                        );
                    }
                }
            ];

            columns.push(...actionColumns);
        }

        return (
            <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            onFinish={t.handleSearch}
                            {...formItemLayoutForSearch}
                            ref={this.formRef}
                            name={"tpcAdvancedSearchForm"}
                        >
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem
                                        label="客户名称"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择客户"
                                            value={filterData.customerIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "customerIds"
                                            )}
                                        >
                                            {customerOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem
                                        label="工程名称"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <Input
                                            placeholder="请输入工程名称"
                                            value={filterData.projectName}
                                            onChange={t.handleSearchBoxChange.bind(
                                                t,
                                                "projectName"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem
                                        label="销售订单号"
                                        style={{ marginBottom: "10px" }}
                                    >
                                        <Input
                                            placeholder="请输入销售订单号"
                                            value={filterData.orderNumber}
                                            onChange={t.handleSearchBoxChange.bind(
                                                t,
                                                "orderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>

                {PermCtl.isAuthorized(Perm.TPC_E) ? (
                    <div style={{ margin: "1%", textAlign: "right" }}>
                        <Row>
                            <Col span={24}>
                                <span>{"导出选项："}</span>
                                <Checkbox
                                    onChange={
                                        t.handleExportProcessingSpecsChange
                                    }
                                    checked={Store.exportProcessingSpecs}
                                >
                                    导出工艺
                                </Checkbox>
                            </Col>
                        </Row>
                    </div>
                ) : null}

                <div>
                    <Table
                        columns={columns}
                        dataSource={soList}
                        pagination={false}
                        rowKey="id"
                        expandedRowRender={t.showSOTilePackingCasesTable}
                        onExpand={t.handleRowExpanded}
                        expandedRowKeys={s.tpcExpandedArr}
                    />
                </div>
                {pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString}
                            pageSize={pagingInfo.pageSize}
                            current={pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private getTotalRecordString = (): string => {
        return `共 ${Store.pagingInfo.totalRecordCount} 个销售订单`;
    };

    private loadData = async () => {
        await Store.loadData();
    };

    private loadTilePackingCases = async (salesOrderId: number) => {
        await Store.loadTilePackingCases(salesOrderId);
    };

    private handleSearch = async () => {
        Store.setPageNo(1);
        await this.loadData();
        this.setState({ tpcExpandedArr: [] });
    };

    private handleChange(label, value) {
        Store.updataFilterData({ ...Store.filterData, [label]: value });
    }

    private handleSearchBoxChange(
        label: string,
        event: ChangeEvent<HTMLInputElement>
    ) {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    }

    private handleExportProcessingSpecsChange = (
        event: CheckboxChangeEvent
    ) => {
        Store.setExportProcessingSpecs(event.target.checked);
    };

    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);
        await this.loadData();
        this.setState({ tpcExpandedArr: [] });
    };

    private handleClearSearchCriteria = async () => {
        const filterData: IFilterData = {
            orderType: [60],
            statusCode: [10, 20, 30, 40]
        };
        Store.setPageNo(1);
        Store.setPageSize(10);
        Store.updataFilterData(filterData);
        Store.setExportProcessingSpecs(false);
        await this.loadData();
        this.setState({ tpcExpandedArr: [] });
    };

    private getTilePackingCaseStatus(status) {
        const s = TilePackingCaseStatusOptions.getStatus(status);
        if (s) {
            return s.text;
        } else {
            return null;
        }
    }

    private printTilePackingSheet(salesOrderId) {
        const selectedCaseIds = this.getSelectedCaseIdsForExporting(
            salesOrderId
        );

        // 将打印接口传入参数处理成字符串
        let caseIdsQueryString = "";
        if (selectedCaseIds) {
            selectedCaseIds.forEach(c => {
                caseIdsQueryString += "tilePackingCaseIds=" + c + "&";
            });
            if (caseIdsQueryString.length > 0) {
                caseIdsQueryString =
                    "&" +
                    caseIdsQueryString.substring(
                        0,
                        caseIdsQueryString.length - 1
                    );
            }
        }
        // 拼接是否打印工艺参数
        const exportProcessingSpecs =
            "&exportProcessingSpecs=" + Store.exportProcessingSpecs;
        const printAPI =
            "Tile/ExportTilePackingSheet?salesOrderId=" +
            salesOrderId +
            caseIdsQueryString +
            exportProcessingSpecs;
        message.info("正在导出工程板装箱单以供下载并打印...");
        ExportUtil.setExportUrl(printAPI);
    }

    private handleRowExpanded = async (expanded: boolean, salesOrder) => {
        const t = this;
        const s = t.state;

        const expandedArr = Util.updateExpandedArray(
            s.tpcExpandedArr,
            salesOrder.id,
            expanded
        );

        t.setState({ tpcExpandedArr: expandedArr });
        if (expanded && !salesOrder.tilePackingCases) {
            await t.loadTilePackingCases(salesOrder.id);
        }
    };

    private updateSelectedCaseIdsForExporting(salesOrderId, selectedCaseIds) {
        const s = this.state;
        const caseIdsForExporting = [...s.caseIdsForExporting];
        let soCaseIds = caseIdsForExporting.find(so => {
            return so.salesOrderId === salesOrderId;
        });
        if (!soCaseIds) {
            soCaseIds = {
                salesOrderId,
                caseIds: selectedCaseIds
            };
            caseIdsForExporting.push(soCaseIds);
        } else {
            soCaseIds.caseIds = selectedCaseIds;
        }
        this.setState({ caseIdsForExporting });
    }

    private getSelectedCaseIdsForExporting(salesOrderId) {
        const soCaseIds = this.state.caseIdsForExporting.find(so => {
            return so.salesOrderId === salesOrderId;
        });
        return soCaseIds && soCaseIds.caseIds && soCaseIds.caseIds.length > 0
            ? soCaseIds.caseIds
            : null;
    }

    private showSOTilePackingCasesTable = salesOrder => {
        const t = this;
        const s = t.state;

        if (!salesOrder.tilePackingCases) {
            return (
                <div className="importingSpinArea">
                    <p>正在加载数据，请等待...</p>
                    <Spin />
                </div>
            );
        } else {
            const packingCases = salesOrder.tilePackingCases;
            const soCaseIds = s.caseIdsForExporting.find(so => {
                return so.salesOrderId === salesOrder.id;
            });
            const selectedCaseIds = soCaseIds ? soCaseIds.caseIds : [];
            const rowSelection = {
                selectedRowKeys: selectedCaseIds,
                onChange: selectedIds => {
                    t.updateSelectedCaseIdsForExporting(
                        salesOrder.id,
                        selectedIds
                    );
                }
            };
            const columns = [
                {
                    title: "包装箱号",
                    dataIndex: "caseNumber",
                    key: "caseNumber"
                },
                {
                    title: "总片数",
                    dataIndex: "totalTileCount",
                    key: "totalTileCount"
                },
                { title: "总面积", dataIndex: "totalArea", key: "totalArea" },
                { title: "价格", dataIndex: "totalPrice", key: "totalPrice" },
                {
                    title: "状态",
                    key: "status",
                    render: tpc => {
                        return t.getTilePackingCaseStatus(tpc.status);
                    }
                }
            ];
            return (
                <div>
                    <Table
                        style={{ marginLeft: "40px" }}
                        rowSelection={rowSelection}
                        rowKey="id"
                        columns={columns}
                        dataSource={packingCases}
                        pagination={false}
                    />
                </div>
            );
        }
    };
}

export default PageExportTilePackingSheet;
