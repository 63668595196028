import { Alert, Button, Col, Collapse, Input, Row, Spin } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";

import {
    FileInfoViewModel,
    ImportingTemplateClient
} from "../../../../app/WebAPIClients";
import {
    colLayoutForButton,
    colLayoutForInput
} from "../../../../components/Consts";
import ErrorHandler from "../../../../components/ErrorHandler";
import { TemplateFileDownloadButton } from "../../../../components/TemplateFileDownloadButton";
import "./PageImportInventoryBundleData.styl";
import Store from "./store";

const Panel = Collapse.Panel;

declare interface IPageImportInventoryBundleDataProps
    extends RouteComponentProps<{}> {}

declare interface IPageImportInventoryBundleDataState
    extends IActionErrorState {
    fileName: string;
    uploading?: boolean;
}

@observer
class PageImportInventoryBundleData extends React.Component<
    IPageImportInventoryBundleDataProps,
    IPageImportInventoryBundleDataState
> {
    private file: React.RefObject<HTMLInputElement>;
    constructor(props) {
        super(props);
        this.state = {
            fileName: null
        };
        this.file = React.createRef();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const errorMsgs = []; // 错误消息列表
        const warningMsgs = []; // 警告消息列表
        const bundlesUpdatingSucceeded = [];
        const bundlesImportingSucceeded = [];
        const slabImportingSucceeded = [];
        const blocksUpdatingSucceeded = [];
        const blocksImportingSucceeded = [];

        const updatedRE = /^成功更新扎信息，扎号：([\S\s]*) #([\S\s]*)$/g;
        const importedBundleRE = /^成功导入新扎信息，扎号：([\S\s]*) #([\S\s]*)$/g;
        const importedSlabRE = /^成功导入新的大板，荒料号：([\S\s]*) 序号：([\S\s]*)$/g;
        if (Store.result) {
            Store.result.dataUpdatingMessages.forEach(msg => {
                if (msg.includes("错误：")) {
                    errorMsgs.push(msg);
                    return;
                } else if (msg.includes("警告：")) {
                    warningMsgs.push(msg);
                    return;
                }

                updatedRE.lastIndex = 0;
                let result = updatedRE.exec(msg);
                if (result) {
                    t.addSlabInfoToArray(
                        bundlesUpdatingSucceeded,
                        result[1],
                        result[2]
                    );
                }

                importedBundleRE.lastIndex = 0;
                result = importedBundleRE.exec(msg);
                if (result) {
                    t.addSlabInfoToArray(
                        bundlesImportingSucceeded,
                        result[1],
                        result[2]
                    );
                }

                importedSlabRE.lastIndex = 0;
                result = importedSlabRE.exec(msg);
                if (result) {
                    t.addSlabInfoToArray(
                        slabImportingSucceeded,
                        result[1],
                        result[2]
                    );
                }
            });
            bundlesUpdatingSucceeded.forEach(bu => {
                t.addBlockInfoToArray(
                    blocksUpdatingSucceeded,
                    bu,
                    slabImportingSucceeded
                );
            });
            bundlesImportingSucceeded.forEach(bi => {
                t.addBlockInfoToArray(
                    blocksImportingSucceeded,
                    bi,
                    slabImportingSucceeded
                );
            });
        }

        return (
            <div>
                <p>导入库存大板</p>
                <Row style={{ marginTop: "20px" }}>
                    <Col {...colLayoutForInput}>
                        <Input disabled={true} value={s.fileName} />
                        <input
                            type="file"
                            ref={this.file}
                            accept=".xlsx"
                            style={{ display: "none" }}
                            onChange={t.fileChanged}
                        />
                    </Col>
                    <Col {...colLayoutForButton}>
                        <Button
                            type="primary"
                            onClick={t.selectFile}
                            disabled={s.uploading}
                        >
                            选择文件
                        </Button>
                        <Button
                            onClick={t.importFile}
                            type="primary"
                            disabled={s.uploading}
                        >
                            上传并导入
                        </Button>
                        <Button
                            onClick={t.clearResult}
                            danger={true}
                            disabled={s.uploading}
                        >
                            清空导入结果
                        </Button>
                        <TemplateFileDownloadButton
                            onDownload={t.onTemplateDownload}
                        />
                    </Col>
                </Row>

                {s.uploading ? (
                    <div className="importingSpinArea">
                        <p>正在上传文件并导入数据，请等待...</p>
                        <Spin />
                    </div>
                ) : null}

                {Store.result ? (
                    <div style={{ marginTop: "20px" }}>
                        <Collapse defaultActiveKey={["1", "2"]}>
                            <Panel header={"大板码单文件解析结果"} key="1">
                                {Store.result.dataParsingMessages.map(msg => {
                                    return (
                                        <Alert
                                            key={msg}
                                            message={msg}
                                            type="error"
                                        />
                                    );
                                })}
                            </Panel>
                            <Panel header={"大板数据导入结果"} key="2">
                                <Collapse defaultActiveKey={["11", "12", "13"]}>
                                    <Panel header={"成功消息"} key="11">
                                        {blocksImportingSucceeded.map(so => {
                                            const msg =
                                                "成功导入新扎数据，荒料号：" +
                                                so.bundleInfo +
                                                "，扎号：" +
                                                so.seqs.join(", ") +
                                                "，大板序号：" +
                                                so.slabs.join(", ");
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="success"
                                                />
                                            );
                                        })}
                                        {blocksUpdatingSucceeded.map(so => {
                                            const msg =
                                                "成功更新扎数据，荒料号：" +
                                                so.bundleInfo +
                                                "，扎号：" +
                                                so.seqs.join(", ");
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="success"
                                                />
                                            );
                                        })}
                                    </Panel>
                                    <Panel header={"警告消息"} key="12">
                                        {warningMsgs.map(msg => {
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="warning"
                                                />
                                            );
                                        })}
                                    </Panel>
                                    <Panel header={"出错消息"} key="13">
                                        {errorMsgs.map(msg => {
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="error"
                                                />
                                            );
                                        })}
                                    </Panel>
                                </Collapse>
                            </Panel>
                        </Collapse>
                    </div>
                ) : null}
            </div>
        );
    }

    private importFile = () => {
        const t = this;

        const fileDOM = this.file.current;

        if (fileDOM.files.length === 0) {
            ErrorHandler.handleErr("请选择一个.xlsx文件进行导入");
            return;
        }

        t.stageUploading();

        const file = fileDOM.files[0];

        const reader = new FileReader();

        reader.onload = async (evt: ProgressEvent<FileReader>) => {
            let fileContent = evt.target.result as string;
            const fileName = file.name;
            if (fileContent.length > 0 && fileContent.includes("base64,")) {
                fileContent = fileContent.split("base64,")[1];
            }
            if (fileContent.length > 0) {
                const params = { fileName, fileContent };
                await Store.importInventoryBundleData(
                    FileInfoViewModel.fromJS(params)
                );
                t.finishUploading();
            }
        };
        reader.readAsDataURL(file);
    };

    private selectFile = () => {
        this.file.current.click();
    };

    private fileChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fileName: evt.target.files[0].name });
    };

    private clearResult = () => {
        Store.setResult();
    };

    private stageUploading = () => {
        this.setState({ uploading: true });
    };

    private finishUploading = () => {
        this.setState({ uploading: false });
    };

    private addSlabInfoToArray(containerArr, bundleInfo, seq) {
        let slabList = containerArr.find(s => s.bundleInfo === bundleInfo);
        if (slabList === undefined) {
            slabList = { bundleInfo, seqs: [] };
            containerArr.push(slabList);
        }
        slabList.seqs.push(seq);
    }

    private addBlockInfoToArray(containerArr, bdInfo, slabsInfo) {
        let blockList = containerArr.find(
            s => s.bundleInfo === bdInfo.bundleInfo
        );
        if (blockList === undefined) {
            blockList = { ...bdInfo, slabs: [] };
            containerArr.push(blockList);
        }
        const slabInfo = slabsInfo.filter(s => {
            return s.bundleInfo === bdInfo.bundleInfo;
        });
        slabInfo.forEach(sl => {
            blockList.slabs.push(sl.seqs);
        });
    }

    private onTemplateDownload = (client: ImportingTemplateClient) => {
        return client.getSlabInventoryImportingTemplate();
    };
}

export default PageImportInventoryBundleData;
