import { message, notification } from "antd";
import { action, autorun, observable } from "mobx";
import { ApiException, PagingInfo } from "../app/WebAPIClients";
import ErrorHandler from "../components/ErrorHandler";

export default class Base {
    @observable
    public hasError: boolean = false;
    @observable
    public errorObj: { message: string; code: number } | null = null;
    @observable
    public errorTitle: string | null = null;
    @observable
    public loading: boolean = false;
    @observable
    public loadingActionMessage: string | null = null;
    @observable
    public pagingInfo?: PagingInfo | null = null;
    @observable
    public pageSize?: number = 10;
    @observable
    public pageNo?: number = 1;

    protected listenable = autorun(() => {
        if (this.loading) {
            message.success(this.loadingActionMessage);
        } else {
            message.destroy();
            if (this.hasError) {
                notification.destroy();
                ErrorHandler.handleErr(this.errorTitle!, this.errorObj!);
            }
        }
    });

    @action
    public apiCallBegin = (msg: string) => {
        this.hasError = false;
        this.errorObj = null;
        this.errorTitle = null;
        this.loading = true;
        this.loadingActionMessage = msg;
    };

    @action
    public apiCallReset = () => {
        this.hasError = false;
        this.errorObj = null;
        this.errorTitle = null;
        this.loading = false;
        this.loadingActionMessage = null;
    };

    @action
    public apiCallFailure = (errorTitle: string, errorObj: any) => {
        this.hasError = true;
        this.errorObj = errorObj;
        this.errorTitle = errorTitle;
        this.loading = false;
        this.loadingActionMessage = null;
    };

    @action
    public setPagingInfo(pagingInfo: PagingInfo | undefined | null) {
        this.pagingInfo = pagingInfo;
        if (pagingInfo && pagingInfo.currentPage) {
            this.pageNo = pagingInfo.currentPage;
        }
    }

    @action
    public setPageSize(pageSize: number) {
        this.pageSize = pageSize;
    }

    @action
    public setPageNo(pageNo: number) {
        this.pageNo = pageNo;
    }

    public async callAPIFun(
        callAPI: () => Promise<void>,
        msg?: string,
        errMsg?: string
    ) {
        this.apiCallBegin(msg);
        try {
            await callAPI();
            this.apiCallReset();
        } catch (error) {
            this.apiCallFailure(errMsg, this.getErrorState(error));
        }
    }

    public async callAPIFunForGet(
        callAPI: () => Promise<void>,
        msg: string = "正在获取数据...",
        errMsg: string = "获取数据失败"
    ) {
        await this.callAPIFun(callAPI, msg, errMsg);
    }

    private getErrorState(ex: ApiException) {
        return {
            message: ex.response || ex.message,
            code: ex.status || -1
        };
    }
}
