import { Alert, Col, Row } from "antd";
import { observer } from "mobx-react";
import "./PageLogin.styl";

import * as dd from "dingtalk-jsapi";
import React from "react";
import { RouteComponentProps } from "react-router";
import { DingtalkConfigDTO, LoginResultDTO } from "../../app/WebAPIClients";
import ErrorHandler from "../../components/ErrorHandler";
import Settings from "../../components/SettingInfo";
import tenantInfo from "../../components/TenantInfo";
import userInfo from "../../components/UserInfo";
import Util from "../../components/Util";
import Store from "./store";

declare interface ILoginProps extends RouteComponentProps<{}> {}

declare interface ILoginState extends IActionErrorState {
    isValidUser: boolean;
    returnToUrl: string;
    tenantAbbrCode: string;
}

@observer
class Login extends React.Component<ILoginProps, ILoginState> {
    private isDDReady: boolean;
    private ddAuthCode: string | null;
    constructor(props) {
        super(props);
        this.state = {
            isValidUser: false,
            returnToUrl: Util.getLocState(this, "nextPathname"),
            tenantAbbrCode: Util.GetUrlParam(
                Util.getLocState(this, "originalUrl"),
                "AC"
            )
        };
        this.isDDReady = false;
        this.ddAuthCode = null;
    }

    public render() {
        const t = this;
        const s = t.state;
        let msg = null;
        if (Store.loggedIn && s.isValidUser) {
            msg = (
                <Alert
                    message="登录成功"
                    description="正在跳转到首页..."
                    type="success"
                    showIcon={true}
                />
            );
        } else if (Store.loggedIn === false) {
            msg = (
                <Alert
                    message="正在登录"
                    description="正在进行钉钉登录...，此功能仅能在钉钉中使用"
                    type="info"
                    showIcon={true}
                />
            );
        } else if (s.isValidUser === false) {
            msg = (
                <Alert
                    message="错误"
                    description="您的账号没有对应的系统角色，请联系管理员分配角色后再试"
                    type="error"
                    showIcon={true}
                />
            );
        }

        return (
            <Row>
                <Col span={6} />
                <Col span={12} className="login">
                    {msg}
                </Col>
                <Col span={6} />
            </Row>
        );
    }

    public async componentDidMount() {
        await this.login();
    }

    public redirectToPreviousPage() {
        const t = this;
        const s = t.state;

        const returnToPath = s.returnToUrl === null ? "/" : s.returnToUrl;
        t.props.history.push(returnToPath);
    }

    public async login() {
        const t = this;
        const s = t.state;

        if (!t.isDDReady) {
            try {
                await Store.getConfig(window.location.href, s.tenantAbbrCode);
                if (!Store.hasError) {
                    const ddConfig = Store.ddConfig;
                    userInfo.setDDConfig(ddConfig);
                    await t.configDD(ddConfig);
                    const authCode: string = await t.getDDAuthCode(
                        ddConfig.corpId
                    );
                    t.isDDReady = true;
                    t.ddAuthCode = authCode;
                    await Store.login(t.ddAuthCode, s.tenantAbbrCode);

                    if (!Store.hasError) {
                        const loginResult = Store.loginResult;
                        t.processLoginResult(loginResult);
                    }
                }
            } catch (error) {
                ErrorHandler.handleErr("登录出错，请重试。详细信息：", error);
            }
        } else {
            await Store.login(t.ddAuthCode, s.tenantAbbrCode);
            if (!Store.hasError) {
                const loginResult = Store.loginResult;
                t.processLoginResult(loginResult);
            }
        }
    }

    public processLoginResult(loginResult: LoginResultDTO): void {
        const t = this;
        if (!Util.isValidSysUser(loginResult.userInfo)) {
            t.setState({ isValidUser: false });
        } else {
            userInfo.setUserInfo(loginResult.userInfo);
            userInfo.setAccessToken(loginResult.jwtToken);
            Settings.setSettings(loginResult.settings);
            tenantInfo.setTenantInfo(loginResult.tenantInfo);
            t.redirectToPreviousPage();
        }
    }

    public configDD(ddConfig: DingtalkConfigDTO): void {
        const apiList = {
            jsApiList: [
                "runtime.info",
                "runtime.permission.requestOperateAuthCode"
            ]
        };

        const configParam = { ...ddConfig, ...apiList };
        dd.config(configParam);
    }

    public async getDDAuthCode(corpId: string): Promise<string> {
        const result = await dd.runtime.permission.requestAuthCode({ corpId });
        return result.code;
    }
}

export default Login;
