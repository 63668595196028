import { CheckCircleOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  Form,
  FormInstance,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from "antd";
// tslint:disable-next-line:no-submodule-imports
import { CheckboxOptionType } from "antd/lib/checkbox";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../../app/Perm";
import {
  BundleForListDTO,
  StockingAreaDTO,
  StoneCategoryDTO,
  StoneGradeDTO,
  WarehouseDTO,
  WarehouseType,
} from "../../../../app/WebAPIClients";
import Consts, {
  formItemLayoutForCheckboxGroup,
  formItemLayoutForCol,
  formItemLayoutForSearch,
  SlabExportOptionName,
} from "../../../../components/Consts";
import ExportSelector from "../../../../components/ExportSelector";
import ExportUtil from "../../../../components/ExportUtil";
import PermCtrl from "../../../../components/PermCtrl";
import SearchButtonGroup from "../../../../components/SearchButtonGroup";
import StockingAreaUtil from "../../../../components/StockingAreaUtil";
import Util from "../../../../components/Util";
import "./PageExportBundles.styl";
import Store from "./store";

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const {
  SlabBundleStatus,
  StoneCategories,
  StoneGrades,
  SlabExportOption,
  ProductStockingAreas,
  Factories,
  Warehouses,
} = Consts;

declare interface IPageExportBundlesProps extends RouteComponentProps<{}> {}

declare interface IPageExportBundlesState extends IActionErrorState {
  categoryList: StoneCategoryDTO[];
  gradeList: StoneGradeDTO[];
  psList: StockingAreaDTO[];
  warehouseList: WarehouseDTO[];
  psOptions: JSX.Element[];
  warehouseOptions: JSX.Element[];
  selectedBundleIds: number[];
  selectedBundles: BundleForListDTO[];
  uniqueWarehouse: boolean;
  selectWarehouse: string[];
  checkBoxOptions: CheckboxOptionType[]; // 状态
}

@observer
class PageExportBundles extends React.Component<
  IPageExportBundlesProps,
  IPageExportBundlesState
> {
  private formRef: React.RefObject<FormInstance>;
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      warehouseOptions: [],
      warehouseList: [],
      categoryList: [],
      gradeList: [],
      psList: [],
      psOptions: [],
      selectedBundleIds: [], // 存放已选择打印的扎的Id
      selectedBundles: [], // 存放已选择打印的扎的信息
      selectWarehouse: [],
      uniqueWarehouse: false,
      checkBoxOptions: [],
    };
  }

  public async componentDidMount() {
    const categoryList = await StoneCategories.getCategories();
    const gradeList = await StoneGrades.getGrades();
    const psList = await ProductStockingAreas.getStockingAreas();
    const factories = await Factories.getFactories(); // 工厂列表
    let warehouseList = await Warehouses.getWarehouses(); // 仓库列表
    warehouseList = warehouseList.filter(
      (w) => w.type === WarehouseType.Product,
    );

    const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
      warehouseList,
      factories,
    );

    const uniqueWarehouse = warehouseList.length === 1;
    const warehouseId = Util.parseToInt(
      Util.getCascaderValue(Store.filterData.selectWarehouse, 1),
    );
    const psOptions = StockingAreaUtil.getStockingAreaOptions(
      uniqueWarehouse,
      warehouseId,
      psList,
      warehouseOptions,
    );

    const checkBoxOptions = SlabBundleStatus.data.map((status) => {
      return { label: status.text, value: status.value.toString() };
    });

    this.setState({
      categoryList,
      gradeList,
      psOptions,
      psList,
      uniqueWarehouse,
      warehouseOptions,
      warehouseList,
      checkBoxOptions,
    });
    await this.loadData();
  }

  public render() {
    const t = this;
    const s = t.state;
    const sbList = Store.stoneBundleList;
    const filterData = Store.filterData;
    const bundleIds = s.selectedBundleIds;
    const actionButtonDisabled = bundleIds.length === 0;
    const selectedBundles = s.selectedBundles;
    const psOptions = s.psOptions;

    const columns = [
      {
        title: "选择",
        key: "select",
        render: (text, record) => (
          <div onClick={t.selectStoneBundle.bind(t, record.id)}>
            <CheckCircleOutlined
              style={{
                color: t.getCheckFlagColor(record.id),
                fontSize: 18,
              }}
            />
          </div>
        ),
      },
      { title: "荒料编号", dataIndex: "prefix", key: "prefix" },
      { title: "扎号", dataIndex: "bundleNo", key: "bundleNo" },
      {
        title: "品名",
        dataIndex: "categoryId",
        key: "categoryId",
        render: (c) => {
          return Util.getItemName(c, s.categoryList);
        },
      },
      {
        title: "总片数",
        dataIndex: "totalSlabCount",
        key: "totalSlabCount",
      },
      { title: "面积", dataIndex: "area", key: "area" },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        render: (c) => {
          return SlabBundleStatus.getStatus(c).text;
        },
      },
      {
        title: "区域",
        dataIndex: "stockingAreaId",
        key: "stockingAreaId",
        render: (c) => {
          return this.getShowStockingArea(c);
        },
      },
    ];

    const gradeColumn = {
      title: "等级",
      dataIndex: "gradeId",
      key: "gradeId",
      render: (g) => {
        return Util.getItemName(g, s.gradeList);
      },
    };

    if (PermCtrl.isAuthorized(Perm.SB_G_R)) {
      columns.splice(3, 0, gradeColumn);
    }

    return (
      <div>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="高级搜索" key="1">
            <Form
              ref={this.formRef}
              name={"exportBundleForm"}
              onFinish={t.handleSearch}
              {...formItemLayoutForSearch}
            >
              <Row>
                <Col span={24}>
                  <FormItem
                    {...formItemLayoutForCheckboxGroup}
                    label="状态"
                    style={{ marginBottom: "10px" }}
                  >
                    <CheckboxGroup
                      options={s.checkBoxOptions}
                      value={filterData.statusCodes}
                      onChange={t.handleChange.bind(t, "statusCodes")}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem
                    label="荒料编号"
                    style={{
                      marginBottom: "10px",
                    }}
                  >
                    <Input
                      placeholder="请输入荒料编号"
                      value={filterData.selectPrefix}
                      onChange={t.handleInputChange.bind(t, "selectPrefix")}
                    />
                  </FormItem>
                </Col>
                {s.uniqueWarehouse ? null : (
                  <Col {...formItemLayoutForCol}>
                    <FormItem label="仓库" style={{ marginBottom: "10px" }}>
                      <Cascader
                        value={filterData.selectWarehouse}
                        options={s.warehouseOptions}
                        placeholder="请选择仓库"
                        onChange={t.handleWarehouseChange}
                      />
                    </FormItem>
                  </Col>
                )}
                <Col {...formItemLayoutForCol}>
                  <FormItem label="区域" style={{ marginBottom: "10px" }}>
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择区域"
                      value={filterData.stockingAreaIds}
                      onChange={t.handleChange.bind(t, "stockingAreaIds")}
                    >
                      {psOptions}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <SearchButtonGroup onClearClick={t.handleResettingData} />
            </Form>
          </Panel>
        </Collapse>
        <Row align="middle" style={{ margin: "1%" }}>
          <Col span={18}>
            {selectedBundles.map((sb) => {
              const tag = t.getBundleTitle(sb);
              const isLongTag = tag.length > 20;
              const tagElem = (
                <Tag
                  key={tag}
                  closable={true}
                  onClose={this.handleBundleTagClose.bind(t, sb)}
                  style={{ margin: "6px 2px" }}
                  color="blue"
                >
                  {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                </Tag>
              );
              return isLongTag ? (
                <Tooltip key={sb.id} title={tag}>
                  {tagElem}
                </Tooltip>
              ) : (
                tagElem
              );
            })}
          </Col>
          <Col span={6}>
            <div
              style={{
                textAlign: "right",
              }}
            >
              <ExportSelector />
              <Button
                type="primary"
                disabled={actionButtonDisabled}
                onClick={t.handlePrintClicked}
              >
                导出码单
              </Button>
              <Button
                danger={true}
                disabled={actionButtonDisabled}
                onClick={t.clearData}
              >
                清空选择
              </Button>
            </div>
          </Col>
        </Row>
        <Table
          columns={columns}
          dataSource={sbList}
          rowKey={"id"}
          pagination={false}
        />
        {Store.pagingInfo ? (
          <div className="paginationArea">
            <Pagination
              total={Store.pagingInfo.totalRecordCount}
              showTotal={t.getTotalDescription}
              pageSize={Store.pageSize}
              current={Store.pageNo}
              defaultCurrent={1}
              showQuickJumper={true}
              onChange={t.handlePaginationChange}
              showSizeChanger={false}
            />
          </div>
        ) : null}
      </div>
    );
  }

  private getTotalDescription = (total: number) => {
    return `共 ${total} 扎 共 ${Store.totalArea} m²`;
  };

  // 设置选择icon的颜色
  private getCheckFlagColor(id) {
    const t = this;
    const s = t.state;
    let color = "";
    const sbSelected = s.selectedBundleIds.some((d) => {
      return d === id;
    });

    color = sbSelected ? "#00CC00" : "#999999";
    return color;
  }

  private printBundles(bundleIds) {
    const t = this;

    const slabSheetType = t.getExportOptionValue(
      SlabExportOptionName.SlabSheetType,
    );
    const exportGrade = t.getExportOptionValue(
      SlabExportOptionName.ExportGrade,
    );
    const exportNotes = t.getExportOptionValue(
      SlabExportOptionName.ExportNotes,
    );
    if (!slabSheetType || !exportGrade || !exportNotes) {
      message.error("请指定码单导出选项!");
      return;
    }

    // 将打印接口传入参数处理成字符串
    let bundleIdList = "";
    bundleIds.forEach((b) => {
      bundleIdList += "bundleIds=" + b + "&";
    });
    const bdIds = bundleIdList.substring(0, bundleIdList.length - 1);

    const exportAPI =
      "StoneBundle/Print?" +
      bdIds +
      "&type=" +
      slabSheetType +
      "&exportGrade=" +
      exportGrade +
      "&exportNotes=" +
      exportNotes;
    message.info("正在导出码单以供下载并导出...");
    ExportUtil.setExportUrl(exportAPI);
  }

  private clearData = () => {
    const selectedBundleIds: number[] = [];
    const selectedBundles: BundleForListDTO[] = [];
    this.setState({
      selectedBundleIds,
      selectedBundles,
    });
  };

  private handleSearch = async () => {
    Store.setPageNo(1);
    await this.loadData();
  };

  private handleResettingData = async () => {
    const t = this;
    const s = t.state;

    const psOptions = StockingAreaUtil.getStockingAreaOptions(
      s.uniqueWarehouse,
      null,
      s.psList,
      s.warehouseOptions,
    );

    Store.updataFilterData({});

    await this.loadData();
    t.setState({
      psOptions,
    });
  };

  private handleInputChange = (label: string, event) => {
    Store.updataFilterData({
      ...Store.filterData,
      [label]: event.target.value,
    });
  };

  private handleChange(label, value) {
    Store.updataFilterData({
      ...Store.filterData,
      [label]: value,
    });
  }

  // 获取扎的显示title
  private getBundleTitle(bundle) {
    let title = "";

    if (!bundle) {
      return title;
    }

    title +=
      bundle.prefix +
      " " +
      "#" +
      bundle.totalBundleCount +
      "-" +
      bundle.bundleNo;

    return title;
  }

  private handleBundleTagClose = (stoneBundle) => {
    const t = this;
    const s = t.state;

    // 删除stoneBundle的同时将selectedBundleIds中对应的扎的Id删除
    const newBundleIds = s.selectedBundleIds.filter((d) => {
      return d !== stoneBundle.id;
    });
    const newBundles = s.selectedBundles.filter((d) => {
      return d.id !== stoneBundle.id;
    });

    this.setState({
      selectedBundleIds: newBundleIds,
      selectedBundles: newBundles,
    });
  };

  private handlePaginationChange = async (page: number) => {
    Store.setPageNo(page);
    await this.loadData();
  };

  private getExportOptionValue(optionName) {
    return SlabExportOption.getSavedExportOptionValue(optionName);
  }

  private async loadData() {
    await Store.reloadData();
  }

  private handlePrintClicked = () => {
    const t = this;
    const s = t.state;
    const bundleIds = s.selectedBundleIds;

    if (bundleIds.length === 0) {
      message.info("请选择需要导出的扎...");
    } else {
      t.printBundles(bundleIds);
    }
  };

  private selectStoneBundle(id) {
    const t = this;
    const s = t.state;
    if (!id) {
      return;
    }
    let newBundleId = [];
    let newBundles = [];

    const stoneBundleSelected = s.selectedBundleIds.some((b) => {
      return b === id;
    });

    if (!stoneBundleSelected) {
      // 没有被选中
      const bundle = Store.stoneBundleList.find((b) => {
        return b.id === id;
      });
      const title = t.getBundleTitle(bundle);

      // 选择的扎中如果没有大板信息，则不能打印
      if (!bundle.slabExist) {
        message.info(title + "没有大板信息，请先添加大板信息再进行导出");
        return;
      } else {
        // 增加扎Id的同时，需将扎的信息同步到selectedBundles
        newBundleId = [...s.selectedBundleIds, bundle.id];
        newBundles = [...s.selectedBundles, bundle];
      }
    } else {
      // 已经被选中 删除扎的同时，需selectedBundles中的对应信息删除
      newBundleId = s.selectedBundleIds.filter((d) => {
        return d !== id;
      });
      newBundles = s.selectedBundles.filter((d) => {
        return d.id !== id;
      });
    }

    // 将选中的扎按荒料编号-扎号排序
    newBundles = newBundles.slice().sort((b1, b2) => {
      const sbStrOne = t.getBundleTitle(b1);
      const sbStrTwo = t.getBundleTitle(b2);
      return sbStrOne.localeCompare(sbStrTwo);
    });
    t.setState({
      selectedBundleIds: newBundleId,
      selectedBundles: newBundles,
    });
  }

  // 选择仓库
  private handleWarehouseChange = (value) => {
    const t = this;
    const s = t.state;
    const psOptions = StockingAreaUtil.getStockingAreaOptions(
      s.uniqueWarehouse,
      parseInt(value[1]),
      s.psList,
      s.warehouseOptions,
    );
    t.setState({ psOptions });
    Store.updataFilterData({
      ...Store.filterData,
      selectWarehouse: value,
      stockingAreaIds: [],
    });
  };

  private getShowStockingArea(itemId) {
    const t = this;
    const s = t.state;

    const stockingArea = s.psList.find((b) => b.id === itemId);
    if (!stockingArea) {
      return;
    }
    return StockingAreaUtil.getShowText(
      s.uniqueWarehouse,
      stockingArea,
      s.warehouseOptions,
    );
  }
}

export default PageExportBundles;
