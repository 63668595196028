import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import { WorkOrderForReportDTO } from "../../../../app/WebAPIClients";
import Base from "../../../../stores/base";

class Store extends Base {
    @observable
    public result: WorkOrderForReportDTO[];
    @observable
    public isEnd: boolean;
    @observable
    public lastUpdatedTime: Date;

    constructor() {
        super();
        this.initData();
    }

    @action
    public initData() {
        this.hasError = false;
        this.errorObj = null;
        this.result = [];
        this.isEnd = false;
        this.pageSize = 12;
        this.pageNo = 0;
        this.lastUpdatedTime = null;
    }

    @action
    public async getRealTimeManufacturingReport() {
        const t = this;

        const currentPageNo = t.pageNo + 1;
        const client = ClientFactory.getReportClient();
        const callAPI = async () => {
            const result = await client.getRealTimeManufacturingReport(
                t.pageSize,
                currentPageNo
            );

            if (!(result && result.length && result.length > 0)) {
                runInAction(() => (t.isEnd = true));
            } else {
                runInAction(() => {
                    t.isEnd = false;
                    t.result = result;
                    t.lastUpdatedTime = new Date();
                });
            }
            t.setPageNo(currentPageNo);
        };

        await t.callAPIFunForGet(callAPI);
    }
}

export default new Store();
