import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import { StoneArtifactType } from "../../../app/WebAPIClients";
import { ITransferOrderListWithDetails } from "../../../components/type";
import Util from "../../../components/Util";
import Base from "../../../stores/base";

export interface IFilterData {
    orderNumber?: string;
    statusCodes?: number[];
    startDate?: Date;
    endDate?: Date;
    selectFromWarehouse?: string[];
    selectToWarehouse?: string[];
}

class Store extends Base {
    @observable
    public transferOrderList: ITransferOrderListWithDetails[] = [];
    @observable
    public filterData: IFilterData = {};

    @action
    public async reloadData() {
        const t = this;
        const {
            orderNumber,
            statusCodes,
            selectFromWarehouse,
            selectToWarehouse,
            startDate,
            endDate
        } = t.filterData;

        const fromWarehouseId = Util.parseToInt(
            Util.getCascaderValue(selectFromWarehouse, 1)
        );
        const toWarehouseId = Util.parseToInt(
            Util.getCascaderValue(selectToWarehouse, 1)
        );

        const client = ClientFactory.getTransferOrderClient();
        const callAPI = async () => {
            const content = await client.getAll(
                orderNumber,
                statusCodes,
                fromWarehouseId ? [fromWarehouseId] : undefined,
                toWarehouseId ? [toWarehouseId] : undefined,
                [StoneArtifactType.Block],
                Util.getValidDate(startDate),
                Util.getValidDate(endDate),
                t.pageSize,
                t.pageNo
            );

            runInAction(() => {
                t.transferOrderList = content.result;
                this.setPagingInfo(content.pagingInfo);
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async getTransferOrder(id: number) {
        const t = this;
        const client = ClientFactory.getTransferOrderClient();
        const callAPI = async () => {
            const result = await client.get(id);

            const transferOrderList = [...t.transferOrderList];
            const to = transferOrderList.find(p => {
                return p.id === id;
            });

            if (to) {
                to.transferDetails = result.transferDetails;
            }

            runInAction(() => {
                t.transferOrderList = transferOrderList;
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }
}

export default new Store();
