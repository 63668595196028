import { Alert, Button, Col, Collapse, Input, Row, Spin } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import {
    FileInfoWithOptionsViewModel,
    IFileInfoWithOptionsViewModel,
    ImportingTemplateClient
} from "../../../app/WebAPIClients";
import {
    colLayoutForButton,
    colLayoutForInput
} from "../../../components/Consts";
import ErrorHandler from "../../../components/ErrorHandler";
import "./PageImportBlockInventoryData.styl";
import Store from "./store";
import { TemplateFileDownloadButton } from "../../../components/TemplateFileDownloadButton";

const Panel = Collapse.Panel;

declare interface IPageImportBlockInventoryDataProps
    extends RouteComponentProps<{}> {}

declare interface IPageImportBlockInventoryDataState extends IActionErrorState {
    fileName: string;
    uploading?: boolean;
}

@observer
class PageImportBlockInventoryData extends React.Component<
    IPageImportBlockInventoryDataProps,
    IPageImportBlockInventoryDataState
> {
    private file: React.RefObject<HTMLInputElement>;
    constructor(props) {
        super(props);
        this.state = {
            fileName: null
        };

        this.file = React.createRef();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;

        const errorMsgs: string[] = []; // 错误消息列表
        const warningMsgs: string[] = []; // 警告消息列表
        const blocksAddingSucceeded: string[] = []; // 成功新增的荒料信息
        const blocksUpdatingSucceeded: string[] = []; // 成功更新的荒料信息
        let blocksAddingSucceededMsg: string;
        let blocksUpdatingSucceededMsg: string;

        const addingSucceededRE = /^成功新增荒料，荒料号：([\S\s]*)$/g;
        const updatingSucceededRE = /^成功更新荒料，荒料号：([\S\s]*)$/g;

        if (Store.result) {
            Store.result.dataUpdatingMessages.forEach(msg => {
                if (msg.includes("错误：")) {
                    errorMsgs.push(msg);
                    return;
                } else if (msg.includes("警告：")) {
                    warningMsgs.push(msg);
                    return;
                }

                addingSucceededRE.lastIndex = 0;
                let result = addingSucceededRE.exec(msg);
                if (result) {
                    t.addBlockInfoToArray(blocksAddingSucceeded, result[1]);
                    return;
                }

                updatingSucceededRE.lastIndex = 0;
                result = updatingSucceededRE.exec(msg);
                if (result) {
                    t.addBlockInfoToArray(blocksUpdatingSucceeded, result[1]);
                    return;
                }
            });

            if (blocksAddingSucceeded.length > 0) {
                blocksAddingSucceededMsg =
                    "成功新增荒料，荒料号：" + blocksAddingSucceeded.join("，");
            }
            if (blocksUpdatingSucceeded.length > 0) {
                blocksUpdatingSucceededMsg =
                    "成功更新荒料，荒料号：" +
                    blocksUpdatingSucceeded.join("，");
            }
        }

        return (
            <div>
                <p>导入荒料库存数据</p>
                <Row style={{ marginTop: "20px" }}>
                    <Col {...colLayoutForInput}>
                        <Input disabled={true} value={s.fileName} />
                        <input
                            type="file"
                            ref={this.file}
                            accept=".xlsx"
                            style={{ display: "none" }}
                            onChange={t.fileChanged}
                        />
                    </Col>
                    <Col {...colLayoutForButton}>
                        <Button
                            type="primary"
                            onClick={t.selectFile}
                            disabled={s.uploading}
                        >
                            选择文件
                        </Button>
                        <Button
                            onClick={t.importFile}
                            type="primary"
                            disabled={s.uploading}
                        >
                            上传并导入
                        </Button>
                        <Button
                            onClick={t.clearResult}
                            danger={true}
                            disabled={s.uploading}
                        >
                            清空导入结果
                        </Button>
                        <TemplateFileDownloadButton
                            onDownload={t.onTemplateDownload}
                        />
                    </Col>
                </Row>

                {s.uploading ? (
                    <div className="importingSpinArea">
                        <p>正在上传文件并导入数据，请等待...</p>
                        <Spin />
                    </div>
                ) : null}

                {Store.result ? (
                    <div style={{ marginTop: "20px" }}>
                        <Collapse defaultActiveKey={["1", "2"]}>
                            <Panel header={"荒料库存表文件解析结果"} key="1">
                                {Store.result.dataParsingMessages.map(msg => {
                                    return (
                                        <Alert
                                            key={msg}
                                            message={msg}
                                            type="error"
                                        />
                                    );
                                })}
                            </Panel>
                            <Panel header={"荒料数据处理结果"} key="2">
                                <Collapse defaultActiveKey={["11", "12", "13"]}>
                                    <Panel header={"成功消息"} key="11">
                                        {blocksAddingSucceeded.length ===
                                        0 ? null : (
                                            <Alert
                                                key={"addingSucceeded"}
                                                message={
                                                    blocksAddingSucceededMsg
                                                }
                                                type="success"
                                            />
                                        )}
                                        {blocksUpdatingSucceeded.length ===
                                        0 ? null : (
                                            <Alert
                                                key={"updatingSucceeded"}
                                                message={
                                                    blocksUpdatingSucceededMsg
                                                }
                                                type="success"
                                            />
                                        )}
                                    </Panel>
                                    <Panel header={"警告消息"} key="12">
                                        {warningMsgs.map(msg => {
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="warning"
                                                />
                                            );
                                        })}
                                    </Panel>
                                    <Panel header={"出错消息"} key="13">
                                        {errorMsgs.map(msg => {
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="error"
                                                />
                                            );
                                        })}
                                    </Panel>
                                </Collapse>
                            </Panel>
                        </Collapse>
                    </div>
                ) : null}
            </div>
        );
    }

    private importFile = () => {
        const t = this;

        const fileDOM = this.file.current;

        if (fileDOM.files.length === 0) {
            ErrorHandler.handleErr("请选择一个.xlsx文件进行导入");
            return;
        }

        t.stageUploading();

        const file = fileDOM.files[0];

        const reader = new FileReader();

        reader.onload = async (evt: ProgressEvent<FileReader>) => {
            let fileContent = evt.target.result as string;
            const fileName = file.name;
            const updateDataIfExists = false;
            if (fileContent.length > 0 && fileContent.includes("base64,")) {
                fileContent = fileContent.split("base64,")[1];
            }
            if (fileContent.length > 0) {
                const params: IFileInfoWithOptionsViewModel = {
                    fileName,
                    updateDataIfExists,
                    fileContent,
                    authCode: ""
                };

                await Store.importBlocks(
                    FileInfoWithOptionsViewModel.fromJS(params)
                );
                t.finishUploading();
            }
        };
        reader.readAsDataURL(file);
    };

    private selectFile = () => {
        this.file.current.click();
    };

    private fileChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fileName: evt.target.files[0].name });
    };

    private clearResult = () => {
        Store.setResult();
    };

    private stageUploading = () => {
        this.setState({ uploading: true });
    };

    private finishUploading = () => {
        this.setState({ uploading: false });
    };

    private addBlockInfoToArray(blockArr: string[], blockNo: string) {
        if (!blockArr.includes(blockNo)) {
            blockArr.push(blockNo);
        }
    }

    private onTemplateDownload = (client: ImportingTemplateClient) => {
        return client.getBlockImportingTemplate();
    };
}

export default PageImportBlockInventoryData;
