import { AxiosRequestConfig } from "axios";
import { WebAPIConfig } from "./WebAPIClients";

export default class JWTWebAPIConfig extends WebAPIConfig {
    constructor(apiUrl: string, private accessToken: string) {
        super(apiUrl);
        this.defaultTimeout = 60; // 默认超时为60s，避免用户在10s超时后重新提交数据，导致部分API重复调用，出现重复数据
        this.apiTimeoutConfig = [
            { url: "api/Report/ExportOEMReport", timeout: 5 * 60 },
            {
                url: "api/Block/ImportBlockInventory",
                timeout: 20 * 60
            },
            {
                url: "api/StoneBundle/ImportBundleSlabData",
                timeout: 20 * 60
            },
            {
                url: "api/StoneBundle/ImportInventoryBundleData",
                timeout: 20 * 60
            },
            {
                url: "api/StoneBundle/ImportReorganizedBundleData",
                timeout: 20 * 60
            },
            { url: "api/Tile/ImportTiles", timeout: 20 * 60 },
            { url: "api/WorkOrder/ImportPolishingData", timeout: 20 * 60 },
            { url: "api/WorkOrder/ImportBundleInStock", timeout: 20 * 60 },
            { url: "api/WorkOrder/Export", timeout: 5 * 60 },
            { url: "api/System/UpdateInStockUnitPrice", timeout: 10 * 60 },
            { url: "api/System/UpdateAllUnitPrice", timeout: 20 * 60 }
        ];
    }

    public transformHttpRequestOptions(
        options: AxiosRequestConfig
    ): Promise<AxiosRequestConfig> {
        if (
            options.headers &&
            this.accessToken &&
            !options.headers.hasOwnProperty("Authorization")
        ) {
            options.headers = {
                ...options.headers,
                Authorization: "Bearer " + this.accessToken
            };
        }

        options.withCredentials = true;

        return super.transformHttpRequestOptions(options);
    }
}
