import { Descriptions } from "antd";
import React from "react";
import { WorkOrderFullInfoDTO } from "../../../app/WebAPIClients";
import Util from "../../../components/Util";

interface IFillingInfoProps {
    wo: WorkOrderFullInfoDTO;
}

export default class FillingInfo extends React.PureComponent<
    IFillingInfoProps
> {
    public render() {
        const t = this;
        const { wo } = t.props;

        return (
            <div>
                {wo.fillingStartTime ? (
                    <Descriptions
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        title={"补胶信息"}
                    >
                        {Util.isDefinedAndNotNull(wo.fillingStartTime) ? (
                            <Descriptions.Item label="开始时间">
                                {Util.formatDateTime(wo.fillingStartTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingEndTime) ? (
                            <Descriptions.Item label="结束时间">
                                {Util.formatDateTime(wo.fillingEndTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.timeConsumingOfFilling) ? (
                            <Descriptions.Item label="耗时">
                                {Util.getTimeConsuming(
                                    wo.timeConsumingOfFilling
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.areaAfterFilling) ? (
                            <Descriptions.Item label="补胶面积">
                                {wo.areaAfterFilling}
                                {" 平方"}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingBackMeshCount) ? (
                            <Descriptions.Item label="背网片数">
                                {wo.fillingBackMeshCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingShapingCount) ? (
                            <Descriptions.Item label="修面片数">
                                {wo.fillingShapingCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingDispensingCount) ? (
                            <Descriptions.Item label="点胶片数">
                                {wo.fillingDispensingCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingSplicingCount) ? (
                            <Descriptions.Item label="拼接片数">
                                {wo.fillingSplicingCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingPatchingCount) ? (
                            <Descriptions.Item label="挖补片数">
                                {wo.fillingPatchingCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingTotalSlabCount) ? (
                            <Descriptions.Item label="完工片数">
                                {wo.fillingTotalSlabCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingOperator) ? (
                            <Descriptions.Item label="补胶负责人">
                                {wo.fillingOperator}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.fillingQE) ? (
                            <Descriptions.Item label="补胶质检员">
                                {wo.fillingQE}
                            </Descriptions.Item>
                        ) : null}
                        {!Util.isNotNullAndNotEmpty(wo.fillingNotes) ? null : (
                            <Descriptions.Item label="备注">
                                {wo.fillingNotes}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                ) : (
                    <div style={{ textAlign: "center" }}>
                        {wo.skipFilling ? (
                            <span className="skipFillingInfo">大板直磨</span>
                        ) : (
                            "无补胶数据"
                        )}
                    </div>
                )}
            </div>
        );
    }
}
