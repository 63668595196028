import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import { BundleForListDTO } from "../../../../app/WebAPIClients";
import Util from "../../../../components/Util";
import Base from "../../../../stores/base";

export interface IFilterData {
    selectPrefix?: string;
    stockingAreaIds?: number[];
    statusCodes?: number[];
    selectWarehouse?: string[];
}

class Store extends Base {
    @observable
    public filterData: IFilterData = {};
    @observable
    public stoneBundleList: BundleForListDTO[] = [];
    @observable
    public totalArea: number = 0;

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }

    @action
    public async reloadData() {
        const t = this;
        const filterData = t.filterData;
        const warehouseId = Util.parseToInt(
            Util.getCascaderValue(filterData.selectWarehouse, 1)
        );
        const client = ClientFactory.getStoneBundleClient();
        const callAPI = async () => {
            const content = await client.getAll(
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                filterData.selectPrefix,
                undefined,
                undefined,
                undefined,
                undefined,
                warehouseId ? [warehouseId] : undefined,
                filterData.stockingAreaIds,
                undefined,
                filterData.statusCodes,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                t.pageSize,
                t.pageNo
            );
            runInAction(() => {
                this.stoneBundleList = content.result;
                this.totalArea = content.totalArea;
            });
            this.setPagingInfo(content.pagingInfo);
        };
        await t.callAPIFunForGet(callAPI);
    }
}

export default new Store();
