import { Alert, Button, Form, FormInstance, Input } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { UserInfoDTO } from "../../app/WebAPIClients";
import Settings from "../../components/SettingInfo";
import tenantInfo from "../../components/TenantInfo";
import userInfo from "../../components/UserInfo";
import Util from "../../components/Util";
import Store from "./store";

const FormItem = Form.Item;
declare interface IPageLoginByPasswordProps extends RouteComponentProps<{}> {}

declare interface IPageLoginByPasswordState extends IActionErrorState {
    returnToUrl: string;
    loginError: boolean;
    loginErrMsg: string;
}

@observer
class PageLoginByPassword extends React.Component<
    IPageLoginByPasswordProps,
    IPageLoginByPasswordState
> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            returnToUrl: Util.getLocState(this, "nextPathname"),
            loginError: false,
            loginErrMsg: null
        };
    }
    public render() {
        const t = this;
        const s = t.state;

        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
        };
        const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };
        let msg = null;

        if (s.loginError) {
            msg = (
                <Alert
                    message="错误"
                    description={s.loginErrMsg}
                    type="error"
                    showIcon={true}
                />
            );
        }

        return (
            <Form
                ref={this.formRef}
                name={"loginByPasswordForm"}
                layout="horizontal"
                onFinish={this.handleSubmit}
                className="login"
            >
                <FormItem
                    label="用户代码"
                    {...formItemLayout}
                    name="tenantAbbrCode"
                    rules={[
                        {
                            required: true,
                            message: "请输入用户代码"
                        }
                    ]}
                >
                    <Input placeholder="请输入用户代码" />
                </FormItem>
                <FormItem
                    label="用户名"
                    {...formItemLayout}
                    name={"userName"}
                    rules={[
                        {
                            required: true,
                            message: "请输入用户名"
                        }
                    ]}
                >
                    <Input placeholder="请输入用户名" />
                </FormItem>
                <FormItem
                    label="密码"
                    {...formItemLayout}
                    name={"password"}
                    rules={[
                        {
                            required: true,
                            message: "请输入密码"
                        }
                    ]}
                >
                    <Input placeholder="请输入" type="password" />
                </FormItem>

                <FormItem {...buttonItemLayout}>
                    <Button type="primary" htmlType="submit">
                        登录
                    </Button>
                </FormItem>

                <FormItem {...buttonItemLayout}>{msg}</FormItem>
            </Form>
        );
    }

    private handleSubmit = values => {
        const userName: string = values.userName;
        const password: string = values.password;
        const tenantAbbrCode: string = values.tenantAbbrCode;
        this.loginByPassword(userName, password, tenantAbbrCode);
    };

    private redirectToPreviousPage(ui: UserInfoDTO) {
        const t = this;
        const s = t.state;

        let returnToPath = s.returnToUrl === null ? "/" : s.returnToUrl;

        if (ui.userName === "DashboardViewer") {
            returnToPath = "/realtimereport";
        }

        t.props.history.push(returnToPath);
    }

    private async loginByPassword(
        userName: string,
        password: string,
        tenantAbbrCode: string
    ) {
        const t = this;
        await Store.loginByPassword(userName, password, tenantAbbrCode);
        if (!Store.hasError) {
            const result = Store.loginResult;
            const ui = result.userInfo;
            const ti = result.tenantInfo;
            const si = result.settings;
            const jwtToken = result.jwtToken;
            // 如果用户没有权限，则显示错误，如果有权限，则跳转到上一个页面
            if (!Util.isValidSysUser(ui)) {
                t.setState({
                    loginError: true,
                    loginErrMsg:
                        "您的账号没有对应的系统权限，请联系管理员分配权限后再试"
                });
            } else {
                userInfo.setUserInfo(ui);
                userInfo.setAccessToken(jwtToken);
                tenantInfo.setTenantInfo(ti);
                Settings.setSettings(si);
                t.redirectToPreviousPage(ui);
            }
        }
    }
}

export default PageLoginByPassword;
