import { Typography } from "antd";
import React from "react";
import "./PageHome.styl";
const { Title } = Typography;

class PageHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    public componentDidMount() {}

    public render() {
        return (
            <Typography style={{ textAlign: "center" }}>
                <Title>欢迎使用石材云ERP</Title>
                <Title level={2}>请从左边菜单选择所需功能</Title>
            </Typography>
        );
    }
}

export default PageHome;
