import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import { StockInAndOutRecordDTO } from "../../../app/WebAPIClients";
import Util from "../../../components/Util";
import Base from "../../../stores/base";

export interface IFilterData {
    selectBlockNumber?: string;
    selectBlockNumberFromQuarry?: string;
    selectWarehouse?: string[];
    recordType?: number;
    stockingAreaIds?: number[];
    ownerIds?: number[];
    startDate?: Date;
    endDate?: Date;
}

class Store extends Base {
    @observable
    public recordList: StockInAndOutRecordDTO[] = [];
    @observable
    public filterData: IFilterData = {};
    public totalVolume: number = 0;

    @action
    public async reloadData() {
        const t = this;
        const filterData = this.filterData;

        const client = ClientFactory.getStockInAndOutRecordClient();

        const warehouseId = Util.parseToInt(
            Util.getCascaderValue(filterData.selectWarehouse, 1)
        );

        const callAPI = async () => {
            const content = await client.getAllForBlock(
                filterData.selectBlockNumber,
                filterData.selectBlockNumberFromQuarry,
                warehouseId ? [warehouseId] : undefined,
                filterData.stockingAreaIds,
                filterData.recordType,
                Util.getValidDate(filterData.startDate),
                Util.getValidDate(filterData.endDate),
                t.pageSize,
                t.pageNo
            );

            runInAction(() => {
                this.recordList = content.result;
                this.totalVolume = content.totalArea;
                this.setPagingInfo(content.pagingInfo);
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }
}

export default new Store();
