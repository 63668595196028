import { DownOutlined } from "@ant-design/icons";
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  Collapse,
  DatePicker,
  Dropdown,
  Form,
  FormInstance,
  Input,
  message,
  Pagination,
  Row,
  Select,
  Spin,
  Table,
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../app/Perm";
import {
  CustomerForListDTO,
  FactoryDTO,
  IQuarryDTO,
  StockingAreaDTO,
  StoneCategoryDTO,
  StoneGradeDTO,
  WarehouseDTO,
  WarehouseType,
} from "../../../app/WebAPIClients";
import ColumnSettings from "../../../components/ColumnSettings";
import Consts, {
  formItemLayoutForCheckboxGroup,
  formItemLayoutForCol,
  formItemLayoutForSearch,
} from "../../../components/Consts";
import ExportUtil from "../../../components/ExportUtil";
import PermCtl from "../../../components/PermCtrl";
import SearchButtonGroup from "../../../components/SearchButtonGroup";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import StockingAreaUtil from "../../../components/StockingAreaUtil";
import UnitPriceUtil from "../../../components/UnitPriceUtil";
import Util from "../../../components/Util";
import validator from "../../../components/Validator";
import Store from "./store";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const {
  Customers,
  BlockStatus,
  Factories,
  StoneCategories,
  StoneGrades,
  BlockStockingAreas,
  Quarry,
  Warehouses,
} = Consts;

declare interface IBlockListProps extends RouteComponentProps<{}> {}

declare interface IBlockListState {
  factories: FactoryDTO[]; // 工厂
  warehouseList: WarehouseDTO[]; // 仓库
  warehouseOptions: any;
  categoryList: StoneCategoryDTO[]; // 石材种类
  categoryOptions: any; // 选择石材种类
  gradeList: StoneGradeDTO[]; // 石材等级
  gradeOptions: JSX.Element[]; // 选择等级
  checkBoxOptions: any; // 状态
  bsList: StockingAreaDTO[]; // 荒料库存区域
  bsOptions: JSX.Element[]; // 选择荒料库存区域
  quarryList: IQuarryDTO[]; // 矿山数据
  visible: boolean; // 控制点击显示关闭下拉菜单
  columnsInfo: ITableColumnInfo[];
  ownerList: CustomerForListDTO[];
  ownerOptions: JSX.Element[];
  enableBlockNumberFromQuarry: boolean;
  enableReferencePrice: boolean;
  selectBlockNumberFromQuarry?: string;
  uniqueWarehouse: boolean;
  buttonDisabled: boolean;
}

@observer
class PageBlockList extends React.Component<IBlockListProps, IBlockListState> {
  private componentName: string = "BlockList";
  private formRef: React.RefObject<FormInstance>;
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      factories: [],
      warehouseOptions: [],
      warehouseList: [],
      categoryList: [], // 石材种类
      categoryOptions: [], // 选择石材种类
      gradeList: [], // 石材等级
      gradeOptions: [], // 选择等级
      checkBoxOptions: [], // 状态
      bsList: [], // 荒料库存区域
      bsOptions: [], // 选择荒料库存区域
      quarryList: [], // 矿山数据
      ownerList: [],
      ownerOptions: [], // 所有者选择列表
      visible: false, // 控制点击显示关闭下拉菜单,
      enableBlockNumberFromQuarry: Settings.getBoolValue(
        SettingsDefinition.EnableBlockNumberFromQuarryKey,
      ),
      enableReferencePrice: Settings.getBoolValue(
        SettingsDefinition.EnableReferencePriceKey,
      ),
      columnsInfo: this.getBlockInfoColumns(),
      uniqueWarehouse: false,
      buttonDisabled: false,
    };
  }

  public async componentDidMount() {
    const t = this;
    const categoryList = await StoneCategories.getCategories();
    const gradeList = await StoneGrades.getGrades();
    const bsList = await BlockStockingAreas.getStockingAreas();
    const quarryList = await Quarry.getQuarries();
    const factories = await Factories.getFactories(); // 工厂列表
    let warehouseList = await Warehouses.getWarehouses(); // 仓库列表
    warehouseList = warehouseList.filter((w) => w.type === WarehouseType.Block);
    const categoryOptions = categoryList.map((c) => {
      return (
        <Option key={c.id} value={c.id}>
          {c.name}
        </Option>
      );
    });
    const gradeOptions = gradeList.map((g) => {
      return (
        <Option key={g.id} value={g.id}>
          {g.name}
        </Option>
      );
    });

    const checkBoxOptions = BlockStatus.data.map((status) => {
      return { label: status.text, value: status.value.toString() };
    });

    const ownerList = await Customers.getCustomers();
    const ownerOptions = ownerList.map((item) => {
      return (
        <Option key={item.id} value={item.id}>
          {item.name}
        </Option>
      );
    });

    // 在所有者列表中增加内部选项，用于筛选自有荒料
    ownerOptions.unshift(
      <Option key={0} value={0}>
        {"内部"}
      </Option>,
    );

    const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
      warehouseList,
      factories,
    );

    const uniqueWarehouse = warehouseList.length === 1;
    const warehouseId = Util.parseToInt(
      Util.getCascaderValue(Store.filterData.selectWarehouse, 1),
    );
    const bsOptions = StockingAreaUtil.getStockingAreaOptions(
      uniqueWarehouse,
      warehouseId,
      bsList,
      warehouseOptions,
    );

    t.setState({
      ownerList,
      ownerOptions,
      categoryList,
      gradeList,
      bsList,
      quarryList,
      categoryOptions,
      gradeOptions,
      bsOptions,
      checkBoxOptions,
      warehouseOptions,
      uniqueWarehouse,
    });

    await t.loadData();
  }

  public render() {
    const t = this;
    const s = t.state;
    const blockList = Store.blockList;
    const categoryOptions = s.categoryOptions;
    const gradeOptions = s.gradeOptions;
    const bsOptions = s.bsOptions;
    const checkBoxOptions = s.checkBoxOptions;

    const {
      selectBlockNumber,
      categoryIds,
      gradeIds,
      stockingAreaIds,
      statusCodes,
      maxLength,
      minLength,
      maxWidth,
      maxUnitPrice,
      minWidth,
      maxHeight,
      minHeight,
      minUnitPrice,
      ownerIds,
      selectBlockNumberFromQuarry,
      selectWarehouse,
      stockInStartDate,
      stockInEndDate,
      stockOutStartDate,
      stockOutEndDate,
      purchaseEndDate,
      purchaseStartDate,
    } = Store.filterData;

    const showUnitPriceFilter =
      PermCtl.isAuthorized(Perm.B_RP_R) && s.enableReferencePrice;
    return (
      <div>
        {s.buttonDisabled ? (
          <div
            className="importingSpinArea"
            ref={() => {
              "spinArea";
            }}
          >
            <p>{`正在导出文件，当前导出荒料数据共计：${Store.pagingInfo.totalRecordCount}颗，请等待...`}</p>
            <Spin />
          </div>
        ) : null}
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="高级搜索" key="1">
            <Form
              ref={this.formRef}
              name={"blockAdvancedSearchForm"}
              onFinish={t.handleSearch}
              {...formItemLayoutForSearch}
            >
              <Row>
                <Col span={24}>
                  <FormItem {...formItemLayoutForCheckboxGroup} label="状态">
                    <CheckboxGroup
                      options={checkBoxOptions}
                      value={statusCodes}
                      onChange={t.handleChange.bind(t, "statusCodes")}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="荒料编号">
                    <Input
                      style={{ width: "100%" }}
                      placeholder="请输入荒料编号"
                      value={selectBlockNumber}
                      onChange={t.handleInputChange.bind(
                        t,
                        "selectBlockNumber",
                      )}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="长度">
                    <Input.Group compact={true}>
                      <Input
                        type="number"
                        style={{
                          width: "42%",
                          textAlign: "center",
                        }}
                        placeholder="最小值"
                        allowClear={true}
                        value={minLength}
                        onChange={t.handleInputChange.bind(t, "minLength")}
                      />
                      <Input
                        style={{
                          width: "16%",
                          textAlign: "center",
                          borderLeft: 0,
                          borderRight: 0,
                          pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled={true}
                      />
                      <Input
                        type="number"
                        style={{
                          width: "42%",
                          textAlign: "center",
                        }}
                        placeholder="最大值"
                        allowClear={true}
                        value={maxLength}
                        onChange={t.handleInputChange.bind(t, "maxLength")}
                      />
                    </Input.Group>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="收料日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        purchaseStartDate,
                        purchaseEndDate,
                      )}
                      onChange={t.handlePurchaseDateChange}
                      placeholder={["开始日期", "结束日期"]}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="石材种类">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="选择石材种类"
                      value={categoryIds}
                      onChange={t.handleChange.bind(t, "categoryIds")}
                    >
                      {categoryOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="宽度">
                    <Input.Group compact={true}>
                      <Input
                        type="number"
                        style={{
                          width: "42%",
                          textAlign: "center",
                        }}
                        placeholder="最小值"
                        allowClear={true}
                        value={minWidth}
                        onChange={t.handleInputChange.bind(t, "minWidth")}
                      />
                      <Input
                        style={{
                          width: "16%",
                          textAlign: "center",
                          borderLeft: 0,
                          borderRight: 0,
                          pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled={true}
                      />
                      <Input
                        type="number"
                        style={{
                          width: "42%",
                          textAlign: "center",
                        }}
                        placeholder="最大值"
                        allowClear={true}
                        value={maxWidth}
                        onChange={t.handleInputChange.bind(t, "maxWidth")}
                      />
                    </Input.Group>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="入库日期">
                    <RangePicker
                      format={dateFormat}
                      style={{ width: "100%" }}
                      value={Util.getMomentArray(
                        stockInStartDate,
                        stockInEndDate,
                      )}
                      onChange={t.handleStockInDateChange}
                      placeholder={["开始日期", "结束日期"]}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="等级">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择等级"
                      value={gradeIds}
                      onChange={t.handleChange.bind(t, "gradeIds")}
                    >
                      {gradeOptions}
                    </Select>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="高度">
                    <Input.Group compact={true}>
                      <Input
                        type="number"
                        style={{
                          width: "42%",
                          textAlign: "center",
                        }}
                        placeholder="最小值"
                        allowClear={true}
                        value={minHeight}
                        onChange={t.handleInputChange.bind(t, "minHeight")}
                      />
                      <Input
                        style={{
                          width: "16%",
                          textAlign: "center",
                          borderLeft: 0,
                          borderRight: 0,
                          pointerEvents: "none",
                        }}
                        placeholder="~"
                        disabled={true}
                      />
                      <Input
                        type="number"
                        style={{
                          width: "42%",
                          textAlign: "center",
                        }}
                        placeholder="最大值"
                        allowClear={true}
                        value={maxHeight}
                        onChange={t.handleInputChange.bind(t, "maxHeight")}
                      />
                    </Input.Group>
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="出库日期">
                    <RangePicker
                      style={{ width: "100%" }}
                      format={dateFormat}
                      value={Util.getMomentArray(
                        stockOutStartDate,
                        stockOutEndDate,
                      )}
                      onChange={t.handleStockOutDateChange}
                      placeholder={["开始日期", "结束日期"]}
                    />
                  </FormItem>
                </Col>
                <Col {...formItemLayoutForCol}>
                  <FormItem label="所有者">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择所有者"
                      value={ownerIds}
                      onChange={t.handleChange.bind(t, "ownerIds")}
                    >
                      {s.ownerOptions}
                    </Select>
                  </FormItem>
                </Col>
                {showUnitPriceFilter ? (
                  <Col {...formItemLayoutForCol}>
                    <FormItem label="价格">
                      <Input.Group compact={true}>
                        <Input
                          type="number"
                          style={{
                            width: "42%",
                            textAlign: "center",
                          }}
                          placeholder="最低价"
                          allowClear={true}
                          value={minUnitPrice}
                          onChange={t.handleInputChange.bind(t, "minUnitPrice")}
                        />
                        <Input
                          style={{
                            width: "16%",
                            textAlign: "center",
                            borderLeft: 0,
                            borderRight: 0,
                            pointerEvents: "none",
                          }}
                          placeholder="~"
                          disabled={true}
                        />
                        <Input
                          type="number"
                          style={{
                            width: "42%",
                            textAlign: "center",
                          }}
                          placeholder="最高价"
                          allowClear={true}
                          value={maxUnitPrice}
                          onChange={t.handleInputChange.bind(t, "maxUnitPrice")}
                        />
                      </Input.Group>
                    </FormItem>
                  </Col>
                ) : null}
                {s.enableBlockNumberFromQuarry ? (
                  <Col {...formItemLayoutForCol}>
                    <FormItem label="矿山荒料号">
                      <Input
                        placeholder="请输入矿山荒料号"
                        value={selectBlockNumberFromQuarry}
                        onChange={t.handleInputChange.bind(
                          t,
                          "selectBlockNumberFromQuarry",
                        )}
                      />
                    </FormItem>
                  </Col>
                ) : null}
                {s.uniqueWarehouse ? null : (
                  <Col {...formItemLayoutForCol}>
                    <FormItem label="仓库">
                      <Cascader
                        value={selectWarehouse}
                        options={s.warehouseOptions}
                        placeholder="请选择仓库"
                        onChange={t.handleWarehouseChange}
                      />
                    </FormItem>
                  </Col>
                )}
                <Col {...formItemLayoutForCol}>
                  <FormItem label="区域">
                    <Select
                      allowClear={true}
                      mode="multiple"
                      style={{ width: "100%" }}
                      optionFilterProp="children"
                      placeholder="请选择区域"
                      value={stockingAreaIds}
                      onChange={t.handleChange.bind(t, "stockingAreaIds")}
                    >
                      {bsOptions}
                    </Select>
                  </FormItem>
                </Col>
              </Row>
              <SearchButtonGroup
                buttonDisabled={s.buttonDisabled}
                onClearClick={t.handleClearSearchCriteria}
              />
            </Form>
          </Panel>
        </Collapse>
        <div style={{ margin: "1%", textAlign: "right" }}>
          {PermCtl.isAuthorized(Perm.B_E) ? (
            <Button disabled={s.buttonDisabled} onClick={t.handleExportClick}>
              导出
            </Button>
          ) : null}
          <Dropdown
            overlay={t.getColumnSettings()}
            trigger={["click"]}
            onVisibleChange={t.handleVisibleChange}
            visible={s.visible}
          >
            <Button>
              更多 <DownOutlined />
            </Button>
          </Dropdown>
        </div>
        <div>
          <Table
            columns={Util.getTableColumns(s.columnsInfo, t.componentName)}
            dataSource={blockList}
            onChange={t.handleSorterChange}
            rowKey={"id"}
            pagination={false}
          />
        </div>
        {Store.pagingInfo ? (
          <div className="paginationArea">
            <Pagination
              total={Store.pagingInfo.totalRecordCount}
              showTotal={t.getTotalRecordString}
              pageSize={Store.pageSize}
              current={Store.pageNo}
              defaultCurrent={1}
              showQuickJumper={true}
              onChange={t.handlePaginationChange}
              showSizeChanger={false}
            />
          </div>
        ) : null}
      </div>
    );
  }

  private async loadData() {
    await Store.reloadData();
  }

  // 跳到第page页
  private handlePaginationChange = async (page: number) => {
    Store.setPageNo(page);
    await this.loadData();
  };

  private handleClearSearchCriteria = async () => {
    const t = this;
    const s = t.state;
    const bsOptions = StockingAreaUtil.getStockingAreaOptions(
      s.uniqueWarehouse,
      null,
      s.bsList,
      s.warehouseOptions,
    );

    ColumnSettings.setSortSettings(this.componentName, null);
    Store.updataFilterData({});
    Store.setPageNo(1);
    await this.loadData();
    t.setState({
      bsOptions,
    });
  };

  private handleSearch = async () => {
    const valid: boolean = this.validate();
    if (!valid) {
      return;
    }
    Store.setPageNo(1);
    await this.loadData();
  };

  private validate(): boolean {
    const {
      minLength,
      maxLength,
      minWidth,
      maxWidth,
      minHeight,
      maxHeight,
      maxUnitPrice,
      minUnitPrice,
    } = Store.filterData;
    let valid = validator.range(minLength, 300, 5000, "最小长度");
    valid = valid && validator.range(maxLength, 300, 5000, "最大长度");
    valid = valid && validator.range(minWidth, 300, 5000, "最小宽度");
    valid = valid && validator.range(maxWidth, 300, 5000, "最大宽度");
    valid = valid && validator.range(minHeight, 300, 5000, "最小高度");
    valid = valid && validator.range(maxHeight, 300, 5000, "最大高度");
    valid =
      valid &&
      validator.range(
        maxUnitPrice,
        Util.isNotNullAndNotEmpty(minUnitPrice) ? minUnitPrice : 0,
        10000,
        "最高价",
      );
    valid =
      valid &&
      validator.range(
        minUnitPrice,
        0,
        Util.isNotNullAndNotEmpty(maxUnitPrice) ? maxUnitPrice : 10000,
        "最低价",
      );
    return valid;
  }

  private handleInputChange(label: string, event) {
    Store.updataFilterData({
      ...Store.filterData,
      [label]: event.target.value,
    });
  }

  private handleVisibleChange = () => {
    this.setState({ visible: !this.state.visible });
  };

  private handleChange = (label: string, value) => {
    Store.updataFilterData({
      ...Store.filterData,
      [label]: value,
    });
  };

  private handSelectColumnsChange = (label: string, value: boolean) => {
    const t = this;
    const s = t.state;
    const visibleColumns: string[] = Util.getVisibleColumns(
      s.columnsInfo,
      t.componentName,
    );

    if (value) {
      if (!visibleColumns.includes(label)) {
        visibleColumns.push(label);
      }
    } else if (visibleColumns.includes(label)) {
      const index = visibleColumns.indexOf(label);
      visibleColumns.splice(index, 1);
    }

    ColumnSettings.setVisibleColumns(t.componentName, visibleColumns);
    t.setState(s);
  };

  private handleCheckAllChange = (checkAll: boolean) => {
    const t = this;
    const s = t.state;
    const columnsInfo = s.columnsInfo;

    Util.checkAllChange(columnsInfo, t.componentName, checkAll);
    t.setState(s);
  };

  // 选择仓库
  private handleWarehouseChange = (value: string[]) => {
    const t = this;
    const s = t.state;
    const bsOptions = StockingAreaUtil.getStockingAreaOptions(
      s.uniqueWarehouse,
      Util.parseToInt(value[1]),
      s.bsList,
      s.warehouseOptions,
    );

    t.setState({ bsOptions });
    Store.updataFilterData({
      ...Store.filterData,
      selectWarehouse: value,
      stockingAreaIds: [],
    });
  };

  // 选择要显示的列
  private getColumnSettings(): JSX.Element {
    const t = this;
    const s = t.state;
    const visibleColumns = Util.getVisibleColumns(
      s.columnsInfo,
      t.componentName,
    );

    const checkAll: boolean = Util.judgeCheckAll(s.columnsInfo, visibleColumns);

    return (
      <div style={{ width: "100%", background: "#FFFFFF" }}>
        <Checkbox
          onChange={t.handleCheckAllChange.bind(t, checkAll)}
          checked={checkAll}
        >
          全选
        </Checkbox>
        <br />
        {s.columnsInfo && s.columnsInfo.length > 0 && visibleColumns
          ? s.columnsInfo.map((ci, index) => {
              let canView = true; // 当前用户的角色是否有权限查看该字段
              const visible = visibleColumns.some((c) => c === ci.title);
              if (ci.viewingPermissions && ci.viewingPermissions.length > 0) {
                canView = PermCtl.isAnyAuthorized(ci.viewingPermissions);
              }

              if (
                canView &&
                (typeof ci.disabled === "undefined" || !ci.disabled)
              ) {
                return (
                  <div key={index}>
                    <Checkbox
                      checked={visible}
                      disabled={ci.alwaysShow}
                      onChange={t.handSelectColumnsChange.bind(
                        t,
                        ci.title,
                        !visible,
                      )}
                    >
                      {ci.title}
                    </Checkbox>
                  </div>
                );
              }
            })
          : null}
      </div>
    );
  }

  private handleSorterChange = async (_1, _2, sorter) => {
    const t = this;
    Util.updateSortSettings(sorter, t.componentName);

    Store.setPageNo(1);
    Store.setPageSize(10);
    await t.loadData();
  };

  private getItemName = (itemId: number, listName: string): string => {
    const t = this;
    const s = t.state;
    const list = s[listName];
    return Util.getItemName(itemId, list);
  };

  private getShowStockingArea(itemId) {
    const t = this;
    const s = t.state;

    const stockingArea = s.bsList.find((b) => b.id === itemId);
    if (!stockingArea) {
      return;
    }
    return StockingAreaUtil.getShowText(
      s.uniqueWarehouse,
      stockingArea,
      s.warehouseOptions,
    );
  }

  private getTotalRecordString(totalCount: number): string {
    return `共 ${totalCount} 颗 共 ${Store.totalVolume} m³`;
  }

  private getDimensionDisplayOrder(): BlockDimensionDisplayOrder {
    return Settings.getValue(
      SettingsDefinition.BlockDimensionDisplayOrderKey,
    ) as BlockDimensionDisplayOrder;
  }

  private getWidthHeightColumnInfoInOrder(
    widthColumn: ITableColumnInfo,
    heightColumn: ITableColumnInfo,
  ): ITableColumnInfo[] {
    const order = this.getDimensionDisplayOrder();
    if (order === SettingsDefinition.BlockDimensionLengthHeightWidth) {
      return [heightColumn, widthColumn];
    } else {
      return [widthColumn, heightColumn];
    }
  }

  private getBlockInfoColumns(): ITableColumnInfo[] {
    const viewCostPricePermissions = [Perm.B_C_R];

    const columnsFirstBatch: ITableColumnInfo[] = [
      {
        title: "荒料编号",
        dataIndex: "blockNumber",
        key: "BlockNumber",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: true,
      },
      {
        title: "矿山荒料号",
        dataIndex: "blockNumberFromQuarry",
        key: "BlockNumberFromQuarry",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        disabled: !Settings.getBoolValue(
          SettingsDefinition.EnableBlockNumberFromQuarryKey,
        ),
      },
      {
        title: "所有者",
        dataIndex: "ownerId",
        key: "OwnerId",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        render: (ownerId) => this.getItemName(ownerId, "ownerList"),
      },
      {
        title: "品名",
        dataIndex: "categoryId",
        key: "categoryId",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (categoryId) => this.getItemName(categoryId, "categoryList"),
      },
      {
        title: "矿山",
        dataIndex: "quarryId",
        key: "quarryId",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (quarryId) => this.getItemName(quarryId, "quarryList"),
      },
      {
        title: "等级",
        dataIndex: "gradeId",
        key: "gradeId",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (gradeId) => this.getItemName(gradeId, "gradeList"),
      },
      {
        title: "价格",
        dataIndex: "unitPrice",
        key: "UnitPrice",
        align: "center",
        sorter: true,
        showByDefault:
          Settings.getBoolValue(SettingsDefinition.EnableReferencePriceKey) &&
          PermCtl.isAuthorized(Perm.B_RP_R),
        alwaysShow: false,
        disabled: !Settings.getBoolValue(
          SettingsDefinition.EnableReferencePriceKey,
        ),
        viewingPermissions: [Perm.B_RP_R],
        render: (unitPrice) => UnitPriceUtil.showUnitPriceJSX(unitPrice),
      },
      {
        title: "收料日期",
        dataIndex: "purchaseDate",
        key: "PurchaseDate",
        align: "center",
        sorter: true,
        showByDefault: false,
        alwaysShow: false,
        render: (purchaseDate) => {
          return Util.formatDate(purchaseDate);
        },
      },
      {
        title: "收方长",
        dataIndex: "quarryReportedLength",
        key: "QuarryReportedLength",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
      },
    ];
    const quarryReportHeightColumn: ITableColumnInfo = {
      title: "收方高",
      dataIndex: "quarryReportedHeight",
      key: "QuarryReportedHeight",
      align: "center",
      sorter: true,
      showByDefault: true,
      alwaysShow: false,
    };
    const quarryReportedWidthColumn: ITableColumnInfo = {
      title: "收方宽",
      dataIndex: "quarryReportedWidth",
      key: "QuarryReportedWidth",
      align: "center",
      sorter: true,
      showByDefault: true,
      alwaysShow: false,
    };
    const quarryReportedWidthHeightColumns: ITableColumnInfo[] =
      this.getWidthHeightColumnInfoInOrder(
        quarryReportedWidthColumn,
        quarryReportHeightColumn,
      );

    const columnsSecondBatch: ITableColumnInfo[] = [
      {
        title: "收方体积",
        key: "quarryReportedVolume",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (block) => {
          return Util.calculateVolume(
            block.quarryReportedWidth,
            block.quarryReportedHeight,
            block.quarryReportedLength,
          );
        },
      },
      {
        title: "收料负责人",
        dataIndex: "purchaser",
        key: "purchaser",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
      },
      {
        title: "单价",
        dataIndex: "price",
        key: "price",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        viewingPermissions: viewCostPricePermissions,
      },
      {
        title: "土地费",
        dataIndex: "landFee",
        key: "landFee",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        viewingPermissions: viewCostPricePermissions,
      },
      {
        title: "运输费",
        dataIndex: "transportFee",
        key: "transportFee",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        viewingPermissions: viewCostPricePermissions,
      },
      {
        title: "税费",
        dataIndex: "tax",
        key: "tax",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        viewingPermissions: viewCostPricePermissions,
      },
      {
        title: "提成",
        dataIndex: "commission",
        key: "commission",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        viewingPermissions: viewCostPricePermissions,
      },
      {
        title: "总金额",
        dataIndex: "totalAmount",
        key: "totalAmount",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        viewingPermissions: viewCostPricePermissions,
      },
      {
        title: "入库日期",
        dataIndex: "stockInTime",
        key: "StockInTime",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
        render: (stockInTime) => {
          return Util.formatDate(stockInTime);
        },
      },
      {
        title: "入库长",
        dataIndex: "validatedLength",
        key: "ValidatedLength",
        align: "center",
        sorter: true,
        showByDefault: true,
        alwaysShow: false,
      },
    ];

    const validatedHeightColumn: ITableColumnInfo = {
      title: "入库高",
      dataIndex: "validatedHeight",
      key: "ValidatedHeight",
      align: "center",
      sorter: true,
      showByDefault: true,
      alwaysShow: false,
    };
    const validatedWidthColumn: ITableColumnInfo = {
      title: "入库宽",
      dataIndex: "validatedWidth",
      key: "ValidatedWidth",
      align: "center",
      sorter: true,
      showByDefault: true,
      alwaysShow: false,
    };
    const validatedWidthHeightColumns: ITableColumnInfo[] =
      this.getWidthHeightColumnInfoInOrder(
        validatedWidthColumn,
        validatedHeightColumn,
      );

    const columnsThirdBatch: ITableColumnInfo[] = [
      {
        title: "入库体积",
        key: "validatedVolume",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (block) => {
          const volume = Util.calculateVolume(
            block.validatedWidth,
            block.validatedHeight,
            block.validatedLength,
          );
          return volume === 0 ? "" : volume;
        },
      },
      {
        title: "入库负责人",
        dataIndex: "stockInOperator",
        key: "stockInOperator",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
      },
      {
        title: "修边长",
        dataIndex: "trimmedLength",
        key: "TrimmedLength",
        align: "center",
        sorter: true,
        showByDefault: false,
        alwaysShow: false,
      },
    ];

    const trimmedHeightColumn: ITableColumnInfo = {
      title: "修边高",
      dataIndex: "trimmedHeight",
      key: "TrimmedHeight",
      align: "center",
      sorter: true,
      showByDefault: false,
      alwaysShow: false,
    };
    const trimmedWidthColumn: ITableColumnInfo = {
      title: "修边宽",
      dataIndex: "trimmedWidth",
      key: "TrimmedWidth",
      align: "center",
      sorter: true,
      showByDefault: false,
      alwaysShow: false,
    };
    const trimmedWidthHeightColumns: ITableColumnInfo[] =
      this.getWidthHeightColumnInfoInOrder(
        trimmedWidthColumn,
        trimmedHeightColumn,
      );

    const columnsFourthBatch: ITableColumnInfo[] = [
      {
        title: "修边体积",
        key: "trimmedVolume",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        render: (block) => {
          const volume = Util.calculateVolume(
            block.trimmedLength,
            block.trimmedWidth,
            block.trimmedHeight,
          );
          return volume === 0 ? "" : volume;
        },
      },
      {
        title: "出库日期",
        dataIndex: "stockOutTime",
        key: "stockOutTime",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        render: (stockOutTime) => {
          return Util.formatDate(stockOutTime);
        },
      },
      {
        title: "出库负责人",
        dataIndex: "stockOutOperator",
        key: "stockOutOperator",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
      },
      {
        title: "荒料出材率",
        key: "outturnPercentage",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
        render: (block) => {
          const outturnPercentage =
            Util.calculateVolume(
              block.trimmedLength,
              block.trimmedWidth,
              block.trimmedHeight,
            ) /
            Util.calculateVolume(
              block.quarryReportedWidth,
              block.quarryReportedHeight,
              block.quarryReportedLength,
            );
          return outturnPercentage === 0
            ? ""
            : Util.toPercent(outturnPercentage);
        },
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (status) => {
          return BlockStatus.getStatus(status).text;
        },
      },
      {
        title: "区域",
        dataIndex: "stockingAreaId",
        key: "stockingAreaId",
        align: "center",
        showByDefault: true,
        alwaysShow: false,
        render: (stockingAreaId) => this.getShowStockingArea(stockingAreaId),
      },
      {
        title: "车牌号",
        dataIndex: "licensePlateNumber",
        key: "licensePlateNumber",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
      },
      {
        title: "加工工艺",
        dataIndex: "appliedProcessesString",
        key: "appliedProcessesString",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
      },
      {
        title: "备注",
        dataIndex: "notes",
        key: "notes",
        align: "center",
        showByDefault: false,
        alwaysShow: false,
      },
    ];

    return [
      ...columnsFirstBatch,
      ...quarryReportedWidthHeightColumns,
      ...columnsSecondBatch,
      ...validatedWidthHeightColumns,
      ...columnsThirdBatch,
      ...trimmedWidthHeightColumns,
      ...columnsFourthBatch,
    ];
  }

  private setButtonEnable(time) {
    if (!Util.isDefinedAndNotNull(time)) {
      return;
    }
    setTimeout(
      // 设置定时器，由于导出数据量可能较大，设置指定时间后再恢复点亮Button
      () => {
        this.setState({ buttonDisabled: false });
      },
      time * 1000,
    );
  }

  private handleExportClick = async () => {
    const t = this;
    const valid = t.validate();
    if (!valid) {
      return;
    }

    Store.setPageNo(1);
    await Store.reloadData();
    if (!Store.hasError) {
      if (
        Store.blockList &&
        Store.blockList.length &&
        Store.blockList.length > 0
      ) {
        t.exportBlocks();
      } else {
        message.error("指定的导出条件没有数据！");
      }
    }
  };

  private exportBlocks = () => {
    const t = this;

    let exportAPI: string = "Block/Export?";

    if (Util.isNotNullAndNotEmpty(Store.filterData.selectBlockNumber)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "blockNumber",
        Store.filterData.selectBlockNumber,
      );
    }

    if (
      Util.isNotNullAndNotEmpty(Store.filterData.selectBlockNumberFromQuarry)
    ) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "blockNumberFromQuarry",
        Store.filterData.selectBlockNumberFromQuarry,
      );
    }

    if (
      Store.filterData.categoryIds &&
      Store.filterData.categoryIds.length &&
      Store.filterData.categoryIds.length > 0
    ) {
      Store.filterData.categoryIds.forEach((categoryId) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "categoryIds",
          categoryId,
        );
      });
    }

    if (
      Store.filterData.gradeIds &&
      Store.filterData.gradeIds.length &&
      Store.filterData.gradeIds.length > 0
    ) {
      Store.filterData.gradeIds.forEach((gradeId) => {
        exportAPI += ExportUtil.addParameter(exportAPI, "gradeIds", gradeId);
      });
    }

    if (
      Store.filterData.stockingAreaIds &&
      Store.filterData.stockingAreaIds.length &&
      Store.filterData.stockingAreaIds.length > 0
    ) {
      Store.filterData.stockingAreaIds.forEach((stockingAreaId) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "stockingAreaIds",
          stockingAreaId,
        );
      });
    }

    if (
      Store.filterData.statusCodes &&
      Store.filterData.statusCodes.length &&
      Store.filterData.statusCodes.length > 0
    ) {
      Store.filterData.statusCodes.forEach((statusCode) => {
        exportAPI += ExportUtil.addParameter(
          exportAPI,
          "statusCodes",
          statusCode,
        );
      });
    }

    if (
      Store.filterData.ownerIds &&
      Store.filterData.ownerIds.length &&
      Store.filterData.ownerIds.length > 0
    ) {
      Store.filterData.ownerIds.forEach((ownerId) => {
        exportAPI += ExportUtil.addParameter(exportAPI, "ownerIds", ownerId);
      });
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.selectWarehouse)) {
      const warehouseId = Util.getCascaderValue(
        Store.filterData.selectWarehouse,
        1,
      );
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "warehouseIds",
        warehouseId,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.maxLength)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "maxLength",
        Store.filterData.maxLength,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.minLength)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "minLength",
        Store.filterData.minLength,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.maxWidth)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "maxWidth",
        Store.filterData.maxWidth,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.minWidth)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "minWidth",
        Store.filterData.minWidth,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.maxHeight)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "maxHeight",
        Store.filterData.maxHeight,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.minHeight)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "minHeight",
        Store.filterData.minHeight,
      );
    }
    if (Util.isNotNullAndNotEmpty(Store.filterData.maxUnitPrice)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "maxUnitPrice",
        Store.filterData.maxUnitPrice,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.minUnitPrice)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "minUnitPrice",
        Store.filterData.minUnitPrice,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.purchaseStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "purchaseStartDate",
        Store.filterData.purchaseStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.purchaseEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "purchaseEndDate",
        Store.filterData.purchaseEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.stockInStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "stockInStartDate",
        Store.filterData.stockInStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.stockInEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "stockInEndDate",
        Store.filterData.stockInEndDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.stockOutStartDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "stockOutStartDate",
        Store.filterData.stockOutStartDate,
      );
    }

    if (Util.isNotNullAndNotEmpty(Store.filterData.stockOutEndDate)) {
      exportAPI += ExportUtil.addParameter(
        exportAPI,
        "stockOutEndDate",
        Store.filterData.stockOutEndDate,
      );
    }

    message.info("正在导出指定的荒料列表数据...");
    ExportUtil.setExportUrl(exportAPI);

    // 为防止导出数据量较大和多用户同时访问操作时，导致服务器性能降低的现象，每次点击导出数据后，将会按不同时长disabled Button

    t.setState({ buttonDisabled: true });

    // 导出荒料根据实际测试，按数据量大小设置不同时间
    // 小于10000，设置5s;
    // 大于10000，小于20000，设置10s;
    // 大于20000，设置30s;

    const time =
      Store.pagingInfo.totalRecordCount < 10000
        ? 5
        : Store.pagingInfo.totalRecordCount < 20000
        ? 10
        : 30;

    t.setButtonEnable(time);
  };

  private handlePurchaseDateChange = (_1, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    Store.updataFilterData({
      ...Store.filterData,
      purchaseStartDate: startDate,
      purchaseEndDate: endDate,
    });
  };

  private handleStockInDateChange = (_1, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    Store.updataFilterData({
      ...Store.filterData,
      stockInStartDate: startDate,
      stockInEndDate: endDate,
    });
  };

  private handleStockOutDateChange = (_1, dateStrings) => {
    const startDate = dateStrings[0];
    const endDate = dateStrings[1];
    Store.updataFilterData({
      ...Store.filterData,
      stockOutStartDate: startDate,
      stockOutEndDate: endDate,
    });
  };
}

export default PageBlockList;
