import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import { LoginResultDTO } from "../../app/WebAPIClients";
import Base from "../../stores/base";

class Store extends Base {
    @observable
    public loggedIn: boolean = false;
    public loginResult: LoginResultDTO | null;

    @action
    public async loginByPassword(
        userName: string,
        password: string,
        tenantAbbrCode: string
    ) {
        const client = ClientFactory.getSecurityClient();
        const callAPI = async () => {
            const content = await client.loginByPassword(
                userName,
                password,
                tenantAbbrCode
            );
            runInAction(() => {
                this.loginResult = content;
                this.loggedIn = true;
            });
        };

        await this.callAPIFun(callAPI, "正在登录...", "登录出错:");
    }
}

export default new Store();
