import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import {
    FileInfoWithOptionsViewModel,
    IDataImportResultDTO
} from "../../../app/WebAPIClients";
import Base from "../../../stores/base";

class Store extends Base {
    @observable
    public result: IDataImportResultDTO = null;

    public async importBlocks(params: FileInfoWithOptionsViewModel) {
        const t = this;

        const client = ClientFactory.getBlockClient();
        const callAPI = async () => {
            const result = await client.importBlockInventory(params);
            runInAction(() => {
                t.result = result;
            });
        };

        await this.callAPIFun(
            callAPI,
            "数据导入中...",
            "数据导入出错，请重试。详细信息："
        );
    }

    @action
    public setResult(result?: IDataImportResultDTO) {
        this.result = result;
    }
}

export default new Store();
