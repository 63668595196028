import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import { DingtalkConfigDTO, LoginResultDTO } from "../../app/WebAPIClients";
import Base from "../../stores/base";

class Store extends Base {
    @observable
    public ddConfig: DingtalkConfigDTO = null;
    @observable
    public authCode: any = null;
    @observable
    public loggedIn: boolean = false;
    @observable
    public loginResult: LoginResultDTO | null = null;

    @action
    public async getConfig(clientUrl: string, tenantAbbrCode: string) {
        const client = ClientFactory.getDingtalkClient();
        const callAPI = async () => {
            const content = await client.getPCConfig(clientUrl, tenantAbbrCode);
            runInAction(() => (this.ddConfig = content));
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public async login(authCode: string, tenantAbbrCode: string) {
        const client = ClientFactory.getSecurityClient();
        const callAPI = async () => {
            const content = await client.login(authCode, tenantAbbrCode);
            runInAction(() => {
                this.loginResult = content;
                this.loggedIn = true;
            });
        };
        await this.callAPIFun(
            callAPI,
            "正在登录...",
            "登录出错，请重试。详细信息："
        );
    }
}

export default new Store();
