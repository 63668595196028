import { Alert, Table } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../../app/Perm";
import Consts from "../../../../components/Consts";
import PagingControls from "../../../../components/PagingControls";
import PermCtl from "../../../../components/PermCtrl";
import Util from "../../../../components/Util";
import "./PageDashboard.styl";
import Store from "./store";

declare interface IPageDashboardProps extends RouteComponentProps<{}> {}

declare interface IPageDashboardState {
    autocycle: boolean;
}

@observer
class PageDashboard extends React.Component<
    IPageDashboardProps,
    IPageDashboardState
> {
    private timeoutId: NodeJS.Timer;
    constructor(props) {
        super(props);
        this.state = {
            autocycle: Util.getLocState(this, "autocycle", true) // 自动循环跳转
        };
        this.timeoutId = null;
    }

    public async componentDidMount() {
        await this.loadData(this.state.autocycle);
    }

    public componentWillUnmount() {
        Store.initData();
        this.clearTimeout();
    }

    public render() {
        const t = this;
        const s = t.state;
        if (!PermCtl.isAuthorized(Perm.RPT_DB_R)) {
            return (
                <div>当前用户没有权限查看此页面，请联系系统管理员分配权限</div>
            );
        }
        const columns = [
            {
                title: "荒料号",
                dataIndex: "blockNumber",
                key: "blockNumber",
                className: "largeFont"
            },
            {
                title: "厚度",
                dataIndex: "thickness",
                key: "thickness",
                className: "largeFont"
            },
            {
                title: "生产工序",
                dataIndex: "manufacturingState",
                key: "manufacturingState",
                render: state => {
                    return this.getManufacturingState(state);
                },
                className: "largeFont"
            },
            {
                title: "收方体积",
                dataIndex: "volumeAfterQuarryReported",
                key: "volumeAfterQuarryReported",
                className: "largeFont"
            },
            {
                title: "修边体积",
                dataIndex: "volumeAfterTrimming",
                key: "volumeAfterTrimming",
                className: "largeFont"
            },
            {
                title: "修边出材率",
                dataIndex: "blockOutturnPercentage",
                key: "blockOutturnPercentage",
                className: "largeFont",
                render: (value, row) => {
                    return this.isBopError(value, row);
                }
            },
            {
                title: "毛板出材",
                dataIndex: "rawSlabOutturnPercentage",
                key: "rawSlabOutturnPercentage",
                className: "largeFont",
                render: (value, row) => {
                    return this.isRsopError(value, row);
                }
            },
            {
                title: "毛板面积",
                dataIndex: "areaAfterSawing",
                key: "areaAfterSawing",
                className: "largeFont"
            },
            {
                title: "光板面积",
                dataIndex: "areaAfterPolishing",
                key: "areaAfterPolishing",
                className: "largeFont"
            }
        ];

        return (
            <div className="realtimeReportBackground">
                {Store.result && Store.result.length > 0 ? (
                    <div className="outlineDiv">
                        <Table
                            columns={columns}
                            dataSource={Store.result}
                            pagination={false}
                            rowClassName={t.getRowClassName}
                            rowKey={"id"}
                        />
                    </div>
                ) : (
                    <Alert
                        message="没有数据"
                        description="没有获取到数据"
                        type="info"
                        showIcon={true}
                    />
                )}
                <PagingControls
                    autocycle={s.autocycle}
                    onPreviousReportClick={t.handlePreviousReportClick.bind(t)}
                    onNextReportClick={t.handleNextReportClick.bind(t)}
                    onPauseClick={t.handlePauseClick.bind(t)}
                    onContinueClick={t.handleContinueClick.bind(t)}
                    allowPageSwitching={true}
                    onPreviousPageClick={t.handlePreviousPageClick.bind(t)}
                    onNextPageClick={t.handleNextPageClick.bind(t)}
                />
            </div>
        );
    }

    private getManufacturingStateText(state) {
        const ms = Consts.ManufacturingState.getState(state);
        return ms === null ? "" : ms.text;
    }

    private getManufacturingState(state) {
        const woStateName = this.getManufacturingStateText(state);
        let className = "";
        switch (state) {
            case 10:
                className = "notStartedWO";
                break;
            case 15:
            case 20:
                className = "materialRequisitionWO";
                break;
            case 25:
            case 30:
                className = "trimmingWO";
                break;
            case 35:
            case 40:
                className = "sawingWO";
                break;
            case 43:
            case 45:
            case 50:
                className = "fillingWO";
                break;
            case 55:
            case 60:
            case 70:
            case 90:
                className = "polishingWO";
                break;
            case 80:
                className = "cancelledWO";
                break;
        }
        return <span className={className}>{woStateName}</span>;
    }

    private getRowClassName(row, index: number) {
        return index % 2 === 0 ? "oddRow" : "evenRow";
    }

    // 跳转到上一个页面
    private handlePreviousReportClick() {
        if (this.timeoutId) {
            this.clearTimeout();
        }

        this.props.history.push({
            pathname: "/reportmanufacturing",
            state: { autocycle: this.state.autocycle }
        });
    }

    // 跳转到下一个页面
    private handleNextReportClick() {
        if (this.timeoutId) {
            this.clearTimeout();
        }
        this.props.history.push({
            pathname: "/realtimereport",
            state: { autocycle: this.state.autocycle }
        });
    }

    // 加载上一页数据
    private async handlePreviousPageClick() {
        if (this.timeoutId) {
            this.clearTimeout();
        }
        if (Store.pageNo > 1) {
            Store.setPageNo(Store.pageNo - 2);
            await this.loadData(this.state.autocycle);
        }
    }

    // 加载下一页数据
    private async handleNextPageClick() {
        if (this.timeoutId) {
            this.clearTimeout();
        }
        await this.loadData(this.state.autocycle);
    }

    // 暂停自动循环跳转
    private handlePauseClick() {
        this.setState({ autocycle: false });
        this.clearTimeout();
    }

    private handleContinueClick() {
        this.setState({ autocycle: true });
        this.timeoutId = setTimeout(async () => {
            await this.loadData(true);
        }, 30 * 1000);
    }

    private isBopError(value, item) {
        if (typeof value === "undefined" || value === null) {
            return null;
        }
        let bop = null;
        let bopWarning = false;
        let bopError = false;
        if (item.blockOutturnPercentage) {
            bop = item.blockOutturnPercentage.toFixed(2);
            if (bop > 1.6 || bop < 0.9) {
                bopError = true;
            } else if (bop > 1.5 || bop < 0.98) {
                bopWarning = true;
            }
        }
        const bopStyle = bopError ? "warning" : bopWarning ? "attention" : null;
        return <span className={bopStyle}>{Util.toPercent(value)}</span>;
    }

    private isRsopError(value, item) {
        if (typeof value === "undefined" || value === null) {
            return null;
        }
        let rsop = null;
        let rsopWarning = false;
        let rsopError = false;
        if (item.rawSlabOutturnPercentage) {
            rsop = item.rawSlabOutturnPercentage.toFixed(2);
            const proportion = rsop / (1000 / (item.thickness + 5));
            if (proportion > 1.09 || proportion < 0.88) {
                rsopError = true;
            } else if (proportion > 1.05 || proportion < 0.92) {
                rsopWarning = true;
            }
        }
        const rsopStyle = rsopError
            ? "warning"
            : rsopWarning
            ? "attention"
            : null;
        return <span className={rsopStyle}>{value}</span>;
    }

    private async loadData(autocycle) {
        const t = this;

        await Store.getRealTimeManufacturingReport();
        if (!Store.hasError && Store.isEnd) {
            this.timeoutId = setTimeout(async () => {
                t.props.history.push({
                    pathname: "/realtimereport",
                    state: { autocycle }
                });
            }, 30 * 1000);
        }
        if (autocycle) {
            this.timeoutId = setTimeout(async () => {
                await this.loadData(autocycle);
            }, 30 * 1000);
        }
    }

    private clearTimeout() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }
}

export default PageDashboard;
