import {
    Cascader,
    Col,
    Collapse,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Pagination,
    Row,
    Select,
    Table
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { WarehouseType } from "../../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../components/Consts";
import SearchButtonGroup from "../../../components/SearchButtonGroup";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import StockInAndOutRecordUtil from "../../../components/StockInAndOutRecordUtil";
import StockingAreaUtil from "../../../components/StockingAreaUtil";
import Util from "../../../components/Util";
import Store from "./store";

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const {
    Factories,
    BlockStockingAreas,
    Warehouses,
    StockInAndOutRecordTypeOptions
} = Consts;

declare interface IBlockRecordListProps
    extends React.Props<PageBlockRecordList> {}

declare interface IBlockRecordListState {
    factories: any; // 工厂
    warehouseList: any; // 仓库
    warehouseOptions: any;
    gradeIds?: number[]; // 石材等级
    bsList: any; // 荒料库存区域
    bsOptions: any; // 选择荒料库存区域
    visible: boolean; // 控制点击显示关闭下拉菜单
    enableBlockNumberFromQuarry: boolean;
    uniqueWarehouse: boolean;
    recordTypeOptions?: any[];
}

@observer
class PageBlockRecordList extends React.Component<
    IBlockRecordListProps,
    IBlockRecordListState
> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            factories: [],
            warehouseOptions: [],
            warehouseList: [],
            bsList: [], // 荒料库存区域
            bsOptions: [], // 选择荒料库存区域
            visible: false, // 控制点击显示关闭下拉菜单,
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            ),
            uniqueWarehouse: false
        };
    }

    public async componentDidMount() {
        const t = this;
        const s = t.state;
        const bsList = await BlockStockingAreas.getStockingAreas();
        const factories = await Factories.getFactories(); // 工厂列表
        let warehouseList = await Warehouses.getWarehouses(); // 仓库列表
        warehouseList = warehouseList.filter(
            w => w.type === WarehouseType.Block
        );

        const recordTypeOptions = StockInAndOutRecordTypeOptions.data.map(d => {
            return (
                <Option key={d.value as number} value={d.value as number}>
                    {d.text}
                </Option>
            );
        });

        const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
            warehouseList,
            factories
        );

        const uniqueWarehouse = warehouseList.length === 1;
        const warehouseId = Util.parseToInt(
            Util.getCascaderValue(Store.filterData.selectWarehouse, 1)
        );
        const bsOptions = StockingAreaUtil.getStockingAreaOptions(
            uniqueWarehouse,
            warehouseId,
            bsList,
            warehouseOptions
        );

        t.setState({
            bsList,
            bsOptions,
            warehouseOptions,
            uniqueWarehouse,
            recordTypeOptions
        });

        await t.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const recordList = Store.recordList;
        const filterData = Store.filterData;
        const bsOptions = s.bsOptions;

        const columns: any = [
            {
                title: "序号",
                dataIndex: "number",
                key: "number",
                width: "5%",
                align: "center"
            },
            {
                title: "荒料编号",
                dataIndex: "title",
                key: "title",
                width: "15%",
                align: "center"
            },
            {
                title: "规格",
                dataIndex: "spec",
                key: "spec",
                width: "20%",
                align: "center"
            },
            {
                title: "方量(m³)",
                dataIndex: "quantity",
                key: "quantity",
                align: "center"
            },
            {
                title: "类型",
                dataIndex: "recordType",
                key: "recordType",
                align: "center",
                render: type => {
                    return StockInAndOutRecordUtil.getStockInAndOutRecordTypeText(
                        type
                    );
                }
            },
            {
                title: "发生日期",
                dataIndex: "transactionDate",
                key: "transactionDate",
                align: "center",
                render: transactionDate => {
                    return Util.formatDate(transactionDate);
                }
            },
            {
                title: "来源",
                dataIndex: "source",
                key: "source"
            },
            {
                title: "操作人",
                dataIndex: "operator",
                key: "operator"
            },
            {
                title: "区域",
                dataIndex: "stockingAreaId",
                key: "stockingAreaId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: stockingAreaId =>
                    this.getShowStockingArea(stockingAreaId)
            }
        ];

        return (
            <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            onFinish={t.handleSearch}
                            ref={this.formRef}
                            name={"BlockRecordAdvancedSearchForm"}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="荒料编号">
                                        <Input
                                            placeholder="请输入荒料编号"
                                            value={filterData.selectBlockNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "selectBlockNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                {s.enableBlockNumberFromQuarry ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="矿山荒料号">
                                            <Input
                                                placeholder="请输入矿山荒料号"
                                                value={
                                                    filterData.selectBlockNumberFromQuarry
                                                }
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "selectBlockNumberFromQuarry"
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                ) : null}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="类型">
                                        <Select
                                            allowClear={true}
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择类型"
                                            value={filterData.recordType}
                                            onChange={
                                                t.handleSelectedRecordTypeChange
                                            }
                                        >
                                            {s.recordTypeOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="发生日期">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            format={dateFormat}
                                            onChange={t.handleDateChange}
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                            value={Util.getMomentArray(
                                                filterData.startDate,
                                                filterData.endDate
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                {s.uniqueWarehouse ? null : (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="仓库">
                                            <Cascader
                                                value={
                                                    filterData.selectWarehouse
                                                }
                                                options={s.warehouseOptions}
                                                placeholder="请选择仓库"
                                                onChange={
                                                    t.handleWarehouseChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                )}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="区域">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择区域"
                                            value={filterData.stockingAreaIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "stockingAreaIds"
                                            )}
                                        >
                                            {bsOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div>
                    <Table
                        columns={columns}
                        dataSource={recordList}
                        rowKey={"id"}
                        pagination={false}
                    />
                </div>
                {Store.pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={Store.pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString.bind(
                                t,
                                Store.pagingInfo.totalRecordCount
                            )}
                            pageSize={Store.pagingInfo.pageSize}
                            current={Store.pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private async loadData() {
        await Store.reloadData();
    }

    // 跳到第page页
    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);

        await this.loadData();
    };

    private handleClearSearchCriteria = async () => {
        const t = this;
        const s = t.state;

        const bsOptions = StockingAreaUtil.getStockingAreaOptions(
            s.uniqueWarehouse,
            null,
            s.bsList,
            s.warehouseOptions
        );
        Store.updataFilterData({});
        Store.setPageNo(1);
        Store.setPageSize(10);
        Store.updataFilterData({});
        t.setState({
            bsOptions
        });
        await this.loadData();
    };

    private handleSearch = async () => {
        Store.setPageNo(1);
        await this.loadData();
    };

    private handleInputChange(label: string, event) {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    }

    private handleChange = (label: string, value) => {
        Store.updataFilterData({ ...Store.filterData, [label]: value });
    };

    // 选择仓库
    private handleWarehouseChange = (value: string[]) => {
        const t = this;
        const s = t.state;
        const bsOptions = StockingAreaUtil.getStockingAreaOptions(
            s.uniqueWarehouse,
            parseInt(value[1]),
            s.bsList,
            s.warehouseOptions
        );

        Store.updataFilterData({
            ...Store.filterData,
            selectWarehouse: value,
            stockingAreaIds: []
        });
        t.setState({ bsOptions });
    };

    private getTotalRecordString(totalCount: number): string {
        return `共 ${totalCount} 条记录 共 ${Store.totalVolume} m³`;
    }

    private handleDateChange(dates, dateStrings) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        Store.updataFilterData({ ...Store.filterData, startDate, endDate });
    }

    private handleSelectedRecordTypeChange = (value: number) => {
        Store.updataFilterData({ ...Store.filterData, recordType: value });
    };

    private getShowStockingArea(itemId) {
        const t = this;
        const s = t.state;

        const stockingArea = s.bsList.find(b => b.id === itemId);
        if (!stockingArea) {
            return;
        }
        return StockingAreaUtil.getShowText(
            s.uniqueWarehouse,
            stockingArea,
            s.warehouseOptions
        );
    }
}

export default PageBlockRecordList;
