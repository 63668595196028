import { Button, Form, FormInstance, Input, Select } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Settings from "../../components/SettingInfo";
import tenantInfo from "../../components/TenantInfo";
import userInfo from "../../components/UserInfo";
import Util from "../../components/Util";
import "./PageDevlogin.styl";
import Store from "./store";
const Option = Select.Option;

const FormItem = Form.Item;
declare interface IDevloginProps extends RouteComponentProps {
    history: any;
}

declare interface IDevloginState {
    returnToUrl: string;
    isValidUser: boolean;
}

@observer
class Devlogin extends React.Component<IDevloginProps, IDevloginState> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props: IDevloginProps) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            returnToUrl: Util.getLocState(this, "nextPathname"),
            isValidUser: false
        };
    }

    public async componentDidMount() {
        await this.loadData();
    }

    public render() {
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 14 }
        };
        const buttonItemLayout = { wrapperCol: { span: 14, offset: 4 } };
        let tenantInfoOptions = null;
        if (Store.fullTenantInfo) {
            tenantInfoOptions = Store.fullTenantInfo.map(tenant => {
                return (
                    <Option
                        key={tenant.abbreviationCode}
                        value={tenant.abbreviationCode}
                    >
                        {tenant.name}
                    </Option>
                );
            });
        }

        return (
            <Form
                ref={this.formRef}
                layout="horizontal"
                onFinish={this.handleSubmit}
                className="devlogin"
                name={"devloginForm"}
            >
                <FormItem
                    label="企业"
                    {...formItemLayout}
                    name={"tenantAbbrCode"}
                    rules={[
                        {
                            required: true,
                            message: "请选择企业!"
                        }
                    ]}
                >
                    <Select
                        style={{ width: "100%" }}
                        placeholder="请选择企业"
                    >
                        {tenantInfoOptions}
                    </Select>
                </FormItem>

                <FormItem
                    label="用户名"
                    {...formItemLayout}
                    name={"userName"}
                    rules={[
                        {
                            required: true,
                            message: "请输入用户名!"
                        }
                    ]}
                >
                    <Input placeholder="请输入用户名" />
                </FormItem>
                <FormItem {...buttonItemLayout}>
                    <Button type="primary" htmlType="submit">
                        Login
                    </Button>
                </FormItem>
            </Form>
        );
    }

    private async loadData() {
        await Store.getAllTenant();
    }

    private async login(
        userName: string,
        tenantAbbrCode: string
    ): Promise<void> {
        const t = this;

        await Store.devLogin(userName, tenantAbbrCode);
        if (!Store.hasError) {
            const result = Store.loginResult;
            const ui = result.userInfo;
            const ti = result.tenantInfo;
            const si = result.settings;
            const jwtToken = result.jwtToken;
            // 如果用户没有访问PC端的权限，则显示错误，如果有权限，则跳转到首页
            if (!Util.isValidSysUser(ui)) {
                t.setState({ isValidUser: false });
            } else {
                userInfo.setUserInfo(ui);
                userInfo.setAccessToken(jwtToken);
                Settings.setSettings(si);
                tenantInfo.setTenantInfo(ti);
                t.redirectToPreviousPage();
            }
        }
    }

    private handleSubmit = values => {
        const userName = values.userName;
        const tenantAbbrCode = values.tenantAbbrCode;
        this.login(userName, tenantAbbrCode);
    };

    private redirectToPreviousPage(): void {
        const t = this;
        const s = t.state;

        const returnToPath = s.returnToUrl === null ? "/" : s.returnToUrl;
        t.props.history.push(returnToPath);
    }
}

export default Devlogin;
