import { action, observable, runInAction } from "mobx";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import { RealTimeReportDTO } from "../../../../app/WebAPIClients";
import Base from "../../../../stores/base";

moment.locale("zh-cn");
const dateFormat = "YYYY-MM-DD";

class Store extends Base {
    @observable
    public result: RealTimeReportDTO = null;
    @observable
    public startDate: string = moment().format(dateFormat); // 将startDate的默认值设置为当前日期
    @observable
    public endDate: string = moment().format(dateFormat); // 将endDate的默认值设置为当前日期

    @action
    public async getRealTimeReport() {
        const t = this;

        const client = ClientFactory.getReportClient();
        const callAPI = async () => {
            const result = await client.getRealTimeReport(
                new Date(this.startDate),
                new Date(this.endDate)
            );
            runInAction(() => {
                this.result = result;
            });
        };
        await t.callAPIFunForGet(callAPI);
    }

    @action
    public UpdateDate(startDate: string, endDate: string) {
        this.startDate = startDate;
        this.endDate = endDate;
    }
}

export default new Store();
