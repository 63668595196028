import { action, observable, runInAction } from "mobx";
import Perm from "../../../../app/Perm";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import {
    ISalesOrderSelectedTileProcessDTO,
    ITileFullInfoDTO,
    SlabType,
    TileAppliedProcessViewModel,
    TileDTO,
    TileMaterialSpecViewModel,
    TileUpdateViewModel
} from "../../../../app/WebAPIClients";
import ColumnSettings from "../../../../components/ColumnSettings";
import PermCtrl from "../../../../components/PermCtrl";
import Util from "../../../../components/Util";
import Base from "../../../../stores/base";

export interface IProcessingsApplied {
    name: string;
    quantity: number;
    operatorName: string;
}

export interface IFilterData {
    statusCodes?: number[];
    areaName?: string;
    packed?: string;
    salesOrderNumber?: string;
    projectName?: string;
    internalWorkOrderNumber?: string;
    packingCaseNumber?: string;
    tileNumber?: string;
    minLength?: number;
    maxLength?: number;
    minWidth?: number;
    maxWidth?: number;
    position?: string;
}

export interface ITileInfo {
    orderNumber: string;
    projectName: string;
    tiles: string[];
}
class Store extends Base {
    @observable
    public tileList: TileDTO[] = [];
    @observable
    public filterData: IFilterData = { packed: null };
    @observable
    public totalArea: number = 0;

    @observable
    public selectedTileIds: number[] = [];
    @observable.shallow
    public selectedTileInfos: ITileInfo[] = [];

    @observable.shallow
    public tile: ITileFullInfoDTO & { type: SlabType } = null;
    @observable.shallow
    public appliedProcesses: IProcessingsApplied[] = [];
    @observable.shallow
    public nonAppliedSOSelectedProcesses: IProcessingsApplied[] = [];
    @observable.shallow
    public originalAppliedProcessNames: string[] = [];

    private componentName: string = "TileList";

    @action
    public setSelectedTile(
        selectedTileIds: number[],
        selectedTileInfos: ITileInfo[]
    ) {
        this.selectedTileIds = selectedTileIds;
        this.selectedTileInfos = selectedTileInfos;
    }

    @action
    public setProcessingsApplied(
        appliedProcesses: IProcessingsApplied[],
        nonAppliedSOSelectedProcesses: IProcessingsApplied[]
    ) {
        this.appliedProcesses = appliedProcesses;
        this.nonAppliedSOSelectedProcesses = nonAppliedSOSelectedProcesses;
    }

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }

    @action
    public setTile(tile: ITileFullInfoDTO & { type: SlabType }) {
        this.tile = tile;
    }

    @action
    public async loadData() {
        const t = this;
        const filterData = t.filterData;
        const ss = ColumnSettings.getSortSettingsForBackend(this.componentName);

        const client = ClientFactory.getTileClient();
        const packed = !Util.isDefinedAndNotNull(filterData.packed)
            ? null
            : filterData.packed === "true"
            ? true
            : filterData.packed === "false"
            ? false
            : null;
        const callAPI = async () => {
            const content = await client.getAll(
                filterData.maxLength,
                filterData.maxWidth,
                filterData.position,
                filterData.internalWorkOrderNumber,
                ss,
                filterData.salesOrderNumber,
                filterData.projectName,
                filterData.packingCaseNumber,
                filterData.tileNumber,
                undefined,
                undefined,
                undefined,
                filterData.statusCodes,
                filterData.areaName,
                packed,
                t.pageSize,
                t.pageNo,
                filterData.minLength,
                filterData.minWidth
            );
            runInAction(() => {
                t.tileList = content.result;
                t.totalArea = content.totalArea;
            });
            t.setPagingInfo(content.pagingInfo);
        };
        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async getTile(tileId: number, salesOrderId: number) {
        const t = this;

        const tileClient = ClientFactory.getTileClient();
        const soClient = ClientFactory.getSalesOrderClient();
        let soSelectedTileProcesses: ISalesOrderSelectedTileProcessDTO[] = [];
        const callAPI = async () => {
            const tileResult = await tileClient.get(tileId);
            if (PermCtrl.isAnyAuthorized([Perm.T_MD_U, Perm.T_SD_U])) {
                soSelectedTileProcesses = await soClient.getSelectedTileProcesses(
                    salesOrderId
                );
            }

            const appliedProcesses: IProcessingsApplied[] = [];
            const nonAppliedSOSelectedProcesses: IProcessingsApplied[] = [];

            const tile: ITileFullInfoDTO & { type: SlabType } = {
                ...tileResult,
                id: tileResult.id,
                tileNumber: tileResult.tileNumber,
                internalWorkOrderNumber: tileResult.internalWorkOrderNumber,
                areaName: tileResult.areaName,
                position: tileResult.position,
                clientSalesOrderNumber: tileResult.clientSalesOrderNumber,
                clientWorkOrderNumber: tileResult.clientWorkOrderNumber,
                categoryId: tileResult.materialSpec.categoryId,
                gradeId: tileResult.materialSpec.gradeId,
                type: tileResult.materialSpec.type,
                thickness: tileResult.materialSpec.thickness,
                caseNumber:
                    tileResult.packingCase !== null
                        ? tileResult.packingCase.caseNumber
                        : null
            };

            soSelectedTileProcesses.forEach(sops => {
                const ps = tileResult.appliedProcesses.find(pa => {
                    return pa.name === sops.name;
                });
                const quantity = typeof ps !== "undefined" ? ps.quantity : null;

                const operatorName =
                    typeof ps !== "undefined" ? ps.operatorName : null;

                if (quantity !== null) {
                    appliedProcesses.push({
                        name: sops.name,
                        quantity,
                        operatorName
                    });
                } else {
                    nonAppliedSOSelectedProcesses.push({
                        name: sops.name,
                        quantity: null,
                        operatorName: null
                    });
                }
            });

            const originalAppliedProcessNames = tileResult.appliedProcesses.map(
                p => p.name
            );
            runInAction(() => {
                t.tile = tile;
                t.appliedProcesses = appliedProcesses;
                t.nonAppliedSOSelectedProcesses = nonAppliedSOSelectedProcesses;
                t.originalAppliedProcessNames = originalAppliedProcessNames;
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async deleteTiles() {
        const t = this;

        if (t.selectedTileIds && t.selectedTileIds.length === 0) {
            return;
        }

        const client = ClientFactory.getTileClient();
        const callAPI = async () => {
            await client.delete(t.selectedTileIds);
        };

        await t.callAPIFun(callAPI, "删除工程板数据...", "删除工程板失败");
    }

    @action
    public async updateTile() {
        const t = this;

        const client = ClientFactory.getTileClient();
        const callAPI = async () => {
            const model = t.getTileViewModel();
            await client.updateTile(model);
        };

        await t.callAPIFun(callAPI, "更新工程板数据...", "更新工程板数据失败");
    }

    private getTileViewModel(): TileUpdateViewModel {
        const tile = this.tile;
        const appliedProcesses = this.appliedProcesses.map(ps => {
            return TileAppliedProcessViewModel.fromJS(ps);
        });

        return TileUpdateViewModel.fromJS({
            id: tile.id,
            tileNumber: tile.tileNumber,
            areaName: tile.areaName,
            position: tile.position,
            materialSpec: TileMaterialSpecViewModel.fromJS({
                type: tile.type,
                categoryId: tile.categoryId,
                gradeId: tile.gradeId,
                thickness: tile.thickness
            }),
            caseNumber: tile.caseNumber,
            length: tile.length,
            width: tile.width,
            cuttingOperator: tile.cuttingOperator,
            cuttingMachineNumber: tile.cuttingMachineNumber,
            internalWorkOrderNumber: tile.internalWorkOrderNumber,
            processingDate: tile.processingDate,
            clientSalesOrderNumber: tile.clientSalesOrderNumber,
            clientWorkOrderNumber: tile.clientWorkOrderNumber,
            orderPlacingDate: tile.orderPlacingDate,
            appliedProcesses
        });
    }
}

export default new Store();
