import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import { TileFilteringOptionsDTO } from "../../../../app/WebAPIClients";
import { ISalesOrderForListForUI } from "../../../../components/type";
import Base from "../../../../stores/base";

export interface IFilterData {
    customerIds?: number[];
    statusCode?: number[];
    orderType?: number[];
    orderNumber?: string;
    projectName?: string;
}
class Store extends Base {
    @observable
    public filterData: IFilterData = {};
    @observable
    public salesOrderList: ISalesOrderForListForUI[] = [];
    @observable
    public salesOrderTileInfo: TileFilteringOptionsDTO = null;

    constructor() {
        super();
        this.initData();
    }

    @action
    public initData() {
        this.filterData = {
            orderType: [60],
            statusCode: [10, 20, 30, 40] // 返回订单状态不是取消状态的所有工程板订单
        };
        this.salesOrderList = [];
        this.setPageNo(1);
        this.setPageSize(10);
    }

    @action
    public async loadData() {
        const t = this;
        const filterData = t.filterData;

        const client = ClientFactory.getSalesOrderClient();
        const callAPI = async () => {
            const content = await client.getAll(
                filterData.customerIds,
                filterData.statusCode,
                filterData.orderType,
                undefined,
                filterData.orderNumber,
                filterData.projectName,
                undefined,
                undefined,
                undefined,
                t.pageSize,
                t.pageNo
            );
            runInAction(() => (this.salesOrderList = content.result));
            this.setPagingInfo(content.pagingInfo);
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async getSOTileInfoForFiltering(salesOrderId: number) {
        const client = ClientFactory.getTileClient();
        const callAPI = async () => {
            const result = await client.getSOTileInfoForFiltering(salesOrderId);
            runInAction(() => (this.salesOrderTileInfo = result));
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }
}

export default new Store();
