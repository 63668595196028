import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../../app/WebAPIClientFactory";
import {
    FileInfoViewModel,
    IBundlePolishedDataImportResultDTO
} from "../../../../app/WebAPIClients";
import Base from "../../../../stores/base";

class Store extends Base {
    @observable
    public result: IBundlePolishedDataImportResultDTO = null;

    @action
    public importPolishingData = async (params: FileInfoViewModel) => {
        const client = ClientFactory.getWorkOrderClient();
        const callAPI = async () => {
            const importResult = await client.importPolishingData(params);
            runInAction(() => {
                this.result = importResult;
            });
        };

        await this.callAPIFun(
            callAPI,
            "数据导入中...",
            "数据导入出错，请重试。详细信息："
        );
    };

    @action
    public setResult = (result?: IBundlePolishedDataImportResultDTO) => {
        this.result = result;
    };
}

export default new Store();
