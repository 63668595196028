import { Descriptions } from "antd";
import React from "react";
import { WorkOrderFullInfoDTO } from "../../../app/WebAPIClients";
import Consts from "../../../components/Consts";
import Util from "../../../components/Util";
const {
    Priority,
    ManufacturingState: ManufacturingStateOptions,
    ManufacturingMethod: ManufacturingMethodOptions,
    SalesOrderStatus: SalesOrderStatusOptions,
    WorkOrderType
} = Consts;

interface IBasicInfoProps {
    wo: WorkOrderFullInfoDTO;
    factoryList: IIdNameItem[];
    enableBlockNumberFromQuarry: boolean;
    categoryList: IIdNameItem[];
}

export default class BasicInfo extends React.PureComponent<IBasicInfoProps> {
    public render() {
        const t = this;
        const {
            wo,
            factoryList,
            enableBlockNumberFromQuarry,
            categoryList,
            ...others
        } = t.props;

        const so = wo.salesOrder;
        return (
            <div>
                <Descriptions
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    title={"工单基本信息"}
                >
                    <Descriptions.Item label="工单编号">
                        {wo.orderNumber}
                    </Descriptions.Item>
                    <Descriptions.Item label="工厂">
                        {Util.isDefinedAndNotNull(wo.factoryId)
                            ? Util.getItemName(wo.factoryId, factoryList)
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="优先级">
                        {Util.isDefinedAndNotNull(wo.priority)
                            ? Priority.getPriority(wo.priority).text
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="生产状态">
                        {Util.isDefinedAndNotNull(wo.manufacturingState) ? (
                            <span className="woMSStyle">
                                {
                                    ManufacturingStateOptions.getState(
                                        wo.manufacturingState
                                    ).text
                                }
                            </span>
                        ) : null}
                    </Descriptions.Item>
                    {Util.isDefinedAndNotNull(wo.cancelReason) ? (
                        <Descriptions.Item label="取消原因">
                            {wo.cancelReason}
                        </Descriptions.Item>
                    ) : null}
                    {Util.isDefinedAndNotNull(wo.materialRequisition) &&
                    Util.isDefinedAndNotNull(wo.materialRequisition.block) ? (
                        <Descriptions.Item label="荒料编号">
                            <span className="woMSStyle">
                                {wo.materialRequisition.block.blockNumber}
                            </span>
                        </Descriptions.Item>
                    ) : null}
                    {enableBlockNumberFromQuarry &&
                    Util.isDefinedAndNotNull(wo.materialRequisition) &&
                    Util.isDefinedAndNotNull(wo.materialRequisition.block) ? (
                        <Descriptions.Item label="矿山荒料号">
                            {wo.materialRequisition.block.blockNumberFromQuarry}
                        </Descriptions.Item>
                    ) : null}

                    <Descriptions.Item label="石材种类">
                        {Util.getItemName(
                            wo.materialSpec.categoryId,
                            categoryList
                        )}
                    </Descriptions.Item>
                    <Descriptions.Item label="生产厚度">
                        {wo.thickness} 毫米
                    </Descriptions.Item>
                    <Descriptions.Item label="生产类型">
                        {Util.isDefinedAndNotNull(wo.orderType)
                            ? WorkOrderType.getOrderType(wo.orderType).text
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="加工方式">
                        {Util.isDefinedAndNotNull(wo.manufacturingMethod)
                            ? ManufacturingMethodOptions.getManufacturingMethod(
                                  wo.manufacturingMethod
                              ).text
                            : null}
                    </Descriptions.Item>
                    {wo.skipTrimming === false ? null : (
                        <Descriptions.Item label="跳过修边">
                            {wo.skipTrimming === true ? "是" : "否"}
                        </Descriptions.Item>
                    )}
                    {wo.splittedBlock === false ? null : (
                        <Descriptions.Item label="是否对剖">
                            {wo.splittedBlock === true ? "是" : "否"}
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label="交付时间">
                        {Util.formatDate(wo.deliveryDate)}
                    </Descriptions.Item>
                    {wo.blockDiscarded ? (
                        <Descriptions.Item label="荒料报废">
                            {wo.sawingStartTime ? "大切" : "修边"}
                            阶段报废
                        </Descriptions.Item>
                    ) : null}
                    {wo.blockDiscarded &&
                    Util.isDefinedAndNotNull(wo.materialRequisition) &&
                    Util.isDefinedAndNotNull(wo.materialRequisition.block) ? (
                        <Descriptions.Item label="报废原因">
                            <span className="blockDiscardedStyle">
                                {wo.materialRequisition.block
                                    ? wo.materialRequisition.block
                                          .discardedReason
                                    : ""}
                            </span>
                        </Descriptions.Item>
                    ) : null}
                    {Util.isNotNullAndNotEmpty(wo.notes) ? (
                        <Descriptions.Item label="备注">
                            {wo.notes}
                        </Descriptions.Item>
                    ) : null}
                    <Descriptions.Item label="创建时间">
                        {Util.formatDateTime(wo.createdTime)}
                    </Descriptions.Item>
                    <Descriptions.Item label="创建人">
                        {wo.creator}
                    </Descriptions.Item>
                </Descriptions>
                <br />
                <Descriptions
                    column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                    title={"订单基本信息"}
                >
                    <Descriptions.Item label="订单编号">
                        {so.orderNumber}
                    </Descriptions.Item>
                    {so.isInternalOrder ? null : (
                        <Descriptions.Item label="客户名称">
                            <span className="woMSStyle">{so.customerName}</span>
                        </Descriptions.Item>
                    )}
                    {!so.isInternalOrder ? null : (
                        <Descriptions.Item label="内部订单">
                            {so.isInternalOrder === true ? "是" : "否"}
                        </Descriptions.Item>
                    )}
                    {so.isInternalOrder ||
                    so.projectName === null ||
                    so.projectName === "" ? null : (
                        <Descriptions.Item label="工程名称">
                            {so.customerName}
                        </Descriptions.Item>
                    )}
                    <Descriptions.Item label="订单状态">
                        {Util.isDefinedAndNotNull(so.priority)
                            ? SalesOrderStatusOptions.getStatus(so.status).text
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="优先级">
                        {Util.isDefinedAndNotNull(so.priority)
                            ? Priority.getPriority(so.priority).text
                            : null}
                    </Descriptions.Item>
                    <Descriptions.Item label="交货时间">
                        {Util.formatDate(so.deliveryDate)}
                    </Descriptions.Item>
                    {so.isInternalOrder ? null : (
                        <Descriptions.Item label="销售代表">
                            {so.salesRep}
                        </Descriptions.Item>
                    )}
                    {Util.isNotNullAndNotEmpty(wo.notes) ? (
                        <Descriptions.Item label="备注">
                            {so.notes}
                        </Descriptions.Item>
                    ) : null}
                </Descriptions>
            </div>
        );
    }
}
