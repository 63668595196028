import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import { SalesOrderDetailDTO } from "../../app/WebAPIClients";
import { ISalesOrderForListForUI } from "../../components/type";
import Base from "../../stores/base";

export interface IFilterData {
    selectedStatusCodes?: number[];
    salesOrderNumber?: string;
    customerIds?: number[];
    salesOrderTypes?: number[];
}

class Store extends Base {
    @observable
    public salesOrderList: ISalesOrderForListForUI[] = [];
    @observable
    public salesOrderDetailList: SalesOrderDetailDTO[] = [];
    @observable
    public filterData: IFilterData = {};

    @action
    public async loadData() {
        const filterData = this.filterData;
        const client = ClientFactory.getSalesOrderClient();
        const callAPI = async () => {
            const content = await client.getAll(
                filterData.customerIds,
                filterData.selectedStatusCodes,
                filterData.salesOrderTypes,
                undefined,
                filterData.salesOrderNumber,
                undefined,
                undefined,
                undefined,
                false, // 由于现在所有的内部订单都不能导出销售清单及导出码单，可以不需要显示内部订单数据，因此在获取后端数据时，不获取内部订单数据
                this.pageSize,
                this.pageNo
            );
            runInAction(() => {
                this.salesOrderList = content.result;
            });
            this.setPagingInfo(content.pagingInfo);
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public async getShippingOrders(id: number) {
        const t = this;

        const client = ClientFactory.getSalesOrderClient();
        const callAPI = async () => {
            const result = await client.getShippingOrders(id);
            const salesOrderList = [...this.salesOrderList];
            const so = salesOrderList.find(b => {
                return b.id === id;
            });
            if (so) {
                so.shippingOrders = result;
            }

            runInAction(() => {
                this.salesOrderList = salesOrderList;
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public async getSalesOrderDetails(id: number) {
        const client = ClientFactory.getSalesOrderClient();
        const callAPI = async () => {
            const result = await client.getSalesOrderDetails(id);

            runInAction(() => {
                this.salesOrderDetailList = result;
            });
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public updataFilterData(filterData: IFilterData) {
        this.filterData = filterData;
    }
}

export default new Store();
