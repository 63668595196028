import {
    CheckCircleOutlined,
    DeleteOutlined,
    DownOutlined,
    EditOutlined,
    EllipsisOutlined,
    ExclamationCircleOutlined
} from "@ant-design/icons";

import {
    Button,
    Card,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Dropdown,
    Form,
    FormInstance,
    Input,
    message,
    Modal,
    Pagination,
    Row,
    Select,
    Table,
    Tabs
} from "antd";
import { observer } from "mobx-react";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import React from "react";
import Perm from "../../../../app/Perm";
import ColumnSettings from "../../../../components/ColumnSettings";
import Consts, {
    formItemLayoutForCheckboxGroup,
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../../components/Consts";
import PermCtl from "../../../../components/PermCtrl";
import SearchButtonGroup from "../../../../components/SearchButtonGroup";
import Util from "../../../../components/Util";
import validator from "../../../../components/Validator";
import Store from "./store";

const {
    TilePackingCaseStatusOptions,
    StoneCategories,
    StoneGrades,
    SlabType
} = Consts;

const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const InputGroup = Input.Group;
const confirm = Modal.confirm;
const TabPane = Tabs.TabPane;

declare interface ITileListProps extends React.Props<PageTileList> {}

declare interface ITileListState {
    canUpdateSpecData: boolean;
    canUpdateManufacturingData: boolean;
    canDeleteTile: boolean;
    showEditPage: boolean;
    showdeletePage: boolean;
    showDropdown: boolean;
    checkBoxOptions: any; // 状态
    categoryList: any; // 石材种类
    gradeList: any; // 石材等级
    categoryOptions: any;
    gradeOptions: any;
    typeOptions: any;
    packedOptions: any;
    packedList: Array<{ value: any; text: string }>;
    columnsInfo: ITableColumnInfo[];
}

@observer
class PageTileList extends React.Component<ITileListProps, ITileListState> {
    private componentName: string = "TileList";
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            canUpdateSpecData: PermCtl.isAuthorized(Perm.T_SD_U),
            canUpdateManufacturingData: PermCtl.isAuthorized(Perm.T_MD_U),
            canDeleteTile: PermCtl.isAuthorized(Perm.T_D),
            showEditPage: false,
            showdeletePage: false,
            showDropdown: false,
            checkBoxOptions: [], // 状态
            categoryList: [], // 石材种类
            gradeList: [], // 石材等级
            categoryOptions: [],
            gradeOptions: [],
            typeOptions: [],
            packedOptions: [],
            packedList: [
                {
                    value: "true",
                    text: "是"
                },
                {
                    value: "false",
                    text: "否"
                },
                {
                    value: null,
                    text: "全部"
                }
            ],
            columnsInfo: [
                {
                    title: "选择",
                    key: "select",
                    align: "center",
                    viewingPermissions: [Perm.T_D],
                    showByDefault: false,
                    alwaysShow: false,
                    render: record => {
                        return record.status === 50 ? null : (
                            <div onClick={this.selectTile.bind(this, record)}>
                                <CheckCircleOutlined
                                    style={{
                                        color: this.getCheckFlagColor(
                                            record.id
                                        ),
                                        fontSize: 18
                                    }}
                                />
                            </div>
                        );
                    }
                },
                {
                    title: "工程名称",
                    dataIndex: "projectName",
                    key: "ProjectName",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: true
                },
                {
                    title: "销售订单号",
                    dataIndex: "orderNumber",
                    key: "OrderNumber",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false
                },
                {
                    title: "生产加工单号",
                    dataIndex: "internalWorkOrderNumber",
                    key: "InternalWorkOrderNumber",
                    align: "center",
                    sorter: true,
                    showByDefault: false,
                    alwaysShow: false
                },
                {
                    title: "图号",
                    dataIndex: "areaName",
                    key: "AreaName",
                    align: "center",
                    showByDefault: true,
                    sorter: true,
                    alwaysShow: false
                },
                {
                    title: "区域位置",
                    dataIndex: "position",
                    key: "Position",
                    align: "center",
                    showByDefault: true,
                    sorter: true,
                    alwaysShow: false
                },
                {
                    title: "工程板编号",
                    dataIndex: "tileNumber",
                    key: "TileNumber",
                    align: "center",
                    sorter: true,
                    showByDefault: true,
                    alwaysShow: true
                },
                {
                    title: "品名",
                    dataIndex: "categoryId",
                    key: "CategoryId",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false,
                    render: categoryId =>
                        this.getItemName(categoryId, "categoryList")
                },
                {
                    title: "等级",
                    dataIndex: "gradeId",
                    key: "GradeId",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false,
                    render: gradeId => this.getItemName(gradeId, "gradeList")
                },
                {
                    title: "厚",
                    dataIndex: "thickness",
                    key: "Thickness",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false
                },
                {
                    title: "长",
                    dataIndex: "length",
                    key: "Length",
                    align: "center",
                    sorter: true,
                    showByDefault: true,
                    alwaysShow: false
                },
                {
                    title: "宽",
                    dataIndex: "width",
                    key: "Width",
                    align: "center",
                    sorter: true,
                    showByDefault: true,
                    alwaysShow: false
                },
                {
                    title: "面积",
                    dataIndex: "area",
                    key: "Area",
                    align: "center",
                    sorter: true,
                    showByDefault: true,
                    alwaysShow: false
                },
                {
                    title: "包装箱号",
                    dataIndex: "caseNumber",
                    key: "CaseNumber",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false
                },
                {
                    title: "状态",
                    dataIndex: "status",
                    key: "Status",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false,
                    render: status =>
                        TilePackingCaseStatusOptions.getStatus(status).text
                },
                {
                    title: "操作",
                    key: "action",
                    align: "center",
                    showByDefault: true,
                    alwaysShow: false,
                    render: record => (
                        <div>
                            <Button
                                type="primary"
                                onClick={this.getTile.bind(
                                    this,
                                    record.id,
                                    record.salesOrderId
                                )}
                            >
                                {record.status !== 50 &&
                                PermCtl.isAnyAuthorized([
                                    Perm.T_SD_U,
                                    Perm.T_MD_U
                                ]) ? (
                                    <EditOutlined />
                                ) : (
                                    <EllipsisOutlined />
                                )}
                            </Button>
                        </div>
                    )
                }
            ]
        };
    }

    public async componentDidMount() {
        const categoryList = await StoneCategories.getCategories();
        const gradeList = await StoneGrades.getGrades();
        const checkBoxOptions = TilePackingCaseStatusOptions.data.map(
            status => {
                return { label: status.text, value: status.value.toString() };
            }
        );

        const categoryOptions = categoryList.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });
        const gradeOptions = gradeList.map(g => {
            return (
                <Option key={g.id} value={g.id}>
                    {g.name}
                </Option>
            );
        });

        const typeOptions = SlabType.data.map(t => {
            return (
                <Option key={t.value.toString()} value={t.value.toString()}>
                    {t.text}
                </Option>
            );
        });

        const packedOptions = this.state.packedList.map(t => {
            return (
                <Option key={t.value} value={t.value}>
                    {t.text}
                </Option>
            );
        });
        this.setState({
            checkBoxOptions,
            categoryList,
            gradeList,
            categoryOptions,
            gradeOptions,
            typeOptions,
            packedOptions
        });
        this.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;

        const checkBoxOptions = s.checkBoxOptions;
        const packedOptions = s.packedOptions;
        const selectedTileInfos = Store.selectedTileInfos;
        const formItemLayout = {
            labelCol: { span: 9 },
            wrapperCol: { span: 15 }
        };
        const tl = Store.tileList;
        const tile = Store.tile;
        const filterData = Store.filterData;
        const selectedTileIds = Store.selectedTileIds;
        const pagingInfo = Store.pagingInfo;

        const visibleColumns: string[] = Util.getVisibleColumns(
            s.columnsInfo,
            t.componentName
        );
        const deleteButtonDisabled: boolean = selectedTileIds.length === 0;
        const select: boolean = visibleColumns.includes("选择");
        return (
            <div>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            ref={this.formRef}
                            name={"tileAdvancedSearchForm"}
                            onFinish={t.handleSearch}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        {...formItemLayoutForCheckboxGroup}
                                        label="状态"
                                    >
                                        <CheckboxGroup
                                            options={checkBoxOptions}
                                            value={filterData.statusCodes}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "statusCodes"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="工程名称">
                                        <Input
                                            placeholder="请输入工程名称"
                                            value={filterData.projectName}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "projectName"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="销售订单号">
                                        <Input
                                            placeholder="请输入销售订单号"
                                            value={filterData.salesOrderNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "salesOrderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="生产加工单号">
                                        <Input
                                            placeholder="请输入生产加工单号"
                                            value={
                                                filterData.internalWorkOrderNumber
                                            }
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "internalWorkOrderNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="工程板编号">
                                        <Input
                                            placeholder="请输入工程板编号"
                                            value={filterData.tileNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "tileNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="长度">
                                        <InputGroup compact={true}>
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最小值"
                                                value={filterData.minLength}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "minLength"
                                                )}
                                            />
                                            <Input
                                                style={{
                                                    width: "16%",
                                                    textAlign: "center",
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: "none"
                                                }}
                                                placeholder="~"
                                                disabled={true}
                                            />
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最大值"
                                                value={filterData.maxLength}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "maxLength"
                                                )}
                                            />
                                        </InputGroup>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="是否装箱">
                                        <Select
                                            style={{ width: "100%" }}
                                            placeholder="请选择是否装箱"
                                            value={filterData.packed}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "packed"
                                            )}
                                        >
                                            {packedOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="图号">
                                        <Input
                                            placeholder="请输入图号"
                                            value={filterData.areaName}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "areaName"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="宽度">
                                        <InputGroup compact={true}>
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最小值"
                                                value={filterData.minWidth}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "minWidth"
                                                )}
                                            />
                                            <Input
                                                style={{
                                                    width: "16%",
                                                    textAlign: "center",
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: "none"
                                                }}
                                                placeholder="~"
                                                disabled={true}
                                            />
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最大值"
                                                value={filterData.maxWidth}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "maxWidth"
                                                )}
                                            />
                                        </InputGroup>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="区域位置">
                                        <Input
                                            placeholder="区域位置"
                                            value={filterData.position}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "position"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="包装箱号">
                                        <Input
                                            placeholder="请输入包装箱号"
                                            value={filterData.packingCaseNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "packingCaseNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ margin: "1%", textAlign: "right" }}>
                    {s.canDeleteTile ? (
                        <Button
                            disabled={deleteButtonDisabled && select}
                            danger={select}
                            onClick={
                                select
                                    ? t.handleShowDeletePageChange
                                    : t.addSelectColumns.bind(
                                          t,
                                          visibleColumns,
                                          "选择"
                                      )
                            }
                        >
                            <DeleteOutlined />
                        </Button>
                    ) : null}
                    <Dropdown
                        overlay={t.getColumnSettings()}
                        trigger={["click"]}
                        onVisibleChange={t.handleShowDropdownChange}
                        visible={s.showDropdown}
                    >
                        <Button>
                            更多 <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
                <Modal
                    title={"删除工程板"}
                    visible={s.showdeletePage}
                    onOk={t.deleteTile}
                    onCancel={t.handleCloseDeletePageChange}
                    okText="确定"
                    cancelText="取消"
                >
                    <div className="deleteTileInfoPage">
                        <ExclamationCircleOutlined
                            style={{ marginRight: "10px", fontSize: "25px" }}
                        />
                        请确认是否需要删除以下工程板
                    </div>
                    {Util.isNotNullAndNotEmptyArray(selectedTileInfos) ? (
                        <Tabs defaultActiveKey="0">
                            {selectedTileInfos.map((info, index) => {
                                const tiles = info.tiles;
                                let tileNumbers = "";
                                tiles.forEach(tileNumber => {
                                    tileNumbers += tileNumber + " 、";
                                });
                                tileNumbers = tileNumbers.substring(
                                    0,
                                    tileNumbers.length - 1
                                );
                                return (
                                    <TabPane
                                        tab={info.projectName}
                                        key={index.toString()}
                                    >
                                        {
                                            <p
                                                key={index}
                                                style={{ color: "red" }}
                                            >
                                                工程板编号：
                                                {tileNumbers}
                                            </p>
                                        }
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                    ) : null}
                </Modal>
                <Table
                    columns={Util.getTableColumns(
                        s.columnsInfo,
                        t.componentName
                    )}
                    dataSource={tl}
                    onChange={t.handleSorterChange}
                    pagination={false}
                    rowKey="id"
                />
                {tile === null ? null : t.TileModalPage()}
                {pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString}
                            pageSize={pagingInfo.pageSize}
                            current={pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private addSelectColumns = (visibleColumns: string[], label: string) => {
        const t = this;
        const s = t.state;
        message.info("请选择要删除的工程板");

        visibleColumns.push(label);
        ColumnSettings.setVisibleColumns(t.componentName, visibleColumns);
        t.setState(s);
    };

    private getTotalRecordString = (total: number): string => {
        return `共 ${total} 片 共 ${Store.totalArea} m²`;
    };

    private async loadData() {
        await Store.loadData();
    }

    // 选择要显示的列
    private getColumnSettings(): JSX.Element {
        const t = this;
        const s = t.state;
        const visibleColumns: string[] = Util.getVisibleColumns(
            s.columnsInfo,
            t.componentName
        );

        const checkAll: boolean = Util.judgeCheckAll(
            s.columnsInfo,
            visibleColumns
        );

        return (
            <div style={{ width: "100%", background: "#FFFFFF" }}>
                <Checkbox
                    onChange={t.handleCheckAllChange.bind(t, checkAll)}
                    checked={checkAll}
                >
                    全选
                </Checkbox>
                <br />
                {s.columnsInfo && s.columnsInfo.length > 0 && visibleColumns
                    ? s.columnsInfo.map((ci, index) => {
                          let canView = true; // 当前用户的角色是否有权限查看该字段
                          const visible: boolean = visibleColumns.some(
                              c => c === ci.title
                          );
                          if (
                              ci.viewingPermissions &&
                              ci.viewingPermissions.length > 0
                          ) {
                              canView = PermCtl.isAnyAuthorized(
                                  ci.viewingPermissions
                              );
                          }

                          if (canView) {
                              return (
                                  <div key={index}>
                                      <Checkbox
                                          checked={visible}
                                          disabled={ci.alwaysShow}
                                          onChange={t.handSelectColumnsChange.bind(
                                              t,
                                              ci.title,
                                              !visible
                                          )}
                                      >
                                          {ci.title}
                                      </Checkbox>
                                  </div>
                              );
                          }
                      })
                    : null}
            </div>
        );
    }

    // 选择列变化
    private handSelectColumnsChange = (label: string, value: boolean) => {
        const t = this;
        const s = t.state;
        const visibleColumns: string[] = Util.getVisibleColumns(
            s.columnsInfo,
            t.componentName
        );

        if (value) {
            if (!visibleColumns.includes(label)) {
                visibleColumns.push(label);
            }
        } else if (visibleColumns.includes(label)) {
            const index = visibleColumns.indexOf(label);
            visibleColumns.splice(index, 1);
        }

        ColumnSettings.setVisibleColumns(t.componentName, visibleColumns);
        t.setState(s);
    };

    // 全选
    private handleCheckAllChange = (checkAll: boolean) => {
        const t = this;
        const s = t.state;
        const columnsInfo: ITableColumnInfo[] = s.columnsInfo;

        Util.checkAllChange(columnsInfo, t.componentName, checkAll);
        t.setState(s);
    };

    // 排序
    private handleSorterChange = async (_1, _2, sorter) => {
        const t = this;
        Util.updateSortSettings(sorter, t.componentName);
        Store.setPageNo(1);
        Store.setPageSize(10);
        await t.loadData();
    };

    private getItemName = (itemId: number, listName: string): string => {
        const t = this;
        const s = t.state;
        const list = s[listName];
        return Util.getItemName(itemId, list);
    };

    private validate(): boolean {
        const filterData = Store.filterData;
        let valid: boolean = validator.range(
            filterData.minLength,
            0,
            10000,
            "最小长度"
        );
        valid =
            valid &&
            validator.range(filterData.maxLength, 0, 10000, "最大长度");
        valid =
            valid && validator.range(filterData.minWidth, 0, 10000, "最小宽度");
        valid =
            valid && validator.range(filterData.maxWidth, 0, 10000, "最大宽度");
        return valid;
    }

    // 搜索
    private handleSearch = async () => {
        const validate = this.validate();
        if (!validate) {
            return;
        }
        Store.setPageNo(1);
        await this.loadData();
    };

    // 清除搜索条件,重置
    private handleClearSearchCriteria = async () => {
        ColumnSettings.setSortSettings(this.componentName, null);
        Store.updataFilterData({ packed: null });
        Store.setPageNo(1);
        Store.setPageSize(10);
        await this.loadData();
    };

    // 跳页
    private handlePaginationChange = async (currentPageNo: number) => {
        Store.setPageNo(currentPageNo);
        await this.loadData();
    };

    private handleCloseEditPageChange = () => {
        this.setState({
            showEditPage: false
        });
    };

    private handleShowDeletePageChange = () => {
        this.setState({
            showdeletePage: true
        });
    };

    private handleCloseDeletePageChange = () => {
        this.setState({
            showdeletePage: false
        });
    };

    private handleShowDropdownChange = () => {
        this.setState({
            showDropdown: !this.state.showDropdown
        });
    };

    private handleInputChange(label: string, event) {
        const selectFieldLabels: string[] = ["statusCodes", "packed"];

        const value = selectFieldLabels.includes(label)
            ? event
            : event.target.value;

        Store.updataFilterData({ ...Store.filterData, [label]: value });
    }

    // 处理工程板数据的变化
    private handleTileInfoChange(label: string, event) {
        const t = this;
        const tile = { ...Store.tile };
        const selectFieldLabels: string[] = ["gradeId", "categoryId", "type"];

        tile[label] = selectFieldLabels.includes(label)
            ? event
            : event.target.value;
        Store.setTile(tile);
    }

    // 处理工程板日期数据的变化
    private handleDateChange(label: string, _1, dateString) {
        const tile = { ...Store.tile };
        tile[label] = dateString;
        Store.setTile(tile);
    }

    private deleteTile = async () => {
        const t = this;
        await Store.deleteTiles();
        if (!Store.hasError) {
            await t.loadData();
            Store.setSelectedTile([], []);
            t.setState({
                showdeletePage: false
            });
        }
    };

    // 获取Tile数据
    private getTile = async (tileId: number, soId: number) => {
        const t = this;
        await Store.getTile(tileId, soId);
        t.setState({
            showEditPage: true
        });
    };

    // 验证工程板数据
    private tileValidate(): boolean {
        const t = this;
        const tile = Store.tile;
        let valid: boolean = true;
        const { appliedProcesses } = Store;
        const appliedProcess = appliedProcesses.find(pa => pa.quantity <= 0);

        if (appliedProcess) {
            message.error(
                `工艺名称：${appliedProcess.name}的工艺数量为空或小于0`
            );
            return false;
        }

        if (!validator.isEmptyOrNull(tile.processingDate)) {
            valid =
                !validator.isEmptyOrNull(tile.cuttingMachineNumber) &&
                !validator.isEmptyOrNull(tile.cuttingOperator) &&
                !validator.isEmptyOrNull(tile.processingDate);
            valid =
                valid &&
                appliedProcesses.every(
                    pa => !validator.isEmptyOrNull(pa.operatorName)
                );

            if (!valid) {
                message.error(
                    "已填写加工日期的工程板必须填写切板员以及机台号、工艺操作员"
                );
                return valid;
            }
        }
        valid = valid && validator.require(tile.tileNumber, "工程板编号");
        valid = valid && validator.require(tile.length, "长度");
        valid = valid && validator.require(tile.width, "宽度");
        valid = valid && validator.require(tile.thickness, "厚度");
        valid = valid && validator.require(tile.areaName, "图号");
        valid = valid && validator.require(tile.categoryId, "品名");
        valid = valid && validator.require(tile.gradeId, "等级");
        valid = valid && validator.require(tile.type, "类型");
        valid = valid && validator.range(tile.length, 0, 10000, "长度");
        valid = valid && validator.range(tile.width, 0, 10000, "宽度");
        return valid;
    }

    // 更新工程板
    private updateTile = () => {
        const t = this;
        const valid: boolean = t.tileValidate();

        if (!valid) {
            return;
        }

        confirm({
            title: "更新工程板",
            content: "请确认是否需要更新工程板信息？",
            okText: "确定",
            cancelText: "取消",
            async onOk() {
                await Store.updateTile();
                if (!Store.hasError) {
                    await t.loadData();
                    t.setState({
                        showEditPage: false
                    });
                }
            },
            onCancel() {
                return;
            }
        });
    };

    // 工程板信息界面
    private TileModalPage() {
        const t = this;
        const s = t.state;
        const tile = Store.tile;
        const gradeOptions = s.gradeOptions;
        const categoryOptions = s.categoryOptions;
        const typeOptions = s.typeOptions;
        const tileArea: number = Util.calculateArea(tile.length, tile.width);
        const canUpdateManufacturingData: boolean =
            s.canUpdateManufacturingData && tile.status !== 50;
        const canUpdateSpecData: boolean =
            s.canUpdateSpecData && tile.status !== 50;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
        };
        return (
            <div>
                <Modal
                    title={
                        canUpdateManufacturingData || canUpdateSpecData
                            ? "编辑工程板信息"
                            : "浏览工程板信息"
                    }
                    visible={s.showEditPage}
                    onOk={t.updateTile.bind(t, "showEditPage")}
                    onCancel={t.handleCloseEditPageChange}
                    okButtonProps={{
                        disabled:
                            !canUpdateManufacturingData && !canUpdateSpecData
                    }}
                    okText="提交"
                    cancelText="取消"
                >
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle(
                            "工程板编号",
                            canUpdateSpecData
                        )}
                    >
                        <Input
                            placeholder="工程板编号"
                            disabled={!canUpdateSpecData}
                            value={tile.tileNumber}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "tileNumber"
                            )}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="生产加工单号">
                        <Input
                            placeholder="生产加工单号"
                            disabled={!canUpdateSpecData}
                            value={tile.internalWorkOrderNumber}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "internalWorkOrderNumber"
                            )}
                        />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("图号", canUpdateSpecData)}
                    >
                        <Input
                            placeholder="图号"
                            value={tile.areaName}
                            disabled={!canUpdateSpecData}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "areaName"
                            )}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="区域位置">
                        <Input
                            placeholder="区域位置"
                            value={tile.position}
                            disabled={!canUpdateSpecData}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "position"
                            )}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="客户方销售单号">
                        <Input
                            placeholder="客户方销售单号"
                            disabled={!canUpdateSpecData}
                            value={tile.clientSalesOrderNumber}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "clientSalesOrderNumber"
                            )}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="客户方工单号">
                        <Input
                            placeholder="客户方工单号"
                            disabled={!canUpdateSpecData}
                            value={tile.clientWorkOrderNumber}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "clientWorkOrderNumber"
                            )}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="下单日期">
                        <DatePicker
                            style={{ width: "100%" }}
                            disabled={!canUpdateSpecData}
                            value={
                                tile.orderPlacingDate
                                    ? moment(tile.orderPlacingDate)
                                    : null
                            }
                            placeholder="选择下单日期"
                            onChange={t.handleDateChange.bind(
                                t,
                                "orderPlacingDate"
                            )}
                        />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("品名", canUpdateSpecData)}
                    >
                        <Select
                            disabled={!canUpdateSpecData}
                            style={{ width: "100%" }}
                            placeholder="选择品名"
                            value={tile.categoryId}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "categoryId"
                            )}
                        >
                            {categoryOptions}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("等级", canUpdateSpecData)}
                    >
                        <Select
                            disabled={!canUpdateSpecData}
                            style={{ width: "100%" }}
                            placeholder="选择等级"
                            value={tile.gradeId}
                            onChange={t.handleTileInfoChange.bind(t, "gradeId")}
                        >
                            {gradeOptions}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("类型", canUpdateSpecData)}
                    >
                        <Select
                            disabled={!canUpdateSpecData}
                            style={{ width: "100%" }}
                            placeholder="选择类型"
                            value={`${tile.type}`}
                            onChange={t.handleTileInfoChange.bind(t, "type")}
                        >
                            {typeOptions}
                        </Select>
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("厚", canUpdateSpecData)}
                    >
                        <Input
                            placeholder="厚"
                            type="number"
                            disabled={!canUpdateSpecData}
                            value={tile.thickness}
                            onChange={t.handleTileInfoChange.bind(
                                t,
                                "thickness"
                            )}
                        />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("长", canUpdateSpecData)}
                    >
                        <Input
                            placeholder="长"
                            type="number"
                            disabled={!canUpdateSpecData}
                            value={tile.length}
                            onChange={t.handleTileInfoChange.bind(t, "length")}
                        />
                    </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={t.getRequiredTitle("宽", canUpdateSpecData)}
                    >
                        <Input
                            placeholder="宽"
                            type="number"
                            disabled={!canUpdateSpecData}
                            value={tile.width}
                            onChange={t.handleTileInfoChange.bind(t, "width")}
                        />
                    </FormItem>
                    <FormItem {...formItemLayout} label="面积">
                        <Input
                            placeholder="面积"
                            disabled={true}
                            value={tileArea}
                        />
                    </FormItem>
                    <div>
                        <FormItem {...formItemLayout} label="包装箱号">
                            <Input
                                placeholder="包装箱号"
                                disabled={!canUpdateManufacturingData}
                                value={tile.caseNumber}
                                onChange={t.handleTileInfoChange.bind(
                                    t,
                                    "caseNumber"
                                )}
                            />
                        </FormItem>
                        <FormItem {...formItemLayout} label="加工日期">
                            <DatePicker
                                style={{ width: "100%" }}
                                disabled={!canUpdateManufacturingData}
                                value={
                                    tile.processingDate
                                        ? moment(tile.processingDate)
                                        : null
                                }
                                placeholder="选择加工日期"
                                onChange={t.handleDateChange.bind(
                                    t,
                                    "processingDate"
                                )}
                            />
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={t.getRequiredTitle(
                                "切板员",
                                canUpdateManufacturingData &&
                                    !validator.isEmptyOrNull(
                                        tile.processingDate
                                    )
                            )}
                        >
                            <Input
                                placeholder="切板员"
                                disabled={!canUpdateManufacturingData}
                                value={tile.cuttingOperator}
                                onChange={t.handleTileInfoChange.bind(
                                    t,
                                    "cuttingOperator"
                                )}
                            />
                        </FormItem>
                        <FormItem
                            {...formItemLayout}
                            label={t.getRequiredTitle(
                                "机台号",
                                canUpdateManufacturingData &&
                                    !validator.isEmptyOrNull(
                                        tile.processingDate
                                    )
                            )}
                        >
                            <Input
                                placeholder="机台号"
                                disabled={!canUpdateManufacturingData}
                                value={tile.cuttingMachineNumber}
                                onChange={t.handleTileInfoChange.bind(
                                    t,
                                    "cuttingMachineNumber"
                                )}
                            />
                        </FormItem>
                        {(Store.appliedProcesses &&
                            Store.appliedProcesses.length > 0) ||
                        (Store.nonAppliedSOSelectedProcesses &&
                            Store.nonAppliedSOSelectedProcesses.length > 0 &&
                            canUpdateSpecData) ? (
                            <FormItem {...formItemLayout} label="生产工艺">
                                {t.getTileProcessesJSX()}
                            </FormItem>
                        ) : null}
                    </div>
                </Modal>
            </div>
        );
    }

    // 工程板生产工艺信息
    private getTileProcessesJSX() {
        const t = this;
        const s = t.state;
        const tile = Store.tile;
        const tileStatus = tile.status;
        const appliedProcesses = Store.appliedProcesses;
        const nonAppliedSOSelectedProcesses =
            Store.nonAppliedSOSelectedProcesses;
        const spaOptions = nonAppliedSOSelectedProcesses.map(spa => {
            return (
                <Option key={spa.name} value={spa.name}>
                    {spa.name}
                </Option>
            );
        });

        const canUpdateManufacturingData: boolean =
            s.canUpdateManufacturingData && tile.status !== 50;
        const canUpdateSpecData: boolean =
            s.canUpdateSpecData && tile.status !== 50;

        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 }
        };
        return (
            <div>
                {nonAppliedSOSelectedProcesses &&
                nonAppliedSOSelectedProcesses.length > 0 &&
                canUpdateSpecData ? (
                    <div>
                        <Select
                            style={{ width: "100%" }}
                            placeholder="添加工艺"
                            allowClear={true}
                            value={undefined}
                            onChange={t.addProcess}
                        >
                            {spaOptions}
                        </Select>
                    </div>
                ) : null}
                {appliedProcesses.map((pa, index) => {
                    return (
                        <Card
                            key={index}
                            style={{
                                width: "90%",
                                marginBottom: "10px"
                            }}
                            bodyStyle={{ padding: "10px" }}
                        >
                            <FormItem
                                {...formItemLayout}
                                label="工艺名称"
                                style={{ marginBottom: "3px" }}
                            >
                                <Input
                                    placeholder="工艺名称"
                                    value={pa.name}
                                    disabled={true}
                                    style={
                                        canUpdateSpecData
                                            ? { width: "77%" }
                                            : null
                                    }
                                />
                                {canUpdateSpecData ? (
                                    <Button
                                        danger={true}
                                        onClick={t.deleteProcess.bind(
                                            t,
                                            pa.name
                                        )}
                                        style={{
                                            marginLeft: "5px",
                                            paddingTop: "3px",
                                            width: "20%"
                                        }}
                                    >
                                        <DeleteOutlined />
                                    </Button>
                                ) : null}
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={t.getRequiredTitle(
                                    "数量",
                                    canUpdateSpecData
                                )}
                                style={{ marginBottom: "3px" }}
                            >
                                <Input
                                    placeholder="数量"
                                    value={pa.quantity}
                                    type="number"
                                    disabled={!canUpdateSpecData}
                                    onChange={t.handleAppliedProcessChange.bind(
                                        t,
                                        pa.name,
                                        "quantity"
                                    )}
                                />
                            </FormItem>
                            <FormItem
                                {...formItemLayout}
                                label={t.getRequiredTitle(
                                    "操作员",
                                    !validator.isEmptyOrNull(pa.quantity) &&
                                        (canUpdateManufacturingData ||
                                            (canUpdateSpecData &&
                                                tileStatus > 20 &&
                                                tileStatus < 50)) &&
                                        !validator.isEmptyOrNull(
                                            tile.processingDate
                                        )
                                )}
                                style={{ marginBottom: "3px" }}
                            >
                                <Input
                                    placeholder="操作员"
                                    disabled={
                                        validator.isEmptyOrNull(pa.quantity) ||
                                        !(
                                            canUpdateManufacturingData ||
                                            (canUpdateSpecData &&
                                                !Store.originalAppliedProcessNames.some(
                                                    p => p === pa.name
                                                ) &&
                                                tileStatus > 20 &&
                                                tileStatus < 50)
                                        )
                                    }
                                    value={pa.operatorName}
                                    onChange={t.handleAppliedProcessChange.bind(
                                        t,
                                        pa.name,
                                        "operatorName"
                                    )}
                                />
                            </FormItem>
                        </Card>
                    );
                })}
            </div>
        );
    }

    // 处理生产工艺
    private handleAppliedProcessChange(name: string, label: string, event) {
        const t = this;
        const s = t.state;
        const appliedProcesses = [...Store.appliedProcesses];

        appliedProcesses.find(pa => pa.name === name)[label] =
            event.target.value;
        if (
            label === "quantity" &&
            validator.isEmptyOrNull(event.target.value)
        ) {
            appliedProcesses.find(pa => pa.name === name).operatorName = null;
        }

        Store.setProcessingsApplied(
            appliedProcesses,
            Store.nonAppliedSOSelectedProcesses
        );
    }

    // 添加生产工艺
    private addProcess = (value: string) => {
        const t = this;
        const s = t.state;
        const processName: string = value;
        const appliedProcesses = [...Store.appliedProcesses];
        let nonAppliedSOSelectedProcesses = [
            ...Store.nonAppliedSOSelectedProcesses
        ];

        const pa = nonAppliedSOSelectedProcesses.find(
            spa => spa.name === processName
        );

        if (Util.isDefinedAndNotNull(pa)) {
            appliedProcesses.unshift(pa);
            nonAppliedSOSelectedProcesses = nonAppliedSOSelectedProcesses.filter(
                spa => {
                    return spa !== pa;
                }
            );
        }

        Store.setProcessingsApplied(
            appliedProcesses,
            nonAppliedSOSelectedProcesses
        );
    };

    // 删除生产工艺
    private deleteProcess(name: string) {
        const t = this;
        const s = t.state;
        const nonAppliedSOSelectedProcesses = [
            ...Store.nonAppliedSOSelectedProcesses
        ];
        let appliedProcesses = [...Store.appliedProcesses];

        const pa = appliedProcesses.find(p => p.name === name);

        if (pa !== null) {
            nonAppliedSOSelectedProcesses.push(pa);
            appliedProcesses = appliedProcesses.filter(spa => {
                return spa !== pa;
            });
        }

        Store.setProcessingsApplied(
            appliedProcesses,
            nonAppliedSOSelectedProcesses
        );
    }

    // 处理已选和未选的颜色
    private getCheckFlagColor(id: number): string {
        const t = this;
        let color: string = "";
        const tileSelected: boolean = Store.selectedTileIds.some(d => {
            return d === id;
        });
        color = tileSelected ? "#40a9ff" : "#999999";
        return color;
    }

    // 选择工程板
    private selectTile(tile) {
        const t = this;
        const s = t.state;
        if (!tile.id) {
            return;
        }
        let selectedTileIds = [];
        let selectedTileInfos = [...Store.selectedTileInfos];
        const tileNumber: string = tile.tileNumber;

        const tileSelected: boolean = Store.selectedTileIds.some(tileId => {
            return tileId === tile.id;
        });

        const tileInfo = selectedTileInfos.find(
            info => info.orderNumber === tile.orderNumber
        );

        if (tileSelected) {
            selectedTileIds = Store.selectedTileIds.filter(tileId => {
                return tileId !== tile.id;
            });

            tileInfo.tiles = tileInfo.tiles.filter(n => {
                return n !== tileNumber;
            });

            if (tileInfo.tiles.length === 0) {
                selectedTileInfos = selectedTileInfos.filter(info => {
                    return info.orderNumber !== tile.orderNumber;
                });
            } else {
                selectedTileInfos.find(
                    info => info.orderNumber === tile.orderNumber
                ).tiles = tileInfo.tiles;
            }
        } else {
            const existOrderNumber: boolean = selectedTileInfos.some(info => {
                return info.orderNumber === tile.orderNumber;
            });

            if (existOrderNumber) {
                selectedTileInfos
                    .find(info => info.orderNumber === tile.orderNumber)
                    .tiles.push(tileNumber);
            } else {
                selectedTileInfos.push({
                    orderNumber: tile.orderNumber,
                    projectName: tile.projectName,
                    tiles: [tileNumber]
                });
            }
            selectedTileIds = [...Store.selectedTileIds, tile.id];
        }
        Store.setSelectedTile(selectedTileIds, selectedTileInfos);
    }

    private getRequiredTitle(
        titleText: string,
        required: boolean
    ): JSX.Element {
        return (
            <span>
                {titleText}
                {required ? <span style={{ color: "red" }}>*</span> : null}
            </span>
        );
    }
}
export default PageTileList;
