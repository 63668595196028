import { Descriptions } from "antd";
import React from "react";
import {
    IBlockAppliedProcessDTO,
    IMachineryDTO,
    WorkOrderFullInfoDTO
} from "../../../app/WebAPIClients";
import AppliedProcessInfoList from "../../../components/AppliedProcessInfoList";
import AppliedProcessUtil from "../../../components/AppliedProcessUtil";
import BlockUtil from "../../../components/BlockUtil";
import { BlockDimensionType } from "../../../components/type";
import Util from "../../../components/Util";
import WorkOrderUtil from "../../../components/WorkOrderUtil";

interface ITrimmingInfoProps {
    wo: WorkOrderFullInfoDTO;
    trimmingProcesses: IBlockAppliedProcessDTO[];
    factoryList: IIdNameItem[];
    machineries: IMachineryDTO[];
}

export default class TrimmingInfo extends React.PureComponent<
    ITrimmingInfoProps
> {
    public render() {
        const t = this;
        const { wo, trimmingProcesses, factoryList, machineries } = t.props;

        const block =
            Util.isDefinedAndNotNull(wo.materialRequisition) &&
            Util.isDefinedAndNotNull(wo.materialRequisition.block)
                ? wo.materialRequisition.block
                : null;

        let trimmingProcessInfo = null;
        let duration = null;
        let trimmingMachineryId = null;
        let startTime = null;
        let endTime = null;
        const appliedProcessesGB = AppliedProcessUtil.GroupByAppliedProcess(
            trimmingProcesses
        );
        if (Util.isNotNullAndNotEmptyArray(appliedProcessesGB)) {
            // 一个工单只对应一组修边工艺
            trimmingProcessInfo = appliedProcessesGB[0];
            duration = trimmingProcessInfo.appliedProcesses[0].duration;
            trimmingMachineryId = trimmingProcessInfo.machineryId;
            startTime = trimmingProcessInfo.startTime;
            endTime = trimmingProcessInfo.endTime;
        }

        const totalCuts =
            Util.isDefinedAndNotNull(trimmingProcessInfo) &&
            Util.isDefinedAndNotNull(trimmingProcessInfo.appliedProcesses)
                ? AppliedProcessUtil.getTotalCuts(
                      trimmingProcessInfo.appliedProcesses
                  )
                : 0;
        return (
            <div>
                {wo.trimmingOperator ? (
                    <Descriptions
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        title={"修边信息"}
                    >
                        {Util.isDefinedAndNotNull(startTime) ? (
                            <Descriptions.Item label="开始时间">
                                {Util.formatDateTime(startTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(endTime) ? (
                            <Descriptions.Item label="结束时间">
                                {Util.formatDateTime(endTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(duration) ? (
                            <Descriptions.Item label="耗时">
                                {Util.getTimeConsuming(duration)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(trimmingMachineryId) ? (
                            <Descriptions.Item label="修边机械">
                                {WorkOrderUtil.getMachineryName(
                                    trimmingMachineryId,
                                    factoryList,
                                    machineries
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {BlockUtil.ShowBlockDimensionField(
                            block,
                            BlockDimensionType.Trimmed,
                            "完成"
                        )}
                        {block ? (
                            <Descriptions.Item label={"完成体积"}>
                                {Util.calculateVolume(
                                    block.trimmedLength,
                                    block.trimmedWidth,
                                    block.trimmedHeight
                                )}
                                {" 立方"}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.blockOutturnPercentage) ? (
                            <Descriptions.Item label="荒料出材率">
                                {Util.toPercent(wo.blockOutturnPercentage)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.trimmingOperator) ? (
                            <Descriptions.Item
                                label={
                                    wo.skipTrimming
                                        ? "尺寸确认人"
                                        : "修边负责人"
                                }
                            >
                                {wo.trimmingOperator}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.trimmingQE) ? (
                            <Descriptions.Item
                                label={
                                    wo.skipTrimming
                                        ? "尺寸复核员"
                                        : "修边质检员"
                                }
                            >
                                {wo.trimmingQE}
                            </Descriptions.Item>
                        ) : null}
                        {wo.skipTrimming ||
                        !Util.isNotNullAndNotEmpty(wo.trimmingNotes) ? null : (
                            <Descriptions.Item label="备注">
                                {wo.trimmingNotes}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                ) : (
                    <div style={{ textAlign: "center" }}>无修边数据</div>
                )}
                {trimmingProcessInfo ? (
                    <AppliedProcessInfoList
                        appliedProcesses={trimmingProcessInfo.appliedProcesses}
                        showHeader={true}
                        label={"修边工艺"}
                        extraInfo={
                            totalCuts > 0 ? (
                                <Descriptions.Item label="总计">
                                    {AppliedProcessUtil.getTotalCuts(
                                        trimmingProcessInfo.appliedProcesses
                                    ) + " 刀"}
                                </Descriptions.Item>
                            ) : null
                        }
                    />
                ) : null}
            </div>
        );
    }
}
