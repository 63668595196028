import { Descriptions } from "antd";
import React from "react";
import {
    IMachineryDTO,
    WorkOrderFullInfoDTO
} from "../../../app/WebAPIClients";
import Consts from "../../../components/Consts";
import Util from "../../../components/Util";
import WorkOrderUtil from "../../../components/WorkOrderUtil";

const { PolishingType } = Consts;

interface IPolishingInfoProps {
    wo: WorkOrderFullInfoDTO;
    factoryList: IIdNameItem[];
    machineries: IMachineryDTO[];
}

export default class PolishingInfo extends React.PureComponent<
    IPolishingInfoProps
> {
    public render() {
        const t = this;
        const { wo, factoryList, machineries } = t.props;

        return (
            <div>
                {wo.polishingStartTime ? (
                    <Descriptions
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        title={"磨抛信息"}
                    >
                        {Util.isDefinedAndNotNull(wo.polishingStartTime) ? (
                            <Descriptions.Item label="开始时间">
                                {Util.formatDateTime(wo.polishingStartTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.polishingEndTime) ? (
                            <Descriptions.Item label="结束时间">
                                {Util.formatDateTime(wo.polishingEndTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(
                            wo.timeConsumingOfPolishing
                        ) ? (
                            <Descriptions.Item label="耗时">
                                {Util.getTimeConsuming(
                                    wo.timeConsumingOfPolishing
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.polishingMachineryId) ? (
                            <Descriptions.Item label="磨抛机械">
                                {WorkOrderUtil.getMachineryName(
                                    wo.polishingMachineryId,
                                    factoryList,
                                    machineries
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.areaAfterPolishing) ? (
                            <Descriptions.Item label="光板面积">
                                {wo.areaAfterPolishing}
                                {" 平方"}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(
                            wo.polishedSlabOutturnPercentage
                        ) ? (
                            <Descriptions.Item label="光板出材率">
                                {wo.polishedSlabOutturnPercentage}
                                {" 平方/立方"}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.polishingType) ? (
                            <Descriptions.Item label="磨抛方式">
                                {PolishingType.getType(wo.polishingType).text}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.polishingOperator) ? (
                            <Descriptions.Item label="磨抛负责人">
                                {wo.polishingOperator}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.polishingQE) ? (
                            <Descriptions.Item label="磨抛质检员">
                                {wo.polishingQE}
                            </Descriptions.Item>
                        ) : null}
                        {!Util.isNotNullAndNotEmpty(
                            wo.polishingNotes
                        ) ? null : (
                            <Descriptions.Item label="备注">
                                {wo.polishingNotes}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                ) : (
                    <div style={{ textAlign: "center" }}>无磨抛数据</div>
                )}
            </div>
        );
    }
}
