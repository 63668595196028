import { Descriptions } from "antd";
import React from "react";
import {
    IBlockAppliedProcessDTO,
    IMachineryDTO,
    WorkOrderFullInfoDTO
} from "../../../app/WebAPIClients";
import AppliedProcessInfoList from "../../../components/AppliedProcessInfoList";
import Util from "../../../components/Util";
import WorkOrderUtil from "../../../components/WorkOrderUtil";

interface ISawingInfoProps {
    wo: WorkOrderFullInfoDTO;
    sawingAppliedProcesses: IBlockAppliedProcessDTO[];
    factoryList: IIdNameItem[];
    machineries: IMachineryDTO[];
}

export default class SawingInfo extends React.PureComponent<ISawingInfoProps> {
    public render() {
        const t = this;
        const {
            wo,
            sawingAppliedProcesses,
            factoryList,
            machineries
        } = t.props;

        return (
            <div>
                {wo.sawingStartTime ? (
                    <Descriptions
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        title={"大切信息"}
                    >
                        {Util.isDefinedAndNotNull(wo.sawingStartTime) ? (
                            <Descriptions.Item label="开始时间">
                                {Util.formatDateTime(wo.sawingStartTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.sawingEndTime) ? (
                            <Descriptions.Item label="结束时间">
                                {Util.formatDateTime(wo.sawingEndTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.timeConsumingOfSawing) ? (
                            <Descriptions.Item label="耗时">
                                {Util.getTimeConsuming(
                                    wo.timeConsumingOfSawing
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(
                            wo.sawingBladeManufacturer
                        ) ? (
                            <Descriptions.Item label="锯条厂家">
                                {wo.sawingBladeManufacturer}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.sawingMachineryId) ? (
                            <Descriptions.Item label="大切机械">
                                {WorkOrderUtil.getMachineryName(
                                    wo.sawingMachineryId,
                                    factoryList,
                                    machineries
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.sawingTotalSlabCount) ? (
                            <Descriptions.Item label="总片数">
                                {wo.sawingTotalSlabCount}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.areaAfterSawing) ? (
                            <Descriptions.Item label="毛板面积">
                                {wo.areaAfterSawing}
                                {" 平方"}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(
                            wo.rawSlabOutturnPercentage
                        ) ? (
                            <Descriptions.Item label="毛板出材率">
                                {wo.rawSlabOutturnPercentage}
                                {" 平方/立方"}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.sawingOperator) ? (
                            <Descriptions.Item label="大切负责人">
                                {wo.sawingOperator}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(wo.sawingQE) ? (
                            <Descriptions.Item label="大切质检员">
                                {wo.sawingQE}
                            </Descriptions.Item>
                        ) : null}
                        {!Util.isNotNullAndNotEmpty(wo.sawingNotes) ? null : (
                            <Descriptions.Item label="备注">
                                {wo.sawingNotes}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                ) : (
                    <div style={{ textAlign: "center" }}>无大切数据</div>
                )}
                {Util.isNotNullAndNotEmptyArray(sawingAppliedProcesses) ? (
                    <AppliedProcessInfoList
                        appliedProcesses={sawingAppliedProcesses}
                        showHeader={true}
                        label={"大切工艺"}
                    />
                ) : null}
            </div>
        );
    }
}
