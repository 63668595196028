// tslint:disable-next-line:no-submodule-imports
import "core-js";
import "antd/dist/antd.css";
import "moment/locale/zh-cn";
import locale from "antd/lib/locale/zh_CN";
import React from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps } from "react-router";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import SysInfoDialog from "../components/SysInfoDialog";
import "./app.styl";

import PageImportBlocks from "../pages/blocks/import";
import PageBlockList from "../pages/blocks/list";
import PageDevLogin from "../pages/devlogin";
import PageHome from "../pages/home";
import PageLogin from "../pages/login";
import PageLoginByPassword from "../pages/loginbypassword";
import PageExportPaymentOrders from "../pages/paymentorderlist";
import PageCompareInventoryExcelFile from "../pages/products/stonebundle/compareinventoryexcelfile";
import PageExportBundles from "../pages/products/stonebundle/export";
import PageImportBundles from "../pages/products/stonebundle/import";
import PageImportPolishingData from "../pages/products/stonebundle/importpolishingdata";
import PageImportReorganizedBundleData from "../pages/products/stonebundle/importreorganizedbundledata";
import PageStoneBundleList from "../pages/products/stonebundle/list";
import PageExportTiles from "../pages/products/tile/export";
import PageImportTiles from "../pages/products/tile/import";
import PageTileList from "../pages/products/tile/list";
import PageExportTilePackingSheet from "../pages/products/tilepackingcase/export";
import PageExportReport from "../pages/reports/export";
import PageDashboard from "../pages/reports/view/dashboard";
import PageReportManufacturing from "../pages/reports/view/manufacturing";
import PageRealTimeReport from "../pages/reports/view/realtimereport";
import PageSOList from "../pages/salesorderlist";
import PageSPOList from "../pages/shippingorderlist";
import PageBlockStockInAndOutRecordList from "../pages/stockinandoutrecords/block";
import PageProductStockInAndOutRecordList from "../pages/stockinandoutrecords/product";
import PageBlockTransferOrderList from "../pages/transferorders/block";
import PageProductTransferOrderList from "../pages/transferorders/product";
import PageWorkOrderList from "../pages/workorders/list";

import {
  ExclamationCircleOutlined,
  LaptopOutlined,
  UserOutlined,
} from "@ant-design/icons";

import { Col, ConfigProvider, Dropdown, Layout, Menu, Row } from "antd";
const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;
// tslint:disable-next-line: no-submodule-imports
import MenuItem from "antd/lib/menu/MenuItem";
import { configure } from "mobx";
import PermCtl from "../components/PermCtrl";
import Settings from "../components/SettingInfo";
import SettingsDefinition from "../components/SettingsDefinition";
import userInfo from "../components/UserInfo";
import Perm from "./Perm";

declare interface IAppProps extends RouteComponentProps<{}> {}
declare interface IAppState {
  visible: boolean;
}

// 设置严格模式
configure({ enforceActions: "observed" });
class App extends React.Component<IAppProps, IAppState> {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  public render(): JSX.Element {
    const t = this;
    const menuItems = [];
    const blockSheetSubMenus = [];
    const bundleSheetSubMenus = [];
    const tileSubMenus = [];
    const woSubMenus = [];
    const salesOrderSubMenus = [];
    const paymentOrderSubMenus = [];
    const reportSubMenus = [];
    const stockInAndOutRecordMenus = [];
    const transferOrderMenus = [];

    const enableTransferOrders = Settings.getBoolValue(
      SettingsDefinition.EnableTransferOrdersKey,
    );

    if (PermCtl.isAuthorized(Perm.B_R)) {
      blockSheetSubMenus.push(<Menu.Item key="27">浏览荒料</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.S_R)) {
      bundleSheetSubMenus.push(<Menu.Item key="26">浏览大板</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.S_CS_E)) {
      bundleSheetSubMenus.push(<Menu.Item key="5">导出大板码单</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.T_R)) {
      tileSubMenus.push(<Menu.Item key="28">浏览工程板</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.WO_R)) {
      woSubMenus.push(<Menu.Item key="40">浏览生产工单</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.TO_B_R)) {
      transferOrderMenus.push(<Menu.Item key="33">荒料调拨单</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.B_R)) {
      stockInAndOutRecordMenus.push(<Menu.Item key="34">荒料出入库</Menu.Item>);
    }

    if (PermCtl.isAnyAuthorized([Perm.TO_S_R, Perm.TO_T_R])) {
      transferOrderMenus.push(<Menu.Item key="35">成品调拨单</Menu.Item>);
    }

    if (PermCtl.isAnyAuthorized([Perm.S_R, Perm.TPC_R])) {
      stockInAndOutRecordMenus.push(<Menu.Item key="36">成品出入库</Menu.Item>);
    }

    if (PermCtl.isAnyAuthorized([Perm.T_MD_I, Perm.T_SD_I])) {
      tileSubMenus.push(<Menu.Item key="10">导入工程板</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.T_E)) {
      tileSubMenus.push(<Menu.Item key="11">导出工程板</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.TPC_E)) {
      tileSubMenus.push(<Menu.Item key="12">导出装箱单</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.S_PQD_I)) {
      bundleSheetSubMenus.push(<Menu.Item key="6">导入磨抛数据</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.S_IRD)) {
      bundleSheetSubMenus.push(<Menu.Item key="29">导入重组数据</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.S_I)) {
      bundleSheetSubMenus.push(<Menu.Item key="31">导入库存大板</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.S_ICIEF)) {
      bundleSheetSubMenus.push(<Menu.Item key="20">对比盘点文件</Menu.Item>);
    }

    if (PermCtl.isAnyAuthorized([Perm.SO_R, Perm.SO_RA])) {
      salesOrderSubMenus.push(<Menu.Item key="13">销售订单列表</Menu.Item>);
    }

    if (PermCtl.isAnyAuthorized([Perm.SS_R, Perm.SS_RA])) {
      paymentOrderSubMenus.push(<Menu.Item key="32">结算单列表</Menu.Item>);
    }

    if (
      PermCtl.isAnyAuthorized([
        Perm.RPT_OEM_E,
        Perm.RPT_SOCP_E,
        Perm.RPT_S_CR_E,
        Perm.RPT_S_LD_E,
        Perm.RPT_S_MS_E,
        Perm.RPT_S_PMD_E,
        Perm.RPT_S_RMD_E,
        Perm.RPT_S_S_E,
      ])
    ) {
      reportSubMenus.push(<Menu.Item key="25">导出报表</Menu.Item>);
    }

    if (PermCtl.isAuthorized(Perm.B_I)) {
      blockSheetSubMenus.push(<Menu.Item key="30">导入库存荒料</Menu.Item>);
    }

    if (blockSheetSubMenus.length > 0) {
      menuItems.push(t.getSubMenu("sub1", "荒料", blockSheetSubMenus));
    }

    if (bundleSheetSubMenus.length > 0) {
      menuItems.push(t.getSubMenu("sub2", "大板", bundleSheetSubMenus));
    }

    if (tileSubMenus.length > 0) {
      menuItems.push(t.getSubMenu("sub3", "工程板", tileSubMenus));
    }

    if (woSubMenus.length > 0) {
      menuItems.push(t.getSubMenu("sub10", "生产工单", woSubMenus));
    }

    if (transferOrderMenus.length > 0 && enableTransferOrders) {
      menuItems.push(t.getSubMenu("sub4", "调拨单", transferOrderMenus));
    }

    if (stockInAndOutRecordMenus.length > 0) {
      menuItems.push(
        t.getSubMenu("sub5", "出入库记录", stockInAndOutRecordMenus),
      );
    }

    if (PermCtl.isAnyAuthorized([Perm.SPO_R, Perm.SPO_RA])) {
      menuItems.push(
        <SubMenu key="sub6" icon={<LaptopOutlined />} title={"装车单"}>
          <Menu.Item key="2">装车单列表</Menu.Item>
        </SubMenu>,
      );
    }

    if (PermCtl.isAnyAuthorized([Perm.SO_R, Perm.SO_RA])) {
      menuItems.push(t.getSubMenu("sub7", "销售订单", salesOrderSubMenus));
    }

    if (paymentOrderSubMenus.length > 0) {
      menuItems.push(t.getSubMenu("sub8", "结算单", paymentOrderSubMenus));
    }

    if (reportSubMenus.length > 0) {
      menuItems.push(t.getSubMenu("sub9", "报表", reportSubMenus));
    }

    const settings = (
      <Menu>
        <Menu.Item key="0">
          <span
            onClick={t.handleSysInfoDialogClick}
            style={{ textAlign: "center" }}
          >
            <ExclamationCircleOutlined
              style={{
                marginRight: "8px",
                fontSize: "16px",
              }}
            />
            系统信息
          </span>
        </Menu.Item>
      </Menu>
    );

    return (
      <Layout>
        <Header className="header">
          <div className="logo" />
          <Row align="middle" justify="center">
            <Col span={21} style={{ textAlign: "left" }}>
              <span
                style={{
                  color: "#FFFFFFA5",
                }}
              >
                石材云ERP
              </span>
            </Col>
            <Col span={3} style={{ textAlign: "right" }}>
              <Dropdown overlay={settings} placement="bottomCenter">
                <span
                  style={{
                    cursor: "pointer",
                    color: "#FFFFFFA5",
                  }}
                >
                  <UserOutlined
                    style={{
                      marginRight: "8px",
                      fontSize: "20px",
                    }}
                  />
                  {userInfo.getName()}
                </span>
              </Dropdown>
            </Col>
          </Row>
        </Header>
        <SysInfoDialog
          onClose={t.handleCloseClick}
          visible={this.state.visible}
        />
        <Layout>
          <Sider width={200} style={{ background: "#fff" }}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              style={{ height: "100%" }}
              onClick={t.handleMenuClick}
            >
              <Menu.Item key="1">首页</Menu.Item>
              {menuItems}
            </Menu>
          </Sider>
          <Layout style={{ padding: "0 24px 24px" }}>
            <Content
              style={{
                background: "#fff",
                padding: 24,
                margin: 0,
                minHeight: 280,
              }}
            >
              <Switch>
                <PrivateRoute path="/" component={PageHome} exact={true} />
                <PrivateRoute
                  path="/blocks/import"
                  component={PageImportBlocks}
                />
                <PrivateRoute path="/blocks/list" component={PageBlockList} />
                <PrivateRoute
                  path="/bundle/importpolishingdata"
                  component={PageImportPolishingData}
                />
                <PrivateRoute
                  path="/bundle/importreorganizedbundledata"
                  component={PageImportReorganizedBundleData}
                />
                <PrivateRoute
                  path="/bundle/compareinventoryexcelfile"
                  component={PageCompareInventoryExcelFile}
                />
                <PrivateRoute
                  path="/bundle/import"
                  component={PageImportBundles}
                />
                <PrivateRoute
                  path="/bundle/export"
                  component={PageExportBundles}
                />
                <PrivateRoute
                  path="/bundle/list"
                  component={PageStoneBundleList}
                />
                <PrivateRoute path="/tile/import" component={PageImportTiles} />
                <PrivateRoute path="/tile/export" component={PageExportTiles} />
                <PrivateRoute path="/tile/list" component={PageTileList} />
                <PrivateRoute
                  path="/tilepackingcase/export"
                  component={PageExportTilePackingSheet}
                />
                <PrivateRoute
                  path="/transferorders/block"
                  component={PageBlockTransferOrderList}
                />
                <PrivateRoute
                  path="/transferorders/product"
                  component={PageProductTransferOrderList}
                />
                <PrivateRoute
                  path="/stockinandoutrecords/block"
                  component={PageBlockStockInAndOutRecordList}
                />
                <PrivateRoute
                  path="/stockinandoutrecords/product"
                  component={PageProductStockInAndOutRecordList}
                />
                <PrivateRoute
                  path="/workorders/list"
                  component={PageWorkOrderList}
                />
                <PrivateRoute
                  path="/reports/export"
                  component={PageExportReport}
                />
                <PrivateRoute path="/shippingorders" component={PageSPOList} />
                <PrivateRoute path="/salesorders" component={PageSOList} />
                <PrivateRoute
                  path="/paymentorders"
                  component={PageExportPaymentOrders}
                />
              </Switch>
            </Content>
          </Layout>
        </Layout>
      </Layout>
    );
  }

  private handleMenuClick = (value: { key: string }) => {
    let path = "/";
    switch (value.key) {
      case "2":
        path = "/shippingorders";
        break;
      case "10":
        path = "/tile/import";
        break;
      case "11":
        path = "/tile/export";
        break;
      case "12":
        path = "/tilepackingcase/export";
        break;
      case "5":
        path = "/bundle/export";
        break;
      case "6":
        path = "/bundle/importpolishingdata";
        break;
      case "20":
        path = "/bundle/compareinventoryexcelfile";
        break;
      case "13":
        path = "/salesorders";
        break;
      case "25":
        path = "/reports/export";
        break;
      case "26":
        path = "/bundle/list";
        break;
      case "27":
        path = "/blocks/list";
        break;
      case "28":
        path = "/tile/list";
        break;
      case "29":
        path = "/bundle/importreorganizedbundledata";
        break;
      case "30":
        path = "/blocks/import";
        break;
      case "31":
        path = "/bundle/import";
        break;
      case "32":
        path = "/paymentorders";
        break;
      case "33":
        path = "/transferorders/block";
        break;
      case "34":
        path = "/stockinandoutrecords/block";
        break;
      case "35":
        path = "/transferorders/product";
        break;
      case "36":
        path = "/stockinandoutrecords/product";
        break;
      case "40":
        path = "/workorders/list";
        break;
    }

    this.props.history.push(path);
  };

  private handleSysInfoDialogClick = () => {
    this.setState({
      visible: true,
    });
  };

  private handleCloseClick = () => {
    this.setState({
      visible: false,
    });
  };

  private getSubMenu(key: string, title: string, menuItems: MenuItem[]) {
    return (
      <SubMenu key={key} icon={<LaptopOutlined />} title={title}>
        {menuItems}
      </SubMenu>
    );
  }
}

ReactDOM.render(
  <ConfigProvider locale={locale} csp={{ nonce: "YourNonceCode" }}>
    <BrowserRouter>
      <Switch>
        <Route path="/devlogin" component={PageDevLogin} />
        <Route path="/login" component={PageLogin} />
        <Route path="/loginbypassword" component={PageLoginByPassword} />
        <PrivateRoute path="/dashboard" component={PageDashboard} />
        <PrivateRoute path="/realtimereport" component={PageRealTimeReport} />
        <PrivateRoute
          path="/reportmanufacturing"
          component={PageReportManufacturing}
        />
        <PrivateRoute path="/" component={App} />
      </Switch>
    </BrowserRouter>
  </ConfigProvider>,
  document.getElementById("App"),
);
