import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../app/WebAPIClientFactory";
import { IPaymentOrderListWithDetails } from "../../components/type";
import Util from "../../components/Util";
import Base from "../../stores/base";

export interface IPOFilterData {
    orderNumber?: string;
    customerIds?: number[];
    statusCodes?: number[];
    startDate?: Date;
    endDate?: Date;
    createStartDate?: Date;
    createEndDate?: Date;
}

class Store extends Base {
    @observable
    public paymentOrderList: IPaymentOrderListWithDetails[] = [];
    @observable
    public filterData: IPOFilterData = {};

    @action
    public async loadData() {
        const t = this;
        const {
            orderNumber,
            customerIds,
            statusCodes,
            startDate,
            endDate,
            createStartDate,
            createEndDate
        } = t.filterData;
        const client = ClientFactory.getPaymentOrderClient();
        const callAPI = async () => {
            const content = await client.getAll(
                orderNumber,
                customerIds,
                undefined,
                statusCodes,
                Util.getValidDate(startDate),
                Util.getValidDate(endDate),
                Util.getValidDate(createStartDate),
                Util.getValidDate(createEndDate),
                t.pageSize,
                t.pageNo
            );

            runInAction(() => {
                t.paymentOrderList = content.result;
                t.setPagingInfo(content.pagingInfo);
            });
        };

        await this.callAPIFunForGet(callAPI);
    }

    @action
    public async getPaymentOrder(id: number) {
        const t = this;

        const client = ClientFactory.getPaymentOrderClient();
        const callAPI = async () => {
            const result = await client.get(id);
            const paymentOrderList = [...this.paymentOrderList];
            const po = paymentOrderList.find(p => {
                return p.id === id;
            });

            if (po) {
                po.items = result.items;
                po.appliedPrices = result.appliedPrices;
            }

            runInAction(() => {
                t.paymentOrderList = paymentOrderList;
            });
        };

        await t.callAPIFunForGet(callAPI);
    }

    @action
    public updataFilterData(filterData: IPOFilterData) {
        this.filterData = filterData;
    }
}

export default new Store();
