import { action, observable, runInAction } from "mobx";
import ClientFactory from "../../../app/WebAPIClientFactory";
import { BlockStatus, IBlockForListDTO } from "../../../app/WebAPIClients";
import ColumnSettings from "../../../components/ColumnSettings";
import Util from "../../../components/Util";
import Base from "../../../stores/base";

export interface IBlockFilterData {
  selectBlockNumber?: string;
  categoryIds?: number[]; // 石材种类
  gradeIds?: number[]; // 石材等级
  stockingAreaIds?: number[]; // 库存区域
  statusCodes?: number[]; // 状态
  maxLength?: number; // 最大长度
  minLength?: number; // 最小长度
  maxWidth?: number; // 最大宽度
  minWidth?: number; // 最小宽度
  maxHeight?: number; // 最大高度
  minHeight?: number; // 最小高度
  maxUnitPrice?: number; // 最高价
  minUnitPrice?: number; // 最低价
  ownerIds?: number[];
  selectBlockNumberFromQuarry?: string;
  selectWarehouse?: string[];
  stockInStartDate?: Date;
  stockInEndDate?: Date;
  stockOutStartDate?: Date;
  stockOutEndDate?: Date;
  purchaseEndDate?: Date;
  purchaseStartDate?: Date;
}

class Store extends Base {
  @observable
  public filterData: IBlockFilterData = {};
  @observable
  public blockList: IBlockForListDTO[] = [];
  @observable
  public totalVolume: number = 0;
  private componentName: string = "BlockList";

  @action
  public async reloadData() {
    const t = this;
    const ss = ColumnSettings.getSortSettingsForBackend(this.componentName);
    let { statusCodes } = t.filterData;
    const {
      selectBlockNumber,
      categoryIds,
      gradeIds,
      stockingAreaIds,
      maxLength,
      minLength,
      maxWidth,
      minWidth,
      maxHeight,
      minHeight,
      maxUnitPrice,
      minUnitPrice,
      ownerIds,
      selectBlockNumberFromQuarry,
      selectWarehouse,
      stockInStartDate,
      stockInEndDate,
      stockOutStartDate,
      stockOutEndDate,
      purchaseEndDate,
      purchaseStartDate,
    } = t.filterData;

    const warehouseId =
      selectWarehouse && selectWarehouse.length >= 2
        ? [parseInt(selectWarehouse[1])]
        : [];

    const client = ClientFactory.getBlockClient();
    // 当用户选择stockOutStartDate和stockOutEndDate，并且未指定状态的情况下，筛选状态默认为排除在库存之外的其它状态，
    // 做此变更是因为在实际应用场景中，如果选择出库时间，通常是需要搜索实际出库的物品，
    // 而通过调拨单产生的在库存的物品会存在出库时间，需要把这部分排除
    statusCodes =
      Util.isNotNullAndNotEmpty(stockOutStartDate) &&
      Util.isNotNullAndNotEmpty(stockOutEndDate) &&
      !Util.isNotNullAndNotEmptyArray(statusCodes)
        ? [
            BlockStatus.Purchased,
            BlockStatus.Manufacturing,
            BlockStatus.Processed,
            BlockStatus.Reserved,
            BlockStatus.Sold,
            BlockStatus.Discarded,
          ]
        : statusCodes;

    const callAPI = async () => {
      const content = await client.getAll(
        maxLength,
        maxWidth,
        maxHeight,
        maxUnitPrice,
        selectBlockNumber,
        selectBlockNumberFromQuarry,
        ownerIds,
        categoryIds,
        gradeIds,
        warehouseId,
        stockingAreaIds,
        statusCodes,
        Util.getValidDate(purchaseStartDate),
        Util.getValidDate(purchaseEndDate),
        Util.getValidDate(stockInStartDate),
        Util.getValidDate(stockInEndDate),
        Util.getValidDate(stockOutStartDate),
        Util.getValidDate(stockOutEndDate),
        ss,
        t.pageSize,
        t.pageNo,
        minLength,
        minWidth,
        minHeight,
        minUnitPrice,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
      );

      runInAction(() => {
        t.blockList = content.result;
        this.totalVolume = content.totalArea;
        this.setPagingInfo(content.pagingInfo);
      });
    };

    await t.callAPIFunForGet(callAPI);
  }

  @action
  public updataFilterData(filterData: IBlockFilterData) {
    this.filterData = filterData;
  }
}

export default new Store();
