import {
    Button,
    Col,
    Collapse,
    DatePicker,
    Form,
    message,
    Radio,
    Row,
    Select,
    Spin
} from "antd";
import React from "react";
import ExportUtil from "../../../components/ExportUtil";
import PermCtl from "../../../components/PermCtrl";
import Settings from "../../../components/SettingInfo";

// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import { RouteComponentProps } from "react-router";
moment.locale("zh-cn");

// tslint:disable-next-line:no-submodule-imports
import { RadioChangeEvent } from "antd/lib/radio/interface";
// tslint:disable-next-line:no-submodule-imports
import { OptionProps } from "antd/lib/select";
import Perm from "../../../app/Perm";
import {
    ICustomerForListDTO,
    OEMExportDateType,
    PriceSource
} from "../../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../components/Consts";
import SettingsDefinition from "../../../components/SettingsDefinition";
import Util from "../../../components/Util";
import Validator from "../../../components/Validator";
const { RangePicker } = DatePicker;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

const { Customers } = Consts;
const dateFormat = "YYYY-MM-DD";

declare interface IPagePaymentOrderListProps extends RouteComponentProps<{}> {}

declare interface IPagePaymentOrderListState {
    startTime?: string;
    endTime?: string;
    buttonDisabled: boolean;
    customers: ICustomerForListDTO[];
    customerId?: number;
    priceSource?: number;
    dateType?: number;
}

class PagePaymentOrderList extends React.Component<
    IPagePaymentOrderListProps,
    IPagePaymentOrderListState
> {
    constructor(props) {
        super(props);
        this.state = {
            startTime: moment()
                .startOf("month")
                .format(dateFormat), // 将startTime的默认值设置为当前月的第一天，同下方对日期控件RangePicker的默认值
            endTime: moment().format(dateFormat), // 将endTime的默认值设置为当前日期，同下方对日期控件RangePicker的默认值
            buttonDisabled: false,
            customers: [],
            priceSource: PriceSource.Sale,
            dateType: OEMExportDateType.Sold
        };
    }

    public async componentDidMount() {
        const t = this;
        const customers = await Customers.getCustomers();
        t.setState({ customers });
    }

    public setButtonEnable(printAPIType) {
        let time = 10;
        if (printAPIType && printAPIType === "销售成本核算报表") {
            time = 30;
        }

        if (printAPIType && printAPIType === "代加工核算报表") {
            time = 40;
        }

        setTimeout(
            // 设置定时器，由于销售成本核算报表数据量较大，设置30秒后再恢复点亮Button，代加工核算报表设置40秒后再恢复点亮Button，其它报表设置时间为10秒
            () => {
                this.setState({ buttonDisabled: false });
            },
            time * 1000
        );
    }

    public exportReport(printAPIType) {
        const t = this;
        const s = t.state;
        let startTime = s.startTime;
        let endTime = s.endTime;

        if (
            startTime === null ||
            endTime === null ||
            typeof startTime === "undefined" ||
            typeof endTime === "undefined"
        ) {
            message.info("日期不合法");
            return;
        }

        if (moment(endTime).isBefore(startTime)) {
            message.info("结束时间不能早于开始时间");
            return;
        }

        const maxDate = moment(startTime)
            .add(3, "months")
            .add(-1, "days")
            .format(dateFormat);

        if (moment(maxDate).isBefore(endTime)) {
            message.info("最多只能导出三个月的报表数据");
            return;
        }

        startTime = encodeURIComponent(startTime);
        endTime = encodeURIComponent(endTime);

        const url = t.getUrl(printAPIType);

        let printAPI = url + startTime + "&endDate=" + endTime;

        if (printAPIType === "代加工核算报表") {
            if (!Validator.require(s.customerId, "客户", true)) {
                return;
            }

            printAPI +=
                "&customerId=" +
                s.customerId +
                "&dateType=" +
                s.dateType +
                "&priceSource=" +
                s.priceSource;
        }

        message.info("正在导出指定日期的" + printAPIType + "数据...");
        ExportUtil.setExportUrl(printAPI);

        // 为防止导出数据量较大和多用户同时访问操作时，导致服务器性能降低的现象，每次点击导出数据后，将会按不同时长disabled Button
        this.setState({ buttonDisabled: true });
        t.setButtonEnable(printAPIType);
    }

    public getUrl(printAPIType) {
        if (!printAPIType) {
            return "";
        }

        let url = "";
        switch (printAPIType) {
            case "销售成本核算报表":
                url = "SalesOrder/ExportSOCostProfitReport?startDate=";
                break;
            case "代加工核算报表":
                url = "Report/ExportOEMReport?startDate=";
                break;
            case "大板入库明细":
                url = "Report/ExportStoneBundleStockingInReport?startDate=";
                break;
            case "红外线领退料":
                url =
                    "Report/ExportTileMaterialRequisitionDetailReport?startDate=";
                break;
            case "大板装车报表":
                url = "Report/ExportStoneBundleShippingDetailReport?startDate=";
                break;
            case "大板生产汇总":
                url = "Report/ExportSlabManufacturingSummaryReport?startDate=";
                break;
            case "毛板生产明细":
                url =
                    "Report/ExportRawSlabManufacturingDetailReport?startDate=";
                break;
            case "光板生产明细":
                url =
                    "Report/ExportPolishedSlabManufacturingDetailReport?startDate=";
                break;
        }

        return url;
    }

    public handleOnChange = (dates: any, dateStrings: [string, string]) => {
        const startTime = dateStrings[0];
        const endTime = dateStrings[1];

        this.setState({ startTime, endTime });
    };

    public getPanelJSX(title, printAPIType) {
        const t = this;
        const s = t.state;
        const formItemLayout = formItemLayoutForSearch;
        const customerOptions = s.customers.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        return (
            <div>
                <p style={{ paddingLeft: "30px" }}>{title}</p>
                <Row>
                    {printAPIType === "代加工核算报表" ? (
                        <Col {...formItemLayoutForCol}>
                            <FormItem {...formItemLayout} label="选择客户">
                                <Select
                                    showSearch={true}
                                    allowClear={true}
                                    style={{ width: "100%" }}
                                    optionFilterProp="children"
                                    placeholder="请选择客户"
                                    value={s.customerId}
                                    onChange={t.handleCustomerIdChange}
                                >
                                    {customerOptions}
                                </Select>
                            </FormItem>
                        </Col>
                    ) : null}
                    {printAPIType === "代加工核算报表" ? (
                        <Col {...formItemLayoutForCol}>
                            <FormItem {...formItemLayout} label="价格计算来源">
                                <RadioGroup
                                    onChange={t.handlePriceSourceChange}
                                    value={s.priceSource}
                                >
                                    <Radio value={PriceSource.Sale}>
                                        取销售价统计
                                    </Radio>
                                    <Radio value={PriceSource.Cost}>
                                        取成本价统计
                                    </Radio>
                                </RadioGroup>
                            </FormItem>
                        </Col>
                    ) : null}
                    {printAPIType === "代加工核算报表" ? (
                        <Col {...formItemLayoutForCol}>
                            <FormItem {...formItemLayout} label="日期类型">
                                <RadioGroup
                                    onChange={t.handleDataTypeChange}
                                    value={s.dateType}
                                >
                                    <Radio value={OEMExportDateType.Sold}>
                                        按销售日期导出
                                    </Radio>
                                    <Radio value={OEMExportDateType.Create}>
                                        按锯切日期导出
                                    </Radio>
                                </RadioGroup>
                            </FormItem>
                        </Col>
                    ) : null}

                    <Col {...formItemLayoutForCol}>
                        <FormItem {...formItemLayout} label="选择日期">
                            <RangePicker
                                style={{ width: "100%" }}
                                placeholder={["开始日期", "结束日期"]}
                                value={Util.getMomentArray(
                                    s.startTime,
                                    s.endTime
                                )}
                                ranges={{
                                    本月: [
                                        moment().startOf("month"),
                                        moment().endOf("month")
                                    ],
                                    上个月: [
                                        moment()
                                            .subtract(1, "month")
                                            .startOf("month"),
                                        moment()
                                            .subtract(1, "month")
                                            .endOf("month")
                                    ]
                                }}
                                format={dateFormat}
                                onChange={t.handleOnChange}
                            />
                        </FormItem>
                    </Col>
                    <Col {...formItemLayoutForCol}>
                        <FormItem {...formItemLayout} label="">
                            <Button
                                type="primary"
                                style={{ marginLeft: "10px" }}
                                disabled={s.buttonDisabled}
                                onClick={t.exportReport.bind(t, printAPIType)}
                            >
                                {title}
                            </Button>
                        </FormItem>
                    </Col>
                </Row>
            </div>
        );
    }

    public render() {
        const t = this;
        const s = t.state;

        const enableExportOEMReport = Settings.getBoolValue(
            SettingsDefinition.EnableExportOEMReportKey
        );

        return (
            <div>
                {s.buttonDisabled ? (
                    <div className="importingSpinArea">
                        <p>正在导出文件，请等待...</p>
                        <Spin />
                    </div>
                ) : null}
                <Collapse
                    defaultActiveKey={["1"]}
                    style={{ marginTop: "20px" }}
                >
                    {PermCtl.isAuthorized(Perm.RPT_SOCP_E) ? (
                        <Panel header="销售成本核算报表" key="1">
                            {t.getPanelJSX(
                                "导出销售成本核算报表",
                                "销售成本核算报表"
                            )}
                        </Panel>
                    ) : null}
                    {PermCtl.isAuthorized(Perm.RPT_OEM_E) &&
                    enableExportOEMReport ? (
                        <Panel header="代加工核算报表" key="2">
                            {t.getPanelJSX(
                                "导出代加工核算报表",
                                "代加工核算报表"
                            )}
                        </Panel>
                    ) : null}

                    {PermCtl.isAnyAuthorized([
                        Perm.RPT_S_S_E,
                        Perm.RPT_S_MS_E,
                        Perm.RPT_S_LD_E,
                        Perm.RPT_S_CR_E
                    ]) ? (
                        <Panel header="大板报表" key="3">
                            <Collapse defaultActiveKey={["1"]}>
                                {PermCtl.isAuthorized(Perm.RPT_S_S_E) ? (
                                    <Panel header="大板入库明细" key="1">
                                        {t.getPanelJSX(
                                            "导出大板入库明细报表",
                                            "大板入库明细"
                                        )}
                                    </Panel>
                                ) : null}
                                {PermCtl.isAuthorized(Perm.RPT_S_CR_E) ? (
                                    <Panel header="红外线领退料" key="2">
                                        {t.getPanelJSX(
                                            "导出红外线领退料报表",
                                            "红外线领退料"
                                        )}
                                    </Panel>
                                ) : null}
                                {PermCtl.isAuthorized(Perm.RPT_S_LD_E) ? (
                                    <Panel header="大板装车报表" key="3">
                                        {t.getPanelJSX(
                                            "导出大板装车报表",
                                            "大板装车报表"
                                        )}
                                    </Panel>
                                ) : null}
                                {PermCtl.isAuthorized(Perm.RPT_S_MS_E) ? (
                                    <Panel header="大板生产汇总" key="4">
                                        {t.getPanelJSX(
                                            "导出大板生产汇总报表",
                                            "大板生产汇总"
                                        )}
                                    </Panel>
                                ) : null}
                            </Collapse>
                        </Panel>
                    ) : null}

                    {PermCtl.isAnyAuthorized([
                        Perm.RPT_S_RMD_E,
                        Perm.RPT_S_PMD_E
                    ]) ? (
                        <Panel header="生产报表" key="4">
                            <Collapse defaultActiveKey={["1"]}>
                                {PermCtl.isAuthorized(Perm.RPT_S_RMD_E) ? (
                                    <Panel header="毛板生产明细" key="1">
                                        {t.getPanelJSX(
                                            "导出毛板生产明细报表",
                                            "毛板生产明细"
                                        )}
                                    </Panel>
                                ) : null}
                                {PermCtl.isAuthorized(Perm.RPT_S_PMD_E) ? (
                                    <Panel header="光板生产明细" key="2">
                                        {t.getPanelJSX(
                                            "导出光板生产明细报表",
                                            "光板生产明细"
                                        )}
                                    </Panel>
                                ) : null}
                            </Collapse>
                        </Panel>
                    ) : null}
                </Collapse>
            </div>
        );
    }

    private handleCustomerIdChange = value => {
        this.setState({ customerId: value });
    };

    private handlePriceSourceChange = (e: RadioChangeEvent) => {
        this.setState({ priceSource: e.target.value });
    };

    private handleDataTypeChange = (e: RadioChangeEvent) => {
        this.setState({ dateType: e.target.value });
    };
}

export default PagePaymentOrderList;
