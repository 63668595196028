import { DownOutlined } from "@ant-design/icons";
import {
    Button,
    Cascader,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Dropdown,
    Form,
    FormInstance,
    Input,
    message,
    Pagination,
    Row,
    Select,
    Spin,
    Table,
    Tabs
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import Perm from "../../../../app/Perm";
import {
    FactoryDTO,
    StoneArtifactType,
    WarehouseDTO,
    WarehouseType
} from "../../../../app/WebAPIClients";
import ColumnSettings from "../../../../components/ColumnSettings";
import Consts, {
    formItemLayoutForCheckboxGroup,
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../../components/Consts";
import ExportUtil from "../../../../components/ExportUtil";
import PermCtrl from "../../../../components/PermCtrl";
import SearchButtonGroup from "../../../../components/SearchButtonGroup";
import Settings from "../../../../components/SettingInfo";
import SettingsDefinition from "../../../../components/SettingsDefinition";
import StockingAreaUtil from "../../../../components/StockingAreaUtil";
import UnitPriceUtil from "../../../../components/UnitPriceUtil";
import Util from "../../../../components/Util";
import validator from "../../../../components/Validator";
import Store, { IFilterData } from "./store";

const { TabPane } = Tabs;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const CheckboxGroup = Checkbox.Group;
const InputGroup = Input.Group;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const {
    Factories,
    SlabBundleStatus,
    ManufacturingMethod,
    SlabType,
    StoneCategories,
    StoneGrades,
    ProductStockingAreas,
    Warehouses,
    ManufacturingState
} = Consts;

declare interface IStoneBundleListProps
    extends React.Props<PageStoneBundleList> {}

declare interface IStoneBundleListState {
    factories: FactoryDTO[]; // 工厂
    warehouseList: WarehouseDTO[]; // 仓库
    warehouseOptions: any;

    categoryList: any; // 石材种类
    categoryOptions: any; // 选择石材种类
    gradeList: any; // 石材等级
    gradeOptions: any; // 选择等级
    checkBoxOptions: any; // 状态
    psList: any; // 成品库存区域
    psOptions: any; // 选择荒料库存区域
    visible: boolean; // 控制点击显示关闭下拉菜单
    columnsInfo: ITableColumnInfo[];
    manufacturingMethodOptions: any;
    reorganizedOptions: any;
    reorganizedList: any;
    bundleExpandedArr: number[];
    slabTypeOptions: any;
    enableBlockNumberFromQuarry: boolean;
    selectBlockNumberFromQuarry?: string;
    uniqueWarehouse: boolean;
    stockInTimeDateValue?: any[]; // 用于显示当前选中的入库日期范围
    stockInTimeStartDate?: string;
    stockInTimeEndDate?: string;
    stockOutTimeDateValue?: any[]; // 用于显示当前选中的出库日期范围
    stockOutTimeStartDate?: string;
    stockOutTimeEndDate?: string;
    manufacturingStateOptions: any[];
    selectWarehouse?: string[];

    /*大板字段定义 */
    slabColumnsInfo: ITableColumnInfo[];
    slabVisible: boolean; // 控制点击显示关闭下拉菜单
    slabSelectWarehouse?: string[];

    buttonDisabled: boolean;
    enableReferencePrice: boolean;
}

@observer
class PageStoneBundleList extends React.Component<
    IStoneBundleListProps,
    IStoneBundleListState
> {
    private bundleTabKey: string = StoneArtifactType.StoneBundle.toString();
    private slabTabKey: string = StoneArtifactType.Slab.toString();
    private componentName: string = "StoneBundleList";
    private slabComponentName: string = "SlabList";
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            factories: [],
            warehouseOptions: [],
            warehouseList: [],
            categoryList: [],
            categoryOptions: null,
            gradeList: [],
            gradeOptions: null,
            checkBoxOptions: [],
            psList: [],
            psOptions: [],
            manufacturingMethodOptions: null,
            reorganizedList: [
                {
                    value: "10",
                    text: "是"
                },
                {
                    value: "20",
                    text: "否"
                }
            ], // 因为Select控件的value数据类型是string | number，并不支持boolean，但后端接口参数reorganized类型为bool?,所以在调用接口时需转换参数类型
            slabTypeOptions: [],
            manufacturingStateOptions: [],
            reorganizedOptions: [],
            visible: false,
            slabVisible: false,
            bundleExpandedArr: [],
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            ),
            enableReferencePrice: Settings.getBoolValue(
                SettingsDefinition.EnableReferencePriceKey
            ),
            columnsInfo: this.getStoneBundleColumns(),
            slabColumnsInfo: this.getSlabColumns(),
            uniqueWarehouse: false,
            buttonDisabled: false
        };
    }

    public async componentDidMount() {
        const t = this;
        const categoryList = await StoneCategories.getCategories();
        const gradeList = await StoneGrades.getGrades();
        const psList = await ProductStockingAreas.getStockingAreas();
        const mmList = ManufacturingMethod.combinationData;
        const factories = await Factories.getFactories(); // 工厂列表
        let warehouseList = await Warehouses.getWarehouses(); // 仓库列表
        warehouseList = warehouseList.filter(
            w => w.type === WarehouseType.Product
        );

        const categoryOptions = categoryList.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        const gradeOptions = gradeList.map(g => {
            return (
                <Option key={g.id} value={g.id}>
                    {g.name}
                </Option>
            );
        });

        const manufacturingMethodOptions = mmList.map(m => {
            return (
                <Option key={m.value as number} value={m.value as number}>
                    {m.text}
                </Option>
            );
        });

        const reorganizedOptions = this.state.reorganizedList.map(m => {
            return (
                <Option key={m.value} value={m.value}>
                    {m.text}
                </Option>
            );
        });

        const checkBoxOptions = SlabBundleStatus.data.map(status => {
            return { label: status.text, value: status.value.toString() };
        });

        const slabTypeOptions = SlabType.data.map(st => {
            return (
                <Option key={st.value as number} value={st.value as number}>
                    {st.text}
                </Option>
            );
        });

        const manufacturingStateOptions = ManufacturingState.slabMSData.map(
            st => {
                return (
                    <Option key={st.value as number} value={st.value as number}>
                        {st.text}
                    </Option>
                );
            }
        );

        const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
            warehouseList,
            factories
        );

        const uniqueWarehouse = warehouseList.length === 1;
        const warehouseId =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle
                ? Store.filterData.warehouseId
                : Store.filterData.slabWarehouseId;
        const psOptions = StockingAreaUtil.getStockingAreaOptions(
            uniqueWarehouse,
            warehouseId,
            psList,
            warehouseOptions
        );

        t.setState({
            categoryList,
            factories,
            gradeList,
            psList,
            categoryOptions,
            gradeOptions,
            psOptions,
            checkBoxOptions,
            manufacturingMethodOptions,
            reorganizedOptions,
            slabTypeOptions,
            warehouseList,
            warehouseOptions,
            uniqueWarehouse,
            manufacturingStateOptions
        });

        t.loadData();
    }

    public render() {
        const t = this;
        const s = t.state;
        const tabsArr = [];
        const pagingInfo = Store.pagingInfo;
        const totalRecordCount = pagingInfo && pagingInfo.totalRecordCount;
        tabsArr.push(t.showBundleList("扎", t.bundleTabKey));
        tabsArr.push(t.showSlabList("大板", t.slabTabKey));
        const spanText =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle
                ? `正在导出文件，当前导出扎数据共计：${totalRecordCount}扎，请等待...`
                : `正在导出文件，当前导出大板数据共计：${totalRecordCount}片，请等待...`;

        return (
            <div>
                {s.buttonDisabled ? (
                    <div
                        className="importingSpinArea"
                        ref={() => {
                            "spinArea";
                        }}
                    >
                        <p>{spanText}</p>
                        <Spin />
                    </div>
                ) : null}
                <Tabs
                    onChange={t.handleTabChanged}
                    activeKey={Store.stoneArtifactType.toString()}
                >
                    {tabsArr}
                </Tabs>
            </div>
        );
    }

    private handleTabChanged = async activeKey => {
        Store.setStoneArtifactType(parseInt(activeKey));
        Store.setPageNo(1);
        await this.loadData();
    };

    private showBundleList = (label: string, key: string): JSX.Element => {
        const t = this;
        const s = t.state;
        const sbList = Store.stoneBundleList;
        const filterData = Store.filterData;
        const categoryOptions = s.categoryOptions;
        const gradeOptions = s.gradeOptions;
        const msOptions = s.manufacturingStateOptions;
        const psOptions = s.psOptions;
        const checkBoxOptions = s.checkBoxOptions;
        const mmOptions = s.manufacturingMethodOptions;
        const reorganizedOptions = s.reorganizedOptions;

        const showUnitPriceFilter =
            PermCtrl.isAuthorized(Perm.S_RP_R) && s.enableReferencePrice;

        const showBundleGrades = PermCtrl.isAuthorized(Perm.SB_G_R);

        return (
            <TabPane tab={label} key={key}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            ref={this.formRef}
                            name={"stoneBundleAdvancedSearchForm"}
                            onFinish={t.handleSearch}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        {...formItemLayoutForCheckboxGroup}
                                        label="状态"
                                    >
                                        <CheckboxGroup
                                            options={checkBoxOptions}
                                            value={filterData.statusCodes}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "statusCodes"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="荒料编号">
                                        <Input
                                            placeholder="请输入荒料编号"
                                            value={filterData.selectPrefix}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "selectPrefix"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                {showUnitPriceFilter ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="价格">
                                            <InputGroup compact={true}>
                                                <Input
                                                    style={{
                                                        width: "42%",
                                                        textAlign: "center"
                                                    }}
                                                    type="number"
                                                    placeholder="最低价"
                                                    value={
                                                        filterData.minUnitPrice
                                                    }
                                                    onChange={t.handleInputChange.bind(
                                                        t,
                                                        "minUnitPrice"
                                                    )}
                                                />
                                                <Input
                                                    style={{
                                                        width: "16%",
                                                        textAlign: "center",
                                                        borderLeft: 0,
                                                        borderRight: 0,
                                                        pointerEvents: "none"
                                                    }}
                                                    placeholder="~"
                                                    disabled={true}
                                                />
                                                <Input
                                                    style={{
                                                        width: "42%",
                                                        textAlign: "center"
                                                    }}
                                                    type="number"
                                                    placeholder="最高价"
                                                    value={
                                                        filterData.maxUnitPrice
                                                    }
                                                    onChange={t.handleInputChange.bind(
                                                        t,
                                                        "maxUnitPrice"
                                                    )}
                                                />
                                            </InputGroup>
                                        </FormItem>
                                    </Col>
                                ) : null}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="补磨方式">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            placeholder="请选择补磨方式"
                                            optionFilterProp="children"
                                            value={
                                                filterData.manufacturingMethods
                                            }
                                            onChange={t.handleChange.bind(
                                                t,
                                                "manufacturingMethods"
                                            )}
                                        >
                                            {mmOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="石材种类">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择石材种类"
                                            value={filterData.categoryIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "categoryIds"
                                            )}
                                        >
                                            {categoryOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="长度">
                                        <InputGroup compact={true}>
                                            <Input
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                type="number"
                                                placeholder="最小值"
                                                value={filterData.minLength}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "minLength"
                                                )}
                                            />
                                            <Input
                                                style={{
                                                    width: "16%",
                                                    textAlign: "center",
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: "none"
                                                }}
                                                placeholder="~"
                                                disabled={true}
                                            />
                                            <Input
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                type="number"
                                                placeholder="最大值"
                                                value={filterData.maxLength}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "maxLength"
                                                )}
                                            />
                                        </InputGroup>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="入库日期">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            size={"middle"}
                                            format={dateFormat}
                                            value={Util.getMomentArray(
                                                filterData.stockInTimeStartDate,
                                                filterData.stockInTimeEndDate
                                            )}
                                            onChange={
                                                t.handleStockInTimeDateChange
                                            }
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="厚度">
                                        <Input
                                            style={{ width: "100%" }}
                                            type="number"
                                            placeholder="请输入厚度"
                                            value={filterData.thickness}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "thickness"
                                            )}
                                        />
                                    </FormItem>
                                </Col>

                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="宽度">
                                        <InputGroup compact={true}>
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最小值"
                                                value={filterData.minWidth}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "minWidth"
                                                )}
                                            />
                                            <Input
                                                style={{
                                                    width: "16%",
                                                    textAlign: "center",
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: "none"
                                                }}
                                                placeholder="~"
                                                disabled={true}
                                            />
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最大值"
                                                value={filterData.maxWidth}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "maxWidth"
                                                )}
                                            />
                                        </InputGroup>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="出库日期">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            size={"middle"}
                                            format={dateFormat}
                                            value={Util.getMomentArray(
                                                filterData.stockOutTimeStartDate,
                                                filterData.stockOutTimeEndDate
                                            )}
                                            onChange={
                                                t.handleStockOutTimeDateChange
                                            }
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                        />
                                    </FormItem>
                                </Col>
                                {showBundleGrades ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="等级">
                                            <Select
                                                allowClear={true}
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                optionFilterProp="children"
                                                placeholder="请选择等级"
                                                value={filterData.gradeIds}
                                                onChange={t.handleChange.bind(
                                                    t,
                                                    "gradeIds"
                                                )}
                                            >
                                                {gradeOptions}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                ) : null}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="生产状态">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            placeholder="请选择生产状态"
                                            optionFilterProp="children"
                                            value={
                                                filterData.manufacturingStates
                                            }
                                            onChange={t.handleChange.bind(
                                                t,
                                                "manufacturingStates"
                                            )}
                                        >
                                            {msOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="是否重组">
                                        <Select
                                            allowClear={true}
                                            style={{ width: "100%" }}
                                            placeholder="请选择是否重组"
                                            value={filterData.reorganized}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "reorganized"
                                            )}
                                        >
                                            {reorganizedOptions}
                                        </Select>
                                    </FormItem>
                                </Col>

                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="大板类型">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择大板类型"
                                            value={filterData.slabTypes}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabTypes"
                                            )}
                                        >
                                            {s.slabTypeOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                {s.uniqueWarehouse ? null : (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="仓库">
                                            <Cascader
                                                style={{ width: "100%" }}
                                                value={s.selectWarehouse}
                                                options={s.warehouseOptions}
                                                placeholder="选择仓库"
                                                onChange={
                                                    t.handleWarehouseChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                )}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="区域">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择区域"
                                            value={filterData.stockingAreaIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "stockingAreaIds"
                                            )}
                                        >
                                            {psOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                {s.enableBlockNumberFromQuarry ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="矿山荒料号">
                                            <Input
                                                placeholder="请输入矿山荒料号"
                                                value={
                                                    filterData.selectBlockNumberFromQuarry
                                                }
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "selectBlockNumberFromQuarry"
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                ) : null}
                            </Row>
                            <SearchButtonGroup
                                buttonDisabled={s.buttonDisabled}
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ margin: "1%", textAlign: "right" }}>
                    {PermCtrl.isAuthorized(Perm.S_E) ? (
                        <Button
                            disabled={s.buttonDisabled}
                            onClick={t.handleExportClick}
                        >
                            导出
                        </Button>
                    ) : null}
                    <Dropdown
                        overlay={t.getColumnSettings()}
                        trigger={["click"]}
                        onVisibleChange={t.handleVisibleChange}
                        visible={s.visible}
                    >
                        <Button>
                            更多 <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
                <div>
                    <Table
                        columns={Util.getTableColumns(
                            s.columnsInfo,
                            t.componentName
                        )}
                        dataSource={sbList}
                        rowKey={"id"}
                        pagination={false}
                        onChange={t.handleSorterChange}
                        expandedRowRender={t.showSlabsTable}
                        onExpand={t.handleRowExpanded}
                        expandedRowKeys={s.bundleExpandedArr}
                    />
                </div>
                {Store.pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={Store.pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString}
                            pageSize={Store.pagingInfo.pageSize}
                            current={Store.pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </TabPane>
        );
    };

    private showSlabList = (label: string, key: string): JSX.Element => {
        const t = this;
        const s = t.state;
        const slabList = Store.slabList;
        const filterData = Store.filterData;
        const categoryOptions = s.categoryOptions;
        const gradeOptions = s.gradeOptions;
        const psOptions = s.psOptions;
        const checkBoxOptions = s.checkBoxOptions;
        const mmOptions = s.manufacturingMethodOptions;
        const msOptions = s.manufacturingStateOptions;
        const reorganizedOptions = s.reorganizedOptions;

        const showUnitPriceFilter =
            PermCtrl.isAuthorized(Perm.S_RP_R) && s.enableReferencePrice;
        return (
            <TabPane tab={label} key={key}>
                <Collapse defaultActiveKey={["2"]}>
                    <Panel header="高级搜索" key="2">
                        <Form
                            onFinish={t.handleSearch}
                            {...formItemLayoutForSearch}
                            ref={this.formRef}
                            name={"slabAdvancedSearchForm"}
                        >
                            <Row>
                                <Col span={24}>
                                    <FormItem
                                        {...formItemLayoutForCheckboxGroup}
                                        label="状态"
                                    >
                                        <CheckboxGroup
                                            options={checkBoxOptions}
                                            value={filterData.slabStatusCodes}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabStatusCodes"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                            </Row>
                            <Row>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="荒料编号">
                                        <Input
                                            placeholder="请输入荒料编号"
                                            value={filterData.slabBlockNumber}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "slabBlockNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                {showUnitPriceFilter ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="价格">
                                            <InputGroup compact={true}>
                                                <Input
                                                    type="number"
                                                    style={{
                                                        width: "42%",
                                                        textAlign: "center"
                                                    }}
                                                    placeholder="最低价"
                                                    value={
                                                        filterData.slabMinUnitPrice
                                                    }
                                                    onChange={t.handleInputChange.bind(
                                                        t,
                                                        "slabMinUnitPrice"
                                                    )}
                                                />
                                                <Input
                                                    style={{
                                                        width: "16%",
                                                        textAlign: "center",
                                                        borderLeft: 0,
                                                        borderRight: 0,
                                                        pointerEvents: "none"
                                                    }}
                                                    placeholder="~"
                                                    disabled={true}
                                                />
                                                <Input
                                                    style={{
                                                        width: "42%",
                                                        textAlign: "center"
                                                    }}
                                                    type="number"
                                                    placeholder="最高价"
                                                    value={
                                                        filterData.slabMaxUnitPrice
                                                    }
                                                    onChange={t.handleInputChange.bind(
                                                        t,
                                                        "slabMaxUnitPrice"
                                                    )}
                                                />
                                            </InputGroup>
                                        </FormItem>
                                    </Col>
                                ) : null}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="新扎编号">
                                        <Input
                                            placeholder="请输入新扎编号"
                                            value={filterData.slabBundlePrefix}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "slabBundlePrefix"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="大板序号">
                                        <Input
                                            placeholder="请输入大板序号"
                                            value={
                                                filterData.slabSequenceNumber
                                            }
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "slabSequenceNumber"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="长度">
                                        <InputGroup compact={true}>
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最小值"
                                                value={filterData.slabMinLength}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "slabMinLength"
                                                )}
                                            />
                                            <Input
                                                style={{
                                                    width: "16%",
                                                    textAlign: "center",
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: "none"
                                                }}
                                                placeholder="~"
                                                disabled={true}
                                            />
                                            <Input
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                type="number"
                                                placeholder="最大值"
                                                value={filterData.slabMaxLength}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "slabMaxLength"
                                                )}
                                            />
                                        </InputGroup>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="入库日期">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            size={"middle"}
                                            format={dateFormat}
                                            value={Util.getMomentArray(
                                                filterData.slabStockInTimeStartDate,
                                                filterData.slabStockInTimeEndDate
                                            )}
                                            onChange={
                                                t.handleStockInTimeDateChange
                                            }
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="石材种类">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择石材种类"
                                            value={filterData.slabCategoryIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabCategoryIds"
                                            )}
                                        >
                                            {categoryOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="宽度">
                                        <InputGroup compact={true}>
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最小值"
                                                value={filterData.slabMinWidth}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "slabMinWidth"
                                                )}
                                            />
                                            <Input
                                                style={{
                                                    width: "16%",
                                                    textAlign: "center",
                                                    borderLeft: 0,
                                                    borderRight: 0,
                                                    pointerEvents: "none"
                                                }}
                                                placeholder="~"
                                                disabled={true}
                                            />
                                            <Input
                                                type="number"
                                                style={{
                                                    width: "42%",
                                                    textAlign: "center"
                                                }}
                                                placeholder="最大值"
                                                value={filterData.slabMaxWidth}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "slabMaxWidth"
                                                )}
                                            />
                                        </InputGroup>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="出库日期">
                                        <RangePicker
                                            size={"middle"}
                                            style={{ width: "100%" }}
                                            format={dateFormat}
                                            value={Util.getMomentArray(
                                                filterData.slabStockOutTimeStartDate,
                                                filterData.slabStockOutTimeEndDate
                                            )}
                                            onChange={
                                                t.handleStockOutTimeDateChange
                                            }
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="厚度">
                                        <Input
                                            style={{ width: "100%" }}
                                            type="number"
                                            placeholder="请输入厚度"
                                            value={filterData.slabThickness}
                                            onChange={t.handleInputChange.bind(
                                                t,
                                                "slabThickness"
                                            )}
                                        />
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="生产状态">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            placeholder="请选择生产状态"
                                            optionFilterProp="children"
                                            value={
                                                filterData.slabManufacturingStates
                                            }
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabManufacturingStates"
                                            )}
                                        >
                                            {msOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="补磨方式">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            placeholder="请选择补磨方式"
                                            optionFilterProp="children"
                                            value={
                                                filterData.slabManufacturingMethods
                                            }
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabManufacturingMethods"
                                            )}
                                        >
                                            {mmOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="等级">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择等级"
                                            value={filterData.slabGradeIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabGradeIds"
                                            )}
                                        >
                                            {gradeOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="是否重组">
                                        <Select
                                            allowClear={true}
                                            style={{ width: "100%" }}
                                            placeholder="请选择是否重组"
                                            value={filterData.slabReorganized}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabReorganized"
                                            )}
                                        >
                                            {reorganizedOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="是否散板">
                                        <Select
                                            allowClear={true}
                                            style={{ width: "100%" }}
                                            placeholder="请选择是否散板"
                                            value={
                                                filterData.slabShowScatteredSlab
                                            }
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabShowScatteredSlab"
                                            )}
                                        >
                                            {reorganizedOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="大板类型">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="选择大板类型"
                                            value={filterData.slabSLTypes}
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabSLTypes"
                                            )}
                                        >
                                            {s.slabTypeOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                {s.uniqueWarehouse ? null : (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="仓库">
                                            <Cascader
                                                style={{ width: "100%" }}
                                                value={s.slabSelectWarehouse}
                                                options={s.warehouseOptions}
                                                placeholder="请选择仓库"
                                                onChange={
                                                    t.handleWarehouseChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                )}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="区域">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择区域"
                                            value={
                                                filterData.slabStockingAreaIds
                                            }
                                            onChange={t.handleChange.bind(
                                                t,
                                                "slabStockingAreaIds"
                                            )}
                                        >
                                            {psOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                {s.enableBlockNumberFromQuarry ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="矿山荒料号">
                                            <Input
                                                placeholder="请输入矿山荒料号"
                                                value={
                                                    filterData.slabBlockNumberFromQuarry
                                                }
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "slabBlockNumberFromQuarry"
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                ) : null}
                            </Row>

                            <SearchButtonGroup
                                buttonDisabled={s.buttonDisabled}
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div style={{ margin: "1%", textAlign: "right" }}>
                    {PermCtrl.isAuthorized(Perm.S_E) ? (
                        <Button
                            disabled={s.buttonDisabled}
                            onClick={t.handleExportClick}
                        >
                            导出
                        </Button>
                    ) : null}
                    <Dropdown
                        overlay={t.getColumnSettings()}
                        trigger={["click"]}
                        onVisibleChange={t.handleSlabVisibleChange}
                        visible={s.slabVisible}
                    >
                        <Button>
                            更多 <DownOutlined />
                        </Button>
                    </Dropdown>
                </div>
                <div>
                    <Table
                        columns={Util.getTableColumns(
                            s.slabColumnsInfo,
                            t.slabComponentName
                        )}
                        dataSource={slabList}
                        rowKey={"id"}
                        pagination={false}
                        onChange={t.handleSorterChange}
                    />
                </div>
                {Store.pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={Store.pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString}
                            pageSize={Store.pagingInfo.pageSize}
                            current={Store.pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </TabPane>
        );
    };

    private getTotalRecordString = (totalCount: number): string => {
        return Store.stoneArtifactType === StoneArtifactType.StoneBundle
            ? `共 ${totalCount} 扎 共 ${Store.totalArea} m²`
            : `共 ${totalCount} 片 共 ${Store.totalArea} m²`;
    };

    // 显示扎下的大板信息
    private showSlabsTable = stoneBundle => {
        const t = this;
        const s = t.state;
        if (stoneBundle.slabExist && !stoneBundle.slabs) {
            return (
                <div className="importingSpinArea">
                    <p>正在加载数据，请等待...</p>
                    <Spin />
                </div>
            );
        } else {
            const slabs = stoneBundle.slabs;
            let columns: any = [
                {
                    title: "荒料编号",
                    dataIndex: "blockNumber",
                    key: "blockNumber",
                    align: "center"
                },
                {
                    title: "大板序号",
                    dataIndex: "sequenceNumber",
                    key: "sequenceNumber",
                    align: "center"
                },
                {
                    title: "等级",
                    dataIndex: "gradeId",
                    key: "gradeId",
                    align: "center",
                    render: gradeId => {
                        return Util.getItemName(gradeId, s.gradeList);
                    }
                },
                {
                    title: "厚度",
                    dataIndex: "thickness",
                    key: "thickness",
                    align: "center"
                }
            ];

            if (
                PermCtrl.isAuthorized(Perm.S_RP_R) &&
                Settings.getBoolValue(
                    SettingsDefinition.EnableReferencePriceKey
                )
            ) {
                columns.push({
                    title: "价格",
                    dataIndex: "unitPrice",
                    key: "UnitPrice",
                    align: "center",
                    viewingPermissions: [Perm.S_RP_R],
                    disabled: !Settings.getBoolValue(
                        SettingsDefinition.EnableReferencePriceKey
                    ),
                    render: unitPrice =>
                        UnitPriceUtil.showUnitPriceJSX(unitPrice)
                });
            }
            columns = [
                ...columns,

                {
                    title: "尺寸(mm)",
                    key: "size",
                    align: "center",
                    render: slab => {
                        return Util.getSlabSpec(slab);
                    }
                },
                {
                    title: "扣尺(mm)",
                    key: "deducted",
                    align: "center",
                    render: slab => {
                        return Util.getSlabDeductedSpec(slab);
                    }
                },
                {
                    title: "面积(m²)",
                    key: "slab",
                    align: "center",
                    render: slab => {
                        return Util.calculateAreaForSlab(slab);
                    }
                },
                {
                    title: "类型",
                    dataIndex: "type",
                    key: "type",
                    align: "center",
                    render: type => {
                        return SlabType.getType(type).text;
                    }
                },
                {
                    title: "补磨方式",
                    dataIndex: "manufacturingMethod",
                    key: "manufacturingMethod",
                    align: "center",
                    render: manufacturingMethod => {
                        return ManufacturingMethod.getManufacturingMethod(
                            manufacturingMethod
                        ).text;
                    }
                },
                {
                    title: "状态",
                    dataIndex: "status",
                    key: "status",
                    align: "center",
                    render: status => {
                        return SlabBundleStatus.getStatus(status).text;
                    }
                },
                {
                    title: "加工工艺",
                    dataIndex: "appliedProcessesString",
                    key: "appliedProcessesString",
                    align: "center"
                },
                {
                    title: "备注",
                    key: "note",
                    align: "center",
                    render: slab => {
                        return Util.getNote(slab);
                    }
                }
            ];

            return (
                <div>
                    <Table
                        style={{ background: "#f0fdff", marginLeft: "30px" }}
                        rowKey="id"
                        size="middle"
                        columns={columns}
                        dataSource={slabs}
                        pagination={false}
                    />
                </div>
            );
        }
    };

    private loadData = async () => {
        await Store.reloadData();
    };

    // 跳到第page页
    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);
        await this.loadData();
        if (Store.stoneArtifactType === StoneArtifactType.StoneBundle) {
            this.setState({ bundleExpandedArr: [] });
        }
    };

    private handleRowExpanded = (expanded: boolean, stoneBundle) => {
        const t = this;
        const s = t.state;

        const expandedArr = Util.updateExpandedArray(
            s.bundleExpandedArr,
            stoneBundle.id,
            expanded
        );
        t.setState({
            bundleExpandedArr: expandedArr
        });
        if (expanded && stoneBundle.slabExist && !stoneBundle.slabs) {
            t.loadSlabs(stoneBundle.id);
        }
    };

    private async loadSlabs(stoneBundleId: number) {
        await Store.loadSlabs(stoneBundleId);
    }

    private handleClearSearchCriteria = async () => {
        const t = this;
        const s = t.state;
        let p: IFilterData = {};
        let componentName = null;
        let newState = null;
        const psOptions = StockingAreaUtil.getStockingAreaOptions(
            s.uniqueWarehouse,
            null,
            s.psList,
            s.warehouseOptions
        );
        switch (Store.stoneArtifactType) {
            case StoneArtifactType.StoneBundle:
                componentName = this.componentName;
                p = {
                    selectPrefix: undefined,
                    categoryIds: [],
                    gradeIds: [],
                    manufacturingMethods: [],
                    stockingAreaIds: [],
                    statusCodes: [],
                    maxLength: undefined,
                    minLength: undefined,
                    maxWidth: undefined,
                    minWidth: undefined,
                    maxUnitPrice: undefined,
                    minUnitPrice: undefined,
                    thickness: undefined,
                    reorganized: undefined,
                    slabTypes: [],
                    selectBlockNumberFromQuarry: undefined,
                    stockInTimeStartDate: undefined,
                    stockInTimeEndDate: undefined,
                    stockOutTimeStartDate: undefined,
                    stockOutTimeEndDate: undefined,
                    manufacturingStates: [],
                    warehouseId: undefined
                };

                newState = {
                    psOptions,
                    bundleExpandedArr: [],
                    selectWarehouse: []
                };

                break;
            case StoneArtifactType.Slab:
                componentName = this.slabComponentName;
                p = {
                    slabCategoryIds: [],
                    slabGradeIds: [],
                    slabStatusCodes: [],
                    slabThickness: undefined,
                    slabBundlePrefix: undefined,
                    slabBlockNumberFromQuarry: undefined,
                    slabBlockNumber: undefined,
                    slabMaxLength: undefined,
                    slabMinLength: undefined,
                    slabMaxWidth: undefined,
                    slabMinWidth: undefined,
                    slabMaxUnitPrice: undefined,
                    slabMinUnitPrice: undefined,
                    slabSequenceNumber: undefined,
                    slabOriginalSequenceNumber: undefined,
                    slabShowScatteredSlab: undefined,
                    slabStockingAreaIds: [],
                    slabStockInTimeEndDate: undefined,
                    slabStockOutTimeStartDate: undefined,
                    slabStockOutTimeEndDate: undefined,
                    slabReorganized: undefined,
                    slabSLTypes: [],
                    slabManufacturingMethods: [],
                    slabManufacturingStates: [],
                    slabWarehouseId: undefined
                };
                newState = {
                    psOptions,
                    slabSelectWarehouse: []
                };
                break;
        }

        ColumnSettings.setSortSettings(componentName, null);
        Store.setPageNo(1);
        Store.setPageSize(10);
        Store.updataFilterData({ ...Store.filterData, ...p });
        await this.loadData();
        this.setState(newState);
    };

    private handleSearch = async () => {
        const t = this;
        const valid = t.validate();
        if (!valid) {
            return;
        }
        Store.setPageNo(1);
        await this.loadData();
        if (Store.stoneArtifactType === StoneArtifactType.StoneBundle) {
            t.setState({ bundleExpandedArr: [] });
        }
    };

    private validate(): boolean {
        const t = this;
        const s = t.state;
        const filterData = Store.filterData;
        const isStoneBundle =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle;
        let valid = validator.range(
            isStoneBundle ? filterData.minLength : filterData.slabMinLength,
            300,
            5000,
            "最小长度"
        );
        valid =
            valid &&
            validator.range(
                isStoneBundle ? filterData.maxLength : filterData.slabMaxLength,
                300,
                5000,
                "最大长度"
            );
        valid =
            valid &&
            validator.range(
                isStoneBundle ? filterData.minWidth : filterData.slabMinWidth,
                300,
                5000,
                "最小宽度"
            );
        valid =
            valid &&
            validator.range(
                isStoneBundle ? filterData.maxWidth : filterData.slabMaxWidth,
                300,
                5000,
                "最大宽度"
            );
        valid =
            valid &&
            validator.range(
                isStoneBundle ? filterData.thickness : filterData.slabThickness,
                10,
                500,
                "厚度"
            );
        if (isStoneBundle) {
            valid =
                valid &&
                validator.range(
                    filterData.maxUnitPrice,
                    Util.isNotNullAndNotEmpty(filterData.minUnitPrice)
                        ? filterData.minUnitPrice
                        : 0,
                    10000,
                    "最高价"
                );
            valid =
                valid &&
                validator.range(
                    filterData.minUnitPrice,
                    0,
                    Util.isNotNullAndNotEmpty(filterData.maxUnitPrice)
                        ? filterData.maxUnitPrice
                        : 10000,
                    "最低价"
                );
        } else {
            valid =
                valid &&
                validator.range(
                    filterData.slabMaxUnitPrice,
                    Util.isNotNullAndNotEmpty(filterData.slabMinUnitPrice)
                        ? filterData.slabMinUnitPrice
                        : 0,
                    10000,
                    "最高价"
                );
            valid =
                valid &&
                validator.range(
                    filterData.slabMinUnitPrice,
                    0,
                    Util.isNotNullAndNotEmpty(filterData.slabMaxUnitPrice)
                        ? filterData.slabMaxUnitPrice
                        : 10000,
                    "最低价"
                );
        }
        return valid;
    }

    private handleVisibleChange = () => {
        this.setState({ visible: !this.state.visible });
    };

    private handleSlabVisibleChange = () => {
        this.setState({ slabVisible: !this.state.slabVisible });
    };

    private handleInputChange = (label: string, event) => {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    };

    private handleChange = (label: string, value) => {
        Store.updataFilterData({ ...Store.filterData, [label]: value });
    };

    private handSelectColumnsChange = (label: string, value: boolean) => {
        const t = this;
        const s = t.state;
        const isStoneBundle =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle;
        const columnsInfo = isStoneBundle ? s.columnsInfo : s.slabColumnsInfo;
        const componentName = isStoneBundle
            ? t.componentName
            : t.slabComponentName;
        const visibleColumns: string[] = Util.getVisibleColumns(
            columnsInfo,
            componentName
        );

        if (value) {
            visibleColumns.push(label);
        } else {
            const index = visibleColumns.indexOf(label);
            visibleColumns.splice(index, 1);
        }

        ColumnSettings.setVisibleColumns(componentName, visibleColumns);
        t.setState(s);
    };

    private handleCheckAllChange = (checkAll: boolean) => {
        const t = this;
        const s = t.state;
        const isStoneBundle =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle;
        const columnsInfo = isStoneBundle ? s.columnsInfo : s.slabColumnsInfo;
        const componentName = isStoneBundle
            ? t.componentName
            : t.slabComponentName;

        Util.checkAllChange(columnsInfo, componentName, checkAll);
        t.setState(s);
    };

    // 选择要显示的列
    private getColumnSettings(): JSX.Element {
        const t = this;
        const s = t.state;
        const isStoneBundle =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle;
        const columnsInfo = isStoneBundle ? s.columnsInfo : s.slabColumnsInfo;
        const componentName = isStoneBundle
            ? t.componentName
            : t.slabComponentName;
        const visibleColumns = Util.getVisibleColumns(
            columnsInfo,
            componentName
        );

        const checkAll: boolean = Util.judgeCheckAll(
            columnsInfo,
            visibleColumns
        );

        return (
            <div style={{ width: "100%", background: "#FFFFFF" }}>
                <Checkbox
                    onChange={t.handleCheckAllChange.bind(t, checkAll)}
                    checked={checkAll}
                >
                    全选
                </Checkbox>
                <br />
                {columnsInfo && columnsInfo.length > 0 && visibleColumns
                    ? columnsInfo.map((ci, index) => {
                          let canView = true; // 当前用户的角色是否有权限查看该字段
                          const visible = visibleColumns.some(
                              c => c === ci.title
                          );
                          if (
                              ci.viewingPermissions &&
                              ci.viewingPermissions.length > 0
                          ) {
                              canView = PermCtrl.isAnyAuthorized(
                                  ci.viewingPermissions
                              );
                          }

                          if (
                              canView &&
                              (typeof ci.disabled === "undefined" ||
                                  !ci.disabled)
                          ) {
                              return (
                                  <div key={index}>
                                      <Checkbox
                                          checked={visible}
                                          disabled={ci.alwaysShow}
                                          onChange={t.handSelectColumnsChange.bind(
                                              t,
                                              ci.title,
                                              !visible
                                          )}
                                      >
                                          {ci.title}
                                      </Checkbox>
                                  </div>
                              );
                          }
                      })
                    : null}
            </div>
        );
    }

    // 处理排序信息
    private handleSorterChange = async (_1, _2, sorter) => {
        const t = this;
        const s = t.state;
        const isStoneBundle =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle;
        const componentName = isStoneBundle
            ? t.componentName
            : t.slabComponentName;
        Util.updateSortSettings(sorter, componentName);
        Store.setPageNo(1);
        Store.setPageSize(10);
        await t.loadData();
    };

    private getItemName = (itemId: number, listName: string): string => {
        const t = this;
        const s = t.state;
        const list = s[listName];
        return Util.getItemName(itemId, list);
    };

    private handleWarehouseChange = value => {
        const t = this;
        const s = t.state;
        const psOptions = StockingAreaUtil.getStockingAreaOptions(
            s.uniqueWarehouse,
            parseInt(value[1]),
            s.psList,
            s.warehouseOptions
        );

        let p: IFilterData = {};
        let newState = null;
        switch (Store.stoneArtifactType) {
            case StoneArtifactType.StoneBundle:
                p = { warehouseId: value[1], stockingAreaIds: [] };
                newState = { selectWarehouse: value, psOptions };

                break;
            case StoneArtifactType.Slab:
                p = { slabWarehouseId: value[1], slabStockingAreaIds: [] };
                newState = { slabSelectWarehouse: value, psOptions };
                break;
        }

        t.setState(newState);
        Store.updataFilterData({ ...Store.filterData, ...p });
    };

    private getShowStockingArea(itemId) {
        const t = this;
        const s = t.state;

        const stockingArea = s.psList.find(b => b.id === itemId);
        if (!stockingArea) {
            return;
        }

        return StockingAreaUtil.getShowText(
            s.uniqueWarehouse,
            stockingArea,
            s.warehouseOptions
        );
    }

    private setButtonEnable(time) {
        if (!Util.isDefinedAndNotNull(time)) {
            return;
        }

        setTimeout(
            // 设置定时器，由于导出数据量可能较大，设置指定时间后再恢复点亮Button
            () => {
                this.setState({ buttonDisabled: false });
            },
            time * 1000
        );
    }

    private handleExportClick = async () => {
        const t = this;
        const valid = t.validate();
        if (!valid) {
            return;
        }

        Store.setPageNo(1);
        await Store.reloadData();
        if (!Store.hasError) {
            let lable = null;
            let exportFunc = null;
            let newState = {};
            let time: number = 0;
            const listLength: number = Store.pagingInfo.totalRecordCount;
            switch (Store.stoneArtifactType) {
                case StoneArtifactType.StoneBundle:
                    lable = "stoneBundleList";
                    exportFunc = t.exportBundles;
                    newState = { bundleExpandedArr: [] };

                    // 导出扎根据实际测试，按数据量大小设置不同时间
                    // 小于10000，设置5s;
                    // 大于10000，小于20000，设置10s;
                    // 大于20000，设置30s;
                    time =
                        listLength < 10000 ? 5 : listLength < 20000 ? 10 : 30;
                    break;
                case StoneArtifactType.Slab:
                    lable = "slabList";
                    exportFunc = t.exportSlabs;

                    // 导出大板根据实际测试，按数据量大小设置不同时间
                    // 小于10000，设置10s;
                    // 大于10000，小于50000，设置30s;
                    // 大于50000，小于100000，设置50s;
                    // 大于100000，小于150000，设置70s;
                    // 大于150000，小于200000，设置120s;
                    // 大于200000，设置180s
                    time =
                        listLength < 10000
                            ? 10
                            : listLength < 50000
                            ? 30
                            : listLength < 100000
                            ? 50
                            : listLength < 150000
                            ? 70
                            : listLength < 200000
                            ? 120
                            : 180;
                    break;
            }
            if (
                lable &&
                exportFunc &&
                Store[lable] &&
                Store[lable].length &&
                Store[lable].length > 0
            ) {
                // 为防止导出数据量较大和多用户同时访问操作时，导致服务器性能降低的现象，每次点击导出数据后，将会按不同时长disabled Button
                newState = { ...newState, buttonDisabled: true };
                t.setState(newState);
                if (time > 0) {
                    t.setButtonEnable(time);
                }

                exportFunc();
            } else {
                message.error("指定的导出条件没有数据！");
            }
        }
    };

    private exportBundles = () => {
        const filterData = Store.filterData;

        // 因为Select控件的value的数据类型是string | number，并不支持boolean，所以调用后台接口时需转换reorganized的值类型
        const reorganized = Util.isDefinedAndNotNull(filterData.reorganized)
            ? filterData.reorganized === "10"
                ? true
                : false
            : null;
        let printAPI: string = "StoneBundle/Export?reorganized=" + reorganized;

        if (Util.isNotNullAndNotEmpty(filterData.selectPrefix)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "bundlePrefix",
                filterData.selectPrefix
            );
        }

        if (
            filterData.statusCodes &&
            filterData.statusCodes.length &&
            filterData.statusCodes.length > 0
        ) {
            filterData.statusCodes.forEach(statusCode => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "statusCodes",
                    statusCode
                );
            });
        }

        if (
            filterData.categoryIds &&
            filterData.categoryIds.length &&
            filterData.categoryIds.length > 0
        ) {
            filterData.categoryIds.forEach(categoryId => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "categoryIds",
                    categoryId
                );
            });
        }

        if (
            filterData.gradeIds &&
            filterData.gradeIds.length &&
            filterData.gradeIds.length > 0
        ) {
            filterData.gradeIds.forEach(gradeId => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "gradeIds",
                    gradeId
                );
            });
        }

        if (
            filterData.stockingAreaIds &&
            filterData.stockingAreaIds.length &&
            filterData.stockingAreaIds.length > 0
        ) {
            filterData.stockingAreaIds.forEach(stockingAreaId => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "stockingAreaIds",
                    stockingAreaId
                );
            });
        }

        if (
            filterData.slabTypes &&
            filterData.slabTypes.length &&
            filterData.slabTypes.length > 0
        ) {
            filterData.slabTypes.forEach(slabType => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "slabTypes",
                    slabType
                );
            });
        }

        if (
            filterData.manufacturingMethods &&
            filterData.manufacturingMethods.length &&
            filterData.manufacturingMethods.length > 0
        ) {
            filterData.manufacturingMethods.forEach(mm => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "manufacturingMethods",
                    mm
                );
            });
        }

        if (
            filterData.manufacturingStates &&
            filterData.manufacturingStates.length &&
            filterData.manufacturingStates.length > 0
        ) {
            filterData.manufacturingStates.forEach(ms => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "manufacturingStates",
                    ms
                );
            });
        }

        if (Util.isNotNullAndNotEmpty(filterData.maxLength)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "maxLength",
                filterData.maxLength
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.minLength)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "minLength",
                filterData.minLength
            );
        }
        if (Util.isNotNullAndNotEmpty(filterData.minWidth)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "minWidth",
                filterData.minWidth
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.maxWidth)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "maxWidth",
                filterData.maxWidth
            );
        }
        if (Util.isNotNullAndNotEmpty(filterData.minUnitPrice)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "minUnitPrice",
                filterData.minUnitPrice
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.maxUnitPrice)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "maxUnitPrice",
                filterData.maxUnitPrice
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.thickness)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "thickness",
                filterData.thickness
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.warehouseId)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "warehouseIds",
                filterData.warehouseId
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.selectBlockNumberFromQuarry)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "blockNumberFromQuarry",
                filterData.selectBlockNumberFromQuarry
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.stockInTimeStartDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockInTimeStartDate",
                filterData.stockInTimeStartDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.stockInTimeEndDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockInTimeEndDate",
                filterData.stockInTimeEndDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.stockOutTimeStartDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockOutTimeStartDate",
                filterData.stockOutTimeStartDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.stockOutTimeEndDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockOutTimeEndDate",
                filterData.stockOutTimeEndDate
            );
        }

        message.info("正在导出指定的扎列表数据...");
        ExportUtil.setExportUrl(printAPI);
    };

    private handleStockInTimeDateChange = (dates, dateStrings) => {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];

        const p: IFilterData =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle
                ? {
                      stockInTimeStartDate: startDate,
                      stockInTimeEndDate: endDate
                  }
                : {
                      slabStockInTimeStartDate: startDate,
                      slabStockInTimeEndDate: endDate
                  };

        Store.updataFilterData({ ...Store.filterData, ...p });
    };

    private handleStockOutTimeDateChange = (dates, dateStrings) => {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        const p: IFilterData =
            Store.stoneArtifactType === StoneArtifactType.StoneBundle
                ? {
                      stockOutTimeStartDate: startDate,
                      stockOutTimeEndDate: endDate
                  }
                : {
                      slabStockOutTimeStartDate: startDate,
                      slabStockOutTimeEndDate: endDate
                  };

        Store.updataFilterData({ ...Store.filterData, ...p });
    };

    private exportSlabs = () => {
        const filterData = Store.filterData;

        // 因为Select控件的value的数据类型是string | number，并不支持boolean，所以调用后台接口时需转换reorganized和showScatteredSlab的值类型
        const reorganized = Util.isDefinedAndNotNull(filterData.slabReorganized)
            ? filterData.slabReorganized === "10"
                ? true
                : false
            : null;
        const showScatteredSlab = Util.isDefinedAndNotNull(
            filterData.slabShowScatteredSlab
        )
            ? filterData.slabShowScatteredSlab === "10"
                ? true
                : false
            : null;

        let printAPI: string = "Slab/Export?reorganized=" + reorganized;

        if (Util.isDefinedAndNotNull(showScatteredSlab)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "showScatteredSlab",
                showScatteredSlab
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabBundlePrefix)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "bundlePrefix",
                filterData.slabBundlePrefix
            );
        }

        if (
            filterData.slabStatusCodes &&
            filterData.slabStatusCodes.length &&
            filterData.slabStatusCodes.length > 0
        ) {
            filterData.slabStatusCodes.forEach(statusCode => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "statusCodes",
                    statusCode
                );
            });
        }

        if (
            filterData.slabCategoryIds &&
            filterData.slabCategoryIds.length &&
            filterData.slabCategoryIds.length > 0
        ) {
            filterData.slabCategoryIds.forEach(categoryId => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "categoryIds",
                    categoryId
                );
            });
        }

        if (
            filterData.slabGradeIds &&
            filterData.slabGradeIds.length &&
            filterData.slabGradeIds.length > 0
        ) {
            filterData.slabGradeIds.forEach(gradeId => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "gradeIds",
                    gradeId
                );
            });
        }

        if (
            filterData.slabStockingAreaIds &&
            filterData.slabStockingAreaIds.length &&
            filterData.slabStockingAreaIds.length > 0
        ) {
            filterData.slabStockingAreaIds.forEach(stockingAreaId => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "stockingAreaIds",
                    stockingAreaId
                );
            });
        }

        if (
            filterData.slabSLTypes &&
            filterData.slabSLTypes.length &&
            filterData.slabSLTypes.length > 0
        ) {
            filterData.slabSLTypes.forEach(slabType => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "slabTypes",
                    slabType
                );
            });
        }

        if (
            filterData.slabManufacturingMethods &&
            filterData.slabManufacturingMethods.length &&
            filterData.slabManufacturingMethods.length > 0
        ) {
            filterData.slabManufacturingMethods.forEach(mm => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "manufacturingMethods",
                    mm
                );
            });
        }

        if (
            filterData.slabManufacturingStates &&
            filterData.slabManufacturingStates.length &&
            filterData.slabManufacturingStates.length > 0
        ) {
            filterData.slabManufacturingStates.forEach(ms => {
                printAPI += ExportUtil.addParameter(
                    printAPI,
                    "manufacturingStates",
                    ms
                );
            });
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabMaxLength)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "maxLength",
                filterData.slabMaxLength
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabMinLength)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "minLength",
                filterData.slabMinLength
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabMinWidth)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "minWidth",
                filterData.slabMinWidth
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabMaxWidth)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "maxWidth",
                filterData.slabMaxWidth
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabMinUnitPrice)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "minUnitPrice",
                filterData.slabMinUnitPrice
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabMaxUnitPrice)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "maxUnitPrice",
                filterData.slabMaxUnitPrice
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabThickness)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "thickness",
                filterData.slabThickness
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabWarehouseId)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "warehouseIds",
                filterData.slabWarehouseId
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabBlockNumberFromQuarry)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "blockNumberFromQuarry",
                filterData.slabBlockNumberFromQuarry
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabBlockNumber)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "blockNumber",
                filterData.slabBlockNumber
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabStockInTimeStartDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockInTimeStartDate",
                filterData.slabStockInTimeStartDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabStockInTimeEndDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockInTimeEndDate",
                filterData.slabStockInTimeEndDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabStockOutTimeStartDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockOutTimeStartDate",
                filterData.slabStockOutTimeStartDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabStockOutTimeEndDate)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "stockOutTimeEndDate",
                filterData.slabStockOutTimeEndDate
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabSequenceNumber)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "sequenceNumber",
                filterData.slabSequenceNumber
            );
        }

        if (Util.isNotNullAndNotEmpty(filterData.slabOriginalSequenceNumber)) {
            printAPI += ExportUtil.addParameter(
                printAPI,
                "originalSequenceNumber",
                filterData.slabOriginalSequenceNumber
            );
        }

        message.info("正在导出指定的大板列表数据...");
        ExportUtil.setExportUrl(printAPI);
    };

    private getStoneBundleColumns = (): ITableColumnInfo[] => {
        return [
            {
                title: "荒料编号",
                dataIndex: "prefix",
                key: "Prefix",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: true
            },
            {
                title: "矿山荒料号",
                dataIndex: "blockNumberFromQuarry",
                key: "BlockNumberFromQuarry",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: false,
                disabled: !Settings.getBoolValue(
                    SettingsDefinition.EnableBlockNumberFromQuarryKey
                )
            },
            {
                title: "扎号",
                key: "bundleNo",
                align: "center",
                showByDefault: true,
                alwaysShow: true,
                render: bundle => {
                    return bundle.totalBundleCount + "-" + bundle.bundleNo;
                }
            },
            {
                title: "品名",
                dataIndex: "categoryId",
                key: "categoryId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: categoryId =>
                    this.getItemName(categoryId, "categoryList")
            },
            {
                title: "厚度",
                dataIndex: "thickness",
                key: "Thickness",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "等级",
                dataIndex: "gradeId",
                key: "GradeId",
                align: "center",
                showByDefault: false,
                alwaysShow: false,
                disabled: !PermCtrl.isAuthorized(Perm.SB_G_R),
                render: gradeId => this.getItemName(gradeId, "gradeList")
            },
            {
                title: "价格",
                dataIndex: "unitPrice",
                key: "UnitPrice",
                align: "center",
                sorter: true,
                showByDefault:
                    Settings.getBoolValue(
                        SettingsDefinition.EnableReferencePriceKey
                    ) && PermCtrl.isAuthorized(Perm.S_RP_R),
                alwaysShow: false,
                viewingPermissions: [Perm.S_RP_R],
                disabled: !Settings.getBoolValue(
                    SettingsDefinition.EnableReferencePriceKey
                ),
                render: unitPrice => UnitPriceUtil.showUnitPriceJSX(unitPrice)
            },
            {
                title: "长度",
                dataIndex: "lengthAfterStockIn",
                key: "LengthAfterStockIn",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "宽度",
                dataIndex: "widthAfterStockIn",
                key: "WidthAfterStockIn",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "总片数",
                dataIndex: "totalSlabCount",
                key: "totalSlabCount",
                align: "center",
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "面积",
                dataIndex: "area",
                key: "Area",
                sorter: true,
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "类型",
                dataIndex: "type",
                key: "type",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: type => {
                    return SlabType.getType(type).text;
                }
            },
            {
                title: "补磨方式",
                dataIndex: "manufacturingMethod",
                key: "manufacturingMethod",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: manufacturingMethod => {
                    return ManufacturingMethod.getManufacturingMethod(
                        manufacturingMethod
                    ).text;
                }
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: status => {
                    return SlabBundleStatus.getStatus(status).text;
                }
            },
            {
                title: "生产状态",
                dataIndex: "manufacturingState",
                key: "manufacturingState",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: ms => {
                    return ManufacturingState.getState(ms).text;
                }
            },
            {
                title: "入库日期",
                dataIndex: "stockInTime",
                key: "stockInTime",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: stockInTime => {
                    return Util.formatDate(stockInTime);
                }
            },
            {
                title: "出库日期",
                dataIndex: "stockOutTime",
                key: "stockOutTime",
                align: "center",
                showByDefault: false,
                alwaysShow: false,
                render: stockOutTime => {
                    return Util.formatDate(stockOutTime);
                }
            },
            {
                title: "区域",
                dataIndex: "stockingAreaId",
                key: "stockingAreaId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: stockingAreaId =>
                    this.getShowStockingArea(stockingAreaId)
            }
        ];
    };

    private getSlabColumns = (): ITableColumnInfo[] => {
        return [
            {
                title: "荒料编号",
                dataIndex: "blockNumber",
                key: "BlockNumber",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: true
            },
            {
                title: "新扎编号",
                dataIndex: "bundlePrefix",
                key: "BundlePrefix",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "矿山荒料号",
                dataIndex: "blockNumberFromQuarry",
                key: "BlockNumberFromQuarry",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false,
                disabled: !Settings.getBoolValue(
                    SettingsDefinition.EnableBlockNumberFromQuarryKey
                )
            },
            {
                title: "大板序号",
                dataIndex: "sequenceNumber",
                key: "SequenceNumber",
                sorter: true,
                align: "center",
                showByDefault: true,
                alwaysShow: true
            },
            {
                title: "大板原始序号",
                dataIndex: "originalSequenceNumber",
                key: "OriginalSequenceNumber",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "扎号",
                dataIndex: "bundleNumber",
                key: "bundleNumber",
                align: "center",
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "品名",
                dataIndex: "categoryId",
                key: "categoryId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: categoryId =>
                    this.getItemName(categoryId, "categoryList")
            },
            {
                title: "厚度",
                dataIndex: "thickness",
                key: "Thickness",
                align: "center",
                sorter: true,
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "等级",
                dataIndex: "gradeId",
                key: "gradeId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: gradeId => this.getItemName(gradeId, "gradeList")
            },
            {
                title: "价格",
                dataIndex: "unitPrice",
                key: "UnitPrice",
                align: "center",
                showByDefault:
                    Settings.getBoolValue(
                        SettingsDefinition.EnableReferencePriceKey
                    ) && PermCtrl.isAuthorized(Perm.S_RP_R),
                alwaysShow: false,
                sorter: true,
                viewingPermissions: [Perm.S_RP_R],
                disabled: !Settings.getBoolValue(
                    SettingsDefinition.EnableReferencePriceKey
                ),
                render: unitPrice => UnitPriceUtil.showUnitPriceJSX(unitPrice)
            },
            {
                title: "长度",
                key: "length",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: slab => Util.getSlabSize(slab).length
            },
            {
                title: "宽度",
                key: "width",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: slab => Util.getSlabSize(slab).width
            },
            {
                title: "扣尺长",
                key: "deductedLength",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: slab => Util.getSlabSize(slab).deductedLength
            },
            {
                title: "扣尺宽",
                key: "deductedWidth",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: slab => Util.getSlabSize(slab).deductedWidth
            },
            {
                title: "大切长度",
                dataIndex: "lengthAfterSawing",
                key: "LengthAfterSawing",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "大切宽度",
                dataIndex: "widthAfterSawing",
                key: "WidthAfterSawing",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "大切扣尺长",
                dataIndex: "deductedLengthAfterSawing",
                key: "deductedLengthAfterSawing",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "大切扣尺宽",
                dataIndex: "deductedWidthAfterSawing",
                key: "deductedWidthAfterSawing",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "补胶长度",
                dataIndex: "lengthAfterFilling",
                key: "LengthAfterFilling",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "补胶宽度",
                dataIndex: "widthAfterFilling",
                key: "WidthAfterFilling",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "补胶扣尺长",
                dataIndex: "deductedLengthAfterFilling",
                key: "deductedLengthAfterFilling",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "补胶扣尺宽",
                dataIndex: "deductedWidthAfterFilling",
                key: "deductedWidthAfterFilling",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "磨抛长度",
                dataIndex: "lengthAfterPolishing",
                key: "LengthAfterPolishing",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "磨抛宽度",
                dataIndex: "widthAfterPolishing",
                key: "WidthAfterPolishing",
                align: "center",
                sorter: true,
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "磨抛扣尺长",
                dataIndex: "deductedLengthAfterPolishing",
                key: "deductedLengthAfterPolishing",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "磨抛扣尺宽",
                dataIndex: "deductedWidthAfterPolishing",
                key: "deductedWidthAfterPolishing",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "大切备注",
                dataIndex: "sawingNote",
                key: "sawingNote",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "补胶备注",
                dataIndex: "fillingNote",
                key: "fillingNote",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "磨抛备注",
                dataIndex: "polishingNote",
                key: "polishingNote",
                align: "center",
                showByDefault: false,
                alwaysShow: false
            },
            {
                title: "面积",
                dataIndex: "area",
                key: "area",
                align: "center",
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "类型",
                dataIndex: "type",
                key: "type",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: type => {
                    return SlabType.getType(type).text;
                }
            },
            {
                title: "补磨方式",
                dataIndex: "manufacturingMethod",
                key: "manufacturingMethod",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: manufacturingMethod => {
                    return ManufacturingMethod.getManufacturingMethod(
                        manufacturingMethod
                    ).text;
                }
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: status => {
                    return SlabBundleStatus.getStatus(status).text;
                }
            },
            {
                title: "生产状态",
                dataIndex: "manufacturingState",
                key: "manufacturingState",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: ms => {
                    return ManufacturingState.getState(ms).text;
                }
            },
            {
                title: "加工工艺",
                dataIndex: "appliedProcessesString",
                key: "appliedProcessesString",
                align: "center",
                showByDefault: true,
                alwaysShow: false
            },
            {
                title: "入库日期",
                dataIndex: "stockInTime",
                key: "stockInTime",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: stockInTime => {
                    return Util.formatDate(stockInTime);
                }
            },
            {
                title: "出库日期",
                dataIndex: "stockOutTime",
                key: "stockOutTime",
                align: "center",
                showByDefault: false,
                alwaysShow: false,
                render: stockOutTime => {
                    return Util.formatDate(stockOutTime);
                }
            },
            {
                title: "区域",
                dataIndex: "stockingAreaId",
                key: "stockingAreaId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: stockingAreaId =>
                    this.getShowStockingArea(stockingAreaId)
            }
        ];
    };
}

export default PageStoneBundleList;
