import Icon from "@ant-design/icons";
import {
    Button,
    Checkbox,
    Col,
    Collapse,
    DatePicker,
    Form,
    FormInstance,
    Input,
    message,
    Pagination,
    Row,
    Select,
    Table,
    Tag
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import Consts, {
    formItemLayoutForCheckboxGroup,
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../components/Consts";
import ExportUtil from "../../components/ExportUtil";
import Settings from "../../components/SettingInfo";
import SettingsDefinition from "../../components/SettingsDefinition";
import StoneArtifactUtil from "../../components/StoneArtifactUtil";
import Util from "../../components/Util";
import AddedToPO from "../../images/addedtopo.svg";
import CurrencySvg from "../../images/currency.svg";
import Loaded from "../../images/loaded.svg";
import Shipped from "../../images/shipped.svg";
import Store from "./store";

// tslint:disable-next-line:no-submodule-imports
import { CheckboxOptionType } from "antd/lib/checkbox/Group";
// tslint:disable-next-line:no-implicit-dependencies
import moment from "moment";
import Perm from "../../app/Perm";
import {
    IAppliedPriceItemDTO,
    ISalesOrderDetailDTO,
    PaymentOrderStatus
} from "../../app/WebAPIClients";
import SearchButtonGroup from "../../components/SearchButtonGroup";
import { IPaymentOrderListWithDetails } from "../../components/type";
import PermCtrl from "../../components/PermCtrl";
import { TableRowSelection } from "antd/lib/table/interface";
moment.locale("zh-cn");

const FormItem = Form.Item;
const CheckboxGroup = Checkbox.Group;
const Option = Select.Option;
const {
    Customers,
    PaymentOrderStatusOptions,
    SlabType,
    StoneCategories,
    StoneGrades
} = Consts;
const Panel = Collapse.Panel;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";

declare interface IPageExportPaymentOrdersProps
    extends RouteComponentProps<{}> {}

declare interface IPageExportPaymentOrdersState {
    customerOptions: any;
    checkBoxOptions: CheckboxOptionType[];
    poExpandedArr: number[];
    categoryList: IIdNameItem[];
    gradeList: IIdNameItem[];
    poStatusOptions: IOption[];
    enableApprovalPO: boolean;
    selectedOrderKeys: React.Key[];
    selectedOrders: IPaymentOrderListWithDetails[];
    enableBatchExport: boolean;
}

@observer
class PageExportPaymentOrders extends React.Component<
    IPageExportPaymentOrdersProps,
    IPageExportPaymentOrdersState
> {
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            customerOptions: [],
            checkBoxOptions: [],
            poExpandedArr: [],
            categoryList: [],
            gradeList: [],
            enableApprovalPO: Settings.getBoolValue(
                SettingsDefinition.EnableApprovalPOKey
            ),
            poStatusOptions: [],
            selectedOrderKeys: [],
            selectedOrders: [],
            enableBatchExport: false
        };
    }

    public async componentDidMount() {
        const t = this;
        const categoryList = await StoneCategories.getCategories();
        const gradeList = await StoneGrades.getGrades();
        const customers = await Customers.getCustomers();
        const customerOptions = customers.map(c => {
            return (
                <Option key={c.id} value={c.id}>
                    {c.name}
                </Option>
            );
        });

        const poStatusOptions =
            Settings.getBoolValue(SettingsDefinition.EnableApprovalPOKey) ===
            true
                ? PaymentOrderStatusOptions.dataWithApproval
                : PaymentOrderStatusOptions.data;
        const checkBoxOptions = poStatusOptions.map(status => {
            return { label: status.text, value: status.value.toString() };
        });
        t.setState({
            customerOptions,
            checkBoxOptions,
            categoryList,
            gradeList,
            poStatusOptions
        });
        t.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const s = t.state;
        const poList = Store.paymentOrderList;
        const filterData = Store.filterData;
        const hasExportPermission = PermCtrl.isAuthorized(Perm.SS_E);

        const { enableBatchExport, selectedOrderKeys, selectedOrders } = s;

        let rowSelection: TableRowSelection<IPaymentOrderListWithDetails> = null;
        let enableBatchExportButton: JSX.Element = null;

        const columns: any = [
            {
                title: "结算单编号",
                dataIndex: "orderNumber",
                key: "orderNumber",
                align: "center"
            },
            {
                title: "客户名称",
                dataIndex: "customerName",
                key: "customerName",
                align: "center"
            },
            {
                title: "结算项数量",
                dataIndex: "itemsCount",
                key: "itemsCount",
                align: "center"
            },
            {
                title: "总金额(元)",
                dataIndex: "totalAmount",
                key: "totalAmount",
                align: "center"
            },
            {
                title: "应收金额(元)",
                dataIndex: "receivableAmount",
                key: "receivableAmount",
                render: (text, record) => {
                    return record.status === 40
                        ? record.receivableAmount
                        : null;
                },
                align: "center"
            },
            {
                title: "状态",
                dataIndex: "status",
                key: "status",
                align: "center",
                render: status => {
                    return this.getPOStatusText(status);
                }
            },
            {
                title: "创建日期",
                dataIndex: "createdTime",
                key: "createdTime",
                align: "center",
                render: createdTime => {
                    return Util.formatDate(createdTime);
                }
            },
            {
                title: "结算日期",
                dataIndex: "paymentReceivedTime",
                key: "paymentReceivedTime",
                align: "center",
                render: paymentReceivedTime => {
                    return Util.formatDate(paymentReceivedTime);
                }
            }
        ];

        if (hasExportPermission) {
            enableBatchExportButton = (
                <Button onClick={t.handleBatchExportChange}>
                    {enableBatchExport ? "关闭批量导出" : "启用批量导出"}
                </Button>
            );

            if (enableBatchExport) {
                rowSelection = {
                    type: "checkbox",
                    selectedRowKeys: selectedOrderKeys,
                    onChange: (
                        selectedRowKeys: React.Key[],
                        selectedRows: IPaymentOrderListWithDetails[]
                    ) => {
                        t.setState({
                            selectedOrderKeys: selectedRowKeys,
                            selectedOrders: selectedRows
                        });
                    },
                    preserveSelectedRowKeys: true
                };
            } else {
                const actionColumns = [
                    {
                        title: "操作",
                        key: "action",
                        align: "center",
                        render: (text, record) => {
                            return (
                                <span>
                                    <a
                                        onClick={t.handlePrintPO.bind(
                                            t,
                                            record
                                        )}
                                    >
                                        导出结算单
                                    </a>
                                </span>
                            );
                        }
                    }
                ];

                columns.push(...actionColumns);
            }
        }

        return (
            <div>
                <div>
                    <Collapse defaultActiveKey={["1"]}>
                        <Panel header="高级搜索" key="1">
                            <Form
                                ref={this.formRef}
                                name={"paymentOrderAdvancedSearchForm"}
                                onFinish={t.handleSearch}
                                {...formItemLayoutForSearch}
                            >
                                <Row>
                                    <Col span={24}>
                                        <FormItem
                                            {...formItemLayoutForCheckboxGroup}
                                            label="状态"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <CheckboxGroup
                                                options={s.checkBoxOptions}
                                                value={filterData.statusCodes}
                                                onChange={
                                                    t.handleSelectedStatusChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="结算单号"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Input
                                                placeholder="请输入结算单号"
                                                value={filterData.orderNumber}
                                                onChange={
                                                    t.handleOrderNumberChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="客户名称"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <Select
                                                allowClear={true}
                                                mode="multiple"
                                                style={{ width: "100%" }}
                                                optionFilterProp="children"
                                                placeholder="请选择客户"
                                                value={filterData.customerIds}
                                                onChange={
                                                    t.handleSelectedCustomerChange
                                                }
                                            >
                                                {s.customerOptions}
                                            </Select>
                                        </FormItem>
                                    </Col>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="结算日期"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <RangePicker
                                                style={{ width: "100%" }}
                                                format={dateFormat}
                                                value={Util.getMomentArray(
                                                    filterData.startDate,
                                                    filterData.endDate
                                                )}
                                                onChange={t.handleDateChange}
                                                placeholder={[
                                                    "开始日期",
                                                    "结束日期"
                                                ]}
                                            />
                                        </FormItem>
                                    </Col>
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label="创建日期"
                                            style={{ marginBottom: "10px" }}
                                        >
                                            <RangePicker
                                                style={{ width: "100%" }}
                                                format={dateFormat}
                                                value={Util.getMomentArray(
                                                    filterData.createStartDate,
                                                    filterData.createEndDate
                                                )}
                                                onChange={
                                                    t.handleCreateDateChange
                                                }
                                                placeholder={[
                                                    "开始日期",
                                                    "结束日期"
                                                ]}
                                            />
                                        </FormItem>
                                    </Col>
                                </Row>
                                <SearchButtonGroup
                                    onClearClick={t.handleClearSearchCriteria}
                                    extraInfo={enableBatchExportButton}
                                />
                            </Form>
                        </Panel>
                    </Collapse>
                    {enableBatchExport ? (
                        <Row align="middle" style={{ margin: "1%" }}>
                            <Col span={18}>
                                <div>
                                    {selectedOrders.map(o => {
                                        const { id, orderNumber } = o;
                                        return (
                                            <Tag
                                                key={id}
                                                closable={true}
                                                onClose={this.deselectOrder.bind(
                                                    t,
                                                    id
                                                )}
                                                style={{ margin: "6px 2px" }}
                                                color="blue"
                                            >
                                                {orderNumber}
                                            </Tag>
                                        );
                                    })}
                                </div>
                            </Col>
                            <Col span={6}>
                                <div
                                    style={{
                                        textAlign: "right"
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        disabled={
                                            selectedOrderKeys.length === 0
                                        }
                                        onClick={t.handleBatchExportClick}
                                    >
                                        批量导出
                                    </Button>
                                    <Button
                                        danger={true}
                                        disabled={
                                            selectedOrderKeys.length === 0
                                        }
                                        onClick={
                                            t.handleClearBatchExportSelectionClick
                                        }
                                    >
                                        清空选择
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    ) : null}
                </div>
                <Table
                    columns={columns}
                    dataSource={poList}
                    rowKey={"id"}
                    pagination={false}
                    expandedRowRender={t.showPOItems}
                    expandedRowKeys={s.poExpandedArr}
                    onExpand={t.handleRowExpanded}
                    rowSelection={rowSelection}
                />
                {Store.pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={Store.pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString.bind(
                                t,
                                Store.pagingInfo.totalRecordCount
                            )}
                            pageSize={Store.pageSize}
                            current={Store.pageNo}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </div>
        );
    }

    private getPOStatusText(value: number) {
        const statusOptions = this.state.poStatusOptions.find(
            sos => sos.value === value
        );
        return Util.isDefinedAndNotNull(statusOptions)
            ? statusOptions.text
            : "";
    }

    private getTotalRecordString = (value: number): string => {
        return `共 ${value} 个结算单`;
    };

    private handlePaginationChange = async (pageNo: number): Promise<void> => {
        Store.setPageNo(pageNo);
        await this.loadData();
    };

    private handleSearch = async () => {
        Store.setPageNo(1);
        this.setState({ poExpandedArr: [] });
        await this.loadData();
    };

    private handleClearSearchCriteria = async () => {
        Store.setPageNo(1);
        Store.setPageSize(10);
        Store.updataFilterData({});
        this.setState({ poExpandedArr: [] });
        await this.loadData();
    };

    private handleSelectedCustomerChange = (value: number[]) => {
        Store.updataFilterData({ ...Store.filterData, customerIds: value });
    };

    private handleSelectedStatusChange = (value: number[]) => {
        Store.updataFilterData({ ...Store.filterData, statusCodes: value });
    };

    private handleOrderNumberChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        Store.updataFilterData({
            ...Store.filterData,
            orderNumber: event.target.value
        });
    };

    private async loadData() {
        await Store.loadData();
    }

    private handlePrintPO(po: IPaymentOrderListWithDetails) {
        if (!po) {
            message.info("请选择需要导出的内容！");
            return false;
        }

        const exportAPI: string = "PaymentOrder/Print?ids=" + po.id;

        message.info("正在导出结算单以供下载并打印...");
        ExportUtil.setExportUrl(exportAPI);
    }

    private handleRowExpanded = async (
        expanded: boolean,
        po: IPaymentOrderListWithDetails
    ): Promise<void> => {
        const t = this;
        const s = t.state;
        const id = po.id;

        const expandedArr = Util.updateExpandedArray(
            s.poExpandedArr,
            po.id,
            expanded
        );
        t.setState({
            poExpandedArr: expandedArr
        });
        if (expanded && po && (!po.items || !po.appliedPrices)) {
            await Store.getPaymentOrder(id);
        }
    };

    private showPOItems = (po: IPaymentOrderListWithDetails): JSX.Element => {
        const t = this;
        const s = t.state;
        const poItems = po.items;
        let blockItems = [];
        let bundleItems = [];
        let tileItems = [];
        if (poItems) {
            blockItems = StoneArtifactUtil.getBlockItems(poItems);
            bundleItems = StoneArtifactUtil.getBundleItems(poItems);
            tileItems = StoneArtifactUtil.getTilePackingCaseItems(poItems);
        }

        const showBlockItems =
            blockItems && blockItems.length && blockItems.length > 0;
        const showBundleItems =
            bundleItems && bundleItems.length && bundleItems.length > 0;
        const showTPCItems =
            tileItems && tileItems.length && tileItems.length > 0;
        const showAPItems =
            po.appliedPrices && po.appliedPrices && po.appliedPrices.length > 0;
        const blockColumns: any = [
            {
                title: "荒料编号",
                key: "number",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return item.block.blockNumber;
                }
            },
            {
                title: "品名",
                key: "categoryName",
                align: "center",
                render: item => {
                    return Util.getItemName(
                        item.block.categoryId,
                        s.categoryList
                    );
                }
            },
            {
                title: "长 x 高 x 宽(mm)",
                key: "spec",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    const block = item.block;
                    return (
                        block.validatedLength +
                        " x " +
                        block.validatedHeight +
                        " x " +
                        block.validatedWidth
                    );
                }
            },
            {
                title: "等级",
                key: "grade",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    const block = item.block;
                    return Util.getItemName(block.gradeId, s.gradeList);
                }
            }
        ];
        const bundleColumns: any = [
            {
                title: "荒料编号",
                key: "blockNumber",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return item.bundle.prefix;
                }
            },
            {
                title: "扎号",
                key: "bundleNo",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return (
                        item.bundle.totalBundleCount +
                        "-" +
                        item.bundle.bundleNo
                    );
                }
            },
            {
                title: "品名",
                key: "categoryId",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return Util.getItemName(
                        item.bundle.categoryId,
                        s.categoryList
                    );
                }
            },
            {
                title: "厚度(mm)",
                key: "thickness",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return item.bundle.thickness;
                }
            },
            {
                title: "长 x 宽(mm)",
                key: "spec",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    const bundle = item.bundle;
                    return (
                        bundle.lengthAfterStockIn +
                        " x " +
                        bundle.widthAfterStockIn
                    );
                }
            },
            {
                title: "类型",
                key: "type",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return SlabType.getType(item.type).text;
                }
            }
        ];

        const bundleGradeColumn = {
            title: "等级",
            key: "grade",
            align: "center",
            render: (item: ISalesOrderDetailDTO) => {
                return Util.getItemName(item.bundle.gradeId, s.gradeList);
            }
        };

        if (PermCtrl.isAuthorized(Perm.SB_G_R)) {
            bundleColumns.splice(5, 0, bundleGradeColumn);
        }

        const tileColumns: any = [
            {
                title: "工程名称",
                key: "proName",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return item.tilePackingCase.projectName;
                }
            },
            {
                title: "包装箱号",
                key: "caseNumber",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return item.tilePackingCase.caseNumber;
                }
            },
            {
                title: "总片数",
                key: "totalTileCount",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return item.tilePackingCase.totalTileCount;
                }
            }
        ];
        const publicColumns = [
            {
                title: "数量",
                key: "quantity",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    return Util.round(item.quantity - item.deductedQuantity, 4);
                }
            },
            {
                title: "单价(元)",
                key: "price",
                dataIndex: "price",
                align: "center"
            },
            {
                title: "总金额(元)",
                key: "subTotal",
                dataIndex: "subTotal",
                align: "center"
            },
            {
                title: "状态",
                key: "status",
                align: "center",
                render: (item: ISalesOrderDetailDTO) => {
                    let icon = null;
                    icon = item.hasShipped ? (
                        <Icon
                            component={Shipped}
                            className="iconShipped"
                            title={"已出厂"}
                        />
                    ) : item.hasLoaded ? (
                        <Icon
                            component={Loaded}
                            className="iconLoaded"
                            title={"已装车"}
                        />
                    ) : null;

                    if (item.paid || item.addedToPO) {
                        const paidIcon = item.paid ? (
                            <Icon
                                component={CurrencySvg}
                                className="iconPaid"
                                title={"已结算"}
                            />
                        ) : item.addedToPO ? (
                            <Icon
                                component={AddedToPO}
                                className="iconAddedToPO"
                                title={"等待确认结算"}
                            />
                        ) : null;

                        if (icon === null) {
                            icon = paidIcon;
                        } else {
                            icon = (
                                <div className="iconStyl">
                                    {paidIcon}
                                    {icon}
                                </div>
                            );
                        }
                    }
                    return po.status !== PaymentOrderStatus.Cancelled
                        ? icon
                        : null;
                }
            },
            {
                title: "销售订单号",
                key: "salesOrderNumber",
                align: "center",
                dataIndex: "salesOrderNumber"
            }
        ];
        blockColumns.push(...publicColumns);
        bundleColumns.push(...publicColumns);
        tileColumns.push(...publicColumns);

        const apCoulums: any = [
            {
                title: "名称",
                key: "name",
                dataIndex: "name",
                align: "center"
            },
            {
                title: "数量",
                key: "quantity",
                align: "center",
                render: (item: IAppliedPriceItemDTO) => {
                    return `${item.quantity} (${item.unit})`;
                }
            },
            {
                title: "单价(元)",
                key: "unitPrice",
                dataIndex: "unitPrice",
                align: "center"
            },
            {
                title: "总价(元)",
                key: "subtotal",
                dataIndex: "subtotal",
                align: "center"
            },
            {
                title: "类型",
                key: "type",
                dataIndex: "type",
                align: "center"
            }
        ];
        return (
            <div>
                {!(
                    showBlockItems ||
                    showBundleItems ||
                    showTPCItems ||
                    showAPItems
                ) ? (
                    <p className="notice">没有明细信息</p>
                ) : (
                    <Collapse
                        defaultActiveKey={["0", "1", "2", "3"]}
                        style={{ marginLeft: "40px" }}
                    >
                        {showBlockItems ? (
                            <Panel header="荒料列表" key="0">
                                <Table
                                    style={{ background: "#f0fdff" }}
                                    rowKey="id"
                                    size="middle"
                                    columns={blockColumns}
                                    dataSource={blockItems}
                                    pagination={false}
                                />
                            </Panel>
                        ) : null}
                        {showBundleItems ? (
                            <Panel header="大板列表" key="1">
                                <Table
                                    style={{ background: "#f0fdff" }}
                                    rowKey="id"
                                    size="middle"
                                    columns={bundleColumns}
                                    dataSource={bundleItems}
                                    pagination={false}
                                />
                            </Panel>
                        ) : null}
                        {showTPCItems ? (
                            <Panel header="工程板列表" key="2">
                                <Table
                                    style={{ background: "#f0fdff" }}
                                    rowKey="id"
                                    size="middle"
                                    columns={tileColumns}
                                    dataSource={tileItems}
                                    pagination={false}
                                />
                            </Panel>
                        ) : null}
                        {showAPItems ? (
                            <Panel header="其它收费" key="3">
                                <Table
                                    style={{ background: "#f0fdff" }}
                                    rowKey="id"
                                    size="middle"
                                    columns={apCoulums}
                                    dataSource={po.appliedPrices}
                                    pagination={false}
                                />
                            </Panel>
                        ) : null}
                    </Collapse>
                )}
            </div>
        );
    };

    private handleDateChange(dates, dateStrings) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        Store.updataFilterData({ ...Store.filterData, startDate, endDate });
    }

    private handleCreateDateChange(dates, dateStrings) {
        const createStartDate = dateStrings[0];
        const createEndDate = dateStrings[1];
        Store.updataFilterData({
            ...Store.filterData,
            createStartDate,
            createEndDate
        });
    }

    private deselectOrder(id: number) {
        const t = this;
        const s = t.state;
        if (!id) {
            return;
        }

        const newKeys = [...s.selectedOrderKeys].filter(poId => poId !== id);
        const newOrders = [...s.selectedOrders].filter(o => o.id !== id);
        t.setState({ selectedOrderKeys: newKeys, selectedOrders: newOrders });
    }

    private handleBatchExportChange = () => {
        const s = this.state;
        const { enableBatchExport } = s;
        if (enableBatchExport) {
            this.setState({
                selectedOrderKeys: [],
                selectedOrders: [],
                enableBatchExport: false
            });
        } else {
            this.setState({ enableBatchExport: true });
        }
    };

    private handleClearBatchExportSelectionClick = () => {
        this.setState({
            selectedOrderKeys: [],
            selectedOrders: []
        });
    };

    private handleBatchExportClick = () => {
        const t = this;
        const s = t.state;
        const { selectedOrderKeys } = s;

        const poIdQuery = selectedOrderKeys.join("&ids=");

        const exportAPI: string = "PaymentOrder/Print?ids=" + poIdQuery;

        message.info("正在批量导出结算单...");
        ExportUtil.setExportUrl(exportAPI);
    };
}

export default PageExportPaymentOrders;
