import { Descriptions } from "antd";
import React from "react";
import { WorkOrderFullInfoDTO } from "../../../app/WebAPIClients";
import BlockUtil from "../../../components/BlockUtil";
import { BlockDimensionType } from "../../../components/type";
import Util from "../../../components/Util";

interface IMaterialRequisitionInfoProps {
    wo: WorkOrderFullInfoDTO;
    enableBlockNumberFromQuarry: boolean;
    categoryList: IIdNameItem[];
    gradeList: IIdNameItem[];
}

export default class MaterialRequisitionInfo extends React.PureComponent<
    IMaterialRequisitionInfoProps
> {
    public render() {
        const t = this;
        const {
            wo,
            enableBlockNumberFromQuarry,
            categoryList,
            gradeList
        } = t.props;

        const block =
            Util.isDefinedAndNotNull(wo.materialRequisition) &&
            Util.isDefinedAndNotNull(wo.materialRequisition.block)
                ? wo.materialRequisition.block
                : null;
        return (
            <div>
                {wo.materialRequisition ? (
                    <Descriptions
                        column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                        title={"领料单信息"}
                    >
                        {Util.isDefinedAndNotNull(block) ? (
                            <Descriptions.Item label="荒料编号">
                                {block.blockNumber}
                            </Descriptions.Item>
                        ) : null}
                        {enableBlockNumberFromQuarry &&
                        Util.isDefinedAndNotNull(block) ? (
                            <Descriptions.Item label="矿山荒料号">
                                {block.blockNumberFromQuarry}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(block) ? (
                            <Descriptions.Item label="石材种类">
                                {Util.getItemName(
                                    block.categoryId,
                                    categoryList
                                )}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(block) ? (
                            <Descriptions.Item label="等级">
                                {Util.getItemName(block.gradeId, gradeList)}
                            </Descriptions.Item>
                        ) : null}
                        {BlockUtil.ShowBlockDimensionField(
                            block,
                            BlockDimensionType.QuarryReported,
                            "收方"
                        )}
                        {BlockUtil.ShowBlockDimensionField(
                            block,
                            BlockDimensionType.Validated,
                            "入库"
                        )}
                        {Util.isDefinedAndNotNull(block) ? (
                            <Descriptions.Item label="收方体积">
                                {Util.calculateVolume(
                                    block.quarryReportedLength,
                                    block.quarryReportedWidth,
                                    block.quarryReportedHeight
                                )}
                                {" 立方"}
                            </Descriptions.Item>
                        ) : null}
                        <Descriptions.Item label="领料人">
                            {wo.materialRequisition.creator}
                        </Descriptions.Item>
                        <Descriptions.Item label="领料时间">
                            {Util.formatDateTime(
                                wo.materialRequisition.createdTime
                            )}
                        </Descriptions.Item>
                        {Util.isDefinedAndNotNull(block.stockOutOperator) ? (
                            <Descriptions.Item label="审批人">
                                {block.stockOutOperator}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isDefinedAndNotNull(block.stockOutOperator) ? (
                            <Descriptions.Item label="审批时间">
                                {Util.formatDateTime(block.stockOutTime)}
                            </Descriptions.Item>
                        ) : null}
                        {Util.isNotNullAndNotEmpty(
                            wo.materialRequisition.notes
                        ) ? (
                            <Descriptions.Item label="备注">
                                {wo.materialRequisition.notes}
                            </Descriptions.Item>
                        ) : null}
                    </Descriptions>
                ) : (
                    <div style={{ textAlign: "center" }}>无领料单数据</div>
                )}
            </div>
        );
    }
}
