import {
    Cascader,
    Col,
    Collapse,
    DatePicker,
    Form,
    FormInstance,
    Input,
    Pagination,
    Row,
    Select,
    Table,
    Tabs
} from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import Perm from "../../../app/Perm";
import {
    StockInAndOutRecordType,
    StoneArtifactType,
    WarehouseType
} from "../../../app/WebAPIClients";
import Consts, {
    formItemLayoutForCol,
    formItemLayoutForSearch
} from "../../../components/Consts";
import PermCtl from "../../../components/PermCtrl";
import SearchButtonGroup from "../../../components/SearchButtonGroup";
import Settings from "../../../components/SettingInfo";
import SettingsDefinition from "../../../components/SettingsDefinition";
import StockInAndOutRecordUtil from "../../../components/StockInAndOutRecordUtil";
import StockingAreaUtil from "../../../components/StockingAreaUtil";
import Util from "../../../components/Util";
import Store, { IFilterData } from "./store";

const { TabPane } = Tabs;
const Panel = Collapse.Panel;
const FormItem = Form.Item;
const Option = Select.Option;
const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const {
    Factories,
    ProductStockingAreas,
    Warehouses,
    StockInAndOutRecordTypeOptions
} = Consts;

declare interface IProductRecordListProps extends RouteComponentProps<{}> {}

declare interface IProductRecordListState {
    factories: any; // 工厂
    warehouseList: any; // 仓库
    warehouseOptions: any;

    psList: any; // 荒料库存区域
    psOptions: any; // 选择荒料库存区域
    enableBlockNumberFromQuarry: boolean;
    uniqueWarehouse: boolean;
    recordTypeOptions?: any[];
}

@observer
class PageProductRecordList extends React.Component<
    IProductRecordListProps,
    IProductRecordListState
> {
    private bundleTabKey: string = StoneArtifactType.StoneBundle.toString();
    private slabTabKey: string = StoneArtifactType.Slab.toString();
    private tpcTabKey: string = StoneArtifactType.Tile.toString();
    private formRef: React.RefObject<FormInstance>;
    constructor(props) {
        super(props);
        this.formRef = React.createRef();
        this.state = {
            factories: [],
            warehouseOptions: [],
            warehouseList: [],
            psList: [], // 成品库存区域
            psOptions: [], // 选择成品库存区域
            enableBlockNumberFromQuarry: Settings.getBoolValue(
                SettingsDefinition.EnableBlockNumberFromQuarryKey
            ),
            uniqueWarehouse: false
        };
    }

    public async componentDidMount() {
        const t = this;
        const s = t.state;
        const psList = await ProductStockingAreas.getStockingAreas();
        const factories = await Factories.getFactories(); // 工厂列表
        let warehouseList = await Warehouses.getWarehouses(); // 仓库列表
        warehouseList = warehouseList.filter(
            w => w.type === WarehouseType.Product
        );

        const recordTypeOptions = StockInAndOutRecordTypeOptions.data.map(d => {
            return (
                <Option key={d.value as number} value={d.value as number}>
                    {d.text}
                </Option>
            );
        });

        const warehouseOptions = StockingAreaUtil.generateWarehouseCascadData(
            warehouseList,
            factories
        );

        const uniqueWarehouse = warehouseList.length === 1;

        const warehouseId = Util.parseToInt(
            Util.getCascaderValue(
                Store.stoneArtifactType === StoneArtifactType.StoneBundle
                    ? Store.filterData.bundleWarehouse
                    : Store.stoneArtifactType === StoneArtifactType.Slab
                    ? Store.filterData.slabWarehouse
                    : Store.filterData.tpcWarehouse,
                1
            )
        );

        const psOptions = StockingAreaUtil.getStockingAreaOptions(
            uniqueWarehouse,
            warehouseId,
            psList,
            warehouseOptions
        );

        t.setState({
            psList,
            psOptions,
            warehouseOptions,
            uniqueWarehouse,
            recordTypeOptions
        });

        await t.loadData();
    }

    public render(): JSX.Element {
        const t = this;
        const tabsArr = [];
        if (PermCtl.isAuthorized(Perm.S_R)) {
            tabsArr.push(t.getShowJSX("扎", t.bundleTabKey));
            tabsArr.push(t.getShowJSX("大板", t.slabTabKey));
        }
        if (PermCtl.isAuthorized(Perm.TPC_R)) {
            tabsArr.push(t.getShowJSX("工程板", t.tpcTabKey));
        }

        if (!Util.isNotNullAndNotEmptyArray(tabsArr)) {
            return <span />;
        }

        return (
            <Tabs
                defaultActiveKey={t.bundleTabKey}
                onChange={t.handleTabChanged}
                activeKey={Store.stoneArtifactType?.toString()}
            >
                {tabsArr}
            </Tabs>
        );
    }

    private getShowJSX = (label: string, key: string): JSX.Element => {
        const t = this;
        const s = t.state;
        const recordList = Store.recordList;
        const filterData = Store.filterData;
        const psOptions = s.psOptions;

        const columns: any = [
            {
                title: "序号",
                dataIndex: "number",
                key: "number",
                width: "5%",
                align: "center"
            },
            {
                title: t.getColumnTitle(),
                dataIndex: "title",
                key: "title",
                width: "15%",
                align: "center"
            },
            {
                title: "规格",
                dataIndex: "spec",
                key: "spec",
                width: "25%",
                align: "center"
            },
            {
                title: "方量(m²)",
                dataIndex: "quantity",
                key: "quantity",
                align: "center"
            },
            {
                title: "类型",
                dataIndex: "recordType",
                key: "recordType",
                align: "center",
                render: type => {
                    return StockInAndOutRecordUtil.getStockInAndOutRecordTypeText(
                        type
                    );
                }
            },
            {
                title: "发生日期",
                dataIndex: "transactionDate",
                key: "transactionDate",
                align: "center",
                render: transactionDate => {
                    return Util.formatDate(transactionDate);
                }
            },
            {
                title: "来源",
                dataIndex: "source",
                key: "source"
            },
            {
                title: "操作人",
                dataIndex: "operator",
                key: "operator"
            },
            {
                title: "区域",
                dataIndex: "stockingAreaId",
                key: "stockingAreaId",
                align: "center",
                showByDefault: true,
                alwaysShow: false,
                render: stockingAreaId =>
                    this.getShowStockingArea(stockingAreaId)
            }
        ];

        const blockNumberLable =
            key === t.bundleTabKey
                ? "bundleSelectBlockNumber"
                : "slabSelectBlockNumber";

        const blockNumberFromQuarryLable =
            key === t.bundleTabKey
                ? "bundleSelectBlockNumberFromQuarry"
                : "slabSelectBlockNumberFromQuarry";

        const itemNumber =
            key === t.bundleTabKey
                ? filterData.bundleNo
                : key === t.slabTabKey
                ? filterData.sequenceNumber
                : filterData.tpcCaseNumber;

        const itemNumberLable =
            key === t.bundleTabKey
                ? "bundleNo"
                : key === t.slabTabKey
                ? "sequenceNumber"
                : "tpcCaseNumber";

        const recordType =
            key === t.bundleTabKey
                ? filterData.bundleRecordType
                : key === t.slabTabKey
                ? filterData.slabRecordType
                : filterData.tpcRecordType;

        const stockingAreaIds =
            key === t.bundleTabKey
                ? filterData.bundleStockingAreaIds
                : key === t.slabTabKey
                ? filterData.slabStockingAreaIds
                : filterData.tpcStockingAreaIds;

        const stockingAreaIdsLable =
            key === t.bundleTabKey
                ? "bundleStockingAreaIds"
                : key === t.slabTabKey
                ? "slabStockingAreaIds"
                : "tpcStockingAreaIds";

        const selectWarehouse =
            key === t.bundleTabKey
                ? filterData.bundleWarehouse
                : key === t.slabTabKey
                ? filterData.slabWarehouse
                : filterData.tpcWarehouse;

        const dateChangeFun =
            key === t.bundleTabKey
                ? t.handleBundleDateChange
                : key === t.slabTabKey
                ? t.handleSlabDateChange
                : t.handleTPCDateChange;

        const dateValue =
            key === t.bundleTabKey
                ? Util.getMomentArray(
                      filterData.bundleStartDate,
                      filterData.bundleEndDate
                  )
                : key === t.slabTabKey
                ? Util.getMomentArray(
                      filterData.slabStartDate,
                      filterData.slabEndDate
                  )
                : Util.getMomentArray(
                      filterData.tpcStartDate,
                      filterData.tpcEndDate
                  );

        return (
            <TabPane tab={label} key={key}>
                <Collapse defaultActiveKey={["1"]}>
                    <Panel header="高级搜索" key="1">
                        <Form
                            onFinish={t.handleSearch}
                            ref={this.formRef}
                            name={"spoAdvancedSearchForm"}
                            {...formItemLayoutForSearch}
                        >
                            <Row>
                                {key === t.tpcTabKey ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="工程名称">
                                            <Input
                                                placeholder="请输入工程名称"
                                                value={
                                                    filterData.tpcProjectNumber
                                                }
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    "tpcProjectNumber"
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                ) : (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="荒料编号">
                                            <Input
                                                placeholder="请输入荒料编号"
                                                value={
                                                    key === t.bundleTabKey
                                                        ? filterData.bundleSelectBlockNumber
                                                        : filterData.slabSelectBlockNumber
                                                }
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    blockNumberLable
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                )}
                                {s.enableBlockNumberFromQuarry &&
                                key !== t.tpcTabKey ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="矿山荒料号">
                                            <Input
                                                placeholder="请输入矿山荒料号"
                                                value={
                                                    key === t.bundleTabKey
                                                        ? filterData.bundleSelectBlockNumberFromQuarry
                                                        : filterData.slabSelectBlockNumberFromQuarry
                                                }
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    blockNumberFromQuarryLable
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                ) : null}
                                {key === t.tpcTabKey ? (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="箱号">
                                            <Input
                                                placeholder="请输入工程板箱号"
                                                value={itemNumber}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    itemNumberLable
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                ) : null}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="类型">
                                        <Select
                                            allowClear={true}
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择类型"
                                            value={recordType}
                                            onChange={
                                                t.handleSelectedRecordTypeChange
                                            }
                                        >
                                            {s.recordTypeOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="发生日期">
                                        <RangePicker
                                            style={{ width: "100%" }}
                                            format={dateFormat}
                                            onChange={dateChangeFun}
                                            placeholder={[
                                                "开始日期",
                                                "结束日期"
                                            ]}
                                            value={dateValue}
                                        />
                                    </FormItem>
                                </Col>
                                {key === t.tpcTabKey ? null : (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem
                                            label={
                                                key === t.bundleTabKey
                                                    ? "扎号"
                                                    : "大板序号"
                                            }
                                        >
                                            <Input
                                                type={"number"}
                                                placeholder={
                                                    key === t.bundleTabKey
                                                        ? "请输入扎号"
                                                        : "请输入大板序号"
                                                }
                                                value={itemNumber}
                                                onChange={t.handleInputChange.bind(
                                                    t,
                                                    itemNumberLable
                                                )}
                                            />
                                        </FormItem>
                                    </Col>
                                )}
                                {s.uniqueWarehouse ? null : (
                                    <Col {...formItemLayoutForCol}>
                                        <FormItem label="仓库">
                                            <Cascader
                                                value={selectWarehouse}
                                                options={s.warehouseOptions}
                                                placeholder="请选择仓库"
                                                onChange={
                                                    t.handleWarehouseChange
                                                }
                                            />
                                        </FormItem>
                                    </Col>
                                )}
                                <Col {...formItemLayoutForCol}>
                                    <FormItem label="区域">
                                        <Select
                                            allowClear={true}
                                            mode="multiple"
                                            style={{ width: "100%" }}
                                            optionFilterProp="children"
                                            placeholder="请选择区域"
                                            value={stockingAreaIds}
                                            onChange={t.handleChange.bind(
                                                t,
                                                stockingAreaIdsLable
                                            )}
                                        >
                                            {psOptions}
                                        </Select>
                                    </FormItem>
                                </Col>
                            </Row>
                            <SearchButtonGroup
                                onClearClick={t.handleClearSearchCriteria}
                            />
                        </Form>
                    </Panel>
                </Collapse>
                <div>
                    <Table
                        columns={columns}
                        dataSource={recordList}
                        rowKey={"id"}
                        pagination={false}
                    />
                </div>
                {Store.pagingInfo ? (
                    <div className="paginationArea">
                        <Pagination
                            total={Store.pagingInfo.totalRecordCount}
                            showTotal={t.getTotalRecordString.bind(
                                t,
                                Store.pagingInfo.totalRecordCount
                            )}
                            pageSize={Store.pagingInfo.pageSize}
                            current={Store.pagingInfo.currentPage}
                            defaultCurrent={1}
                            showQuickJumper={true}
                            onChange={t.handlePaginationChange}
                            showSizeChanger={false}
                        />
                    </div>
                ) : null}
            </TabPane>
        );
    };

    private async loadData() {
        await Store.reloadData();
    }

    // 跳到第page页
    private handlePaginationChange = async (page: number) => {
        Store.setPageNo(page);
        await this.loadData();
    };

    private handleClearSearchCriteria = async () => {
        const t = this;
        const s = t.state;
        let p: IFilterData = null;
        let newState = null;

        const psOptions = StockingAreaUtil.getStockingAreaOptions(
            s.uniqueWarehouse,
            null,
            s.psList,
            s.warehouseOptions
        );
        switch (Store.stoneArtifactType) {
            case StoneArtifactType.StoneBundle:
                p = {
                    bundleSelectBlockNumber: undefined,
                    bundleSelectBlockNumberFromQuarry: undefined,
                    bundleNo: undefined,
                    bundleStockingAreaIds: [], // 库存区域
                    bundleWarehouse: [], // 工厂
                    bundleStartDate: undefined,
                    bundleEndDate: undefined,
                    bundleRecordType: undefined
                };
                newState = {
                    psOptions,
                    bundleSelectWarehouse: []
                };
                break;
            case StoneArtifactType.Slab:
                p = {
                    slabSelectBlockNumber: undefined,
                    slabSelectBlockNumberFromQuarry: undefined,
                    sequenceNumber: undefined,
                    slabStockingAreaIds: [], // 库存区域
                    slabWarehouse: [], // 工厂
                    slabStartDate: undefined,
                    slabEndDate: undefined,
                    slabRecordType: undefined
                };
                newState = {
                    psOptions,
                    slabSelectWarehouse: []
                };
                break;
            case StoneArtifactType.Tile:
                p = {
                    tpcCaseNumber: undefined,
                    tpcProjectNumber: undefined,
                    tpcStockingAreaIds: [], // 库存区域
                    tpcWarehouse: [], // 工厂
                    tpcStartDate: undefined,
                    tpcEndDate: undefined,
                    tpcRecordType: undefined
                };
                newState = {
                    psOptions,
                    tpcSelectWarehouse: []
                };
                break;
        }

        Store.setPageNo(1);
        Store.setPageSize(10);
        Store.updataFilterData({ ...Store.filterData, ...p });
        await this.loadData();
        t.setState(newState);
    };

    private handleSearch = async () => {
        Store.setPageNo(1);
        await this.loadData();
    };

    private handleInputChange(label: string, event) {
        Store.updataFilterData({
            ...Store.filterData,
            [label]: event.target.value
        });
    }

    private handleChange = (label: string, value) => {
        Store.updataFilterData({ ...Store.filterData, [label]: value });
    };

    // 选择仓库
    private handleWarehouseChange = (value: string[]) => {
        const t = this;
        const s = t.state;
        const psOptions = StockingAreaUtil.getStockingAreaOptions(
            s.uniqueWarehouse,
            parseInt(value[1]),
            s.psList,
            s.warehouseOptions
        );
        let newState = null;
        let updateNewState: IFilterData = {};
        switch (Store.stoneArtifactType) {
            case StoneArtifactType.StoneBundle:
                newState = { bundleSelectWarehouse: value, psOptions };
                updateNewState = {
                    bundleWarehouse: value,
                    bundleStockingAreaIds: []
                };
                break;
            case StoneArtifactType.Slab:
                newState = { slabSelectWarehouse: value, psOptions };
                updateNewState = {
                    slabWarehouse: value,
                    slabStockingAreaIds: []
                };
                break;
            case StoneArtifactType.Tile:
                newState = { tpcSelectWarehouse: value, psOptions };
                updateNewState = {
                    tpcWarehouse: value,
                    tpcStockingAreaIds: []
                };
                break;
        }

        t.setState(newState);
        Store.updataFilterData({ ...Store.filterData, ...updateNewState });
    };

    private getTotalRecordString(totalCount: number): string {
        return `共 ${totalCount} 条记录 共 ${Store.totalArea} m²`;
    }

    private handleBundleDateChange(dates, dateStrings) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];

        Store.updataFilterData({
            ...Store.filterData,
            bundleStartDate: startDate,
            bundleEndDate: endDate
        });
    }

    private handleSlabDateChange(dates, dateStrings) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        Store.updataFilterData({
            ...Store.filterData,
            slabStartDate: startDate,
            slabEndDate: endDate
        });
    }

    private handleTPCDateChange(dates, dateStrings) {
        const startDate = dateStrings[0];
        const endDate = dateStrings[1];
        Store.updataFilterData({
            ...Store.filterData,
            tpcStartDate: startDate,
            tpcEndDate: endDate
        });
    }

    private handleSelectedRecordTypeChange = (
        value: StockInAndOutRecordType
    ) => {
        let newState: IFilterData = {};
        switch (Store.stoneArtifactType) {
            case StoneArtifactType.StoneBundle:
                newState = {
                    bundleRecordType: value
                };
                break;
            case StoneArtifactType.Slab:
                newState = {
                    slabRecordType: value
                };
                break;
            case StoneArtifactType.Tile:
                newState = {
                    tpcRecordType: value
                };
                break;
        }

        Store.updataFilterData({
            ...Store.filterData,
            ...newState
        });
    };

    private getShowStockingArea(itemId) {
        const t = this;
        const s = t.state;

        const stockingArea = s.psList.find(b => b.id === itemId);
        if (!stockingArea) {
            return;
        }
        return StockingAreaUtil.getShowText(
            s.uniqueWarehouse,
            stockingArea,
            s.warehouseOptions
        );
    }

    private handleTabChanged = async activeKey => {
        Store.setStoneArtifactType(parseInt(activeKey));
        Store.setPageNo(1);
        await this.loadData();
    };

    private getColumnTitle = (): string => {
        const t = this;
        const s = t.state;
        let title = "";
        switch (Store.stoneArtifactType) {
            case StoneArtifactType.StoneBundle:
            case StoneArtifactType.Slab:
                title = "编号";
                break;
            case StoneArtifactType.Tile:
                title = "箱号";
                break;
        }
        return title;
    };
}

export default PageProductRecordList;
