import { action, observable, runInAction } from "mobx";
import clientFactory from "../../app/WebAPIClientFactory";
import { LoginResultDTO, TenantForListDto } from "../../app/WebAPIClients";
import Base from "../../stores/base";

class Store extends Base {
  @observable
  public loginResult: LoginResultDTO | null = null;
  @observable
  public fullTenantInfo: TenantForListDto[] = [];

  @action
  public async devLogin(userName: string, tenantAbbrCode: string) {
    const client = clientFactory.getSecurityClient();
    const callAPI = async () => {
      const loginResult: LoginResultDTO = await client.loginByUserName(
        userName,
        tenantAbbrCode,
      );
      runInAction(() => {
        this.loginResult = loginResult;
      });
    };

    await this.callAPIFun(
      callAPI,
      "正在登录...",
      "登录出错，请重试。详细信息：",
    );
  }

  @action
  public async getAllTenant() {
    const client = clientFactory.getSecurityClient();
    const callAPI = async () => {
      const tenants = await client.getAllTenants();
      runInAction(() => {
        this.fullTenantInfo = tenants;
      });
    };

    await this.callAPIFunForGet(callAPI);
  }
}

export default new Store();
