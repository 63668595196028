import { Alert, Button, Col, Collapse, Input, Row, Spin } from "antd";
import { observer } from "mobx-react";
import React from "react";
import { RouteComponentProps } from "react-router";
import { FileInfoViewModel } from "../../../../app/WebAPIClients";
import {
    colLayoutForButton,
    colLayoutForInput
} from "../../../../components/Consts";
import ErrorHandler from "../../../../components/ErrorHandler";
import "./PageImportReorganizedBundleData.styl";
import Store from "./store";
const Panel = Collapse.Panel;

declare interface IPageImportReorganizedBundleDataProps
    extends RouteComponentProps<{}> {}

declare interface IPageImportReorganizedBundleDataState
    extends IActionErrorState {
    fileName: string;
    uploading?: boolean;
}

@observer
class PageImportReorganizedBundleData extends React.Component<
    IPageImportReorganizedBundleDataProps,
    IPageImportReorganizedBundleDataState
> {
    private file: React.RefObject<HTMLInputElement>;

    constructor(props) {
        super(props);
        this.state = {
            fileName: null
        };
        this.file = React.createRef();
    }

    public render() {
        const t = this;
        const s = t.state;

        const errorMsgs = []; // 错误消息列表
        const warningMsgs = []; // 警告消息列表
        const addingSlabSucceeded = []; // 成功导入的大板信息
        const addingBundleSucceed = []; // 成功新增工程板信息
        const bundleInfoSucceeded = []; // 每一扎中现有大板信息

        const addingSlabSucceededRE = /^成功将大板移到编号为：([\S\s]*)的扎中，大板原始荒料编号：([\S\s]*)，大板序号：(\d*)$/g;
        const addingBundleSucceededRE = /^增加新扎数据，编号：([\S\s]*) ([\S\s]*)$/g;
        const bundleInfoSucceededRE = /^新扎编号为：([\S\s]*)大板原始序号：([\S\s]*)，已有大板序号：([\S\s]*) 共(\d*)片，请与此扎实际大板数量核对！$/g;

        if (Store.result) {
            Store.result.dataUpdatingMessages.forEach(msg => {
                if (msg.includes("错误：")) {
                    errorMsgs.push(msg);
                    return;
                } else if (msg.includes("警告：")) {
                    warningMsgs.push(msg);
                    return;
                }

                addingSlabSucceededRE.lastIndex = 0;
                let result = addingSlabSucceededRE.exec(msg);
                if (result) {
                    t.addMsgInfoToArray(
                        addingSlabSucceeded,
                        result[1],
                        result[2],
                        result[3]
                    );
                    return;
                }

                addingBundleSucceededRE.lastIndex = 0;
                result = addingBundleSucceededRE.exec(msg);
                if (result) {
                    if (!addingBundleSucceed.includes(result[1])) {
                        addingBundleSucceed.push(result[1]);
                    }
                    return;
                }

                bundleInfoSucceededRE.lastIndex = 0;
                result = bundleInfoSucceededRE.exec(msg);
                if (result) {
                    const detail = {
                        prefix: result[1],
                        result: msg,
                        slabSeqNumsByBlockNumber: result[2].split(";"),
                        slabNewSeqNums: result[3],
                        totalSlabCount: result[4]
                    };
                    bundleInfoSucceeded.push(detail);
                    return;
                }
            });
        }

        return (
            <div>
                <p>导入重组数据</p>
                <Row style={{ marginTop: "20px" }}>
                    <Col {...colLayoutForInput}>
                        <Input disabled={true} value={s.fileName} />
                        <input
                            type="file"
                            ref={t.file}
                            accept=".xlsx"
                            style={{ display: "none" }}
                            onChange={t.fileChanged}
                        />
                    </Col>
                    <Col {...colLayoutForButton}>
                        <Button
                            type="primary"
                            onClick={t.selectFile}
                            disabled={s.uploading}
                        >
                            选择文件
                        </Button>
                        <Button
                            onClick={t.importFile}
                            type="primary"
                            disabled={s.uploading}
                        >
                            上传并导入数据
                        </Button>
                        <Button
                            onClick={t.clearResult}
                            danger={true}
                            disabled={s.uploading}
                        >
                            清空导入结果
                        </Button>
                    </Col>
                </Row>

                {s.uploading ? (
                    <div className="importingSpinArea">
                        <p>正在上传文件并导入数据，请等待...</p>
                        <Spin />
                    </div>
                ) : null}

                {Store.result ? (
                    <div style={{ marginTop: "20px" }}>
                        <Collapse defaultActiveKey={["1", "2"]}>
                            <Panel header={"重组数据文件解析结果"} key="1">
                                {Store.result.dataParsingMessages.map(
                                    (msg, index) => {
                                        return (
                                            <Alert
                                                key={index}
                                                message={msg}
                                                type="error"
                                            />
                                        );
                                    }
                                )}
                            </Panel>
                            <Panel header={"重组数据导入结果"} key="2">
                                <Collapse defaultActiveKey={["11", "12", "13"]}>
                                    <Panel header={"成功消息"} key="11">
                                        {addingSlabSucceeded.map(mg => {
                                            const msg = t.processMsg(mg);
                                            const description = t.getMsgDescription(
                                                msg,
                                                mg,
                                                bundleInfoSucceeded
                                            );

                                            return (
                                                <Alert
                                                    key={msg.msg}
                                                    message={msg.msg}
                                                    description={description}
                                                    type="success"
                                                    showIcon={true}
                                                />
                                            );
                                        })}
                                        {addingBundleSucceed.length > 0 ? (
                                            <Alert
                                                message={
                                                    "成功增加新扎编号如下："
                                                }
                                                description={addingBundleSucceed.join(
                                                    ", "
                                                )}
                                                type="success"
                                                showIcon={true}
                                            />
                                        ) : null}
                                    </Panel>
                                    <Panel header={"警告消息"} key="12">
                                        {warningMsgs.map(msg => {
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="warning"
                                                    showIcon={true}
                                                />
                                            );
                                        })}
                                    </Panel>
                                    <Panel header={"出错消息"} key="13">
                                        {errorMsgs.map(msg => {
                                            return (
                                                <Alert
                                                    key={msg}
                                                    message={msg}
                                                    type="error"
                                                    showIcon={true}
                                                />
                                            );
                                        })}
                                    </Panel>
                                </Collapse>
                            </Panel>
                        </Collapse>
                    </div>
                ) : null}
            </div>
        );
    }

    private importFile = () => {
        const t = this;

        const fileDOM = this.file.current;

        if (fileDOM.files.length === 0) {
            ErrorHandler.handleErr("请选择一个.xlsx文件进行导入");
            return;
        }

        t.stageUploading();

        const file = fileDOM.files[0];

        const reader = new FileReader();

        reader.onload = async (evt: ProgressEvent<FileReader>) => {
            let fileContent = evt.target.result as string;
            const fileName = file.name;
            if (fileContent.length > 0 && fileContent.includes("base64,")) {
                fileContent = fileContent.split("base64,")[1];
            }
            if (fileContent.length > 0) {
                try {
                    const params = {
                        fileName,
                        fileContent
                    };
                    await Store.importReorganizedBundleData(
                        FileInfoViewModel.fromJS(params)
                    );
                    t.finishUploading();
                } catch (err) {
                    t.finishUploading();
                    ErrorHandler.handleErr(
                        "遇到不可恢复错误，请关闭工作台重新进入再试一次。详细信息：",
                        err
                    );
                }
            }
        };
        reader.readAsDataURL(file);
    };

    private selectFile = () => {
        this.file.current.click();
    };

    private fileChanged = (evt: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ fileName: evt.target.files[0].name });
    };

    private clearResult = () => {
        Store.setResult();
    };

    private stageUploading() {
        this.setState({ uploading: true });
    }

    private finishUploading() {
        this.setState({ uploading: false });
    }

    private addMsgInfoToArray(
        containerArr,
        bundlePrefix,
        blockNumber,
        sequenceNumber
    ) {
        let detail = containerArr.find(b => b.bundlePrefix === bundlePrefix);
        if (detail === undefined) {
            const sequenceNumbers = [];
            sequenceNumbers.push(sequenceNumber);
            const snGroup = {
                blockNumber,
                sequenceNumbers
            };
            const snGroups = [];
            snGroups.push(snGroup);
            detail = { bundlePrefix, snGroups };
            containerArr.push(detail);
        } else {
            const snGroup = detail.snGroups.find(
                b => b.blockNumber === blockNumber
            );

            if (snGroup === undefined) {
                const sequenceNumbers = [];
                sequenceNumbers.push(sequenceNumber);
                const newSNGroup = {
                    blockNumber,
                    sequenceNumbers
                };

                detail.snGroups.push(newSNGroup);
            } else {
                snGroup.sequenceNumbers.push(sequenceNumber);
            }
        }
    }

    private processMsg(ms) {
        if (
            !ms ||
            !ms.bundlePrefix ||
            !ms.snGroups ||
            ms.snGroups.length === 0
        ) {
            return;
        }
        const msg = "编号：" + ms.bundlePrefix + " 的扎成功添加以下大板：";
        const description = ms.snGroups.map((m, index) => {
            return (
                <p className="alertStyl" key={index}>
                    {"原始荒料编号：" +
                        m.blockNumber +
                        " " +
                        " 大板序号：" +
                        m.sequenceNumbers.join("，")}
                </p>
            );
        });
        return { msg, description };
    }

    private getMsgDescription(msg, mg, bundleInfoSucceeded) {
        if (!msg || !mg || !mg.bundlePrefix) {
            return "";
        }
        if (!bundleInfoSucceeded || bundleInfoSucceeded.length === 0) {
            return msg.description;
        }

        const bundleInfo = bundleInfoSucceeded.find(
            bi => bi.prefix === mg.bundlePrefix
        );

        if (typeof bundleInfo === "undefined" || bundleInfo === null) {
            return msg.description;
        }

        return (
            <span>
                {msg.description}
                <p className="alertStylForBundleInfo">
                    {"此扎由以下大板组成："}
                </p>
                {bundleInfo.slabSeqNumsByBlockNumber.map(ss => (
                    <p className="alertStyl">{ss}</p>
                ))}
                <p className="alertStylForBundleInfo">
                    {"现有大板序号：" + bundleInfo.slabNewSeqNums}
                </p>
                <p>
                    {"共 " +
                        bundleInfo.totalSlabCount +
                        " 片 请核对实际大板数量！"}
                </p>
            </span>
        );
    }
}

export default PageImportReorganizedBundleData;
